"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));var

showSettings = _settings.default.showSettings,tagsView = _settings.default.tagsView,fixedHeader = _settings.default.fixedHeader,sidebarLogo = _settings.default.sidebarLogo;

var state = {
  theme: _elementVariables.default.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo };


var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {var key = _ref.key,value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  } };


var actions = {
  changeSetting: function changeSetting(_ref2, data) {var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;