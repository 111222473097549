"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.generateTitle = generateTitle; // translate router.meta.title, be used in breadcrumb sidebar tagsview
function generateTitle(title) {
  /*
                                 问题：菜单栏及tagsView无法国际化
                                 原因：src/router/index.js 的route的title定义的是大写的，而src/lang/包下的语言包定义的是小写的
                                 粗略做法：将title转为小写，因为i18n的$te方法不适配大小写
                               */
  title = title.toLowerCase();
  var hasKey = this.$te('route.' + title);

  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    var translatedTitle = this.$t('route.' + title);

    return translatedTitle;
  }
  return title;
}