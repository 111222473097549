"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.number.constructor");





































var _emitter = _interopRequireDefault(require("element-ui/src/mixins/emitter"));
var _focus = _interopRequireDefault(require("element-ui/src/mixins/focus"));
var _migrating = _interopRequireDefault(require("element-ui/src/mixins/migrating")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'OgSwitch', mixins: [(0, _focus.default)('input'), _migrating.default, _emitter.default], inject: { elForm: { default: '' } }, props: { value: { type: [Boolean, String, Number], default: false }, disabled: { type: Boolean, default: false }, width: { type: Number, default: 40 }, activeIconClass: { type: String, default: '' }, inactiveIconClass: { type: String, default: '' }, activeText: String, inactiveText: String, activeColor: { type: String, default: '' }, inactiveColor: {
      type: String,
      default: '' },

    activeValue: {
      type: [Boolean, String, Number],
      default: true },

    inactiveValue: {
      type: [Boolean, String, Number],
      default: false },

    name: {
      type: String,
      default: '' },

    validateEvent: {
      type: Boolean,
      default: true },

    id: String,
    asyncChange: {
      type: Boolean,
      default: false } },


  data: function data() {
    return {
      coreWidth: this.width };

  },
  created: function created() {
    if (!~[this.activeValue, this.inactiveValue].indexOf(this.value)) {
      this.$emit('input', this.inactiveValue);
    }
  },
  computed: {
    checked: function checked() {
      return this.value === this.activeValue;
    },
    switchDisabled: function switchDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    } },

  watch: {
    checked: function checked() {
      this.$refs.input.checked = this.checked;
      if (this.activeColor || this.inactiveColor) {
        this.setBackgroundColor();
      }
      if (this.validateEvent) {
        this.dispatch('ElFormItem', 'el.form.change', [this.value]);
      }
    } },

  methods: {
    handleChange: function handleChange(event) {var _this = this;
      if (!this.switchDisabled) {
        var oldValue = this.checked ? this.inactiveValue : this.activeValue;
        if (!this.asyncChange) {
          this.$emit('input', oldValue);
        }
        this.$nextTick(function () {
          _this.$emit('change', oldValue);
        });
      }
      return;
      var val = this.checked ? this.inactiveValue : this.activeValue;
      this.$emit('input', val);
      this.$emit('change', val);
      this.$nextTick(function () {
        // set input's checked property
        // in case parent refuses to change component's value
        if (_this.$refs.input) {
          _this.$refs.input.checked = _this.checked;
        }
      });

    },
    setBackgroundColor: function setBackgroundColor() {
      var newColor = this.checked ? this.activeColor : this.inactiveColor;
      this.$refs.core.style.borderColor = newColor;
      this.$refs.core.style.backgroundColor = newColor;
    },
    switchValue: function switchValue() {
      !this.switchDisabled && this.handleChange();
    },
    getMigratingConfig: function getMigratingConfig() {
      return {
        props: {
          'on-color': 'on-color is renamed to active-color.',
          'off-color': 'off-color is renamed to inactive-color.',
          'on-text': 'on-text is renamed to active-text.',
          'off-text': 'off-text is renamed to inactive-text.',
          'on-value': 'on-value is renamed to active-value.',
          'off-value': 'off-value is renamed to inactive-value.',
          'on-icon-class': 'on-icon-class is renamed to active-icon-class.',
          'off-icon-class': 'off-icon-class is renamed to inactive-icon-class.' } };


    } },

  mounted: function mounted() {
    /* istanbul ignore if */
    this.coreWidth = this.width || 40;
    if (this.activeColor || this.inactiveColor) {
      this.setBackgroundColor();
    }
    this.$refs.input.checked = this.checked;
  } };exports.default = _default;