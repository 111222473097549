"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;








































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'model', data: function data() {return { tableData: [], // 列表数据
      tableColumns: [{ prop: 'insName', label: '运营组织', isShow: true }, { prop: 'typeCode', label: '设备型号', isShow: true }, { prop: 'years', label: '产品年限', isShow: true }, { prop: 'createTime', label: '添加时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }], searchData: { typeCode: '', // 型号
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, newadd: false, title: '新增', addData: { id: '', typeCode: '', years: '' }, defaultData: { id: '', typeCode: '', years: '' }, rules: { typeCode: [{ required: true, message: '请输入设备型号', trigger: 'blur' }], years: [{ required: true, message: '请输入产品年限', trigger: 'blur' }] } };}, mounted: function mounted() {var self = this;self.init();}, methods: { init: function init() {var self = this;
      self.getData('/api/device/typeCode', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.typeCode = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/device/typeCode/add', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateMenu: function updateMenu(item) {
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.addData = JSON.parse(JSON.stringify(self.defaultData));
      self.addData = {
        id: item.id,
        typeCode: item.typeCode,
        years: item.years };

    },
    onUpdate: function onUpdate() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/device/typeCode/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/device/typeCode/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    } } };exports.default = _default;