"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;






















































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'channelLedger', data: function data() {return { tableData: [], // 列表数据
      optionsList: [], // 渠道商下拉数据
      merCodes: '', searchData: { merchCode: '', cooperation: '', disable: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { merchCode: '', insId: '', divRatio: '', syncDownLvl: '', disabled: '' }, newadd: false, // 表格列
      tableColumns: [{ prop: 'insName', label: '运营商', isShow: true }, { prop: 'pmerchName', label: '上级渠道商', isShow: true }, { prop: 'merchName', label: '渠道商', isShow: true }, { prop: 'cooperation', label: '合作方式', formatter: _formatter.cooperationFormatter, isShow: true }, // {
      // 	prop: 'memberId',
      // 	label: '产品型号',
      // 	isShow: true
      // },
      // {
      // 	prop: 'telNo',
      // 	label: '第一年月佣金',
      // 	isShow: true
      // },
      // {
      // 	prop: 'telNo',
      // 	label: '第二年月佣金',
      // 	isShow: true
      // },
      { prop: 'divRatio', label: '服务费(%)', isShow: true }, { prop: 'payWay', label: '收款方式', isShow: true }, { prop: 'mainMemberName', label: '收款方账号', isShow: true }, { prop: 'divMemberId', label: '分账接收方', isShow: true }, { prop: 'divMemberName', label: '分账方名称', isShow: true }], props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, cooperationList: [{ name: '买断', id: '0' }, { name: '联营', id: '1' }, { name: '直营', id: '2' }, { name: '其它', id: '3' }, { name: '代理', id: 'D' }, { name: '自营', id: 'Z' }], disableList: [{ name: '启用', id: '0' }, { name: '禁用', id: '1' }] };}, mounted: function mounted() {var self = this;self.init();self.getDataList();}, methods: { init: function init() {var self = this;self.getData('/api/payment/merch/divRule/list', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, resetFun: function resetFun() {// 重置
      this.searchData.merchCode = '';this.searchData.cooperation = '';this.searchData.disable = '';this.init();}, searchFun: function searchFun() {// 搜索事件
      this.searchData.page = 1;this.init();}, handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.merCodes = [];
      val.map(function (item) {
        self.merCodes.push(item.merchCode);
      });
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    updateItem: function updateItem(item) {
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.getData(
      '/api/payment/merch/divRule',
      {
        merchCode: item.merchCode },

      function (res) {
        self.addData = {
          merchCode: res.merchCode,
          insId: res.insId,
          divRatio: res.divRatio,
          syncDownLvl: res.syncDownLvl + '',
          disabled: res.disabled + '' };

      });

      self.newadd = true;
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.divRatio <= 0 && self.addData.divRatio >= 100) {
        self.$message({
          showClose: true,
          message: '分出服务费只能是0~100之间的值，请重填',
          type: 'error' });

        self.addData.divRatio = '';
        return false;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/payment/merch/divRule', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(item) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData(
        '/api/payment/merch/divRule',
        {
          merchCode: item.merchCode },

        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });

      }).
      catch(function () {});
    },
    switchFun: function switchFun(item, row) {
      var self = this;
      // if(item == 1) {
      // 	var disabled = 0
      // }else{
      // 	var disabled = 1
      // }
      self.putData('/api/payment/merch/divRule/able', {
        merchCode: row.merchCode,
        disabled: item },
      function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    // switchFun(item, row) {
    // 	console.log('item:',item)
    // 	console.log('row:',row)
    // 	let self = this;
    // 	if(item == 1) {
    // 		var disabled = 0
    // 	}else{
    // 		var disabled = 1
    // 	}
    // 	self.putData(
    // 		'/api/payment/merch/divRule/able',
    // 		{
    // 			merchCode: row.merchCode,
    // 			disabled: disabled
    // 		},
    // 		function(res) {
    // 			self.$message({
    // 				message: '修改成功!',
    // 				type: 'success'
    // 			});
    // 			self.init();
    // 		}
    // 	);
    // },
    batchUpgradeFun: function batchUpgradeFun(item) {
      var self = this;
      if (self.merCodes.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择要启用的数据',
          type: 'error' });

      } else {
        self.$confirm('确定批量修改这些记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true }).

        then(function () {
          self.putData('/api/payment/merch/divRule/ableBatch', {
            disable: item,
            merCodes: self.merCodes },
          function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.init();
          });
        }).
        catch(function () {});
      }
    },
    getDataList: function getDataList() {
      var self = this;
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.merchantCode = val;
    } } };exports.default = _default;