"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");




























































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {return { tableData: [], // 列表数据
      searchData: { thirdOrderId: '', userPhone: '', startTime: '', endTime: '', status: '', platCode: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, // 表格列
      tableColumns: [{ prop: 'orderId', label: '订单编号', isShow: true }, { prop: 'thirdOrderId', label: '第三方商户单号', isShow: true }, { prop: 'platCode', label: '运营商', isShow: true }, { prop: 'mchName', label: '渠道商', isShow: true }, { prop: 'networkName', label: '网点', isShow: true }, { prop: 'deviceType', label: '产品型号', isShow: true }, { prop: 'userPhone', label: '用户手机', isShow: true }, { prop: 'thirdNickname', label: '昵称', isShow: true }, { prop: 'pkgName', label: '套餐',
        isShow: true },

      {
        prop: 'pkgDuration',
        label: '时长',
        isShow: true },

      {
        prop: 'pkgAmt',
        label: '套餐金额',
        isShow: true },

      {
        prop: 'payAmt',
        label: '实际支付',
        isShow: true },

      {
        prop: 'payTime',
        label: '订单时间',
        formatter: _formatter.dateFormatter,
        isShow: false },

      {
        prop: 'payChannel',
        label: '支付方式',
        formatter: _formatter.payChannelFormatter,
        isShow: false },

      {
        prop: 'status',
        label: '订单状态',
        formatter: _formatter.orderStatusFormatter,
        isShow: true },

      {
        prop: 'rechargeBalance',
        label: '剩余实付余额',
        isShow: false },

      {
        prop: 'rewardBalance',
        label: '剩余返现余额',
        isShow: false }],


      statusList: [
      { name: '未支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '支付取消', id: '2' },
      { name: '人工退款', id: '3' },
      { name: '一键退款', id: '4' },
      { name: '自动退款', id: '5' },
      { name: '所有已退款', id: '6' }],

      platCodeList: [] };

  },
  mounted: function mounted() {
    var self = this;
    self.searchData.userPhone = self.$route.params.parentId;
    self.init();
    self.getPlatCodeList();
  },
  methods: {
    init: function init() {
      var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('/api/recharge/bill/balance/detail', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    getPlatCodeList: function getPlatCodeList() {
      var self = this;
      self.getData('/api/payment/brand', {}, function (res) {
        self.platCodeList = res;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.thirdOrderId = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.searchData.status = '';
      this.searchData.platCode = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.go(-1);
    } } };exports.default = _default;