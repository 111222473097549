"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));


var _layout = _interopRequireDefault(require("@/layout"));
var _Empty = _interopRequireDefault(require("@/layout/Empty")); /*
                                                                	定义好所有菜单的路径及组件，用于匹配用户拥有的菜单，合并成用于拥有的路由
                                                                */ // import Accountgllist from '@/pages/permiss/accountgllist'
var _default = [
// 首页
{
  path: '/shouye',
  component: _layout.default,
  meta: {
    icon: 'dashboard' },

  children: [{
    path: 'index',
    name: '统计页面',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/shouye/index'));});} }] },



// 系统管理
{
  path: '/permiss',
  component: _layout.default,
  meta: {
    icon: 'user' },

  children: [{
    path: 'accountgllist',
    name: 'accountgllist',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/account'));});} },

  {
    path: 'menulist',
    name: 'permmenu',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/menu'));});} },

  {
    path: 'rolegllist',
    name: '角色管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/role'));});} },

  {
    path: 'rolefplist',
    name: '角色分配',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/roleAssign'));});} },

  {
    path: 'organization',
    name: '组织管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/organize'));});} },

  {
    path: 'operationLog',
    name: '操作日志',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/operationLog'));});} },

  {
    path: 'moreMsg',
    name: '本月日台均排行',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/sys/moreMsg'));});} }] },



// 渠道商管理
{
  path: '/channel',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'distributor',
    name: '渠道商管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/distributor'));});} },

  {
    path: 'twoDistributors',
    name: '二级渠道商管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/twoDistributors'));});} },

  {
    path: 'cConsole',
    name: '渠道商控制台',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/cConsole'));});} },

  {
    path: 'channelAccount',
    name: '渠道商账号',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/channelAccount'));});} },

  {
    path: 'channelConsole',
    name: '渠道商控制台',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/channelConsole'));});} },

  {
    path: 'query',
    name: '渠道商查询',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/query'));});} },

  {
    path: 'twoDistributor/:parentId',
    name: '二级渠道商管理',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/twoDistributor'));});} },

  {
    path: 'onePriceDetail/:parentId',
    name: '编辑价格模板',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/onePriceDetail'));});} },

  {
    path: 'network',
    name: '网点管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/network'));});} },

  {
    path: 'priceDetail/:parentId',
    name: '编辑价格模板',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/priceDetail'));});} },

  {
    path: 'distributeDetail/:parentId',
    name: '分布图',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/distributeDetail'));});} },

  {
    path: 'seatDetail/:parentId',
    name: '座位设置',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/seatDetail'));});} },

  {
    path: 'subAccount',
    name: '子账号管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/subAccount'));});} },

  {
    path: 'news',
    name: '消息通知管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/channel/news'));});} }] },



// 充值管理 recharge
{
  path: '/recharge',
  component: _layout.default,
  meta: {
    icon: 'money' },

  children: [{
    path: 'activity',
    name: '充值活动设置',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/recharge/activity'));});} },

  {
    path: 'cashback',
    name: '充值返现明细',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/recharge/cashback'));});} },

  {
    path: 'cashbackDetail/:parentId',
    name: '余额明细',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/recharge/cashbackDetail'));});} }] },



// 订单管理  orderForm
{
  path: '/orderForm',
  component: _layout.default,
  meta: {
    icon: 'form' },

  children: [{
    path: 'order',
    name: '订单管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/orderForm/order'));});} },

  {
    path: 'unusual',
    name: '退款异常订单',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/orderForm/unusual'));});} }] },



// 基础管理 basics
{
  path: '/basics',
  component: _layout.default,
  meta: {
    icon: 'model' },

  children: [{
    path: 'official',
    name: '官方模板管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/basics/official'));});} },

  {
    path: 'factory',
    name: '工厂测试套餐管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/basics/factory'));});} },

  {
    path: 'newsPeport',
    name: '通讯模块',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/basics/newsPeport'));});} },

  {
    path: 'newsPeportDetail/:parentId',
    name: '查看型号',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/basics/newsPeportDetail'));});} }] },



// 设备管理 device
{
  path: '/device',
  component: _layout.default,
  meta: {
    icon: 'device' },

  children: [{
    path: 'model',
    name: '设备型号管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/model'));});} },

  {
    path: 'fault',
    name: '设备故障',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/fault'));});} },

  {
    path: 'journal',
    name: '设备日志',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/journal'));});} },

  {
    path: 'longRange',
    name: '远程启动统计',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/longRange'));});} },

  {
    path: 'information',
    name: '设备信息',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/information'));});} },

  {
    path: 'orderDetail/:parentId',
    name: '查看订单',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/orderDetail'));});} },

  {
    path: 'debug',
    name: '设备调试日志',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/device/debug'));});} }] },



{
  path: '/customer',
  component: _layout.default,
  meta: {
    icon: 'star' },

  children: [{
    path: 'feedback',
    name: '问题反馈',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/customer/index'));});} }] },


// 活动管理 activity
{
  path: '/activity',
  component: _layout.default,
  meta: {
    icon: 'device' },

  children: [{
    path: 'releaseActivity',
    name: '发布活动',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/activity/releaseActivity'));});} },
  {
    path: 'coupon',
    name: '优惠券管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/activity/coupon'));});} }] },



// 工厂管理 factory
{
  path: '/factory',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'qrCode',
    name: '二维码生成',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/qrCode'));});} },
  {
    path: 'shipment',
    name: '出货管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/shipment'));});} },

  {
    path: 'common',
    name: '工厂固件升级',
    meta: { icon: 'robot' },
    component: _Empty.default,
    children: [
    {
      path: 'communicate',
      name: '通讯模块管理',
      meta: { icon: 'star' },
      component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/common/communicate'));});} },

    {
      path: 'voiceBoard',
      name: '语音模块管理',
      meta: { icon: 'star' },
      component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/common/voiceBoard'));});} },


    {
      path: 'core',
      name: '中心板管理',
      meta: { icon: 'star' },
      component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/common/core'));});} },


    {
      path: 'firmwareNews',
      name: '设备固件信息',
      meta: { icon: 'star' },
      component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/common/firmwareNews'));});} },


    {
      path: 'firmwareUpdate',
      name: '设备固件升级',
      meta: { icon: 'star' },
      component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/factory/common/firmwareUpdate'));});} }] }] },






// 报表统计 report
{
  path: '/report',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'census',
    name: '设备投放统计 ',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/report/census'));});} }] },



// 用户管理 userManagement
{
  path: '/userManagement',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'userList',
    name: '渠道商分账设置',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/userManagement/userList'));});} }] },



// 分账管理 ledger
{
  path: '/ledger',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'channelLedger',
    name: '渠道商分账设置',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/ledger/channelLedger'));});} },

  {
    path: 'ledgerDetails',
    name: '分账明细',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/ledger/ledgerDetails'));});} },

  {
    path: 'personalLedger',
    name: '个人商户进件',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/ledger/personalLedger'));});} },

  {
    path: 'enterpriseLedger',
    name: '企业商户进件',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/ledger/enterpriseLedger'));});} }] },



// 用户端营销 market
{
  path: '/market',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'banner',
    name: 'banner管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/market/banner'));});} },

  {
    path: 'hotline',
    name: '招商热线',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/market/hotline'));});} },

  {
    path: 'notice',
    name: '公告管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/market/notice'));});} }] },



// 固件升级 firmware
{
  path: '/firmware',
  component: _layout.default,
  meta: {
    icon: 'component' },

  children: [{
    path: 'voice',
    name: '语音版本管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/voice'));});} },

  {
    path: 'voiceDetail/:parentId',
    name: '语音管理',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/voiceDetail'));});} },

  {
    path: 'communication',
    name: '通讯模块管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/communication'));});} },

  {
    path: 'communicationDetail/:parentId',
    name: '通讯管理',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/communicationDetail'));});} },

  {
    path: 'centerPlate',
    name: '中心板管理',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/centerPlate'));});} },

  {
    path: 'centerPlateDetail/:parentId',
    name: '中心管理',
    hidden: true,
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/centerPlateDetail'));});} },

  {
    path: 'upgrade',
    name: '设备固件升级',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/upgrade'));});} },

  {
    path: 'news',
    name: '设备固件信息',
    meta: {
      icon: 'star' },

    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/firmware/news'));});} }] }];exports.default = _default;