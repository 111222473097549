"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));var _default =
{
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: '' },

    title: {
      type: String,
      default: '' } },


  render: function render(h, context) {var _context$props =
    context.props,icon = _context$props.icon,title = _context$props.title;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("svg-icon", { "attrs": { "icon-class": icon } }));
    }

    if (title) {
      if (title == '客服处理' && _store.default.state.user.problems > 0) {
        vnodes.push(h("span", { "slot": 'title' }, [title, h("el-badge", { "style": "padding-left: 5px; padding-top: 5px", "attrs": { "is-dot": true } })]));
      } else if (title == '问题处理' && _store.default.state.user.problems > 0) {
        vnodes.push(h("span", { "slot": 'title' }, [title, h("el-badge", { "style": "padding-left: 5px; padding-top: 5px", "attrs": { "value": _store.default.state.user.problems } })]));
      } else {
        vnodes.push(h("span", { "slot": 'title' }, [title]));
      }
    }
    return vnodes;
  } };exports.default = _default;