"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");






















































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'userList', data: function data() {return { timeRange: '', //时间范围
      tableData: [], totalCnt: '', memberCnt: '', visitorCnt: '', searchData: { thirdOpenId: '', thirdNickname: '', member: '', userPhone: '', startTime: '', endTime: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, tableColumns: [{ prop: 'thirdNickname', label: '昵称', isShow: true }, { prop: 'member', label: '是否会员', formatter: _formatter.memberFormatter, isShow: true }, { prop: 'userPhone', label: '手机号', isShow: true }, { prop: 'thirdOpenId', label: 'OpenId', isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'rechargeBalance', label: '实际充值余额', isShow: true }, { prop: 'rewardBalance', label: '赠送余额', isShow: true }, { prop: 'infocenBalanceRefund', label: '旧系统余额', isShow: true }], memberList: [{ name: '会员', id: 'Y' }, { name: '非会员', id: 'N' }] };}, mounted: function mounted() {var self = this;self.init();}, methods: { init: function init() {if (this.timeRange) {this.$set(this.searchData, 'startTime', this.timeRange[0]);this.$set(this.searchData, 'endTime', this.timeRange[1]);} else {this.$set(this.searchData, 'startTime', '');this.$set(this.searchData, 'endTime', '');}var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('/api/share/user/list', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
      self.getData('/api/share/user/statistics', {}, function (res) {
        self.totalCnt = res.totalCnt;
        self.memberCnt = res.memberCnt;
        self.visitorCnt = res.visitorCnt;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.thirdOpenId = '';
      this.searchData.thirdNickname = '';
      this.searchData.member = '';
      this.searchData.userPhone = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    excelNetworkFun: function excelNetworkFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/share/user/export?thirdOpenId=' +
      self.searchData.thirdOpenId +
      '&thirdNickname=' +
      self.searchData.thirdNickname +
      '&member=' +
      self.searchData.member +
      '&startTime=' +
      self.searchData.startTime +
      '&endTime=' +
      self.searchData.endTime;
    } } };exports.default = _default;