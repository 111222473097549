var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-cascader", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              options: _vm.optionsList,
              props: _vm.props,
              "collapse-tags": "",
              filterable: "",
              clearable: "",
              placeholder: "请选择渠道商"
            },
            on: { change: _vm.changeMechanism },
            model: {
              value: _vm.searchData.merchantCode,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "merchantCode", $$v)
              },
              expression: "searchData.merchantCode"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入出库单号",
              clearable: ""
            },
            model: {
              value: _vm.searchData.outstorageNo,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "outstorageNo", $$v)
              },
              expression: "searchData.outstorageNo"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入设备单号",
              clearable: ""
            },
            model: {
              value: _vm.searchData.sn,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "sn", $$v)
              },
              expression: "searchData.sn"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("按时间：")]),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              clearable: "",
              "picker-options": _vm.pickerOptions,
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "range-separator": "至",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.timeRange,
              callback: function($$v) {
                _vm.timeRange = $$v
              },
              expression: "timeRange"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "success" },
              on: { click: _vm.download }
            },
            [_vm._v("下载导入模板")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "warning" },
              on: { click: _vm.importFun }
            },
            [_vm._v("导入")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "success", icon: "el-icon-s-promotion" },
              on: { click: _vm.excelFun }
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t\t显示/隐藏列\n\t\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          },
          on: { "selection-change": _vm.handleSelectionChange2 }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "出库单号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "colBlue",
                        attrs: { title: "单号详情" },
                        on: {
                          click: function($event) {
                            return _vm.countFun(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.no))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道商名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(scope.row.merchantNameL1) +
                        " " +
                        _vm._s(scope.row.merchantNameL2) +
                        "\n\t\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "310", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini", title: "编辑" },
                        on: {
                          click: function($event) {
                            return _vm.updateItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.delMenu(scope.row.no)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "600px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small"
              }
            },
            [
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "出库单号", prop: "outstorageNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "15",
                          placeholder: "请输入",
                          readonly: ""
                        },
                        model: {
                          value: _vm.addData.outstorageNo,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "outstorageNo", $$v)
                          },
                          expression: "addData.outstorageNo"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "导入"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道商", prop: "merchantCode" } },
                    [
                      _c("el-cascader", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: {
                          options: _vm.optionsList,
                          props: _vm.props,
                          "collapse-tags": "",
                          filterable: "",
                          clearable: ""
                        },
                        on: { change: _vm.changeMechanism2 },
                        model: {
                          value: _vm.addData.merchantCode,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "merchantCode", $$v)
                          },
                          expression: "addData.merchantCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "导入"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          maxlength: "300",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.addData.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "remark", $$v)
                          },
                          expression: "addData.remark"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "导入"
                ? _c(
                    "el-button",
                    {
                      staticClass: "ml10 mb20",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.onChoice }
                    },
                    [_vm._v("选择设备")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "导入"
                ? _c(
                    "el-table",
                    {
                      ref: "table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.depotData,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "typeCode", label: "产品类型" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "sn", label: "设备序列号" }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "入库时间" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm._f("dateFilter")(
                                          scope.row.createTime
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3826305577
                        )
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", prop: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delDepotMenu(
                                            scope.$index,
                                            _vm.depotData
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3651351601
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "导入"
                ? _c("el-form-item", { attrs: { label: "导入:" } }, [
                    _c(
                      "input",
                      _vm._b(
                        {
                          attrs: {
                            type: "file",
                            prop: "file",
                            id: "daoru",
                            name: "file",
                            value: "",
                            accept:
                              ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          },
                          on: {
                            change: function($event) {
                              return _vm.getFile($event)
                            }
                          }
                        },
                        "input",
                        _vm.addData.importFile,
                        false
                      )
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "新增"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu ml-110" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu ml-110" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "导入"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu ml-110" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onImport }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "600px",
            visible: _vm.newadd2,
            "before-close": _vm.closeRefund2,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd2 = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.qrData,
                "tooltip-effect": "dark",
                border: "",
                stripe: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "typeCode", label: "产品型号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sn", label: "序列号编码" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "二维码图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              title: "查看",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.lookView(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "dialog2" } }, [
        _c("div", { attrs: { id: "dia_box" } }, [
          _c("div", { attrs: { id: "qrcode" } }),
          _vm._v(" "),
          _c("div", { staticClass: "imgName" }, [_vm._v(_vm._s(_vm.imgName))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnBox" },
            [
              _c("el-button", { on: { click: _vm.onCencal3 } }, [
                _vm._v("关闭")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadQrcode }
                },
                [_vm._v("下载")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title2,
            width: "900px",
            visible: _vm.newadd3,
            "before-close": _vm.closeRefund3,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd3 = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  type: "text",
                  placeholder: "请输入产品型号",
                  clearable: ""
                },
                model: {
                  value: _vm.qrSData.typeCode,
                  callback: function($$v) {
                    _vm.$set(_vm.qrSData, "typeCode", $$v)
                  },
                  expression: "qrSData.typeCode"
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  type: "text",
                  placeholder: "请输入序列号",
                  clearable: ""
                },
                model: {
                  value: _vm.qrSData.sn,
                  callback: function($$v) {
                    _vm.$set(_vm.qrSData, "sn", $$v)
                  },
                  expression: "qrSData.sn"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.searchFun2 }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "warning", icon: "el-icon-refresh" },
                  on: { click: _vm.resetFun2 }
                },
                [_vm._v("重置")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.outstorageData,
                "tooltip-effect": "dark",
                border: "",
                stripe: ""
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "typeCode", label: "产品类型" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sn", label: "设备序列号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "生成时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm._f("dateFilter")(scope.row.createTime)) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.qsPageData.total > 0,
                    expression: "qsPageData.total > 0"
                  }
                ],
                attrs: {
                  "current-page": _vm.qsPageData.page,
                  "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
                  "page-size": _vm.qsPageData.rows,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.qsPageData.total
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "anniu" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOutstorage } },
                [_vm._v("新增")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.onCencal2 } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }