"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");var _utils = _interopRequireDefault(require("./utils"));
var OSS = require('ali-oss');var _default =
{
  /**
   * 创建随机字符串
   * @param num
   * @returns {string}
   */
  randomString: function randomString(num) {
    var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var res = '';
    for (var i = 0; i < num; i++) {
      var id = Math.ceil(Math.random() * 35);
      res += chars[id];
    }
    return res;
  },
  /**
      * 获取环境信息
      */
  evnString: function evnString() {
    var evn = process.env.NODE_ENV;
    console.log('当前环境' + evn);
    var url = '';
    if (evn === 'development') {
      url += 'dev/';
    } else if (evn === 'production') {
      url += 'prod/';
    } else if (evn === 'stage') {
      url += 'test/';
    } else {
      url += 'dev/';
    }
    return url;
  },
  /**
      * 创建oss客户端对象
      * @returns {*}
      */
  createOssClient: function createOssClient() {
    return new Promise(function (resolve, reject) {
      var client = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI4FwjzDNtBJGzxDaQ5dQm',
        accessKeySecret: 'tPRcQ5u5IiQSAQkbfNWj4PKjxoJ4EJ',
        // bucket: 'familyiot',
        bucket: 'share-chair'
        // sisToken: resolve.data.securityToken
      });
      resolve(client);
    });
  },
  /**
      * 文件上传
      * @param option 参考csdn: https://blog.csdn.net/qq_27626333/article/details/81463139
      */
  ossUploadFile: function ossUploadFile(option, path) {
    var file = option.file;
    var self = this;
    console.log('option：' + option);
    console.log('path：' + path);
    return new Promise(function (resolve, reject) {
      var dateTime = _utils.default.dateFormat(new Date(), 'yyyyMMddhhmmss'); // 当前时间
      var randomStr = self.randomString(4); //  4位随机字符串
      var extensionName = file.name.substr(file.name.indexOf('.')); // 文件扩展名
      // const fileName = self.evnString() + path + '/' + dateTime + '_' + randomStr + extensionName // 文件名字（相对于根目录的路径 + 文件名）
      var fileName = self.evnString() + path + '/' + option.file.name;
      console.log('fileName：' + fileName);
      // 执行上传
      self.createOssClient().then(function (client) {
        // 异步上传,返回数据
        resolve({
          fileName: file.name,
          fileUrl: fileName });

        // 上传处理
        // 分片上传文件
        client.multipartUpload(fileName, file, {
          progress: function progress(p) {
            var e = {};
            e.percent = Math.floor(p * 100);
            console.log('Progress: ' + p);
            option.onProgress(e);
          } }).
        then(function (val) {
          // console.info(val)
          if (val.res.statusCode === 200) {
            option.onSuccess(val);
            return val;
          } else {
            option.onError('上传失败');
          }
        }, function (err) {
          option.onError('上传失败');
          reject(err);
        });
      });
      return 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + fileName;
    });
  } };exports.default = _default;