"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;










































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { data: function data() {return { timeRange: '', //时间范围
      tableData: [], // 列表数据
      typeCodeList: [], commModuleFactoryList: [], commModuleVersionList: [], statusList: [{ name: '空闲', id: '0' }, { name: '使用中', id: '1' }, { name: '离线', id: '2' }, { name: '暂停到复位', id: '3' }, { name: '暂停', id: '4' }, { name: '故障', id: '5' }, { name: '升级中', id: '6' }, { name: '时间到复位', id: '7' }], tableColumns: [{ prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'productTypeCode', label: '设备型号', isShow: true }, { prop: 'commModuleFactoryName', label: '模块生产商（通讯）', isShow: true }, { prop: 'commModuleVersionName', label: '模块型号', isShow: true }, { prop: 'currentCommVersion', label: '通讯版本', isShow: true // 是否显示
      }, { prop: 'currentCenterVersion', label: '中心板版本', isShow: true // 是否显示
      }, // {
      // 	prop: 'currentMusicVersion',
      // 	label: '语音版本',
      // 	isShow: true // 是否显示
      // },
      { prop: 'status', label: '设备状态', formatter: _formatter.deviceStatusFormatter, isShow: true // 是否显示
      }, { prop: 'updateTime', label: '上报时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'merName', label: '渠道商', isShow: true }, { prop: 'networkName', label: '网点名称', isShow: true }], searchData: { sn: '', status: '', productTypeCode: '', commModuleFactoryName: '', commModuleVersionName: '', currentCommVersion: '',
        currentCenterVersion: '',
        currentMusicVersion: '',
        merName: '',
        networkName: '',
        startTime: '',
        endTime: '',
        page: 1, // 当前页码
        rows: 10 // 页码条数
      },
      pageData: {
        page: 1,
        rows: 10,
        total: 0,
        pages: 1 } };


  },
  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
  },
  methods: {
    init: function init() {
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      self.getData('/api/firmwareUpgradeDevice/page/all', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.sn = '';
      this.searchData.status = '';
      this.searchData.productTypeCode = '';
      this.searchData.commModuleFactoryName = '';
      this.searchData.commModuleVersionName = '';
      this.searchData.currentCommVersion = '';
      this.searchData.currentCenterVersion = '';
      this.searchData.currentMusicVersion = '';
      this.searchData.merName = '';
      this.searchData.networkName = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
      // 通讯模块厂商
      self.getData('/api/commModuleFactory/list', {}, function (res) {
        self.commModuleFactoryList = res;
      });
    },
    getcommModuleVersion: function getcommModuleVersion() {
      var self = this;
      self.getData('/api/commModuleVersion//list/' + self.searchData.commModuleFactoryName, {}, function (res) {
        console.log('res:', res);
        self.commModuleVersionList = res;
      });
    } } };exports.default = _default;