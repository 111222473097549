var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("ThirdAppMain"),
      _vm._v(" "),
      _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }