var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.checkPermission(["admin:menu:save"])
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addMenu }
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "新增",
                visible: _vm.newadd,
                width: "500px",
                "before-close": _vm.closeRefund,
                center: "",
                "close-on-click-modal": false,
                "show-close": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.newadd = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticClass: "mark",
                  attrs: {
                    model: _vm.addData,
                    rules: _vm.rules,
                    "label-width": "160px",
                    size: "small"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "menuName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "10" },
                        model: {
                          value: _vm.addData.menuName,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "menuName", $$v)
                          },
                          expression: "addData.menuName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级菜单", prop: "parentId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.addData.parentId,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "parentId", $$v)
                            },
                            expression: "addData.parentId"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "作为第一级", value: 0 }
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.level, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.menuName, value: item.id }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.twoShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "二级菜单" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.twoSelect,
                                callback: function($$v) {
                                  _vm.twoSelect = $$v
                                },
                                expression: "twoSelect"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "作为第二级", value: 0 }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.child, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.menuName,
                                    value: item.id
                                  }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.twoSelect == 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "链接地址" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "300" },
                            model: {
                              value: _vm.addData.url,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "url", $$v)
                              },
                              expression: "addData.url"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "2" },
                        model: {
                          value: _vm.addData.orders,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "orders", $$v)
                          },
                          expression: "addData.orders"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.addData.permission,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "permission", $$v)
                          },
                          expression: "addData.permission"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "tree-props": { children: "child", hasChildren: "hasChildren" }
          }
        },
        [
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "200",
              align: "center",
              prop: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.menuType === 1
                      ? _c(
                          "div",
                          [
                            _vm.checkPermission(["admin:menu:save"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      title: "编辑"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.updateMenu(scope.row),
                                          (_vm.hasUrl = true)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["admin:menu:del"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      title: "删除"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.delMenu(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["admin:menu:save"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      title: "新增"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.addTwoMenu(scope.row),
                                          (_vm.hasUrl = true)
                                      }
                                    }
                                  },
                                  [_vm._v("新增")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.menuType === 2
                      ? _c(
                          "div",
                          [
                            _vm.checkPermission(["admin:menu:save"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      title: "编辑"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.updateMenu(scope.row),
                                          (_vm.hasUrl = true)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["admin:menu:del"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      title: "删除"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.delMenu(scope.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.update,
            width: "500px",
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.update = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "updateForm",
              staticClass: "mark",
              attrs: {
                model: _vm.updateData,
                rules: _vm.rules,
                "label-width": "160px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "menuName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10" },
                    model: {
                      value: _vm.updateData.menuName,
                      callback: function($$v) {
                        _vm.$set(_vm.updateData, "menuName", $$v)
                      },
                      expression: "updateData.menuName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型", prop: "menuType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择菜单类型" },
                      model: {
                        value: _vm.updateData.menuType,
                        callback: function($$v) {
                          _vm.$set(_vm.updateData, "menuType", $$v)
                        },
                        expression: "updateData.menuType"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "菜单", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "按钮", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasUrl
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接地址" } },
                    [
                      _vm.updateData.target != "button"
                        ? _c("el-input", {
                            attrs: { maxlength: "300" },
                            model: {
                              value: _vm.updateData.url,
                              callback: function($$v) {
                                _vm.$set(_vm.updateData, "url", $$v)
                              },
                              expression: "updateData.url"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "orders" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "2" },
                    model: {
                      value: _vm.updateData.orders,
                      callback: function($$v) {
                        _vm.$set(_vm.updateData, "orders", $$v)
                      },
                      expression: "updateData.orders"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权限" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 5, maxlength: "200" },
                    model: {
                      value: _vm.updateData.permission,
                      callback: function($$v) {
                        _vm.$set(_vm.updateData, "permission", $$v)
                      },
                      expression: "updateData.permission"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onUpdate } },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.onCancelUpdate } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加",
            visible: _vm.addTwo,
            width: "500px",
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addTwo = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newTwoForm",
              staticClass: "mark",
              attrs: {
                model: _vm.newTwoData,
                rules: _vm.rules,
                "label-width": "160px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "menuName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10" },
                    model: {
                      value: _vm.newTwoData.menuName,
                      callback: function($$v) {
                        _vm.$set(_vm.newTwoData, "menuName", $$v)
                      },
                      expression: "newTwoData.menuName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型", prop: "menuType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择菜单类型" },
                      model: {
                        value: _vm.newTwoData.menuType,
                        callback: function($$v) {
                          _vm.$set(_vm.newTwoData, "menuType", $$v)
                        },
                        expression: "newTwoData.menuType"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "菜单", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "按钮", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasUrl
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接地址" } },
                    [
                      _vm.updateData.target != "button"
                        ? _c("el-input", {
                            attrs: { maxlength: "30" },
                            model: {
                              value: _vm.newTwoData.url,
                              callback: function($$v) {
                                _vm.$set(_vm.newTwoData, "url", $$v)
                              },
                              expression: "newTwoData.url"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "2" },
                    model: {
                      value: _vm.newTwoData.orders,
                      callback: function($$v) {
                        _vm.$set(_vm.newTwoData, "orders", $$v)
                      },
                      expression: "newTwoData.orders"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权限" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 5, maxlength: "200" },
                    model: {
                      value: _vm.newTwoData.permission,
                      callback: function($$v) {
                        _vm.$set(_vm.newTwoData, "permission", $$v)
                      },
                      expression: "newTwoData.permission"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onAddTwo } },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.onCancelTwo } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }