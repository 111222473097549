var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    ref: "relChannelCheckBox",
    staticClass: "filter-item",
    staticStyle: { width: "200px" },
    attrs: {
      options: _vm.channelList,
      props: _vm.defaultProps,
      "collapse-tags": "",
      placeholder: "请选择渠道商",
      filterable: "",
      clearable: ""
    },
    on: { change: _vm.changeAddress },
    model: {
      value: _vm.channelValue,
      callback: function($$v) {
        _vm.channelValue = $$v
      },
      expression: "channelValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }