"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));




































































var _vuex = require("vuex");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { data: function data() {return { tableData: [], // 列表数据
      deviceData: [], // 弹窗列表数据
      optionsList: [], // 渠道商下拉数据
      id: '', code: '', tableColumns: [{ prop: 'id', label: '订单编号', isShow: true }, { prop: 'thirdOrderId', label: '第三方商户单号', isShow: true }, { prop: 'mchnameL1', label: '一级渠道商', isShow: false }, { prop: 'mchnameL2', label: '二级渠道商', isShow: false }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'thirdNickname', label: '用户昵称', isShow: true // 是否显示
      }, { prop: 'deviceType', label: '产品型号', isShow: false }, { prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'pkgName', label: '购买套餐', isShow: true }, // {
      // 	prop: 'pkgName',
      // 	label: '套餐价格',
      // 	isShow: true
      // },
      // {
      // 	prop: 'runTime',
      // 	label: '按摩时间',
      // 	isShow: false
      // },
      { prop: 'payTime', label: '下单时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'status', label: '订单状态', formatter: _formatter.orderStatusFormatter, isShow: true }, { prop: 'payChannel', label: '支付方式', formatter: _formatter.payChannelFormatter, isShow: true },
      {
        prop: 'payAmt',
        label: '支付金额(元)',
        isShow: true },

      {
        prop: 'couponNo',
        label: '券号',
        isShow: false },

      {
        prop: 'couponName',
        label: '券包名',
        isShow: false },

      {
        prop: 'couponType',
        label: '券包类型',
        isShow: false },

      {
        prop: 'refundTime',
        label: '退款时间',
        formatter: _formatter.dateFormatter,
        sortable: true,
        isShow: false // 是否显示
      },
      {
        prop: 'refundAmt',
        label: '退款金额',
        isShow: false }],


      ids: '',
      searchData: {
        id: '',
        thirdOrderId: '',
        merchCode: '',
        networkName: '',
        status: '',
        startTime: '',
        endTime: '',
        page: 1, // 当前页码
        rows: 10 // 页码条数
      },
      pageData: {
        page: 1,
        rows: 10,
        pages: 1 },

      props: {
        children: 'subMers',
        label: 'name',
        value: 'code',
        checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: false },

      statusList: [
      { name: '未支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '支付取消', id: '2' },
      { name: '人工退款', id: '3' },
      { name: '一键退款', id: '4' },
      { name: '自动退款', id: '5' },
      { name: '所有已退款', id: '6' }] };


  },
  computed: (0, _objectSpread2.default)({},
  (0, _vuex.mapGetters)([
  'name'])),


  mounted: function mounted() {
    var self = this;
    self.searchData.merchCode = self.$route.query.code;
    self.init();
    self.getDataList();
  },
  watch: {
    "searchData.startTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'startTime', '');
        }
      },
      deep: true },

    "searchData.endTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'endTime', '');
        }
      },
      deep: true } },


  methods: {
    init: function init() {
      var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('/api/package/bill/list', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        // for(var i in self.tableData) {
        // 	console.log(parseFloat(self.tableData[i].id))
        // }
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.id = '';
      this.searchData.thirdOrderId = '';
      this.searchData.networkName = '';
      this.searchData.status = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDataList: function getDataList() {
      var self = this;
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.merchCode = val || '';
    },
    // dailog
    excelFun: function excelFun() {
      var self = this;
      window.location.href =
      '/api/package/bill/export?id=' +
      self.searchData.id +
      '&thirdOrderId=' +
      self.searchData.thirdOrderId +
      '&networkName=' +
      self.searchData.networkName +
      '&merchCode=' +
      self.searchData.merchCode +
      '&status=' +
      self.searchData.status +
      '&startTime=' +
      self.searchData.startTime +
      '&endTime=' +
      self.searchData.endTime;
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.go(-1);
    } } };exports.default = _default;