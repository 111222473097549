"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =

{
  name: 'Page404',
  computed: {
    message: function message() {
      return 'The webmaster said that you can not enter this page...';
    } } };exports.default = _default;