"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;











































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {return { tableData: [], // 列表数据
      searchData: { version: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', version: '', remarks: '', commModuleFactoryId: '' }, defaultData: { id: '', version: '', remarks: '', commModuleFactoryId: '' }, rules: { version: [{ required: true, message: '请输入通信模块型号', trigger: 'blur' }], remarks: [{ required: true, message: '请输入备注', trigger: 'blur' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'version', label: '通讯模块型号', isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'remarks', label: '备注', isShow: true }] };}, mounted: function mounted() {var self = this;self.addData.commModuleFactoryId = self.$route.params.parentId;self.defaultData.commModuleFactoryId = self.$route.params.parentId;self.init();}, methods: { init: function init() {var self = this;self.getData('/api/commModuleVersion/page/' + self.$route.params.parentId, self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.version = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/commModuleVersion', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.getData('/api/commModuleVersion/' + item.id, self.searchData, function (res) {
        self.addData = {
          id: item.id,
          commModuleFactoryId: item.commModuleFactoryId,
          version: item.version,
          remarks: item.remarks };

      });
      self.title = '编辑';
      self.newadd = true;
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/commModuleVersion/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/commModuleVersion/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.push('/basics/newsPeport');
    } } };exports.default = _default;