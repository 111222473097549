var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择设备型号",
                clearable: ""
              },
              model: {
                value: _vm.searchData.productTypeCode,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "productTypeCode", $$v)
                },
                expression: "searchData.productTypeCode"
              }
            },
            _vm._l(_vm.typeCodeList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item, value: item }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择版本号",
                clearable: ""
              },
              model: {
                value: _vm.searchData.version,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "version", $$v)
                },
                expression: "searchData.version"
              }
            },
            _vm._l(_vm.commModuleVersionList2, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.version, value: item.version }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择厂商",
                clearable: ""
              },
              on: {
                change: function($event) {
                  return _vm.getcommModuleVersion(
                    _vm.searchData.commModuleFactoryId
                  )
                }
              },
              model: {
                value: _vm.searchData.commModuleFactoryId,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "commModuleFactoryId", $$v)
                },
                expression: "searchData.commModuleFactoryId"
              }
            },
            _vm._l(_vm.commModuleFactoryList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: _vm.placeHolder,
                clearable: ""
              },
              model: {
                value: _vm.searchData.commModuleVersion,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "commModuleVersion", $$v)
                },
                expression: "searchData.commModuleVersion"
              }
            },
            _vm._l(_vm.commModuleVersionList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.version, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择是否启用",
                clearable: ""
              },
              model: {
                value: _vm.searchData.isEnable,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "isEnable", $$v)
                },
                expression: "searchData.isEnable"
              }
            },
            _vm._l(_vm.isEnableList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("时间：")]),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              clearable: "",
              "picker-options": _vm.pickerOptions,
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "range-separator": "至",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.timeRange,
              callback: function($$v) {
                _vm.timeRange = $$v
              },
              expression: "timeRange"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t\t显示/隐藏列\n\t\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "250", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isEnable == "0"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.qEnableFun(scope.row)
                              }
                            }
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.isEnable == "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.jEnableFun(scope.row)
                              }
                            }
                          },
                          [_vm._v("禁用")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini", title: "编辑" },
                        on: {
                          click: function($event) {
                            return _vm.updateItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.delMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "warning",
                          title: "复制下载地址",
                          size: "mini"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openCopy(scope.row)
                          }
                        }
                      },
                      [_vm._v("复制下载地址")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "600px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设备型号", prop: "productTypeCode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { filterable: "", clearable: "" },
                      model: {
                        value: _vm.addData.productTypeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "productTypeCode", $$v)
                        },
                        expression: "addData.productTypeCode"
                      }
                    },
                    _vm._l(_vm.typeCodeList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块厂商", prop: "commModuleFactoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { filterable: "", clearable: "" },
                      on: {
                        change: function($event) {
                          return _vm.getcommModuleVersion(
                            _vm.addData.commModuleFactoryId
                          )
                        }
                      },
                      model: {
                        value: _vm.addData.commModuleFactoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "commModuleFactoryId", $$v)
                        },
                        expression: "addData.commModuleFactoryId"
                      }
                    },
                    _vm._l(_vm.commModuleFactoryList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块版本号", prop: "commModuleVersionId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: _vm.placeHolder
                      },
                      model: {
                        value: _vm.addData.commModuleVersionId,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "commModuleVersionId", $$v)
                        },
                        expression: "addData.commModuleVersionId"
                      }
                    },
                    _vm._l(_vm.commModuleVersionList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.version, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "固件版本号", prop: "version" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.addData.version,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "version", $$v)
                      },
                      expression: "addData.version"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt10",
                  attrs: { label: "通讯文件", prop: "filePath" }
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        "http-request": _vm.fnUploadRequest,
                        action: "string",
                        "before-remove": _vm.beforeRemove,
                        "on-remove": _vm.handleOnRemove,
                        "on-success": _vm.onsuccess,
                        "on-change": _vm.handleChangePic,
                        "show-file-list": false,
                        accept: "_US1"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "colred" }, [
                        _vm._v("提示：设备上报版本号需与上传文件名称一致")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.addData.filePath,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "filePath", $$v)
                      },
                      expression: "addData.filePath"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否启用", prop: "isEnable" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.addData.isEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "isEnable", $$v)
                        },
                        expression: "addData.isEnable"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.addData.isEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "isEnable", $$v)
                        },
                        expression: "addData.isEnable"
                      }
                    },
                    [_vm._v("禁用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      maxlength: "300",
                      placeholder: "请输入",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.addData.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "remarks", $$v)
                      },
                      expression: "addData.remarks"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "新增"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu ml-110" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu ml-110" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复制下载地址",
            visible: _vm.newadd2,
            width: "480px",
            "before-close": _vm.closeDiglog,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd2 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "url地址：" } },
                [
                  _c("el-input", {
                    staticClass: "textarea-my",
                    attrs: {
                      type: "textarea",
                      rows: 8,
                      id: "copyObj",
                      readonly: ""
                    },
                    model: {
                      value: _vm.addData.filePath,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "filePath", $$v)
                      },
                      expression: "addData.filePath"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.copyFun } },
                    [_vm._v("复制下载地址")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.closeDiglog } }, [
                    _vm._v("关 闭")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }