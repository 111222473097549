"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");


























































































var _formatter = require("@/utils/formatter");
var _aliOss = _interopRequireDefault(require("@/utils/aliOss"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _vueQuillEditor = require("vue-quill-editor");
var _quillImageExtendModule = require("quill-image-extend-module");
var _quillImageResizeModule = _interopRequireDefault(require("quill-image-resize-module"));
var _quillImageDropModule = require("quill-image-drop-module");
var _quill = _interopRequireDefault(require("quill"));



require("@/styles/ogquill.css"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_quill.default.register('modules/imageResize', _quillImageResizeModule.default);_quill.default.register('modules/imageDrop', _quillImageDropModule.ImageDrop);var size = [false, '16px', '18px', '20px', '22px', '26px', '28px', '30px'];var toolbarOptions = [['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
['blockquote', 'code-block'], //引用，代码块
[{ 'header': 1 }, { 'header': 2 }], //几级标题
[{ 'list': 'ordered' }, { 'list': 'bullet' }], //有序列表，无序列表
[{ 'script': 'sub' }, { 'script': 'super' }], //下角标，上角标
[{ 'indet': '-1' }, { 'indent': '+1' }], //缩进
[{ 'direction': 'rtl' }], //文字输入方向
[{ 'size': size }], //字体大小
[{ 'header': [false, 1, 2, 3, 4, 5, 6] }], //标题
[{ 'color': [] }, { 'background': [] }], //颜色选择
[{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'] }], //字体
[{ 'align': [] }], //居中
['clean'], ['link', 'image'] //上传图片、上传视频
];var _default = { name: 'news', components: { quillEditor: _vueQuillEditor.quillEditor }, data: function data() {return { editorOption: { placeholder: '请填写内容', modules: { imageDrop: true, imageResize: { displayStyles: { backgroundColor: 'black', border: 'none', color: 'white' }, modules: ['Resize', 'DisplaySize', 'Toolbar'] }, toolbar: { container: toolbarOptions, handlers: { image: function image(value) {if (value) {document.querySelector('.avatar-uploader input').click();} else {this.quill.format('image', false);}} } } } }, tableData: [], // 列表数据
      tableColumns: [{ prop: 'title', label: '消息标题', isShow: true }, { prop: 'brief', label: '消息概要', width: '400', isShow: true }, { prop: 'isPublish', label: '发布状态', formatter: _formatter.isPublishFormatter, isShow: true // 是否显示
      }, { prop: 'insName', label: '组织', isShow: true }, { prop: 'publishTime', label: '发布时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }], searchData: { title: '', // 标题
        isPublish: '', // 发布状态
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, isPublishList: [{ name: '未发布', id: '0' }, { name: '发布', id: '1' }], receiveLevelList: [{ name: '一级', id: '1' }, { name: '二级', id: '2' }, { name: '全部', id: '3' }], newadd: false, title: '新增', addData: { id: '', title: '', brief: '', content: '', receiveLevel: '', isPublish: '' }, defaultData: {
        id: '',
        title: '',
        brief: '',
        content: '',
        receiveLevel: '',
        isPublish: '' },

      rules: {
        title: [
        {
          required: true,
          message: '请输入标题',
          trigger: 'blur' }],


        brief: [
        {
          required: true,
          message: '请输入概要',
          trigger: 'blur' }],


        content: [
        {
          required: true,
          message: '请输入消息内容',
          trigger: 'blur' }],


        receiveLevel: [
        {
          required: true,
          message: '请选择接收的渠道商',
          trigger: 'change' }],


        isPublish: [
        {
          required: true,
          message: '请选择是否发布',
          trigger: 'change' }] } };




  },
  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    // 图片上传
    handleChangePic: function handleChangePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    fnUploadRequest: function fnUploadRequest(option) {
      var path = 'mxsg/banner';
      _aliOss.default.ossUploadFile(option, path);
    },
    onsuccess: function onsuccess(res) {
      var fileUrl = 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + res.name;
      var quill = this.$refs.quillEditor.quill;
      var length = quill.getSelection().index;
      quill.insertEmbed(length, 'image', fileUrl);
      // this.addData.picture = fileUrl;
    },
    init: function init() {
      var self = this;
      self.getData('/api/merMessage/page', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.title = '';
      this.searchData.isPublish = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merMessage', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateMenu: function updateMenu(item) {
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.addData = JSON.parse(JSON.stringify(self.defaultData));
      self.getData('/api/merMessage/' + item.id, {}, function (res) {
        self.addData = {
          id: res.id,
          title: res.title,
          brief: res.brief,
          content: res.content,
          isPublish: res.isPublish,
          receiveLevel: res.receiveLevel + '' };

      });
    },
    onUpdate: function onUpdate() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merMessage/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merMessage/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    enableMenu: function enableMenu(item) {
      var self = this;
      self.postData('/api/merMessage/' + item.id + '/publish', {}, function (res) {
        self.$message({
          message: '已发布!',
          type: 'success' });

        self.init();
      });
    },
    disableMenu: function disableMenu(item) {
      var self = this;
      self.postData('/api/merMessage/' + item.id + '/unpublish', {}, function (res) {
        self.$message({
          message: '已下架!',
          type: 'success' });

        self.init();
      });
    } } };exports.default = _default;