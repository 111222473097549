import { render, staticRenderFns } from "./newsPeportDetail.vue?vue&type=template&id=42f88dfa&scoped=true&"
import script from "./newsPeportDetail.vue?vue&type=script&lang=js&"
export * from "./newsPeportDetail.vue?vue&type=script&lang=js&"
import style0 from "./newsPeportDetail.vue?vue&type=style&index=0&id=42f88dfa&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f88dfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42f88dfa')) {
      api.createRecord('42f88dfa', component.options)
    } else {
      api.reload('42f88dfa', component.options)
    }
    module.hot.accept("./newsPeportDetail.vue?vue&type=template&id=42f88dfa&scoped=true&", function () {
      api.rerender('42f88dfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/basics/newsPeportDetail.vue"
export default component.exports