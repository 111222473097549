var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入设备序列号",
              clearable: ""
            },
            model: {
              value: _vm.searchData.sn,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "sn", $$v)
              },
              expression: "searchData.sn"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择设备型号",
                clearable: ""
              },
              model: {
                value: _vm.searchData.deviceTypeCode,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "deviceTypeCode", $$v)
                },
                expression: "searchData.deviceTypeCode"
              }
            },
            _vm._l(_vm.typeCodeList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item, value: item }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                filterable: "",
                placeholder: "请选择厂商",
                clearable: ""
              },
              model: {
                value: _vm.searchData.commModuleFactoryId,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "commModuleFactoryId", $$v)
                },
                expression: "searchData.commModuleFactoryId"
              }
            },
            _vm._l(_vm.commModuleFactoryList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: { click: _vm.addFun }
            },
            [_vm._v("批量升级")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("\n\t\t\t显示/隐藏列\n\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "升级失败原因", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 2
                      ? _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("failReasonFilter")(scope.row.failReason)
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "250", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              title: "重新升级"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("重新升级")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "1000px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.qrData,
                "tooltip-effect": "dark",
                border: "",
                stripe: ""
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "50" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sn", label: "设备序列号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "productTypeCode", label: "设备型号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commModuleFactoryName",
                  label: "(通信)模块厂商"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "commModuleVersionName", label: "模块型号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "commVersion", label: "通信模块版本" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "centerVersion", label: "中心板版本" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm._f("statusFilter")(scope.row.status)) +
                            "\n\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上报时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm._f("dateFilter")(scope.row.updateTime)) +
                            "\n\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: { "label-width": "0px", size: "small" }
            },
            [
              _vm.title == "批量升级"
                ? _c(
                    "el-form-item",
                    { staticClass: "anniu" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("批量升级")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }