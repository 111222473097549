"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));









































































































































































































var _aliOss = _interopRequireDefault(require("@/utils/aliOss"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {return { tableData: [], // 列表数据
      qrData: [], // 二维码详情列表数据
      depotData: [], outstorageData: [], optionsList: [], networkList: [], typeCodeList: [], isEnableList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }], datalist: [], nos: [], imgName: '', checked: false, searchData: { upgradeName: '', upgradeCode: '', upgradeVersion: '', productTypeCode: '', isEnable: '', merName: '', networkName: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, qrSData: { sn: '', productTypeCode: '', sns: [], merCodes: [], networkCodes: [], page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, qsPageData: { page: 1, rows: 10, total: 0, pages: 1 }, props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: true }, title: '', title2: '', addData: { id: '', name: '', version: '', productTypeCode: '', downloadType: '0', ctype: '0', isForceUpgrade: '', isEnable: '', remarks: '', merCodes: [], networkCodes: [], sns: [], filePath: '' }, defaultData: { id: '', name: '', version: '', productTypeCode: '', downloadType: '0', ctype: '0', isForceUpgrade: '', isEnable: '', remarks: '', merCodes: [], networkCodes: [], sns: [], filePath: '' }, rules: { name: [{ required: true, message: '请输入升级计划名称', trigger: 'blur' }], version: [{ required: true, message: '请输入语音板版本', trigger: 'blur' }], productTypeCode: [{ required: true, message: '请选择产品型号', trigger: 'change' }], filePath: [{ required: true, message: '请上传固件文件', trigger: 'change' }], merCodes: [{ required: true, message: '请选择渠道商', trigger: 'change' }], sns: [{ required: true, message: '请选择序列号', trigger: 'change' }], isForceUpgrade: [{ required: true, message: '请选择是否强制升级', trigger: 'change' }], isEnable: [{ required: true, message: '请选择是否启用', trigger: 'change' }] }, newadd: false, newadd2: false, newadd3: false, // 表格列
      tableColumns: [{ prop: 'name', label: '升级计划名称', isShow: true }, { prop: 'code', label: '编码', isShow: true }, { prop: 'version', label: '语音版本', isShow: true }, { prop: 'productTypeCode', label: '设备型号', isShow: true }, { prop: 'downloadType', label: '下载方式', formatter: _formatter.downloadTypeFormatter, isShow: true }, { prop: 'isEnable', label: '是否启用', formatter: _formatter.isEnableFormatter, isShow: true }, { prop: 'createTime', label: '上传时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'upgradeTime', label: '升级时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'merNames', label: '渠道商', isShow: true }, { prop: 'networkNames', label: '网点', isShow: true }, { prop: 'remarks', label: '备注',
        isShow: true }],


      modelVersionList: [{ name: 'WiFi', id: 'A' }, { name: '4G', id: 'B' }, { name: '2G', id: 'C' }],
      factoryList: [
      { name: '同安蒙发利', value: '同安蒙发利' },
      { name: '集美蒙发利', value: '集美蒙发利' },
      { name: '漳州蒙发利', value: '漳州蒙发利' },
      { name: '怡和电子', value: '怡和电子' },
      { name: '深圳蒙发利', value: '深圳蒙发利' }] };


  },
  filters: {
    statusFilters: function statusFilters(val) {
      if (val == '0') {
        return '空闲';
      } else if (val == '1') {
        return '使用中';
      } else if (val == '2') {
        return '离线';
      } else if (val == '3') {
        return '暂停到复位';
      } else if (val == '4') {
        return '暂停';
      } else if (val == '5') {
        return '故障';
      } else if (val == '6') {
        return '升级中';
      } else if (val == '7') {
        return '时间到复位';
      } else {
        return val;
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/firmwareUpgrade/ctype/0', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.upgradeName = '';
      this.searchData.upgradeCode = '';
      this.searchData.upgradeVersion = '';
      this.searchData.productTypeCode = '';
      this.searchData.isEnable = '';
      this.searchData.merName = '';
      this.searchData.networkName = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        console.log('res:', res);
        self.optionsList = res;
      });
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.merCodes = val;
      if (this.addData.merCodes.length != 0) {
        this.getNetwork();
      } else {
        this.addData.networkCodes = [];
      }
    },
    getNetwork: function getNetwork() {
      var self = this;
      self.postData('/api/merNetwork/list/merCodes', {
        merCodes: self.addData.merCodes },
      function (res) {
        self.networkList = res;
      });
    },
    checkedFun: function checkedFun(item) {
      var self = this;
      if (item == true) {
        self.doObj(self.optionsList);
        self.addData.merCodes = self.datalist;
      } else if (item == false) {
        self.addData.merCodes = [];
        self.datalist = [];
      }
    },
    doObj: function doObj(datas) {
      var self = this;
      for (var i in datas) {
        if (datas[i].subMers && datas[i].subMers.length > 0) {
          self.doObj(datas[i].subMers);
        }
        self.datalist.push(datas[i].code);
      }
    },
    onChoice: function onChoice() {
      var self = this;
      if (self.depotData.length > 0) {
        var sns = [];
        for (var i in self.depotData) {
          sns.push(self.depotData[i].sn);
        }
        self.qrSData.sns = sns;
      }
      if (self.addData.merCodes.length > 0) {
        self.qrSData.merCodes = [];
        self.qrSData.merCodes = self.addData.merCodes;
      } else {
        self.$message({
          message: '请先选择产品型号和渠道商!',
          type: 'warning' });

        return false;
      }
      if (self.addData.networkCodes.length > 0) {
        self.qrSData.networkCodes = [];
        self.qrSData.networkCodes = self.addData.networkCodes;
      }
      if (self.addData.productTypeCode == '') {
        self.$message({
          message: '请先选择产品型号和渠道商!',
          type: 'warning' });

        return false;
      } else {
        self.qrSData.productTypeCode = self.addData.productTypeCode;
      }
      self.postData('/api/firmwareUpgrade/device', self.qrSData, function (res) {
        console.log('res:', res);
        self.outstorageData = res.list;
        self.qsPageData.page = res.pageNum;
        self.qsPageData.total = res.total;
        self.title2 = '选择设备';
        self.newadd3 = true;
      });
    },
    resetFun2: function resetFun2() {
      // 重置
      // this.qrSData.productTypeCode = '';
      this.qrSData.sn = '';
      this.onChoice();
    },
    searchFun2: function searchFun2() {
      // 搜索事件
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.qrSData.rows = val;
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.qrSData.page = val;
      this.onChoice();
    },
    delDepotMenu: function delDepotMenu(index, rows) {
      console.log('index:', index);
      console.log('rows:', rows);
      rows.splice(index, 1);
      console.log(this.depotData);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.choiceStr = val;
    },
    onOutstorage: function onOutstorage() {
      var self = this;
      if (self.depotData.length > 0) {var _self$depotData;
        (_self$depotData = self.depotData).push.apply(_self$depotData, (0, _toConsumableArray2.default)(self.choiceStr));
      } else {
        self.depotData = self.choiceStr;
      }
      self.newadd3 = false;
      console.log(self.depotData);
    },
    onCencal2: function onCencal2() {
      this.choiceStr = [];
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      this.newadd3 = false;
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '新增';
      this.depotData = [];
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      this.checked = false;
      this.datalist = [];
      done();
    },
    closeRefund3: function closeRefund3(done) {
      // 重置新增列表
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.checked = false;
      this.datalist = [];
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      console.log('self.depotData:', self.depotData);
      if (self.depotData.length > 0) {
        self.addData.sns = [];
        for (var i in self.depotData) {
          self.addData.sns.push(self.depotData[i].sn);
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/firmwareUpgrade', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      // 编辑弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.getData('/api/firmwareUpgrade/' + item.id, {}, function (res) {
        console.log('res:', res);
        self.addData = {
          id: res.id,
          name: res.name,
          version: res.version,
          productTypeCode: res.productTypeCode,
          ctype: res.ctype,
          downloadType: res.downloadType,
          isForceUpgrade: res.isForceUpgrade + '',
          isEnable: res.isEnable + '',
          remarks: res.remarks,
          merCodes: res.merCodes,
          networkCodes: res.networkCodes,
          sns: res.sns,
          filePath: res.filePath };

        if (self.addData.merCodes.length != 0) {
          self.getNetwork();
        } else {
          self.addData.networkCodes = [];
        }
        self.depotData = res.sns;
        self.addData.sns = [];
        for (var i in res.sns) {
          self.addData.sns.push(res.sns[i].sn);
        }
      });
    },
    onUpdate: function onUpdate() {
      // 编辑弹窗提交
      var self = this;
      if (self.depotData.length > 0) {
        self.addData.sns = [];
        for (var i in self.depotData) {
          self.addData.sns.push(self.depotData[i].sn);
        }
      } else {
        self.addData.sns = [];
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/firmwareUpgrade/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/firmwareUpgrade/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    getFile: function getFile(event) {
      this.addData.importFile = event.target.files[0];
      console.log('importFile', this.addData.importFile);
    },
    // 固件上传
    handleChangePic: function handleChangePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    fnUploadRequest: function fnUploadRequest(option) {
      var path = 'mxsg/voice';
      _aliOss.default.ossUploadFile(option, path);
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var fileName = file.name;
      var confirm = '确定移除' + fileName + '？';
      return this.$confirm(confirm);
    },
    handleOnRemove: function handleOnRemove(file, fileList) {
      this.addData.filePath = '';
      this.$message('移除' + file.name + '成功');
    },
    onsuccess: function onsuccess(res) {
      var fileUrl = 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + res.name;
      this.addData.filePath = fileUrl;
    },
    openCopy: function openCopy(item) {
      var self = this;
      self.addData.filePath = item.filePath;
      self.newadd2 = true;
    },
    copyFun: function copyFun() {
      var url = document.querySelector('#copyObj');
      url.select(); // 选择对象
      document.execCommand('Copy');
      this.closeDiglog();
    },
    closeDiglog: function closeDiglog() {// 关闭弹出层
      this.$refs['addForm'].resetFields();
      this.newadd2 = false;
    } } };exports.default = _default;