var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入公告标题",
              clearable: ""
            },
            model: {
              value: _vm.searchData.title,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "title", $$v)
              },
              expression: "searchData.title"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择是否启用",
                filterable: "",
                clearable: ""
              },
              model: {
                value: _vm.searchData.enable,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "enable", $$v)
                },
                expression: "searchData.enable"
              }
            },
            _vm._l(_vm.enableList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t显示/隐藏列\n\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "imageUrl", label: "活动图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "upload-img",
                      attrs: {
                        src: scope.row.imageUrl,
                        "preview-src-list": _vm.srcImgList,
                        alt: "",
                        width: "100"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "310", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", title: "编辑", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.updateItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.delMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _vm._v(" "),
                    scope.row.enable == "0"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.enableMenu(scope.row)
                              }
                            }
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.enable == "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.disableMenu(scope.row)
                              }
                            }
                          },
                          [_vm._v("禁用")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "620px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动公告标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.addData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "title", $$v)
                      },
                      expression: "addData.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告内容备注", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px" },
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      maxlength: "256",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.addData.content,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "content", $$v)
                      },
                      expression: "addData.content"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渠道商", prop: "merCodeList" } },
                [
                  _c("el-cascader", {
                    ref: "downLoadLvlRef",
                    staticClass: "filter-item",
                    staticStyle: { width: "360px" },
                    attrs: {
                      options: _vm.optionsList,
                      props: _vm.props,
                      "collapse-tags": "",
                      filterable: "",
                      clearable: ""
                    },
                    on: { change: _vm.changeMechanism },
                    model: {
                      value: _vm.addData.merCodeList,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "merCodeList", $$v)
                      },
                      expression: "addData.merCodeList"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      on: {
                        change: function($event) {
                          return _vm.checkedFun(_vm.checked)
                        }
                      },
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    },
                    [_vm._v("全选")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "420px" },
                  attrs: { label: "是否启用", prop: "enable" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.addData.enable,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "enable", $$v)
                        },
                        expression: "addData.enable"
                      }
                    },
                    [_vm._v("禁用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.addData.enable,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "enable", $$v)
                        },
                        expression: "addData.enable"
                      }
                    },
                    [_vm._v("启用")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动时间", prop: "startTime" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间"
                    },
                    model: {
                      value: _vm.addData.startTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "startTime", $$v)
                      },
                      expression: "addData.startTime"
                    }
                  }),
                  _vm._v("\n\t\t\t\t至\n\t\t\t\t"),
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间"
                    },
                    model: {
                      value: _vm.addData.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "endTime", $$v)
                      },
                      expression: "addData.endTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "链接", prop: "link" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px" },
                    model: {
                      value: _vm.addData.link,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "link", $$v)
                      },
                      expression: "addData.link"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动图片", prop: "imageUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        "http-request": _vm.fnUploadRequest,
                        action: "string",
                        "before-remove": _vm.beforeRemove,
                        "on-remove": _vm.handleOnRemove,
                        "on-success": _vm.onsuccess,
                        "on-change": _vm.handleChangePic,
                        "show-file-list": false,
                        accept: ".png"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "colred" }, [
                        _vm._v(
                          "提示：图片上传png格式，图片小于750*1334（像素）"
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("img", {
                  attrs: { src: _vm.addData.imageUrl, width: "150", alt: "" }
                })
              ]),
              _vm._v(" "),
              _vm.title == "新增"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }