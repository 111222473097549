"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;








var _validate = require("@/utils/validate"); //
//
//
//
//
//
//
//
var _default = { props: { to: { type: String, required: true } },
  methods: {
    linkProps: function linkProps(url) {
      if ((0, _validate.isExternal)(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener' };

      }
      return {
        is: 'router-link',
        to: url };

    } } };exports.default = _default;