var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c(
      "div",
      { staticClass: "dashboard-text" },
      [
        _vm._v(" 尊敬的 "),
        _c("el-tag", [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" 用户, 欢迎使用摩享靠垫")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }