"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;





















































var _formatter = require("@/utils/formatter");
var _user = require("@/utils/user"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'organization', data: function data() {return { tableData: [], // 列表数据
      newadd: false, // 新增弹窗
      tableColumns: [{ prop: 'insName', label: '组织名称', isShow: true }, { prop: 'status', label: '状态', formatter: _formatter.statusFormatter, isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: true }], title: '新增', addData: { // 新增数据
        id: '', parentId: 0, // 父级ID
        insName: '', status: '1' }, addDefault: { // 新增数据
        id: '', parentId: 0, // 父级ID
        insName: '', status: '1' }, optionsList: [], props: { children: 'child', label: 'insName', value: 'id', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover' }, child: [], hasUrl: false, rules: { insName: [{ required: true, message: '请输写组织名称', trigger: 'blur' }] } };
  },
  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/institution/list', {}, function (res) {
        self.tableData = res;
      });
    },
    checkPermission: _user.checkPermission,
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onSubmit: function onSubmit() {
      // 提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/institution/add', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.init();
            self.onCencal();
          });
        } else {
          return false;
        }
      });
    },
    onUpdate: function onUpdate(item) {
      // 编辑提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.$confirm('确定修改该记录吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true }).

          then(function () {
            self.putData('/api/institution/edit/' + self.addData.id, self.addData, function (res) {
              self.$message({
                message: '修改成功!',
                type: 'success' });

              self.init();
              self.onCencal();
            });
          }).
          catch(function () {});
        } else {
          return false;
        }
      });
    },
    updateMenu: function updateMenu(item) {
      // 编辑弹窗
      console.log(item);
      this.title = '编辑';
      this.getDeviceTypeList();
      this.addData = {
        id: item.id,
        insName: item.insName,
        parentId: item.parentId,
        status: item.status };

      this.newadd = true;
    },
    delMenu: function delMenu(delId) {
      // 删除记录
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/institution/del/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    addMenu: function addMenu() {
      // 新增弹窗
      var self = this;
      self.title = '新增';
      self.addData = JSON.parse(JSON.stringify(self.addDefault));
      self.getDeviceTypeList();
      self.newadd = true;
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/institution/list', {}, function (res) {
        console.log('res:', res);
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.parentId = val;
    } } };exports.default = _default;