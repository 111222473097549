var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { type: "text", placeholder: "请输入名称", clearable: "" },
            model: {
              value: _vm.searchData.name,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "name", $$v)
              },
              expression: "searchData.name"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { type: "text", placeholder: "请输入联系人", clearable: "" },
            model: {
              value: _vm.searchData.contact,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "contact", $$v)
              },
              expression: "searchData.contact"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { type: "text", placeholder: "请输入手机号", clearable: "" },
            model: {
              value: _vm.searchData.phone,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "phone", $$v)
              },
              expression: "searchData.phone"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t显示/隐藏列\n\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "合作渠道商" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm._f("merNameFilters")(scope.row.merName)) +
                        "\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "310", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", title: "编辑", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.updateItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.delMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "850px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { maxlength: "20" },
                            model: {
                              value: _vm.addData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "name", $$v)
                              },
                              expression: "addData.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { maxlength: "11" },
                            model: {
                              value: _vm.addData.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "phone", $$v)
                              },
                              expression: "addData.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "contact" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { maxlength: "20" },
                            model: {
                              value: _vm.addData.contact,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "contact", $$v)
                              },
                              expression: "addData.contact"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "是否官方设置", prop: "isOfficial" }
                        },
                        [
                          _c("el-switch", {
                            staticStyle: {
                              width: "200px",
                              "text-align": "center"
                            },
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#2d8cf0",
                              "inactive-color": "#cccccc",
                              "active-text": "是",
                              "inactive-text": "否"
                            },
                            model: {
                              value: _vm.addData.isOfficial,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "isOfficial", $$v)
                              },
                              expression: "addData.isOfficial"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.addData.isOfficial == 0
                ? _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合作渠道商", prop: "name" } },
                            [
                              _c("el-transfer", {
                                attrs: {
                                  filterable: "",
                                  "render-content": _vm.renderFunc,
                                  titles: ["未合作渠道商", "已合作渠道商"],
                                  "button-texts": ["移除", "添加"],
                                  format: {
                                    noChecked: "${total}",
                                    hasChecked: "${checked}/${total}"
                                  },
                                  data: _vm.uncooperativeData
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.cooperatedData,
                                  callback: function($$v) {
                                    _vm.cooperatedData = $$v
                                  },
                                  expression: "cooperatedData"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "添加"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }