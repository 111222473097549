"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;







































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'debug', data: function data() {return { searchForm: { sn: '' }, timeRange: '', pickerOptions: { disabledDate: function disabledDate(time) {return time.getTime() > Date.now();} }, page: 1, //页码
      rows: 10, //每页条数
      pageData: { page: 1, rows: 10, total: 0, pages: 1 }, tableData: [], //表格数据
      tableColumns: [{ props: 'sn', label: '设备序列号', isShow: true }, { props: 'log', label: '功能', isShow: true }, { props: 'ts', label: '时间', isShow: true, formatter: _formatter.dateFormatter }] };},

  mounted: function mounted() {
    this.fetchDeviceDebugList();
  },

  methods: {
    /**
              * 查询
              */
    searchFun: function searchFun() {
      this.page = 1;
      this.fetchDeviceDebugList();
    },

    /**
        * 重置
        */
    resetFun: function resetFun() {
      this.$set(this.searchForm, 'sn', '');
      this.$set(this, 'timeRange', '');
      this.page = 1;
      this.rows = 10;
      this.fetchFeedbackList();
    },

    /**
        * 导出
        */
    excelFun: function excelFun() {

    },

    /**
        * 获取设备调试日志列表
        */
    fetchDeviceDebugList: function fetchDeviceDebugList() {var _this = this;
      var params = Object.assign({}, this.searchForm);
      params.page = this.page;
      params.rows = this.rows;
      if (this.timeRange) {
        params.startTime = this.timeRange[0];
        params.endTime = this.timeRange[1];
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      this.getData('/api/device/debuglog', params, function (res) {
        // console.log('res====>',JSON.stringify(res))
        _this.tableData = res.list;
        _this.pageData.page = res.pageNum;
        _this.pageData.total = res.total;
      });
    },

    /**
        * 每页size-change
        */
    handleSizeChange: function handleSizeChange(val) {
      this.rows = val;
      this.page = 1;
      this.fetchDeviceDebugList();
    },

    /**
        * page-change
        */
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.fetchDeviceDebugList();
    } } };exports.default = _default;