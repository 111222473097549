"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));
















var _vuex = require("vuex"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { props: { // channelValue: {
    //   type: Array,
    //   default: () => []
    // }
    // 使用{}不报错：[Vue warn]: Invalid prop: type check failed for prop "channelValue". Expected Object, got Array
    channelValue: {} }, data: function data() {return { mechanism: [], // 并没有什么作用，使用props中的channelValue作为父组件传递过来的变量即可
      defaultProps: { children: 'subMers', label: 'name', value: 'id', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: true } };}, computed: (0, _objectSpread2.default)({},
  (0, _vuex.mapGetters)({
    channelList: 'channelList' })),


  // 并没有什么作用，使用props中的channelValue作为父组件传递过来的变量即可，否则组件只有在值改变的时候才能显示数据，初始化的时候不会
  // watch: {
  //   channelValue: 'changeValue'
  // },
  created: function created() {
    this.channelList.length === 0 ? this.getAddress() : '';
  },
  mounted: function mounted() {
    // 实时刷新
    this.getAddress();
  },
  methods: {
    // changeValue: function() {
    // },
    getAddress: function getAddress() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.$store.dispatch('channel/setChannelList', res);
      });
    },
    changeAddress: function changeAddress(val) {
      // var tString = val.toString()
      this.$emit('relChannelCheckBox', val);
    } } };exports.default = _default;