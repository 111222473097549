import { render, staticRenderFns } from "./news.vue?vue&type=template&id=638edb7d&"
import script from "./news.vue?vue&type=script&lang=js&"
export * from "./news.vue?vue&type=script&lang=js&"
import style0 from "./news.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('638edb7d')) {
      api.createRecord('638edb7d', component.options)
    } else {
      api.reload('638edb7d', component.options)
    }
    module.hot.accept("./news.vue?vue&type=template&id=638edb7d&", function () {
      api.rerender('638edb7d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/firmware/news.vue"
export default component.exports