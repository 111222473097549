"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;


















































































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'census', data: function data() {return { timeRange: '', //时间范围
      timeRange1: '', //时间范围
      tableData: [], // 列表数据
      deviceData: [], // 弹窗列表数据
      optionsList: [], // 渠道商下拉数据
      networkCodeList: [], // 网点下拉数据
      sceneCodeList: [], // 场景类型下拉数据
      tableColumns: [{ prop: 'merchantNameL1', label: '一级渠道商', isShow: true }, { prop: 'merchantNameL2', label: '二级渠道商', isShow: true }, { prop: 'merchantCodeL1', label: '一级渠道商编码', isShow: false }, { prop: 'merchantCodeL2', label: '二级渠道商编码', isShow: false }, { prop: 'agreement', label: '渠道商属性', formatter: _formatter.cooperationFormatter, isShow: true }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'networkCode', label: '网点编码', isShow: true }, { prop: 'sceneNameL1', label: '场景类型', isShow: true // 是否显示
      }, { prop: 'networkAddress', label: '网点地址', isShow: true }, { prop: 'networkCreateTime', label: '网点创建时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'incomeTotal', label: '实收总金额(元)', isShow: true }, { prop: 'packageTotal', label: '套餐总金额(元)', isShow: true }, { prop: 'incomeAvgDay', label: '日台均收益(元)', isShow: true }], ids: '', searchData: { merchantCode: '', networkCode: '', sceneCode: '', beginTime: '', endTime: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, newadd: false, title: '', deviceSData: { sn: '', begintTime: '', endTime: '' }, networkCode: '', sns: '' };}, filters: { statusFilter: function statusFilter(val) {if (val == '0') {return '空闲';} else if (val == '1') {return '使用中';} else if (val == '2') {return '离线';} else if (val == '3') {return '暂停到复位';
      } else if (val == '4') {
        return '暂停';
      } else if (val == '5') {
        return '故障';
      } else if (val == '6') {
        return '升级中';
      } else if (val == '7') {
        return '时间到复位';
      } else {
        return val;
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDataList();
  },
  methods: {
    init: function init() {
      if (this.timeRange) {
        this.$set(this.searchData, 'beginTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'beginTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      self.getData('/api/report/network', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.merchantCode = '';
      this.searchData.networkCode = '';
      this.searchData.sceneCode = '';
      this.searchData.beginTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      var ids = [];
      for (var i in val) {
        ids.push(val[i].networkId);
      }
      self.ids = ids.toString();
      console.log("ids:", ids);
      console.log("self.ids:", self.ids);
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDataList: function getDataList() {
      var self = this;
      // 网点下拉
      self.getData('/api/merNetwork/droplist', {}, function (res) {
        self.networkCodeList = res;
      });
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
      // 场景类型下拉
      self.getData('/api/merScene/primary', {}, function (res) {
        self.sceneCodeList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.merchantCode = val || '';
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      done();
    },
    lookMenu: function lookMenu(item) {
      var self = this;
      self.networkCode = item.networkCode;
      self.init2();
    },
    init2: function init2() {
      if (this.timeRange1) {
        this.$set(this.deviceSData, 'beginTime', this.timeRange1[0]);
        this.$set(this.deviceSData, 'endTime', this.timeRange1[1]);
      } else {
        this.$set(this.deviceSData, 'beginTime', '');
        this.$set(this.deviceSData, 'endTime', '');
      }
      var self = this;
      self.getData('/api/report/network/device/' + self.networkCode, self.deviceSData, function (res) {
        self.deviceData = res;
        self.title = '查看设备';
        self.newadd = true;
      });
    },
    handleSelectionChange2: function handleSelectionChange2(val) {
      console.log(val);
      var self = this;
      var sns = [];
      for (var i in val) {
        sns.push(val[i].sn);
      }
      self.sns = sns.toString();
      console.log("sns:", sns);
      console.log("self.sns:", self.sns);
    },
    // dailog
    resetFun2: function resetFun2() {
      // 重置
      this.deviceSData.sn = '';
      this.deviceSData.beginTime = '';
      this.deviceSData.endTime = '';
      this.timeRange1 = '';
      this.init2();
    },
    searchFun2: function searchFun2() {
      // 搜索事件
      this.init2();
    },
    excelNetworkFun: function excelNetworkFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'beginTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'beginTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/report/network/export?merchantCode=' +
      self.searchData.merchantCode +
      '&networkCode=' +
      self.searchData.networkCode +
      '&sceneCode=' +
      self.searchData.sceneCode +
      '&beginTime=' +
      self.searchData.beginTime +
      '&endTime=' +
      self.searchData.endTime +
      '&ids=' +
      self.ids;
    },
    excelDeviceFun: function excelDeviceFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'beginTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'beginTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/report/network/export/device?merchantCode=' +
      self.searchData.merchantCode +
      '&networkCode=' +
      self.searchData.networkCode +
      '&sceneCode=' +
      self.searchData.sceneCode +
      '&beginTime=' +
      self.searchData.beginTime +
      '&endTime=' +
      self.searchData.endTime +
      '&ids=' +
      self.ids;
    },
    excelFun: function excelFun() {
      if (this.timeRange1) {
        this.$set(this.deviceSData, 'beginTime', this.timeRange1[0]);
        this.$set(this.deviceSData, 'endTime', this.timeRange1[1]);
      } else {
        this.$set(this.deviceSData, 'beginTime', '');
        this.$set(this.deviceSData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/report/network/device/' + self.networkCode + '/export?sn=' +
      self.deviceSData.sn +
      '&beginTime=' +
      self.deviceSData.beginTime +
      '&endTime=' +
      self.deviceSData.endTime +
      '&sns=' +
      self.sns;
    } } };exports.default = _default;