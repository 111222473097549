"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.constructor");require("core-js/modules/es6.regexp.replace"); /**
                                                                                                                                                                                                      * 工具类
                                                                                                                                                                                                      */var _default =
{
  /**
   * 时间日期格式化
   * @param format
   * @returns {*}
   */
  dateFormat: function dateFormat(dateObj, format) {
    var date = {
      'M+': dateObj.getMonth() + 1,
      'd+': dateObj.getDate(),
      'h+': dateObj.getHours(),
      'm+': dateObj.getMinutes(),
      's+': dateObj.getSeconds(),
      'q+': Math.floor((dateObj.getMonth() + 3) / 3),
      'S+': dateObj.getMilliseconds() };

    if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in date) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ?
        date[k] : ('00' + date[k]).substr(('' + date[k]).length));
      }
    }
    return format;
  } };exports.default = _default;