"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.to-string");




































































































































































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'information', data: function data() {return { tableData: [], // 列表数据
      mTableData: [], // 价格模板列表
      totalCount: '', // 总设备数
      onlineCount: '', // 设备在线数
      offlineCount: '', // 设备离线数
      faultCount: '', // 设备故障数
      insIdList: [], typeCodeList: [], deviceStatusList: [{ name: '离线', id: '2' }, { name: '空闲', id: '0' }, { name: '使用中', id: '1' }, { name: '暂停到复位', id: '3' }, { name: '暂停', id: '4' }, { name: '故障', id: '5' }, { name: '升级中', id: '6' }, { name: '时间到复位', id: '7' }], statusList: [{ name: '已发货', id: '0' }, { name: '未绑定未上架', id: '4' }, { name: '未绑定已上架', id: '5' }, { name: '已绑定未上架', id: '6' }, { name: '已绑定已上架', id: '7' }], networkCodeList: [], optionsList: [], // 渠道商下拉列表
      networkList: [], // 网点下拉列表
      positionRowList: [], // 行下拉列表
      positionColumnList: [], // 列下拉列表
      props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, droplist: [], sns: [], searchData: { insId: '', sn: '', typeCode: '', deviceStatus: '', merchantCode: '', networkCode: '', status: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, showDialog: false, deviceCode: '', newadd: false, newadd2: false, title: '新增', addData: { sn: '', merchantCode: '', networkCode: '', positionRow: '', positionColumn: '', importFile: '' }, defaultData: { sn: '', merchantCode: '', networkCode: '', positionRow: '', positionColumn: '', importFile: '' }, // 表格列
      tableColumns: [{ prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'typeCode', label: '设备型号', isShow: true }, { prop: 'insName', label: '运营组织', isShow: true }, { prop: 'merchantNameL1', label: '一级渠道商', isShow: true }, { prop: 'merchantNameL2', label: '二级渠道商', isShow: true }, { prop: 'networkName', label: '网点名称', isShow: false }, { prop: 'positionRow', label: '行号', isShow: false }, { prop: 'positionColumn', label: '列号', isShow: false }, { prop: 'networkAddress', label: '网点地址', isShow: false }, // {
      // 	prop: 'businessStatus',
      // 	label: '运营状态',
      // 	formatter: businessStatusFormatter,
      // 	isShow: true
      // },
      { prop: 'operateTime', label: '运营时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'runningMinutes', label: '运行时长', isShow: true }, { prop: 'deviceStatus', label: '设备状态', formatter: _formatter.deviceStatusFormatter, isShow: true }, { prop: 'refundRate', label: '退款率(%)', formatter: _formatter.refundRateFormatter, isShow: true }], rules: { merchantCode: [{ required: true, message: '请选择渠道商', trigger: 'change' }], networkCode: [{ required: true, message: '请选择网点', trigger: 'change' }] } };}, filters: { ctypeFilters: function ctypeFilters(val) {if (val == '1') {return '单个';} else if (val == '2') {return '多个';} else {return val;}}, templateNameFilters: function templateNameFilters(val) {if (val == null) {return '无模板名称';} else {return val;}}, businessStatusFilters: function businessStatusFilters(val) {if (val.businessStatus == 0) {return '已发货';} else {if (val.bindStatus == 1 && val.putStatus == 1) {return '已绑定已上架';} else if (val.bindStatus == 0 && val.putStatus == 1) {return '未绑定已上架';} else if (val.bindStatus == 1 && val.putStatus == 0) {return '已绑定未上架';} else if (val.bindStatus == 0 && val.putStatus == 0) {return '未绑定未上架';}}} }, mounted: function mounted() {var self = this;self.init();self.getDataList(); // self.getdrop();
  }, methods: {
    init: function init() {
      var self = this;
      var url = '/api/device/list';
      self.getData(url, self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
      self.getData('/api/device/count', {}, function (res) {
        self.totalCount = res.totalCount;
        self.onlineCount = res.onlineCount;
        self.offlineCount = res.offlineCount;
        self.faultCount = res.faultCount;
      });
    },
    getDataList: function getDataList() {
      var self = this;
      // 运营组织下拉
      self.getData('/api/institution/list', {}, function (res) {
        self.insIdList = res;
      });
      // 设备型号下拉
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
      // 网点下拉
      self.getData('/api/merNetwork/droplist', {}, function (res) {
        self.networkCodeList = res;
      });
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      if (val) {
        this.searchData.merIds = val.toString();
      } else {
        this.searchData.merIds = '';
      }
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.insId = '';
      this.searchData.sn = '';
      this.searchData.typeCode = '';
      this.searchData.deviceStatus = '';
      this.searchData.networkCode = '';
      this.searchData.status = '';
      this.searchData.merchantCode = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      done();
    },
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var self = this;
      self.sns = [];
      val.map(function (item) {
        self.sns.push(item.sn);
      });
    },
    batchUpgradeFun: function batchUpgradeFun() {
      var self = this;
      if (self.sns.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择要上架的数据',
          type: 'error' });

      } else {
        self.$confirm('确定修改该记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true }).

        then(function () {
          self.postData('/api/device/business', {
            status: '1',
            sns: self.sns },
          function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.init();
          });
        }).
        catch(function () {});
      }
    },
    batchNoUpgradeFun: function batchNoUpgradeFun() {
      var self = this;
      if (self.sns.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择要下架的数据',
          type: 'error' });

      } else {
        self.$confirm('确定修改该记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true }).

        then(function () {
          self.postData('/api/device/business', {
            status: '0',
            sns: self.sns },
          function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.init();
          });
        }).
        catch(function () {});
      }
    },
    UnboundFun: function UnboundFun() {
      var self = this;
      if (self.sns.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择要解绑的数据',
          type: 'error' });

      } else {
        self.$confirm('确定修改该记录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true }).

        then(function () {
          self.postData('/api/device/business', {
            status: '2',
            sns: self.sns },
          function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.init();
          });
        }).
        catch(function () {});
      }
    },
    templateFun: function templateFun(item) {
      console.log(item);
      var self = this;
      self.title = '价格模板';
      self.getData('/api/merTemplateDetail/temp/' + item.templateId, {}, function (res) {
        self.mTableData = res;
        self.newadd2 = true;
      });
    },
    bindingFun: function bindingFun(item) {
      console.log(item);
      var self = this;
      this.title = '绑定';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
      self.addData.sn = item.sn;
    },
    jbFun: function jbFun(item) {
      var self = this;
      var status = '';
      var sns = [];
      sns.push(item.sn);
      self.postData('/api/device/business', {
        status: '2',
        sns: sns },
      function (res) {
        self.$message({
          message: '操作成功!',
          type: 'success' });

        self.init();
      });
    },
    putStatusFun: function putStatusFun(item) {
      console.log(item);
      var self = this;
      var status = '';
      var sns = [];
      if (item.putStatus == '0') {
        status = '1';
      } else if (item.putStatus == '1') {
        status = '0';
      }
      sns.push(item.sn);
      self.postData('/api/device/business', {
        status: status,
        sns: sns },
      function (res) {
        self.$message({
          message: '操作成功!',
          type: 'success' });

        self.init();
      });
    },
    changeMechanism2: function changeMechanism2() {
      var self = this;
      self.getData('/api/merNetwork/droplist/' + self.addData.merchantCode, {}, function (res) {
        self.networkList = res;
        self.addData.networkCode = '';
        self.addData.positionRow = '';
        self.addData.positionColumn = '';
      });
    },
    networkCodeFun: function networkCodeFun() {
      var self = this;
      self.getData('/api/merNetworkRank/list/' + self.addData.networkCode, {}, function (res) {
        self.positionRowList = res;
        self.addData.positionRow = '';
        self.addData.positionColumn = '';
      });
    },
    positionRowFun: function positionRowFun() {
      var self = this;
      self.getData('/api/merNetworkRank/list/' + self.addData.networkCode + '/' + self.addData.positionRow, {}, function (res) {
        self.positionColumnList = res;
        self.addData.positionColumn = '';
      });
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/device/bind', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    download: function download() {
      window.location.href = 'https://manual.cozzia.com.cn/download/设备信息导入模板.xlsx';
    },
    importFun: function importFun() {
      var self = this;
      self.title = '导入';
      self.newadd = true;
      document.getElementById("daoru").value = '';
    },
    getFile: function getFile(event) {
      this.addData.importFile = event.target.files[0];
      console.log('importFile', this.addData.importFile);
    },
    onImport: function onImport(event) {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          var File = new FormData();
          File.append('importFile', self.addData.importFile);
          self.postData('/api/device/import', File, function (res) {
            console.log('res:', res);
            // if (response.data.isSuccess == true) {
            // 	self.$message({
            // 		message: '导入成功!',
            // 		type: 'success'
            // 	});
            // } else {
            // 	if (response.data.errorNos != '' && response.data.repeatNos != '') {
            // 		self.$notify.error({
            // 			title: '导入异常提示',
            // 			message: response.data.messages,
            // 			duration: 0,
            // 			position: 'top-right'
            // 		});
            // 	}else{
            // 		if (response.data.repeatNos != '') {
            // 			self.$notify.error({
            // 				title: '导入异常提示',
            // 				message: response.data.messages,
            // 				duration: 0
            // 			});
            // 		}
            // 		if (response.data.errorNos != '') {
            // 			self.$notify.error({
            // 				title: '导入异常提示',
            // 				message: response.data.messages,
            // 				duration: 0,
            // 				position: 'top-right'
            // 			});
            // 		}
            // 	}
            // }
            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    excelFun: function excelFun() {
      var self = this;
      window.location.href =
      '/api/device/export?insId=' +
      self.searchData.insId +
      '&typeCode=' +
      self.searchData.typeCode +
      '&merchantCode=' +
      self.searchData.merchantCode +
      '&networkCode=' +
      self.searchData.networkCode +
      '&deviceStatus=' +
      self.searchData.deviceStatus +
      '&status=' +
      self.searchData.status +
      '&sns=' +
      self.sns +
      '&sn=' +
      self.searchData.sn;
    } } };exports.default = _default;