"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
var _default =
{
  name: 'ThirdAppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    } } };exports.default = _default;