"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.adminPage = adminPage;

var _request = _interopRequireDefault(require("@/utils/request")); /* 系统管理 相关api */

function adminPage(params) {
  return (0, _request.default)({
    url: '/api/admin/page',
    method: 'get',
    params: params // get不能使用data
  });
}