"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");

































































































































var _aliOss = _interopRequireDefault(require("@/utils/aliOss"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'voiceBoard', data: function data() {return { tableData: [], // 列表数据
      typeCodeList: [], commModuleFactoryList: [], commModuleVersionList: [], isEnableList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }], searchData: { ctype: '0', productTypeCode: '', version: '', isEnable: '', startTime: '', endTime: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: true }, title: '', addData: { id: '', ctype: '0', productTypeCode: '', version: '', filePath: '', isEnable: '', remarks: '', downloadType: '0' }, defaultData: { id: '', ctype: '0', productTypeCode: '', version: '', filePath: '', isEnable: '', remarks: '', downloadType: '0' }, rules: { version: [{ required: true, message: '请输入语音板版本', trigger: 'blur' }], productTypeCode: [{ required: true, message: '请选择产品型号', trigger: 'change' }], filePath: [{ required: true, message: '请上传固件文件', trigger: 'change' }], isEnable: [{ required: true, message: '请选择是否启用', trigger: 'change' }] }, newadd: false, newadd2: false, // 表格列
      tableColumns: [{ prop: 'productTypeCode', label: '设备型号', isShow: true }, { prop: 'version', label: '语音版本号', isShow: true }, { prop: 'downloadType', label: '下载方式', formatter: _formatter.downloadTypeFormatter, isShow: true }, { prop: 'isEnable', label: '是否启用', formatter: _formatter.isEnableFormatter, isShow: true }, { prop: 'createTime', label: '上传时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'remarks', label: '备注', isShow: true }] };}, mounted: function mounted() {var self = this;self.init();self.getDeviceTypeList();self.getcommModuleVersion();}, methods: { init: function init() {
      var self = this;
      self.getData('/api/factoryFirmware', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.productTypeCode = '';
      this.searchData.version = '';
      this.searchData.isEnable = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        console.log('res11111:', res);
        self.typeCodeList = res;
      });
      // 通讯模块厂商
      self.getData('/api/commModuleFactory/list', {}, function (res) {
        self.commModuleFactoryList = res;
      });
    },
    getcommModuleVersion: function getcommModuleVersion(item) {
      var self = this;
      var productTypeCode = '';
      if (item != undefined) {
        productTypeCode = item;
      }
      self.getData('/api/factoryFirmware/list', {
        ctype: '0',
        productTypeCode: productTypeCode },
      function (res) {
        if (res != []) {
          self.commModuleVersionList = res;
        }
      });
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/factoryFirmware', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      // 编辑弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.getData('/api/factoryFirmware/' + item.id, {}, function (res) {
        console.log('res:', res);
        self.addData = {
          id: res.id,
          version: res.version,
          productTypeCode: res.productTypeCode,
          ctype: res.ctype,
          downloadType: res.downloadType,
          commModuleVersionId: res.commModuleVersionId,
          isEnable: res.isEnable + '',
          remarks: res.remarks,
          filePath: res.filePath };

      });
    },
    onUpdate: function onUpdate() {
      // 编辑弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/factoryFirmware/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/factoryFirmware/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    qEnableFun: function qEnableFun(item) {
      var self = this;
      self.postData('/api/factoryFirmware/' + item.id + '/enable/1', self.addData, function (res) {
        self.$message({
          message: '启用成功!',
          type: 'success' });

        self.init();
      });
    },
    jEnableFun: function jEnableFun(item) {
      var self = this;
      self.postData('/api/factoryFirmware/' + item.id + '/enable/0', self.addData, function (res) {
        self.$message({
          message: '禁用成功!',
          type: 'success' });

        self.init();
      });
    },
    getFile: function getFile(event) {
      this.addData.importFile = event.target.files[0];
      console.log('importFile', this.addData.importFile);
    },
    // 固件上传
    handleChangePic: function handleChangePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    fnUploadRequest: function fnUploadRequest(option) {
      var path = 'mxsg/voiceBoard';
      _aliOss.default.ossUploadFile(option, path);
      console.log('res.name:', option.name);
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var fileName = file.name;
      var confirm = '确定移除' + fileName + '?';
      return this.$confirm(confirm);
    },
    handleOnRemove: function handleOnRemove(file, fileList) {
      this.addData.filePath = '';
      this.$message('移除' + file.name + '成功');
    },
    onsuccess: function onsuccess(res) {
      var fileUrl = 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + res.name;
      this.addData.filePath = fileUrl;
    },
    openCopy: function openCopy(item) {
      var self = this;
      self.addData.filePath = item.filePath;
      self.newadd2 = true;
    },
    copyFun: function copyFun() {
      var url = document.querySelector('#copyObj');
      url.select(); // 选择对象
      document.execCommand('Copy');
      this.closeDiglog();
    },
    closeDiglog: function closeDiglog() {// 关闭弹出层
      this.$refs['addForm'].resetFields();
      this.newadd2 = false;
    } } };exports.default = _default;