"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");require("core-js/modules/es6.regexp.to-string");require("core-js/modules/es6.regexp.constructor");require("core-js/modules/es6.regexp.replace");require("core-js/modules/web.dom.iterable");var _vue = _interopRequireDefault(require("vue"));
var _axios = _interopRequireDefault(require("axios"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _store = _interopRequireDefault(require("@/store"));






_vue.default.prototype.showHide = function (data) {
  data = !data;
};

/**
    *  设置对齐方式：居中
    *  用于table表格得表头和内容居中
    */
_vue.default.prototype.isCenter = function () {
  return 'text-align: center';
};

_vue.default.prototype.getData = function (url, data, callback) {// get
  var self = this;
  var loading = this.$loading({
    background: 'rgba(0,0,0,0)' });

  (0, _request.default)({
    url: url,
    method: 'get',
    params: data }).
  then(function (response) {
    console.log('resposne===->', response);
    setTimeout(function () {
      loading.close();
    }, 100);
    if (response.errCode === '0') {
      return callback(response.data);
    } else if (response.errCode === 'E1001') {
      console.log('ressdasdasdasdas');
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      self.$alert(response.errMsg, '错误提示', {
        confirmButtonText: '确定' });

    }
  }).
  catch(function (error) {
    setTimeout(function () {
      loading.close();
    }, 100);
    console.log(error);
  });
  // axios.get(url, { params: data })
  //   .then(function(response) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     if (response.data.errCode === '0') {
  //       return callback(response.data.data)
  //     } else if (response.data.errCode === 'E1001') {
  //       self.$router.push({ path: '/login' })
  //     } else {
  //       self.$alert(response.data.errMsg, '错误提示', {
  //         confirmButtonText: '确定'
  //       })
  //     }
  //   })
  //   .catch(function(error) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     console.log(error)
  //   })
};

_vue.default.prototype.getSelectData = function (url, data, callback) {// 没有加载动画的get
  (0, _request.default)({
    url: url,
    method: 'get',
    params: data }).
  then(function (response) {
    if (response.errCode === '0') {
      return callback(response.data);
    } else {
      return [];
    }
  }).catch(function (error) {
    console.log(error);
    return [];
  });
  // axios.get(url, { params: data })
  //   .then(function(response) {
  //     if (response.data.errCode === '0') {
  //       return callback(response.data.data)
  //     } else {
  //       return []
  //     }
  //   })
  //   .catch(function(error) {
  //     console.log(error)
  //     return []
  //   })
};

_vue.default.prototype.delData = function (url, data, callback) {// 删除
  var self = this;
  var loading = this.$loading({
    background: 'rgba(0,0,0,0)' });

  (0, _request.default)({
    url: url,
    method: 'delete',
    params: data }).
  then(function (response) {
    setTimeout(function () {
      loading.close();
    }, 100);
    if (response.errCode === '0') {
      return callback(response.data);
    } else if (response.errCode === 'E1001') {
      // self.$router.push({ path: '/login' })
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      self.$alert(response.errMsg, '错误提示', {
        confirmButtonText: '确定' });

    }
  }).catch(function (error) {
    setTimeout(function () {
      loading.close();
    }, 100);
    console.log(error);
  });
  // axios.delete(url, { params: data })
  //   .then(function(response) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     if (response.data.errCode === '0') {
  //       return callback(response.data.data)
  //     } else if (response.data.errCode === 'E1001') {
  //       self.$router.push({ path: '/login' })
  //     } else {
  //       self.$alert(response.data.errMsg, '错误提示', {
  //         confirmButtonText: '确定'
  //       })
  //     }
  //   })
  //   .catch(function(error) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     console.log(error)
  //   })
};

_vue.default.prototype.putData = function (url, data, callback) {// 删除
  var self = this;
  var loading = this.$loading({
    background: 'rgba(0,0,0,0)' });

  (0, _request.default)({
    url: url,
    method: 'put',
    data: data }).
  then(function (response) {
    setTimeout(function () {
      loading.close();
    }, 100);
    if (response.errCode === '0') {
      return callback(response.data);
    } else if (response.errCode === 'E1001') {
      // self.$router.push({ path: '/login' })
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      self.$alert(response.errMsg, '错误提示', {
        confirmButtonText: '确定' });

    }
  }).catch(function (error) {
    setTimeout(function () {
      loading.close();
    }, 100);
    console.log(error);
  });
  // axios.put(url, data)
  //   .then(function(response) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     if (response.data.errCode === '0') {
  //       return callback(response.data.data)
  //     } else if (response.data.errCode === 'E1001') {
  //       self.$router.push({ path: '/login' })
  //     } else {
  //       self.$alert(response.data.errMsg, '错误提示', {
  //         confirmButtonText: '确定'
  //       })
  //     }
  //   })
  //   .catch(function(error) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     console.log(error)
  //   })
};

_vue.default.prototype.postData = function (url, data, callback) {// post
  var self = this;
  var loading = this.$loading({
    background: 'rgba(0,0,0,0)' });

  (0, _request.default)({
    url: url,
    method: 'post',
    data: data }).
  then(function (response) {
    setTimeout(function () {
      loading.close();
    }, 100);
    if (response.errCode === '0') {
      return callback(response.data);
    } else if (response.errCode === 'E1001') {
      // self.$alert(response.errMsg, '错误提示', {
      //   confirmButtonText: '确定'
      // })
      // self.$router.push({ path: '/login' })
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      self.$alert(response.errMsg, '错误提示', {
        confirmButtonText: '确定' });

    }
  }).catch(function (error) {
    setTimeout(function () {
      loading.close();
    }, 100);
    console.log(error);
  });
  // axios.post(url, data)
  //   .then(function(response) {

  //   })
  //   .catch(function(error) {

  //   })
};

/**
    * @description 对象转url参数
    * @param {object} data,对象
    * @param {Boolean} isPrefix,是否自动加上"?"
    * @param {string} arrayFormat 规则 indices|brackets|repeat|comma
    */
_vue.default.prototype.queryParams = function queryParams() {var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};var isPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;var arrayFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'brackets';
  var prefix = isPrefix ? '?' : '';
  var _result = [];
  if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) == -1) arrayFormat = 'brackets';var _loop = function _loop(
  key) {
    var value = data[key];
    // 去掉为空的参数
    if (['', undefined, null].indexOf(value) >= 0) {
      return "continue";
    }
    // 如果值为数组，另行处理
    if (value.constructor === Array) {
      // e.g. {ids: [1, 2, 3]}
      switch (arrayFormat) {
        case 'indices':
          // 结果: ids[0]=1&ids[1]=2&ids[2]=3
          for (var i = 0; i < value.length; i++) {
            _result.push("".concat(key, "[").concat(i, "]=").concat(value[i]));
          }
          break;
        case 'brackets':
          // 结果: ids[]=1&ids[]=2&ids[]=3
          value.forEach(function (_value) {
            _result.push("".concat(key, "[]=").concat(_value));
          });
          break;
        case 'repeat':
          // 结果: ids=1&ids=2&ids=3
          value.forEach(function (_value) {
            _result.push("".concat(key, "=").concat(_value));
          });
          break;
        case 'comma':
          // 结果: ids=1,2,3
          var commaStr = '';
          value.forEach(function (_value) {
            commaStr += (commaStr ? ',' : '') + _value;
          });
          _result.push("".concat(key, "=").concat(commaStr));
          break;
        default:
          value.forEach(function (_value) {
            _result.push("".concat(key, "[]=").concat(_value));
          });}

    } else {
      _result.push("".concat(key, "=").concat(value));
    }};for (var key in data) {var _ret = _loop(key);if (_ret === "continue") continue;
  }
  return _result.length ? prefix + _result.join('&') : '';
};

var herder = {
  headers: {
    'Content-Type': 'multipart/form-data;charset=UTF-8' } };



_vue.default.prototype.postFormData = function (url, data, callback) {
  var self = this;
  var loading = this.$loading({
    background: 'rgba(0,0,0,0)' });


  (0, _request.default)({
    url: url,
    method: "POST",
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data: data }).
  then(function (response) {
    setTimeout(function () {
      loading.close();
    }, 100);

    if (response.data.errCode === '0') {
      return callback(response.data.data);
    } else if (response.data.errCode === 'E1001') {
      // self.$alert(response.errMsg, '错误提示', {
      //   confirmButtonText: '确定'
      // })
      // self.$router.push({ path: '/login' })
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else {
      self.$alert(response.data.errMsg, '错误提示', {
        confirmButtonText: '确定' });

    }
  }).catch(function (error) {
    setTimeout(function () {
      loading.close();
    }, 100);
    console.log(error);
  });
  // axios.post(url, data, herder)
  //   .then(function(response) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)

  //     if (response.data.errCode === '0') {
  //       return callback(response.data.data)
  //     } else if (response.data.errCode === 'E1001') {
  //       // self.$alert(response.data.errMsg, '错误提示', {
  //       //   confirmButtonText: '确定'
  //       // })
  //       // self.$router.push({ path: '/login' })
  //       store.dispatch('user/resetToken').then(() => {
  //         location.reload()
  //       })
  //     } else {
  //       self.$alert(response.data.errMsg, '错误提示', {
  //         confirmButtonText: '确定'
  //       })
  //     }
  //   })
  //   .catch(function(error) {
  //     setTimeout(() => {
  //       loading.close()
  //     }, 100)
  //     console.log(error)
  //   })
};

// Vue.prototype.ajaxData = function(url, data, callback) { // post
//   const self = this
//   const loading = this.$loading({
//     background: 'rgba(0,0,0,0)'
//   })
//   $.ajax({
//     type: 'POST',
//     url: url,
//     data: qs.stringify(data),
//     success: function(response) {
//       setTimeout(() => {
//         loading.close()
//       }, 100)
//       if (response.errCode === '0') {
//         return callback(response.data)
//       } else if (response.errCode === 'E1001') {
//         self.$router.push({ path: '/login' })
//       } else {
//         self.$alert(response.errMsg, '错误提示', {
//           confirmButtonText: '确定'
//         })
//       }
//     },
//     error: function(error) {
//       setTimeout(() => {
//         loading.close()
//       }, 100)
//       console.log(error)
//     }
//   })
// }

_vue.default.filter('dateFilter', function (value) {// 日期格式化
  var fmt = 'yyyy-MM-dd hh:mm:ss';
  if (!value) {
    return '';
  }
  var date = new Date(value);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds() };

  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
});

_vue.default.filter('isAbledFilter', function (value) {// 是否禁用
  switch (parseInt(value)) {
    case 0:
      return '否';
    case 1:
      return '是';
    default:
      return value;}

});

_vue.default.filter('isAuditFilter', function (value) {// 是否审核
  switch (value.toString()) {
    case 'false':
      return '未审核';
    case 'true':
      return '审核';
    default:
      return value;}

});

_vue.default.filter('isAudit2Filter', function (value) {// 是否审核
  switch (parseInt(value)) {
    case 0:
      return '未审核';
    case 1:
      return '审核';
    default:
      return value;}

});

_vue.default.filter('isPublishFilter', function (value) {// 是否审核
  switch (parseInt(value)) {
    case 0:
      return '未发布';
    case 1:
      return '发布';
    default:
      return value;}

});

_vue.default.filter('enableFilter', function (value) {// 是否禁用
  switch (parseInt(value)) {
    case 0:
      return '启用';
    case 1:
      return '禁用';
    default:
      return value;}

});

_vue.default.filter('merchantFilter', function (value) {// 是否禁用
  switch (parseInt(value)) {
    case 0:
      return '一级商户';
    case 1:
      return '二级商户';
    default:
      return value;}

});

_vue.default.filter('isEnabledFilter', function (value) {// 是否可操作
  switch (parseInt(value)) {
    case 0:
      return '是';
    case 1:
      return '否';
    default:
      return value;}

});

_vue.default.filter('payFilter', function (value) {// 支付渠道
  switch (parseInt(value)) {
    case 0:
      return '微信';
    case 1:
      return '支付宝';
    case 2:
      return '光大银行';
    case 3:
      return '免费';
    case 4:
      return '招行';
    case 5:
      return '翼支付';
    default:
      return value;}

});

_vue.default.filter('accountTypeFilter', function (value) {// 支付渠道
  switch (parseInt(value)) {
    case 0:
      return '个人';
    case 1:
      return '企业';
    default:
      return value;}

});

_vue.default.filter('dataStatusFilter', function (value) {// 支付渠道
  switch (parseInt(value)) {
    case 0:
      return '有效';
    case 1:
      return '无效';
    default:
      return value;}

});

_vue.default.filter('wdStatusFilter', function (value) {// 提现状态
  switch (parseInt(value)) {
    case 0:
      return '提现处理中';
    case 3:
      return '提现成功';
    case 4:
      return '提现失败';
    default:
      return value;}

});

_vue.default.filter('refundTypeFilter', function (value) {// 退款状态
  switch (parseInt(value)) {
    case 0:
      return '成功';
    case 1:
      return '失败';
    case 2:
      return '待处理';
    default:
      return value;}

});

_vue.default.filter('feePayerFilter', function (value) {// 手续费承担方
  switch (parseInt(value)) {
    case 0:
      return '商户平台';
    case 1:
      return '会员';
    default:
      return value;}

});

_vue.default.filter('orderTypeFilter', function (value) {// 订单状态
  switch (parseInt(value)) {
    case 0:
      return '支付成功';
    case 1:
      return '自动退款';
    case 2:
      return '手动退款';
    case 3:
      return '未支付';
    default:
      return value;}

});

_vue.default.filter('busTypeFilter', function (value) {// 经营类型
  switch (value) {
    case 1:
      return '联营';
    case 2:
      return '买断';
    case 3:
      return '直营';
    default:
      return value;}

});

// Date.prototype.Format = function(fmt) { // author: meizz
//   var o = {
//     'M+': this.getMonth() + 1, // 月份
//     'd+': this.getDate(), // 日
//     'h+': this.getHours(), // 小时
//     'm+': this.getMinutes(), // 分
//     's+': this.getSeconds(), // 秒
//     'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
//     'S': this.getMilliseconds() // 毫秒
//   }
//   if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
//   for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
//   return fmt
// }

_vue.default.prototype.formatDate = function (value) {
  var fmt = 'yyyy-MM-dd hh:mm:ss';
  if (!value) {
    return '';
  }
  var date = new Date(value);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds() };

  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
};