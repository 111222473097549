"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");var getters = {
  sidebar: function sidebar(state) {return state.app.sidebar;},
  language: function language(state) {return state.app.language;},
  size: function size(state) {return state.app.size;},
  env: function env(state) {return state.app.env;},
  device: function device(state) {return state.app.device;},
  visitedViews: function visitedViews(state) {return state.tagsView.visitedViews;},
  cachedViews: function cachedViews(state) {return state.tagsView.cachedViews;},
  showSettings: function showSettings(state) {return state.settings.showSettings;},
  token: function token(state) {return state.user.token;},
  avatar: function avatar(state) {return state.user.avatar;},
  name: function name(state) {return state.user.name;},
  introduction: function introduction(state) {return state.user.introduction;},
  roles: function roles(state) {return state.user.roles;},
  routers: function routers(state) {return state.user.routers;}, // 完整路由
  addRouters: function addRouters(state) {return state.user.addRouters;}, // 动态路由
  routerLoadSuccess: function routerLoadSuccess(state) {return state.user.routerLoadSuccess;}, // 路由是否加载成功标识
  permissions: function permissions(state) {return state.user.permissions;}, // 用户所拥有权限集合
  permissionLoadSuccess: function permissionLoadSuccess(state) {return state.user.permissionLoadSuccess;}, // 权限集合是否加载成功标识
  permission_routes: function permission_routes(state) {return state.permission.routes;},
  errorLogs: function errorLogs(state) {return state.errorLog.logs;},
  mechanismList: function mechanismList(state) {return state.mechanism.mechanismList;}, // 机构列表
  departmentList: function departmentList(state) {return state.mechanism.departmentList;}, // 部门列表
  categoryList: function categoryList(state) {return state.mechanism.categoryList;}, // 品类列表
  channelList: function channelList(state) {return state.channel.channelList;} // 渠道商列表
};var _default =
getters;exports.default = _default;