"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.replace");




















































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {var _this = this;var validatePass = function validatePass(rule, value, callback) {if (value === '') {callback(new Error('请再次输入密码'));} else if (value !== _this.addData.password) {callback(new Error('两次输入密码不一致!'));} else {callback();}};return { tableData: [], // 列表数据
      id: '', //渠道商id
      code: '', //渠道商code
      userName: '', //账号
      contactName: '', //联系人
      contactNumber: '', //联系人方式
      totalIncome: '', //总收入
      deviceCount: '', //设备总数
      hisrotyDayAvgIncome: '', //上月日台均
      currentMonthDayAvgIncome: '', //当月日台均
      searchData: { sn: '', typeCode: '', status: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { password: '', confirmPwd: '' }, defaultData: { password: '', confirmPwd: '' }, newadd: false, // 表格列
      tableColumns: [{ prop: 'sn', label: '设备编码', isShow: true }, { prop: 'typeCode', label: '设备型号', isShow: true }, { prop: 'totalIncome', label: '历史总收益', isShow: true }, { prop: 'currentMonthIncome', label: '本月收益', isShow: true }, { prop: 'todayIncome', label: '当日收益', isShow: true }, { prop: 'currentMonthlyDailyAvgIncome', label: '本月日台均', isShow: true }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'createTime', label: '运营时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'status', label: '设备状态', formatter: _formatter.deviceStatusFormatter, isShow: true }], statusList: [{ name: '空闲', id: '0' }, { name: '使用中', id: '1' }, { name: '离线', id: '2' }, { name: '暂停到复位', id: '3' }, { name: '暂停', id: '4' }, { name: '故障', id: '5' }, { name: '升级中', id: '6' }, { name: '时间到复位', id: '6' }] };}, mounted: function mounted() {var self = this;self.id = self.$route.query.id;self.code = self.$route.query.code;self.init();}, methods: { init: function init() {var self = this;self.getData('/api/merMerchant/' + self.id + '/income/statistics', {}, function (res) {self.userName = res.merInfo.userName;self.contactName = res.merInfo.contactName;self.contactNumber = res.merInfo.contactNumber;self.totalIncome = res.statistics.totalIncome;self.deviceCount = res.statistics.deviceCount;self.hisrotyDayAvgIncome = res.statistics.hisrotyDayAvgIncome;self.currentMonthDayAvgIncome = res.statistics.currentMonthDayAvgIncome;});self.getData('/api/merMerchant/' + self.id + '/device/income', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.sn = '';
      this.searchData.typeCode = '';
      this.searchData.status = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.go(-1);
    },
    consoleFun: function consoleFun() {
      var self = this;
      self.$router.push({
        path: "/channel/channelConsole",
        query: {
          id: self.id,
          code: self.code } });


    },
    resetPasswordFun: function resetPasswordFun() {
      this.title = '重置密码';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    InputKg: function InputKg(item) {
      this.addData.password = item.replace(/\s+/g, '');
    },
    InputKg2: function InputKg2(item) {
      this.addData.confirmPwd = item.replace(/\s+/g, '');
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.password.length < 6) {
        self.$message({
          showClose: true,
          message: '密码强度不够，请重填',
          type: 'error' });

        self.addData.password = '';
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merMerchant/' + self.$route.query.id + '/resetPassword', self.addData, function (res) {
            self.$message({
              message: '密码重置成功!',
              type: 'success' });

            self.onCencal();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    } } };exports.default = _default;