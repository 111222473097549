"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;





















































































































































var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'qrCode', // components: { QRCode },
  data: function data() {return { tableData: [], // 列表数据
      qrData: [], // 二维码详情列表数据
      srcImgList: [], //大图数据源
      imgName: '', searchData: { typeCode: '', sn: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, qrSData: { id: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', typeCode: '', insDeviceCode: '', modelVersion: '', factory: '', createDate: '', count: '' }, defaultData: { id: '', typeCode: '', insDeviceCode: '', modelVersion: '', factory: '', createDate: '', count: '' }, rules: { typeCode: [{ required: true, message: '请选择产品类型', trigger: 'change' }], insDeviceCode: [{ required: true, message: '请输入组织设备编码', trigger: 'blur' }], modelVersion: [{ required: true, message: '请选择模块版本', trigger: 'change' }], count: [{ required: true, message: '请输入生产数量', trigger: 'blur' }], createDate: [{ required: true, message: '请选择年月日', trigger: 'change' }] }, newadd: false, newadd2: false, // 表格列
      tableColumns: [{ prop: 'typeCode', label: '产品型号', isShow: true }, { prop: 'firstSn', label: '序列号编码', isShow: true }, { prop: 'createTime', label: '生成时间', formatter: _formatter.dateFormatter, isShow: true }], typeCodeList: [], modelVersionList: [{ name: 'WiFi', id: 'A' }, { name: '4G', id: 'B' }, { name: '2G', id: 'C' }], factoryList: [{ name: '同安蒙发利', value: '同安蒙发利' }, { name: '集美蒙发利', value: '集美蒙发利' }, { name: '漳州蒙发利', value: '漳州蒙发利' }, { name: '怡和电子', value: '怡和电子' }, { name: '深圳蒙发利', value: '深圳蒙发利' }] };}, mounted: function mounted() {var self = this;self.init();self.typeCodeFun();}, methods: { init: function init() {var self = this;self.getData('/api/factory/qr', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, resetFun: function resetFun() {// 重置
      this.searchData.typeCode = '';this.searchData.sn = '';this.init();}, searchFun: function searchFun() {// 搜索事件
      this.searchData.page = 1;this.init();}, handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;this.searchData.page = 1;this.init();}, handleCurrentChange: function handleCurrentChange(val) {// 分页页码
      this.searchData.page = val;this.init();}, countFun: function countFun(item) {var self = this;self.qrSData.id = item;self.init2();}, init2: function init2() {var self = this;
      self.title = '二维码详情';
      self.getData('/api/factory/qr/detail/' + self.qrSData.id, self.qrSData, function (res) {
        self.qrData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
        self.srcImgList = [];
        for (var img in res.list) {
          self.srcImgList.push(res.list[img].qrCodeUrl);
          // setTimeout(function() {
          //  self.qrcode(res.list[img].qrCodeUrl)
          // },500)
        }
        // setTimeout(function() {
        // 	self.bindQRCode()
        // },500)
      });
      self.newadd2 = true;
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.qrSData.rows = val;
      this.qrSData.page = 1;
      this.init2();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.qrSData.page = val;
      this.init2();
    },
    // bindQRCode: function () {
    // 	this.$nextTick(() => {
    // 		this.srcImgList.forEach((item,index)=>{
    // 			console.log('item:',this.$refs.codeItem[index])
    // 			this.$refs.codeItem[index].innerHTML="";
    // 			var qrcode = new QRCode(this.$refs.codeItem[index], {
    // 				text: item, //二维码内容
    // 				width: 100,
    // 				height: 100,
    // 				render: 'table',
    // 				colorDark: "#333333", //二维码颜色
    // 				colorLight: "#ffffff", //二维码背景色
    // 				correctLevel : QRCode.CorrectLevel.H
    // 			})
    // 			var childs = document.getElementsByClassName('qrcode')[index].childNodes;
    // 			document.getElementsByClassName('qrcode')[index].removeChild(childs[0]);
    // 		});                          
    // 	},500)
    // },
    // qrcode(item) {
    // 	console.log('item:',item)
    // 	document.getElementById('qrcode').innerHTML = ''
    // 	new QRCode('qrcode', {
    // 		width: 100,
    // 		height: 100, // 高度
    // 		text: item, // 二维码内容
    // 		render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
    // 		//			        background: 'red',
    // 		//			        foreground: 'green',
    // 	})
    //   //		      	downloadButton: true
    // },
    typeCodeFun: function typeCodeFun() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '生成二维码';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.merCode = val;
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/factory/qr/add', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    downLoadItem: function downLoadItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      window.location.href = '/api/factory/qr/download?id=' + item.id;
      // self.getData('/api/factory/qr/download?id=' + item.id, {}, function(res) {
      // 	console.log(res)
      // });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/factory/qr/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    lookView: function lookView(item) {// 查看弹窗
      this.title = '查看';
      console.log('item:', item);
      $('#dialog2').show();
      this.addData.code = item.code;
      this.qrcode(item);
    },
    onCencal2: function onCencal2() {
      $('#dialog2').hide();
      this.imgName = '';
      // window.location.reload()
    },
    qrcode: function qrcode(item) {
      console.log('item:', item);
      this.imgName = item.sn;
      document.getElementById('qrcode').innerHTML = '';
      new _qrcodejs.default('qrcode', {
        width: 200,
        height: 200, // 高度
        text: item.qrCodeUrl, // 二维码内容
        render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        //			        background: 'red',
        //			        foreground: 'green',
      });
      //		      	downloadButton: true
    },
    downloadQrcode: function downloadQrcode() {
      var qrcode = document.getElementById('qrcode');
      var img = qrcode.getElementsByTagName('img')[0];
      var link = document.createElement('a');
      var url = img.getAttribute('src');
      link.setAttribute('href', url);
      link.setAttribute('download', this.imgName + '.png');
      link.click();
      this.onCencal2();
    } } };exports.default = _default;