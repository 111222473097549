var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入套餐名称",
              clearable: ""
            },
            model: {
              value: _vm.searchData.name,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "name", $$v)
              },
              expression: "searchData.name"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "success", icon: "el-icon-back" },
              on: { click: _vm.returnFun }
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-plus" },
              on: { click: _vm.mouldFun }
            },
            [_vm._v("使用模板")]
          ),
          _vm._v(" "),
          _vm.templates.ctype != "1" && _vm.templates.isSelected != "1"
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addFun }
                },
                [_vm._v("新增套餐")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "success" },
              on: { click: _vm.copyFun }
            },
            [_vm._v("复制创建模板")]
          ),
          _vm._v(" "),
          _vm.templates.ctype != "1" && _vm.templates.isSelected != "1"
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.preserveFun }
                },
                [_vm._v("保存并使用模板")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t\t显示/隐藏列\n\t\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          ref: "eltableCurrentRow",
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": "",
            "row-key": "id",
            "default-expand-all": "",
            "tree-props": { children: "childrens" }
          }
        },
        [
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.prop == "packageType" && column.isShow
                ? _c("el-table-column", {
                    attrs: { type: "index", label: "套餐类型", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.comboType == 0 ||
                                      scope.row.comboType == null
                                      ? "普通套餐"
                                      : "特殊套餐"
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              column.isShow && column.prop != "packageType"
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _vm.templates.ctype != "1" && _vm.templates.isSelected != "1"
            ? _c("el-table-column", {
                attrs: { label: "操作", width: "310", prop: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            [
                              scope.row.addBtn == true &&
                              scope.row.menuType == 1 &&
                              scope.row.comboType == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "success",
                                        title: "加单",
                                        size: "mini"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addItem(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("加单")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    title: "编辑",
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editPackageOrMarket(
                                        scope.row,
                                        scope.row.menuType
                                      )
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delPackageOrMarket(
                                        scope.row.id,
                                        scope.row.menuType
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2900777173
                )
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "430px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "130px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "4" },
                    model: {
                      value: _vm.addData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "name", $$v)
                      },
                      expression: "addData.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择时长", prop: "time" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 60 },
                    model: {
                      value: _vm.addData.time,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "time", $$v)
                      },
                      expression: "addData.time"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格", prop: "price" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.addData.price,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "price", $$v)
                      },
                      expression: "addData.price"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "档次", prop: "orderNum" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15", placeholder: "请输入数字整数" },
                    model: {
                      value: _vm.addData.orderNum,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "orderNum", $$v)
                      },
                      expression: "addData.orderNum"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推荐该套餐" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      on: {
                        change: function($event) {
                          return _vm.tuijianFun(
                            _vm.addData.recommend,
                            _vm.tuijian
                          )
                        }
                      },
                      model: {
                        value: _vm.addData.recommend,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "recommend", $$v)
                        },
                        expression: "addData.recommend"
                      }
                    },
                    [_vm._v("否")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        label: "1",
                        disabled:
                          _vm.tuijian == true && _vm.tuijianZt != _vm.addData.id
                            ? true
                            : false
                      },
                      on: {
                        change: function($event) {
                          return _vm.tuijianFun(
                            _vm.addData.recommend,
                            _vm.tuijian
                          )
                        }
                      },
                      model: {
                        value: _vm.addData.recommend,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "recommend", $$v)
                        },
                        expression: "addData.recommend"
                      }
                    },
                    [_vm._v("是")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角标", prop: "angleMark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      maxlength: "4",
                      disabled: _vm.addData.recommend == "0" ? false : true
                    },
                    model: {
                      value: _vm.addData.angleMark,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "angleMark", $$v)
                      },
                      expression: "addData.angleMark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动名称", prop: "actId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        placeholder: "请选择活动名称",
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.addData.actId,
                        callback: function($$v) {
                          _vm.$set(_vm.addData, "actId", $$v)
                        },
                        expression: "addData.actId"
                      }
                    },
                    _vm._l(_vm.actIdList, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "说明", prop: "remarks" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      maxlength: "256",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.addData.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "remarks", $$v)
                      },
                      expression: "addData.remarks"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "新增套餐"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("新增套餐")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "500px",
            visible: _vm.newadd2,
            "before-close": _vm.closeRefund2,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd2 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "mouldForm",
              staticClass: "mark",
              attrs: {
                model: _vm.mouldData,
                rules: _vm.specialRules,
                size: "small"
              }
            },
            [
              _c(
                "el-table",
                {
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.mTableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: ""
                  },
                  on: { "current-change": _vm.handleRadio }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "55",
                      align: "center",
                      fixed: "left"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: scope.row },
                                model: {
                                  value: _vm.mouldData.contentId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.mouldData, "contentId", $$v)
                                  },
                                  expression: "mouldData.contentId"
                                }
                              },
                              [_c("i")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "模板名称" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "创建时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm._f("dateFilter")(scope.row.createTime)
                                ) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "使用模板"
                ? _c(
                    "el-form-item",
                    { staticClass: "center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onMouldSubmit }
                        },
                        [_vm._v("使用模板")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal2 } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "430px",
            visible: _vm.newadd3,
            "before-close": _vm.closeRefund3,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd3 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm2",
              staticClass: "mark",
              attrs: {
                model: _vm.addOrderData,
                rules: _vm.rules,
                "label-width": "130px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "加按摩套餐时间", prop: "time" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.addOrderData.time,
                      callback: function($$v) {
                        _vm.$set(_vm.addOrderData, "time", $$v)
                      },
                      expression: "addOrderData.time"
                    }
                  }),
                  _vm._v(" 分钟\n\t\t\t\t")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "套餐费用", prop: "price" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.addOrderData.price,
                      callback: function($$v) {
                        _vm.$set(_vm.addOrderData, "price", $$v)
                      },
                      expression: "addOrderData.price"
                    }
                  }),
                  _vm._v(" 元\n\t\t\t\t")
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "升级加单套餐"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _vm.title2 == "加单"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onAddOrderFun }
                            },
                            [_vm._v("确定")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.title2 == "加单修改"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onUpdateFun }
                            },
                            [_vm._v("确定")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal3 } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.specialForm.id > 0 ? "编辑特殊套餐" : "新增特殊套餐",
            center: "",
            width: "430px",
            visible: _vm.newAdd5,
            "before-close": _vm.closeRefund5,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newAdd5 = $event
            },
            close: _vm.onSpecialDialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "special",
              staticClass: "mark",
              attrs: {
                rules: _vm.specialRules,
                model: _vm.specialForm,
                "label-width": "130px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "4", clearable: "" },
                    model: {
                      value: _vm.specialForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.specialForm, "name", $$v)
                      },
                      expression: "specialForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择时长", prop: "time" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 60 },
                    model: {
                      value: _vm.specialForm.time,
                      callback: function($$v) {
                        _vm.$set(_vm.specialForm, "time", $$v)
                      },
                      expression: "specialForm.time"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格", prop: "price" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.specialForm.price,
                      callback: function($$v) {
                        _vm.$set(_vm.specialForm, "price", $$v)
                      },
                      expression: "specialForm.price"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "套餐模式", prop: "specialType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.specialForm.specialType,
                        callback: function($$v) {
                          _vm.$set(_vm.specialForm, "specialType", $$v)
                        },
                        expression: "specialForm.specialType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.hasCharge || _vm.specialForm.id > 0,
                            label: "2"
                          }
                        },
                        [_vm._v("充电")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.hasDouble || _vm.specialForm.id > 0,
                            label: "1"
                          }
                        },
                        [_vm._v("双人按摩")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推荐该套餐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.specialForm.recommend,
                        callback: function($$v) {
                          _vm.$set(_vm.specialForm, "recommend", $$v)
                        },
                        expression: "specialForm.recommend"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否")]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled:
                              _vm.tuijian == true &&
                              _vm.tuijianZt != _vm.addData.id
                                ? true
                                : false,
                            label: "1"
                          }
                        },
                        [_vm._v("是")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角标", prop: "angleMark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      clearable: "",
                      maxlength: "4",
                      disabled: _vm.specialForm.recommend == 1
                    },
                    model: {
                      value: _vm.specialForm.angleMark,
                      callback: function($$v) {
                        _vm.$set(_vm.specialForm, "angleMark", $$v)
                      },
                      expression: "specialForm.angleMark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onSpecialSubmit }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.specialForm.id > 0 ? "保存" : "新增套餐")
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.onSpecialCancel } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            title: "请选择新增的套餐类型",
            width: "430px",
            visible: _vm.newAdd4,
            "before-close": _vm.closeRefund4,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newAdd4 = $event
            },
            close: _vm.closeRefund4
          }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.packageType,
                callback: function($$v) {
                  _vm.packageType = $$v
                },
                expression: "packageType"
              }
            },
            [
              _vm._l(_vm.packageTypeList, function(item, index) {
                return [
                  item.enable
                    ? _c("el-radio", {
                        key: item.id,
                        staticStyle: {
                          "margin-bottom": "20px",
                          "margin-left": "0"
                        },
                        attrs: { label: item.label, border: "" }
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { slot: "footer", type: "primary" },
              on: { click: _vm.onConfirmType },
              slot: "footer"
            },
            [_vm._v("确定")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "续单套餐",
            width: "430px",
            visible: _vm.newadd6,
            "before-close": _vm.closeRefund6,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd6 = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm2",
              staticClass: "mark",
              attrs: {
                model: _vm.renewOrder,
                rules: _vm.rules,
                "label-width": "130px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "续单套餐时间", prop: "time" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.renewOrder.time,
                      callback: function($$v) {
                        _vm.$set(_vm.renewOrder, "time", $$v)
                      },
                      expression: "renewOrder.time"
                    }
                  }),
                  _vm._v(" 分钟\n    \t\t")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "套餐费用", prop: "price" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { maxlength: "15" },
                    model: {
                      value: _vm.renewOrder.price,
                      callback: function($$v) {
                        _vm.$set(_vm.renewOrder, "price", $$v)
                      },
                      expression: "renewOrder.price"
                    }
                  }),
                  _vm._v(" 元\n    \t\t")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.newadd6 = false
                        }
                      }
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.newadd6 = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }