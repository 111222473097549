"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;




















































































var _mechanism = _interopRequireDefault(require("@/components/Cascader/mechanism.vue"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意：vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'rolegllist', components: { Mechanism: _mechanism.default }, data: function data() {return { tableData: [], // 列表数据
      tableColumns: [{ prop: 'roleName', label: '名称', isShow: true }, { prop: 'insName', label: '组织名称', isShow: true }, { prop: 'createBy', label: '创建者', isShow: false }, { prop: 'status', label: '状态', formatter: _formatter.statusFormatter, isShow: true // 是否显示
      }, { prop: 'createDate', label: '创建时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }], searchData: { roleName: '', // 角色名称
        insName: '', //组织名称
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, newadd: false, title: '新增', addData: { id: '', roleName: '', insId: '', //	roleType: '',
        status: 0 //  isfilter: 0
      }, defaultData: { roleName: '', insId: '', //	roleType: '',
        status: 0 //	isfilter: 0
      }, rules: { roleName: [{ required: true, message: '请输入名称', trigger: 'blur' }], insId: [{ required: true, message: '请选择所属组织', trigger: 'change' }] } };}, mounted: function mounted() {var self = this;self.init();}, methods: { init: function init() {var self = this;self.getData('/api/role/page', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.roleName = '';
      this.searchData.insName = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    updateMenu: function updateMenu(item) {
      this.title = '编辑';
      this.newadd = true;
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.addData.id = item.id;
      this.addData.roleName = item.roleName;
      this.addData.insId = item.insId;
      //				this.addData.roleType = item.roleType,
      this.addData.status = item.status;
      //				this.addData.isfilter = item.isfilter
    },
    delMenu: function delMenu(delId) {
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/role/role/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },

    lookView: function lookView(item) {
      this.title = '查看';
      this.newadd = true;
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.addData.roleName = item.roleName;
      this.addData.insId = item.insId;
      //				this.addData.roleType = item.roleType,
      this.addData.status = item.status;
      //				this.addData.isfilter = item.isfilter
    },

    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onUpdate: function onUpdate() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/role/role/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onSubmit: function onSubmit() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/role/role', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.insId = val;
    } } };exports.default = _default;