"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.array.find");require("core-js/modules/es6.function.name");require("core-js/modules/web.dom.iterable");
































































































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {return { tableData: [], // 列表数据
      tableData2: [], arrMin: {}, mTableData: [], actIdList: [], templates: {}, searchData: { name: '', program: '', networkId: '', tempId: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', title2: '', addData: { id: '', name: '', // ctype: '',
        // program: '',
        time: '1', price: '', orderNum: '', angleMark: '', remarks: '', tempType: '3', networkId: '', actId: '', recommend: '0', comboType: 0 }, defaultData: { id: '', name: '', // ctype: '',
        // program: '',
        time: '1', price: '', orderNum: '', angleMark: '', remarks: '', tempType: '3', networkId: '', actId: '', recommend: '0', comboType: 0 }, addOrderData: { time: '', price: '', tempDetailId: '' }, defaultOrderData: { time: '', price: '', tempDetailId: '' }, mouldData: { contentId: '', tempType: '3' }, rules: { name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }], price: [{ required: true, message: '请输入价格', trigger: 'blur' }], orderNum: [{ required: true, message: '请输入档次', trigger: 'blur' }] }, newadd: false, newadd2: false, newadd3: false, // 表格列
      tableColumns: [{ prop: 'packageType', label: '套餐类型', isShow: true }, { prop: 'name', label: '套餐名称', isShow: true }, // {
      //  prop: 'ctype',
      // 	label: '套餐类型',
      // 	formatter: ctypeFormatter,
      // 	isShow: true
      // },
      // {
      //  prop: 'program',
      // 	label: '套餐程序',
      // 	isShow: true
      // },
      { prop: 'price', label: '金额', isShow: true }, { prop: 'time', label: '时长', isShow: true }, { prop: 'orderNum', label: '档次', isShow: true }, { prop: 'remarks', label: '说明', isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: false }], tuijianZt: 0, // 推荐状态
      // programList: [
      // 	{ name: '肩部按摩', id: '0' },
      // 	{ name: '腰部按摩', id: '1' },
      // 	{ name: '全身按摩', id: '2' },
      // 	{ name: '定时按摩', id: '3' },
      // 	{ name: '充电按摩', id: '4' }
      // ],
      // ctypeList: [
      // 	{ name: '单个', id: '1' },
      // 	{ name: '多个', id: '2' }
      // ],
      newAdd4: false, newAdd5: false, newadd6: false, renewOrder: { time: '', price: '' }, packageTypeList: [{ label: '普通套餐', id: 1, enable: true }, { label: '特殊套餐', id: 2, enable: true }], packageType: 0, //当前选择的套餐类型
      specialForm: { ctype: 1, program: 1, name: '', time: '', angleMark: '', recommend: '0', price: '', orderNum: '', comboType: 1, specialType: 0, tempType: '3' }, defSpecialForm: { ctype: 1, program: 1, name: '', time: '', angleMark: '', recommend: '0', price: '', orderNum: '', comboType: 1, specialType: 0, tempType: '3' }, specialRules: { name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }], price: [{ required: true, message: '请输入价格', trigger: 'blur' }], time: [{ required: true, message: '请选择套餐时间', trigger: 'blur' }], specialType: [{ required: true, message: '请选择套餐模式', trigger: 'blur' }] }, currentPackage: '' //当前套餐
    };}, watch: { "specialForm.recommend": { handler: function handler(val) {if (parseInt(val) == 1) {this.$set(this.specialForm, 'angleMark', '');}}, deep: true } }, computed: { // 是否推荐
    tuijian: function tuijian() {var tj = false;var arr = [];for (var i in this.tableData) {arr.push(parseInt(this.tableData[i].recommend));}console.log('arr:', arr);if (arr.indexOf(1) == -1) {this.tuijianZt = 0;
        return tj = false;
      } else {
        this.tuijianZt = this.tableData[arr.indexOf(1)].id;
        return tj = true;
      }
    },
    onlyCommon: function onlyCommon() {
      //是否已有双人及充电套餐
      return this.hasDouble && this.hasCharge;
    },
    hasDouble: function hasDouble() {
      //是否已有双人套餐
      var has = false;
      this.tableData.forEach(function (e) {
        if (e.comboType == 1 && e.specialType == 1) {
          has = true;
        }
      });
      return has;
    },
    hasCharge: function hasCharge() {
      //是否已有充电套餐
      var has = false;
      this.tableData.forEach(function (e) {
        if (e.comboType == 1 && e.specialType == 2) {
          has = true;
        }
      });
      return has;
    } },

  mounted: function mounted() {
    var self = this;
    self.searchData.networkId = self.$route.params.parentId;
    self.addData.networkId = self.$route.params.parentId;
    self.defaultData.networkId = self.$route.params.parentId;
    self.init();
    self.getActId();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/merTemplateDetail/3/page', self.searchData, function (res) {
        self.tableData2 = res.pageResult.list;
        self.pageData.page = res.pageResult.pageNum;
        self.pageData.total = res.pageResult.total;
        self.templates = res.template;
        self.searchData.tempId = res.template.id;
        // 加单查询
        self.tableData = [];
        for (var i in self.tableData2) {
          self.getMerTemplateDetailMarket(self.tableData2[i]);
        }
      });
    },
    getMerTemplateDetailMarket: function getMerTemplateDetailMarket(item) {
      var self = this;
      self.arrMin = {};
      var bigArr = {};
      self.getData('/api/merTemplateDetailMarket', {
        detailId: item.id },
      function (res) {
        if (res.length > 0) {
          for (var j in res) {
            if (item.id == res[j].tempDetailId) {
              self.arrMin = {
                id: res[j].id,
                name: '加单',
                createTime: res[j].createTime,
                price: res[j].price,
                time: res[j].time,
                tempDetailId: res[j].tempDetailId,
                orderNum: '',
                remarks: '',
                menuType: '2' };

              bigArr = {
                id: item.id,
                createTime: item.createTime,
                name: item.name,
                orderNum: item.orderNum,
                price: item.price,
                remarks: item.remarks,
                time: item.time,
                angleMark: item.angleMark,
                actId: item.actId,
                insId: item.insId,
                tempId: item.tempId,
                childrens: [],
                addBtn: false,
                menuType: '1',
                recommend: item.recommend + '',
                comboType: item.comboType,
                specialType: item.specialType };

              bigArr.childrens.push(self.arrMin);
              self.tableData.push(bigArr);
            }
          }
        } else {
          bigArr = {
            id: item.id,
            createTime: item.createTime,
            name: item.name,
            orderNum: item.orderNum,
            price: item.price,
            remarks: item.remarks,
            time: item.time,
            angleMark: item.angleMark,
            actId: item.actId,
            insId: item.insId,
            tempId: item.tempId,
            childrens: [],
            addBtn: true,
            menuType: '1',
            recommend: item.recommend + '',
            comboType: item.comboType,
            specialType: item.specialType };

          self.tableData.push(bigArr);
        }
      });
    },
    mInit: function mInit() {
      var self = this;
      self.getData('/api/merTemplate/list/official', self.searchData, function (res) {
        self.mTableData = res;
      });
    },
    getActId: function getActId() {
      var self = this;
      self.getData('/api/actActivity/experience', {}, function (res) {
        self.actIdList = res;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.name = '';
      this.searchData.program = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增套餐
      if (this.onlyCommon) {
        this.title = '新增套餐';
        this.addData = JSON.parse(JSON.stringify(this.defaultData));
        this.newadd = true;
      } else {
        this.newAdd4 = true;
      }
    },
    copyFun: function copyFun() {
      var self = this;
      self.$confirm('确定复制并创建模板吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.postData('/api/merTemplateDetail/copyTemp/3/' + self.$route.params.parentId, {}, function (res) {
          self.tableData2 = res.pageResult.list;
          self.pageData.page = res.pageResult.pageNum;
          self.pageData.total = res.pageResult.total;
          self.templates = res.template;
          self.searchData.tempId = res.template.id;
          self.addData.tempId = res.template.id;
          // 加单查询
          self.tableData = [];
          for (var i in self.tableData2) {
            self.getMerTemplateDetailMarket(self.tableData2[i]);
          }
          self.$message({
            message: '复制创建成功!',
            type: 'success' });

        });
      }).
      catch(function () {});
    },
    preserveFun: function preserveFun() {
      var self = this;
      self.postData('/api/merTemplateDetail/syncNetwork/' + self.$route.params.parentId + '/' + self.templates.id, {}, function (res) {
        self.$message({
          message: '保存并使用模板成功!',
          type: 'success' });

        self.init();
      });
    },
    mouldFun: function mouldFun() {
      // 新增套餐
      this.title = '使用模板';
      this.mInit();
      this.newadd2 = true;
    },
    synchroFun: function synchroFun() {
      var self = this;
      self.postData('/api/merTemplateDetail/syncNetwork/' + self.$route.params.parentId, {}, function (res) {
        self.$message({
          message: '模板同步成功!',
          type: 'success' });

        self.init();
      });
    },
    handleRadio: function handleRadio(val) {
      this.mouldData.contentId = val.id;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      this.$refs['mouldForm'].resetFields();
      done();
    },
    closeRefund3: function closeRefund3(done) {
      // 重置新增列表
      this.$refs['addForm2'].resetFields();
      done();
    },
    closeRefund4: function closeRefund4(done) {
      this.newAdd4 = false;
      // done()
    },
    closeRefund5: function closeRefund5(done) {
      this.newAdd5 = false;
    },
    closeRefund6: function closeRefund6(done) {
      this.newadd6 = false;
    },
    onSpecialSubmit: function onSpecialSubmit() {
      if (this.specialForm.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(this.specialForm.price)) {
          this.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          this.specialForm.price = '';
          return false;
        }
      }
      this.specialForm.tempId = this.searchData.tempId;
      this.specialForm.tempType = 3;
      this.specialForm.networkId = this.$route.params.parentId;
      var that = this;
      that.$refs.special.validate(function (valid) {
        if (valid) {
          console.log('form====>', JSON.stringify(that.specialForm));
          if (that.specialForm.id > 0) {
            //编辑
            that.putData('/api/merTemplateDetail/' + that.specialForm.id, Object.assign({}, that.specialForm), function (res) {
              that.$message({
                message: '保存成功!',
                type: 'success' });

              that.newAdd5 = false;
              that.searchData.page = 1;
              that.init();
            });
          } else {
            //新增
            that.postData('/api/merTemplateDetail', Object.assign({}, that.specialForm), function (res) {
              that.$message({
                message: '添加成功!',
                type: 'success' });

              that.newAdd5 = false;
              that.searchData.page = 1;
              that.init();
            });
          }

        }
      });
    },
    onSpecialCancel: function onSpecialCancel() {
      this.newAdd5 = false;
    },
    onSpecialDialogClose: function onSpecialDialogClose() {var _this = this;
      this.$nextTick(function () {
        _this.specialForm = _this.defSpecialForm;
      });
    },
    onConfirmType: function onConfirmType() {
      if (!this.packageType) {
        this.$message({
          showClose: true,
          message: '请先选择套餐类型',
          type: 'error' });

        return;
      }
      this.newAdd4 = false;
      if (this.packageType == '普通按摩套餐') {
        this.title = '新增套餐';
        this.addData = JSON.parse(JSON.stringify(this.defaultData));
        this.newadd = true;
      } else {
        if (this.hasCharge && !this.hasDouble) {
          //含有充电套餐，无双人套餐，套餐模式默认选中双人套餐
          this.$set(this.specialForm, 'specialType', '1');
        }
        if (this.hasDouble && !this.hasCharge) {
          //含有双人套餐，套餐模式默认选中充电套餐
          this.$set(this.specialForm, 'specialType', '2');
        }
        this.newAdd5 = true;
      }
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onCencal2: function onCencal2() {
      // 弹窗关闭
      this.newadd2 = false;
      this.$refs['mouldForm'].resetFields();
    },
    onCencal3: function onCencal3() {
      // 弹窗关闭
      this.newadd3 = false;
      this.$refs['addForm2'].resetFields();
    },
    onMouldSubmit: function onMouldSubmit() {
      var self = this;
      if (self.mouldData.contentId == '') {
        self.$message({
          showClose: true,
          message: '未选择模板,请选择完再提交',
          type: 'error' });

        return false;
      }
      self.$refs.mouldForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merTemplateDetail/tempadd/3/' + self.$route.params.parentId, self.mouldData, function (res) {
            self.tableData2 = res.pageResult.list;
            self.pageData.page = res.pageResult.pageNum;
            self.pageData.total = res.pageResult.total;
            self.templates = res.template;
            self.searchData.tempId = res.template.id;
            self.addData.tempId = res.template.id;
            // 加单查询
            self.tableData = [];
            for (var i in self.tableData2) {
              self.getMerTemplateDetailMarket(self.tableData2[i]);
            }
            self.$message({
              message: '当前为官方模板，使用请点击“保存并使用”!',
              type: 'success' });

            self.onCencal2();
          });
        } else {
          return false;
        }
      });
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.orderNum) {
        if (!/(^[0-9]\d*$)/.test(self.addData.orderNum)) {
          self.$message({
            showClose: true,
            message: '档次有误，请重填',
            type: 'error' });

          self.addData.orderNum = '';
          return false;
        }
      }
      if (self.addData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          self.addData.price = '';
          return false;
        }
      }
      self.addData.tempId = self.searchData.tempId;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merTemplateDetail', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item, e) {
      console.log(item);
      console.log('e:', e);
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.addData = {
        id: item.id,
        name: item.name,
        // ctype: item.ctype + '',
        // program: item.program + '',
        time: item.time,
        price: item.price,
        orderNum: item.orderNum,
        angleMark: item.angleMark,
        remarks: item.remarks,
        tempType: '3',
        actId: item.actId,
        networkId: self.defaultData.networkId,
        recommend: item.recommend + '' };

      if (self.addData.recommend == '1') {
        self.addData.angleMark = '';
      }
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.currentPackage.childrens && self.currentPackage.childrens.length && parseInt(self.currentPackage.childrens[0].time) + parseInt(self.addData.time) > 60) {
        self.$message({
          showClose: true,
          message: '套餐时长和加单时长总和大于60分钟，请重新设置时长',
          type: 'error' });

        return false;
      }
      if (self.addData.orderNum) {
        if (!/(^[0-9]\d*$)/.test(self.addData.orderNum)) {
          self.$message({
            showClose: true,
            message: '档次有误，请重填',
            type: 'error' });

          self.addData.orderNum = '';
          return false;
        }
      }
      if (self.addData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          self.addData.price = '';
          return false;
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/merTemplateDetail/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merTemplateDetail/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.push('/channel/network');
    },
    addItem: function addItem(item) {
      // 升级加单套餐
      console.log('item:', item);
      this.currentPackage = item;
      this.title = '升级加单套餐';
      this.title2 = '加单';
      this.addOrderData = JSON.parse(JSON.stringify(this.defaultOrderData));
      this.addOrderData.tempDetailId = item.id;
      this.newadd3 = true;
    },
    updateItem2: function updateItem2(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '升级加单套餐';
      self.title2 = '加单修改';
      self.getData('/api/merTemplateDetailMarket/' + item.id, {}, function (res) {
        console.log(res);
        self.addOrderData = {
          tempDetailId: res.id,
          time: res.time,
          price: res.price };

      });
      self.newadd3 = true;
    },
    onAddOrderFun: function onAddOrderFun() {
      // 弹窗提交
      var self = this;
      if (self.addOrderData.time) {
        if (!/(^[0-9]\d*$)/.test(self.addOrderData.time)) {
          self.$message({
            showClose: true,
            message: '套餐时间有误，请重填',
            type: 'error' });

          self.addOrderData.time = '';
          return false;
        }
        if (parseInt(self.addOrderData.time) + parseInt(self.currentPackage.time) > 60) {
          self.$message({
            showClose: true,
            message: '套餐时长和加单时长总和大于60分钟，请重新设置时长',
            type: 'error' });

          return false;
        }
      }
      if (self.addOrderData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addOrderData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          self.addOrderData.price = '';
          return false;
        }
      }
      self.$refs.addForm2.validate(function (valid) {
        if (valid) {
          self.postData('/api/merTemplateDetailMarket', self.addOrderData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal3();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onUpdateFun: function onUpdateFun() {
      var self = this;
      if (self.addOrderData.time) {
        if (!/(^[0-9]\d*$)/.test(self.addOrderData.time)) {
          self.$message({
            showClose: true,
            message: '套餐时间有误，请重填',
            type: 'error' });

          self.addOrderData.time = '';
          return false;
        }
        if (parseInt(self.addOrderData.time) + parseInt(self.currentPackage.time) > 60) {
          self.$message({
            showClose: true,
            message: '套餐时长和加单时长总和大于60分钟，请重新设置时长',
            type: 'error' });

          return false;
        }
      }
      if (self.addOrderData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addOrderData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          self.addOrderData.price = '';
          return false;
        }
      }
      self.$refs.addForm2.validate(function (valid) {
        if (valid) {
          self.postData('/api/merTemplateDetailMarket/' + self.addOrderData.tempDetailId, self.addOrderData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal3();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu2: function delMenu2(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merTemplateDetailMarket/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    // 推荐操作
    tuijianFun: function tuijianFun(i, e) {
      console.log(i);
      console.log(e);
      if (e == false) {
        if (i == '1') {
          this.addData.angleMark = '';
        }
      } else {
        if (i == '1') {
          this.addData.angleMark = '';
        }
      }
    },
    /**
        * 删除套餐||套餐营销
        * @param {Object} delId
        * @param {Object} type 1:删除套餐；2：删除套餐营销
        */
    delPackageOrMarket: function delPackageOrMarket(delId, type) {
      type = parseInt(type);
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).
      then(function () {
        var url = type == 1 ? '/api/merTemplateDetail/' + delId : '/api/merTemplateDetailMarket/' + delId;
        self.delData(url, {}, function (res) {
          self.$message({
            message: '删除成功',
            type: 'success' });

          self.init();
        });
      });
    },
    /**
        * 编辑套餐||套餐营销
        * @param {Object} type 1:编辑套餐；2：编辑套餐营销
        */
    editPackageOrMarket: function editPackageOrMarket(item, type) {var _this2 = this;
      if (parseInt(item.comboType) == 1) {
        //特殊套餐编辑
        this.specialForm = {
          id: item.id,
          ctype: 1,
          program: 1,
          name: item.name,
          time: item.time,
          recommend: item.recommend,
          price: item.price,
          orderNum: item.orderNum,
          comboType: item.comboType,
          specialType: item.specialType + '',
          tempType: item.tempType };

        this.$set(this.specialForm, 'angleMark', item.angleMark);
        this.$forceUpdate();
        console.log('specialForm===>', JSON.stringify(this.specialForm));
        this.$nextTick(function () {
          _this2.newAdd5 = true;
        });
      } else {
        type = parseInt(type);
        if (type == 1) {
          this.currentPackage = item;
          // 修改弹窗
          var self = this;
          self.title = '编辑';
          self.addData = {
            id: item.id,
            name: item.name,
            // ctype: item.ctype + '',
            // program: item.program + '',
            time: item.time,
            price: item.price,
            orderNum: item.orderNum,
            angleMark: item.angleMark,
            remarks: item.remarks,
            actId: item.actId,
            tempType: '3',
            networkId: self.defaultData.networkId,
            recommend: item.recommend + '' };

          if (self.addData.recommend == '1') {
            self.addData.angleMark = '';
          }
          self.newadd = true;
        } else {
          console.log(item);
          this.currentPackage = this.fetchAddParent(item);
          // 修改弹窗
          var _self = this;
          _self.title = '升级加单套餐';
          _self.title2 = '加单修改';
          _self.getData('/api/merTemplateDetailMarket/' + item.id, {}, function (res) {
            console.log(res);
            _self.addOrderData = {
              tempDetailId: res.id,
              time: res.time,
              price: res.price };

          });
          _self.newadd3 = true;
        }
      }
    },

    /**
        * 获取当前加单节点得父节点信息
        */
    fetchAddParent: function fetchAddParent(row) {var _this3 = this;
      console.log('tableData====>', JSON.stringify(this.tableData));
      var parent = '';
      this.tableData.forEach(function (e) {
        if (_this3.$lodash.find(e.childrens, function (temp) {return temp.id == row.id;})) {
          parent = e;
        }
      });
      return parent;
    } } };exports.default = _default;