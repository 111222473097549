"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.filterAsyncRoutes = filterAsyncRoutes;exports.default = void 0;var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));require("core-js/modules/web.dom.iterable");require("core-js/modules/es7.array.includes");require("core-js/modules/es6.string.includes");var _router = require("@/router");






function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {return route.meta.roles.includes(role);});
  } else {
    return true;
  }
}

/**
   * Filter asynchronous routing tables by recursion
   * @param routes asyncRoutes
   * @param roles
   */
function filterAsyncRoutes(routes, roles) {
  var res = [];

  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

var state = {
  routes: [],
  addRoutes: [] };


var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  } };


var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes('admin')) {
        accessedRoutes = _router.asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;