"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  data: function data() {
    return {
      tableData: [], // 列表数据
      searchData: {
        merId: '',
        page: 1, // 当前页码
        rows: 10 // 页码条数
      },
      pageData: {
        page: 1,
        rows: 10,
        total: 0,
        pages: 1 },

      optionsList: [],
      props: {
        children: 'subMers',
        label: 'name',
        value: 'id',
        checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: false },

      // 表格列
      tableColumns: [{
        prop: 'merName',
        label: '渠道商名称',
        isShow: true },

      {
        prop: 'networkName',
        label: '网点名称',
        isShow: true },

      {
        prop: 'amount',
        label: '本月日台均',
        sortable: true,
        isShow: true }] };



  },
  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/homePageStatistics/rankingPage', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    // 设置imageList
    resetFun: function resetFun() {// 重置
      this.searchData.merId = '';
      this.init();
    },
    searchFun: function searchFun() {// 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {// 分页页码
      this.searchData.page = val;
      this.init();
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.push('/shouye/index');
      // this.$router.go(-1);
    } } };exports.default = _default;