"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;




























































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'subAccount', data: function data() {return { tableData: [], // 列表数据
      oneMerchantList: [], //一级渠道商数据
      tableColumns: [{ prop: 'merName', label: '一级渠道商', isShow: true }, { prop: 'userName', label: '子账户', isShow: true }, { prop: 'nickName', label: '昵称', isShow: true }, { prop: 'status', label: '账户状态', formatter: _formatter.statusFormatter, isShow: true // 是否显示
      }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }], searchData: { merId: '', // 商户ID
        merCode: '', // 商户code
        status: '', // 启禁用
        subAccount: '', // 子账号
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, statusList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }] };}, mounted: function mounted() {var self = this;self.init();self.oneMerchantFun();}, methods: { init: function init() {var self = this;self.getData('/api/merSubAccount/page', self.searchData, function (res) {console.log(res);self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, resetFun: function resetFun() {// 重置
      this.searchData.merId = '';
      this.searchData.merCode = '';
      this.searchData.subAccount = '';
      this.searchData.status = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    oneMerchantFun: function oneMerchantFun() {
      var self = this;
      self.getData('/api/merMerchant/primary/list', {}, function (res) {
        self.oneMerchantList = res;
      });
    },
    delMenu: function delMenu(delId) {
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merSubAccount/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    enableMenu: function enableMenu(item) {
      var self = this;
      self.postData('/api/merSubAccount/' + item.id + '/enable', {}, function (res) {
        self.$message({
          message: '已启用!',
          type: 'success' });

        self.init();
      });
    },
    disableMenu: function disableMenu(item) {
      var self = this;
      self.postData('/api/merSubAccount/' + item.id + '/disable', {}, function (res) {
        self.$message({
          message: '已禁用!',
          type: 'success' });

        self.init();
      });
    } } };exports.default = _default;