var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "waitBox" }, [
        _c("div", { staticClass: "waitContent" }, [
          _c("div", { staticClass: "waitL" }, [
            _c("div", { staticClass: "wait_r" }, [
              _c("div", { staticClass: "waitTitle" }, [_vm._v("历史收益")]),
              _vm._v(" "),
              _c("div", { staticClass: "waitNum" }, [
                _vm._v(_vm._s(_vm.total || "0.00"))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "waitL" }, [
            _c("div", { staticClass: "wait_r" }, [
              _c("div", { staticClass: "waitTitle" }, [_vm._v("本月收益")]),
              _vm._v(" "),
              _c("div", { staticClass: "waitNum" }, [
                _vm._v(_vm._s(_vm.currentMonth || "0.00"))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "waitL" }, [
            _c("div", { staticClass: "wait_r" }, [
              _c("div", { staticClass: "waitTitle" }, [_vm._v("本月台均收益")]),
              _vm._v(" "),
              _c("div", { staticClass: "waitNum" }, [
                _vm._v(_vm._s(_vm.monthlyAvg || "0.00"))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "statistics" }, [
        _c("div", { staticClass: "statistics_title" }, [
          _vm._v("设备状态（台）")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "statisticsContent" }, [
          _c("div", { staticClass: "statisticsBox" }, [
            _c("div", { staticClass: "el-icon-shouhou-dingdan tb" }),
            _vm._v(" "),
            _c("div", { staticClass: "statistics_r" }, [
              _c("div", { staticClass: "statisticsTitle" }, [_vm._v("投放数")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNum" }, [
                _vm._v(_vm._s(_vm.totalNum))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statisticsBox statisticsBox_orange" }, [
            _c("div", { staticClass: "el-icon-shouhou-dingdan tb" }),
            _vm._v(" "),
            _c("div", { staticClass: "statistics_r" }, [
              _c("div", { staticClass: "statisticsTitle" }, [_vm._v("在线数")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNum" }, [
                _vm._v(_vm._s(_vm.onlineNum))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statisticsBox statisticsBox_blue" }, [
            _c("div", { staticClass: "el-icon-shouhou-dingdan tb" }),
            _vm._v(" "),
            _c("div", { staticClass: "statistics_r" }, [
              _c("div", { staticClass: "statisticsTitle" }, [_vm._v("故障数")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNum" }, [
                _vm._v(_vm._s(_vm.faultNum))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statisticsBox statisticsBox_young" }, [
            _c("div", { staticClass: "el-icon-shouhou-dingdan tb" }),
            _vm._v(" "),
            _c("div", { staticClass: "statistics_r" }, [
              _c("div", { staticClass: "statisticsTitle" }, [_vm._v("离线数")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNum" }, [
                _vm._v(_vm._s(_vm.offlineNum))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "statisticsBox statisticsBox_hui" }, [
            _c("div", { staticClass: "el-icon-shouhou-dingdan tb" }),
            _vm._v(" "),
            _c("div", { staticClass: "statistics_r" }, [
              _c("div", { staticClass: "statisticsTitle" }, [_vm._v("退款率")]),
              _vm._v(" "),
              _c("div", { staticClass: "statisticsNum" }, [
                _vm._v(_vm._s(_vm.refundRate) + "%")
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "bgBai" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "charts" }, [
              _c("div", {
                style: { width: "100%", height: "350px" },
                attrs: { id: "myChart1" }
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "mt40",
                style: { width: "100%", height: "350px" },
                attrs: { id: "myChart2" }
              })
            ])
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "tableBox" },
              [
                _c(
                  "div",
                  { staticClass: "titleBox" },
                  [
                    _c("span", [_vm._v("本月日台均排行")]),
                    _vm._v(" "),
                    _c("router-link", { attrs: { to: "/permiss/moreMsg" } }, [
                      _vm._v(" 更多》")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: {
                      width: "100%",
                      "background-color": "#f7f7f7",
                      "font-size": "15px"
                    },
                    attrs: { data: _vm.tableData }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "排行", type: "index", width: "60" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "networkName", label: "网点名" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "amount", label: "本月日台均" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }