"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));var _slicedToArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));require("core-js/modules/es7.symbol.async-iterator");require("core-js/modules/es6.symbol");require("core-js/modules/web.dom.iterable");require("core-js/modules/es7.array.includes");require("core-js/modules/es6.string.includes");require("core-js/modules/es6.function.name");var state = {
  visitedViews: [],
  cachedViews: [] };


var mutations = {
  ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
    if (state.visitedViews.some(function (v) {return v.path === view.path;})) return;
    state.visitedViews.push(
    Object.assign({}, view, {
      title: view.meta.title || view.name }));


  },
  ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },

  DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {var _iteratorNormalCompletion = true;var _didIteratorError = false;var _iteratorError = undefined;try {
      for (var _iterator = state.visitedViews.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {var _step$value = (0, _slicedToArray2.default)(_step.value, 2),i = _step$value[0],v = _step$value[1];
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }} catch (err) {_didIteratorError = true;_iteratorError = err;} finally {try {if (!_iteratorNormalCompletion && _iterator.return != null) {_iterator.return();}} finally {if (_didIteratorError) {throw _iteratorError;}}}
  },
  DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    index > -1 && state.cachedViews.splice(index, 1);
  },

  DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
    state.visitedViews = state.visitedViews.filter(function (v) {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },

  DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
    // keep affix tags
    var affixTags = state.visitedViews.filter(function (tag) {return tag.meta.affix;});
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
    state.cachedViews = [];
  },

  UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {var _iteratorNormalCompletion2 = true;var _didIteratorError2 = false;var _iteratorError2 = undefined;try {
      for (var _iterator2 = state.visitedViews[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {var v = _step2.value;
        if (v.path === view.path) {
          v = Object.assign(v, view);
          break;
        }
      }} catch (err) {_didIteratorError2 = true;_iteratorError2 = err;} finally {try {if (!_iteratorNormalCompletion2 && _iterator2.return != null) {_iterator2.return();}} finally {if (_didIteratorError2) {throw _iteratorError2;}}}
  } };


var actions = {
  addView: function addView(_ref, view) {var dispatch = _ref.dispatch;
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView: function addVisitedView(_ref2, view) {var commit = _ref2.commit;
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView: function addCachedView(_ref3, view) {var commit = _ref3.commit;
    commit('ADD_CACHED_VIEW', view);
  },

  delView: function delView(_ref4, view) {var dispatch = _ref4.dispatch,state = _ref4.state;
    return new Promise(function (resolve) {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews) });

    });
  },
  delVisitedView: function delVisitedView(_ref5, view) {var commit = _ref5.commit,state = _ref5.state;
    return new Promise(function (resolve) {
      commit('DEL_VISITED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delCachedView: function delCachedView(_ref6, view) {var commit = _ref6.commit,state = _ref6.state;
    return new Promise(function (resolve) {
      commit('DEL_CACHED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },

  delOthersViews: function delOthersViews(_ref7, view) {var dispatch = _ref7.dispatch,state = _ref7.state;
    return new Promise(function (resolve) {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews) });

    });
  },
  delOthersVisitedViews: function delOthersVisitedViews(_ref8, view) {var commit = _ref8.commit,state = _ref8.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delOthersCachedViews: function delOthersCachedViews(_ref9, view) {var commit = _ref9.commit,state = _ref9.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },

  delAllViews: function delAllViews(_ref10, view) {var dispatch = _ref10.dispatch,state = _ref10.state;
    return new Promise(function (resolve) {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews) });

    });
  },
  delAllVisitedViews: function delAllVisitedViews(_ref11) {var commit = _ref11.commit,state = _ref11.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delAllCachedViews: function delAllCachedViews(_ref12) {var commit = _ref12.commit,state = _ref12.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },

  updateVisitedView: function updateVisitedView(_ref13, view) {var commit = _ref13.commit;
    commit('UPDATE_VISITED_VIEW', view);
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;