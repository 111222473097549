"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _defineProperty2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));require("core-js/modules/es6.regexp.replace");require("core-js/modules/es6.array.find");












































































































































var _sys = require("@/api/sys");
var _mechanism = _interopRequireDefault(require("@/components/Cascader/mechanism.vue"));

var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { mapGetters } from 'vuex'
// 权限判断函数 注意：vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'accountgllist', components: { Mechanism: _mechanism.default }, data: function data() {var _this = this;var validatePass = function validatePass(rule, value, callback) {if (value === '') {callback(new Error('请再次输入密码'));} else if (value !== _this.addData.password) {callback(new Error('两次输入密码不一致!'));} else {callback();}};return { list: null, listLoading: true, tableData: [], // 列表数据
      rolesData: [], searchData: { userName: '', // 账户名称
        insName: '', // 组织名称
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, optionsList: [], props: { children: 'child', label: 'insName', value: 'insName', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover' }, newadd: false, updatePwd: false, title: '新增', addData: { id: '', userName: '', nickName: '', password: '', confirmPwd: '', mobile: '', email: '', // insId: '',
        roleId: '', roleName: '', insName: '', sex: '1', status: '1' }, defaultData: { userName: '', nickName: '', password: '', confirmPwd: '', mobile: '', email: '', // insId: '',
        roleId: '', roleName: '', insName: '', sex: '1', status: '1' }, // 表格列
      tableColumns: [{ prop: 'userName', label: '用户名', isShow: true // 是否显示
      }, { prop: 'nickName', label: '姓名', isShow: true // 是否显示
      }, { prop: 'mobile', label: '手机号', isShow: true // 是否显示
      }, { prop: 'email', label: '邮箱', isShow: false // 是否显示
      }, { prop: 'insName', label: '组织', isShow: true // 是否显示
      }, { prop: 'roleName', label: '角色名称', isShow: true // 是否显示
      }, { prop: 'sex', label: '性别', formatter: _formatter.sexFormatter, isShow: true // 是否显示
      }, { prop: 'status', label: '状态', formatter: _formatter.statusFormatter, isShow: true // 是否显示
      }, { prop: 'lastLoginTime', label: '最后登录时间', sortable: true, width: '130px', formatter: _formatter.dateFormatter, isShow: false // 是否显示
      }, { prop: 'createTime', label: '创建时间', sortable: true, formatter: _formatter.dateFormatter, isShow: true // 是否显示
      }], rules: { userName: [{ required: true, message: '请输入名称', trigger: 'blur' }], password: [{ required: true, message: '请输入密码', trigger: 'blur' }], confirmPwd: [{ required: true, validator: validatePass, trigger: 'blur' }], roleId: [{ required: true, message: '请选择角色类型', trigger: 'change' }]
        // insId: [{
        //   required: true,
        //   message: '请选择机构名称',
        //   trigger: 'blur'
        // }]
      } };

  },
  // computed: {
  //   ...mapGetters({
  //     mechanismList: 'mechanismList'
  //   })
  // },
  mounted: function mounted() {
    this.init();
    this.roleFun();
    this.getDeviceTypeList();
  },
  methods: (0, _defineProperty2.default)({
    // 获取数据
    init: function init() {var _this2 = this;
      this.listLoading = true;
      (0, _sys.adminPage)(this.searchData).then(function (response) {
        var res = response.data;
        _this2.tableData = res.list;
        _this2.pageData.page = res.pageNum;
        _this2.pageData.total = res.total;
        _this2.listLoading = false;
      });
    },
    checkPermission: _user.checkPermission,
    // 每页多少条数据
    handleSizeChange: function handleSizeChange(val) {
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    // 当前页
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchData.page = val;
      this.init();
      console.log("\u5F53\u524D\u9875: ".concat(val));
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.userName = '';
      this.searchData.insName = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    updateItem: function updateItem(item) {
      // 修改弹窗
      this.title = '编辑';
      this.newadd = true;
      this.addData.userName = item.userName;
      this.addData.nickName = item.nickName;
      this.addData.password = item.password;
      this.addData.confirmPwd = item.password;
      this.addData.mobile = item.mobile;
      this.addData.email = item.email;
      this.addData.insName = item.insName;
      this.addData.roleId = item.roleId;
      this.addData.roleName = item.roleName;
      this.addData.status = item.status + '';
      this.addData.id = item.id;
      this.addData.sex = item.sex + '';
    },
    updatePwdFun: function updatePwdFun(item) {
      // 更新密码弹窗
      this.updatePwd = true;
      this.addData.userName = item.userName;
      this.addData.nickName = item.nickName;
      this.addData.sex = item.sex;
      this.addData.password = '';
      this.addData.confirmPwd = '';
      this.addData.mobile = item.mobile;
      this.addData.email = item.email;
      // this.addData.insId = item.insId
      this.addData.roleId = item.roleId;
      this.addData.roleName = item.roleName;
      this.addData.status = item.status;
      this.addData.id = item.id;
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/admin/del/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    lookView: function lookView(item) {
      // 查看弹窗
      this.title = '查看';
      this.newadd = true;
      this.addData.userName = item.userName;
      this.addData.nickName = item.nickName;
      this.addData.password = item.password;
      this.addData.confirmPwd = item.password;
      this.addData.mobile = item.mobile;
      this.addData.email = item.email;
      this.addData.insName = item.insName;
      this.addData.roleId = item.roleId;
      this.addData.roleName = item.roleName;
      this.addData.status = item.status + '';
      this.addData.id = item.id;
      this.addData.sex = item.sex + '';
    },

    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closePwd: function closePwd(done) {
      // 重置新增列表
      this.$refs['pwdForm'].resetFields();
      done();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      // const insi = self.addData.insId
      // console.log('insi',insi)
      // const ins = self.addData.insId.toString()
      // let insi = ''
      // if (self.addData.insId.length === 1) {
      //   insi = ins
      // } else {
      //   insi = ins.charAt(ins.length - 1)
      // }
      if (self.addData.password.length < 6) {
        self.$message({
          showClose: true,
          message: '密码强度不够，请重填',
          type: 'error' });

        self.addData.password = '';
      }
      if (self.addData.mobile) {
        if (!/^1[3456789]\d{9}$/.test(self.addData.mobile)) {
          self.$message({
            showClose: true,
            message: '手机号码有误，请重填',
            type: 'error' });

          self.addData.mobile = '';
          return false;
        }
      }
      if (self.addData.email) {
        var emailNum = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!emailNum.test(self.addData.email)) {
          self.$message({
            showClose: true,
            message: '邮箱有误，请重填',
            type: 'error' });

          self.addData.email = '';
          return false;
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/admin/save', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      // const insi = self.addData.insId
      if (self.addData.mobile) {
        if (!/^1[3456789]\d{9}$/.test(self.addData.mobile)) {
          self.$message({
            showClose: true,
            message: '手机号码有误，请重填',
            type: 'error' });

          self.addData.mobile = '';
          return false;
        }
      }
      if (self.addData.email) {
        var emailNum = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!emailNum.test(self.addData.email)) {
          self.$message({
            showClose: true,
            message: '邮箱有误，请重填',
            type: 'error' });

          self.addData.email = '';
          return false;
        }
      }
      // self.addData.insId = insi
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/admin/save', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    upSubmit: function upSubmit() {
      // 修改密码提交
      var self = this;
      self.$refs.pwdForm.validate(function (valid) {
        if (valid) {
          if (self.addData.password.length < 6) {
            self.$message({
              showClose: true,
              message: '密码强度不够，请重填',
              type: 'error' });

            self.addData.password = '';
            return false;
          }
          self.postData('/api/admin/updatePwd', self.addData, function (res) {
            self.$message({
              message: '修改密码成功!',
              type: 'success' });

            self.upCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    upCencal: function upCencal() {
      // 修改密码弹窗关闭
      this.updatePwd = false;
      this.$refs['pwdForm'].resetFields();
    },

    changeinsFun: function changeinsFun(data) {
      var obj = {};
      obj = this.droplist.find(function (item) {
        return item.id === data;
      });
      this.addData.productType = obj.productType;
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.insId = val;
    },
    roleFun: function roleFun() {
      // 角色下拉列表
      var self = this;
      self.getData('/api/role/dropList', {}, function (res) {
        self.rolesData = res;
        for (var i = 0; i <= self.rolesData.length; i++) {
          self.rolesData[i] = {
            id: self.rolesData[i].id,
            name: self.rolesData[i].insName + '_' + self.rolesData[i].roleName };

          // console.log(self.rolesData[i])
          // console.log(self.rolesData)
        }
      });
    },
    changeRole: function changeRole(value) {
      // 下拉角色事件
      var obj = {};
      obj = this.rolesData.find(function (item) {
        return item.id === value;
      });
      this.addData.roleName = obj.roleName;
      this.addData.insName = obj.insName;
    },
    InputKg: function InputKg(item) {
      this.addData.userName = item.replace(/\s+/g, '');
    },
    InputKg2: function InputKg2(item) {
      this.addData.password = item.replace(/\s+/g, '');
    },
    InputKg3: function InputKg3(item) {
      this.addData.confirmPwd = item.replace(/\s+/g, '');
    },
    InputKg4: function InputKg4(item) {
      this.addData.nickName = item.replace(/\s+/g, '');
    },
    InputKg5: function InputKg5(item) {
      this.addData.mobile = item.replace(/\s+/g, '');
    },
    InputKg6: function InputKg6(item) {
      this.addData.email = item.replace(/\s+/g, '');
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/institution/list', {}, function (res) {
        console.log('res:', res);
        self.optionsList = res;
      });
    } }, "changeMechanism", function changeMechanism(
  val) {
    this.searchData.insName = val;
  }) };exports.default = _default;