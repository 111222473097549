"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.split");var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));



























































































































































var _vuex = require("vuex");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'unusual', data: function data() {return { timeRange: '', //时间范围
      tableData: [], // 列表数据
      deviceData: [], // 弹窗列表数据
      tableColumns: [{ prop: 'id', label: '订单编号', isShow: true }, { prop: 'thirdOrderId', label: '第三方商户单号', isShow: true }, { prop: 'refundErrorMsg', label: '退款失败原因', isShow: true }, // {
      // 	prop: 'deviceType',
      // 	label: '套餐金额（元）',
      // 	isShow: false
      // },
      // {
      // 	prop: 'mchnameL1',
      // 	label: '支付金额（元）',
      // 	isShow: false
      // },
      { prop: 'refundTime', label: '退款失败时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'reason', label: '退款原因', isShow: true }, { prop: 'amt', label: '退款金额（元）', isShow: true }, { prop: 'status', label: '订单状态', formatter: _formatter.orderStatusFormatter, isShow: true }], ids: '', searchData: { // insId: '32',
        id: '', thirdOrderId: '', startTime: '', endTime: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, addData: { id: '', insName: '', mchnameL1: '', amt: '', thirdNickname: '', deviceType: '', sn: '', payTime: '', pkgEndTime: '', pkgErrTime: '', pkgAmt: '', pkgName: '', runTime: '', pkgDuration: '', status: '3', refundContact: '', refundPhone: '', reason: '', refundState: '', refundPasswd: '', optUser: '' }, defaultData: { id: '', insName: '', mchnameL1: '', amt: '', thirdNickname: '', deviceType: '', sn: '', payTime: '', pkgEndTime: '', pkgErrTime: '', pkgAmt: '', pkgName: '', runTime: '', pkgDuration: '', status: '3', refundContact: '', refundPhone: '', reason: '', refundState: '', refundPasswd: '', optUser: '' }, statusList: [{ name: '未支付', id: '0' }, { name: '已支付', id: '1' }, { name: '支付取消', id: '2' }, { name: '人工退款', id: '3' }, { name: '一键退款', id: '4' }, { name: '自动退款', id: '5' }, { name: '所有已退款', id: '6' }], newadd: false, title: '', pickerOptions: { disabledDate: function disabledDate(time) {return time.getTime() > Date.now();} } };}, computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])), mounted: function mounted() {var self = this;self.init();}, methods: { init: function init() {if (this.timeRange) {this.$set(this.searchData, 'startTime', this.timeRange[0]);this.$set(this.searchData, 'endTime', this.timeRange[1]);} else {this.$set(this.searchData, 'startTime', '');this.$set(this.searchData, 'endTime', '');}var self = this;if (this.searchData.endTime) {var endTime = this.searchData.endTime;if (endTime.split(' ').length < 2) {endTime = endTime + ' 23:59:59';this.searchData.endTime = endTime;}}if (this.searchData.startTime) {var startTime = this.searchData.startTime;if (startTime.split(' ').length < 2) {startTime = startTime + ' 00:00:00';this.searchData.startTime = startTime;}}self.getData('/api/package/bill/refund/error', self.searchData, function (res) {self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
        // for(var i in self.tableData) {
        // 	console.log(parseFloat(self.tableData[i].id))
        // }
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.id = '';
      this.searchData.thirdOrderId = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      var start = new Date(this.searchData.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.searchData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.searchData.endTime = '';
        return false;
      }
      this.init();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      var ids = [];
      for (var i in val) {
        ids.push(val[i].networkId);
      }
      self.ids = ids.toString();
      console.log("ids:", ids);
      console.log("self.ids:", self.ids);
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      done();
    },
    lookMenu: function lookMenu(item) {
      var self = this;
      self.getData('api/package/bill/list', {
        id: item.id,
        page: 1,
        rows: 10 },
      function (res) {
        console.log('res', res.list[0]);
        var ress = res.list[0];
        if (ress != null) {
          self.addData = {
            id: item.id,
            insName: item.insName,
            mchnameL1: ress.mchnameL1,
            amt: ress.payAmt,
            thirdNickname: ress.thirdNickname,
            deviceType: ress.deviceType,
            sn: ress.sn,
            payTime: ress.payTime,
            pkgEndTime: ress.pkgEndTime,
            pkgErrTime: ress.pkgErrTime,
            pkgAmt: ress.pkgAmt,
            pkgName: ress.pkgName,
            runTime: ress.runTime,
            pkgDuration: ress.pkgDuration,
            status: '3',
            refundContact: ress.refundContact,
            refundPhone: ress.refundPhone,
            reason: ress.reason,
            refundState: ress.refundState,
            optUser: self.name };

        } else {
          self.addData.id = item.id,
          self.addData.insName = item.insName,
          self.addData.mchnameL1 = ress.mchnameL1,
          self.addData.amt = ress.payAmt,
          self.addData.thirdNickname = ress.thirdNickname,
          self.addData.deviceType = ress.deviceType,
          self.addData.sn = ress.sn,
          self.addData.payTime = ress.payTime,
          self.addData.pkgEndTime = ress.pkgEndTime,
          self.addData.pkgErrTime = ress.pkgErrTime,
          self.addData.pkgAmt = ress.pkgAmt,
          self.addData.pkgName = ress.pkgName,
          self.addData.runTime = ress.runTime,
          self.addData.pkgDuration = ress.pkgDuration,
          self.addData.optUser = self.name;
        }
        self.title = '退款';
        self.newadd = true;
      });
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        // if(self.addData.preferentialWay != '4') {
        // 	self.addData.preferentialCycleDays = ''
        // }
        // if(self.addData.ctype == '2') {
        // 	self.addData.cmode = ''
        // 	self.addData.massageIntervalMinutes = ''
        // 	self.addData.preferentialPackage = ''
        // 	self.addData.preferentialDiscount = ''
        // 	self.addData.preferentialAmount = ''
        // 	self.addData.merIds = ''
        // }
        // if(self.addData.cmode == '0') {
        // 	self.addData.preferentialAmount = '1'
        // }else{
        // 	self.addData.preferentialDiscount = ''
        // }
        // if(self.addData.preferentialPackage != '') {
        // 	self.addData.preferentialPackage = self.addData.preferentialPackage.toString()
        // }
        if (valid) {
          self.postData('/api/package/bill/refund', self.addData, function (res) {
            self.$message({
              message: '退款成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
    },
    // dailog
    // excelFun: function() {
    // 	const self = this;
    // 	window.location.href =
    // 		'/api/package/bill/export?id=' +
    // 		self.searchData.id +
    // 		'&startTime=' +
    // 		self.searchData.startTime +
    // 		'&endTime=' +
    // 		self.searchData.endTime;
    // },
    switchFun: function switchFun(item) {
      var self = this;
      self.addData.status = item;
    } } };exports.default = _default;