"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _store = _interopRequireDefault(require("@/store"));var _document =

document,body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design
var _default =
{
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        _store.default.dispatch('app/closeSideBar', { withoutAnimation: false });
      }
    } },

  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.$_isMobile();
    if (isMobile) {
      _store.default.dispatch('app/toggleDevice', 'mobile');
      _store.default.dispatch('app/closeSideBar', { withoutAnimation: true });
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile: function $_isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.$_isMobile();
        _store.default.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop');

        if (isMobile) {
          _store.default.dispatch('app/closeSideBar', { withoutAnimation: true });
        }
      }
    } } };exports.default = _default;