"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;








































































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'menulist', components: {}, data: function data() {return { tableData: [], // 列表数据
      newadd: false, // 新增弹窗
      tableColumns: [{ prop: 'menuName', label: '菜单名称', isShow: true // 是否显示
      }, { prop: 'url', label: '链接地址', isShow: true // 是否显示
      }, { prop: 'orders', label: '排序', isShow: true // 是否显示
      }, { prop: 'permission', label: '权限', isShow: true // 是否显示
      }, { prop: 'menuType', label: '菜单类型', formatter: _formatter.menuTypeFormatter, isShow: true // 是否显示
      }], addData: { // 新增数据
        menuName: '', // 菜单名称
        parentId: 0, // 父级ID
        url: '', // 地址
        orders: '0', // 排序
        permission: '', // 权限
        target: 'frmright', level: '', status: '1', menuType: 1 }, addDefault: { // 新增数据
        menuName: '', parentId: 0, url: '', orders: '0', permission: '', target: 'frmright', level: '' }, level: [], child: [], twoShow: false, twoSelect: 0, updateData: { menuName: '', parentId: 0, url: '', orders: '0', permission: '', target: 'frmright', level: '', menuType: 2 }, update: false, addTwo: false, hasUrl: false, newTwoData: { menuName: '', parentId: 0, url: '', orders: '0', permission: '', target: 'frmright', level: '2', status: '1', menuType: 1, parentLevel: 0 }, rules: { menuName: [{ required: true, message: '请输入名称', trigger: 'blur' }], parentId: [{ required: true, message: '请选择上级菜单', trigger: 'change' }] } };}, mounted: function mounted() {var self = this;self.init(); // self.getOneLeve()
  }, methods: { init: function init() {var self = this;self.getData('/api/menu/list', {}, function (res) {self.tableData = res;});}, checkPermission: _user.checkPermission, // getOneLeve: function() { // 获取一级菜单
    //   const self = this
    //   self.getData('/api/menu/level?level=1&parentId=0', {}, function(res) {
    //     self.level = res
    //   })
    // },
    // changeOneMenu: function() {
    //   const self = this
    //   self.twoSelect = 0
    //   if (self.addData.parentId === 0) {
    //     self.twoShow = false
    //     return
    //   }
    //   self.twoShow = true
    //   self.getData('/api/menu/level/' + self.addData.level + self.addData.parentId, {}, function(res) {
    //     self.child = res
    //   })
    // },
    onCencal: function onCencal() {this.newadd = false;this.twoSelect = 0;this.twoShow = false;this.$refs['addForm'].resetFields();}, closeRefund: function closeRefund(done) {// 重置新增列表
      this.twoSelect = 0;this.twoShow = false;this.$refs['addForm'].resetFields();done();}, onSubmit: function onSubmit() {// 提交
      var self = this;if (self.addData.orders) {if (!/^[0-9]*$/.test(self.addData.orders)) {self.$message({ showClose: true, message: '排序只允许输入数字，请重填', type: 'error' });self.addData.orders = '';return false;}}if (self.addData.parentId === 0) {self.addData.level = 1;self.addData.menuType = 1;} else if (self.addData.parentId >= 1) {self.addData.level = 2;self.addData.menuType = 1;}self.$refs.addForm.validate(function (valid) {if (valid) {var postData = { menuName: self.addData.menuName, url: self.addData.url, orders: self.addData.orders, permission: self.addData.permission, level: self.addData.level, status: self.addData.status, menuType: self.addData.menuType };postData.target = self.twoSelect ? 'button' : 'frmright';postData.parentId = self.twoSelect ? self.twoSelect : self.addData.parentId;self.postData('/api/menu/save', postData, function (res) {self.$message({ message: '添加成功!', type: 'success' });self.init(); // self.getOneLeve()
            self.onCencal();});} else {return false;}});}, onUpdate: function onUpdate() {// 编辑提交
      var self = this;if (self.updateData.orders) {if (!/^[0-9]*$/.test(self.updateData.orders)) {self.$message({ showClose: true, message: '排序只允许输入数字，请重填', type: 'error' });self.updateData.orders = '';return false;}}self.$refs.updateForm.validate(function (valid) {if (valid) {self.$confirm('确定修改该记录吗？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning', center: true }).then(function () {var postData = { menuName: self.updateData.menuName, url: self.updateData.url, orders: self.updateData.orders, permission: self.updateData.permission, id: self.updateData.id, parentId: self.updateData.parentId, menuType: self.updateData.menuType };self.postData('/api/menu/save', postData, function (res) {self.$message({ message: '修改成功!', type: 'success' });self.init(); // self.getOneLeve()
              self.onCancelUpdate();});}).catch(function () {});} else {return false;}});}, onCancelUpdate: function onCancelUpdate() {this.update = false;this.$refs['updateForm'].resetFields();},
    updateMenu: function updateMenu(item) {// 编辑弹窗
      this.update = true;
      this.updateData = JSON.parse(JSON.stringify(item));
      console.log('updateData:', this.updateData);
    },
    delMenu: function delMenu(delId) {// 删除记录
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).
      then(function () {
        self.delData('/api/menu/del/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
          // self.getOneLeve()
        });
      }).catch(function () {});
    },
    onAddTwo: function onAddTwo() {
      var self = this;
      if (self.newTwoData.orders) {
        if (!/^[0-9]*$/.test(self.newTwoData.orders)) {
          self.$message({
            showClose: true,
            message: '排序只允许输入数字，请重填',
            type: 'error' });

          self.newTwoData.orders = '';
          return false;
        }
      }
      self.newTwoData.level = self.newTwoData.parentLevel + 1;
      // self.newTwoData.menuType = 2
      self.$refs.newTwoForm.validate(function (valid) {
        if (valid) {
          var postData = {
            menuName: self.newTwoData.menuName,
            url: self.newTwoData.url,
            orders: self.newTwoData.orders,
            permission: self.newTwoData.permission,
            parentId: self.newTwoData.id,
            level: self.newTwoData.level,
            menuType: self.newTwoData.menuType,
            status: self.newTwoData.status };

          self.postData('/api/menu/save', postData, function (res) {
            self.$message({
              message: '新增成功!',
              type: 'success' });


            self.init();
            // self.getOneLeve()
            self.onCancelTwo();
          });
        } else {
          return false;
        }
      });
    },
    onCancelTwo: function onCancelTwo() {
      this.addTwo = false;
      this.$refs['newTwoForm'].resetFields();
    },
    addMenu: function addMenu() {// 新增弹窗
      var self = this;
      self.addData = JSON.parse(JSON.stringify(self.addDefault));
      self.newadd = true;
    },
    addTwoMenu: function addTwoMenu(item) {
      var self = this;
      self.newTwoData = JSON.parse(JSON.stringify(item));
      self.newTwoData.menuName = '';
      self.newTwoData.parentLevel = item.level;
      self.addTwo = true;
    },
    addThreeMenu: function addThreeMenu(item) {
      var self = this;
      self.newTwoData = JSON.parse(JSON.stringify(item));
      self.newTwoData.menuName = '';
      self.newTwoData.url = '';
      self.newTwoData.orders = '0';
      self.newTwoData.parentId = '';
      self.newTwoData.permission = '';
      self.newTwoData.parentLevel = item.level;
      // self.newTwoData.target = 'button'
      self.addTwo = true;
    },
    changeMenuTypeFun: function changeMenuTypeFun(val) {
      var self = this;
      if (val === 1) {
        self.newTwoData.menuType = 1;
      } else if (val === 2) {
        self.newTwoData.menuType = 2;
      }
    } } };exports.default = _default;