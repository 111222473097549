import { render, staticRenderFns } from "./firmwareNews.vue?vue&type=template&id=04aa79a3&scoped=true&"
import script from "./firmwareNews.vue?vue&type=script&lang=js&"
export * from "./firmwareNews.vue?vue&type=script&lang=js&"
import style0 from "./firmwareNews.vue?vue&type=style&index=0&id=04aa79a3&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04aa79a3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04aa79a3')) {
      api.createRecord('04aa79a3', component.options)
    } else {
      api.reload('04aa79a3', component.options)
    }
    module.hot.accept("./firmwareNews.vue?vue&type=template&id=04aa79a3&scoped=true&", function () {
      api.rerender('04aa79a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/factory/common/firmwareNews.vue"
export default component.exports