"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));


























































































var _vuex = require("vuex");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { data: function data() {return { tableData: [], // 列表数据
      depotData: [], // 弹窗列表数据
      tableColumns: [{ prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'currentCenterVersion', label: '当前版本号', isShow: true }, { prop: 'targetVersion', label: '目标版本号', isShow: true }, { prop: 'status', label: '状态', formatter: _formatter.voiceStatusFormatter, isShow: true }, { prop: 'updateTime', label: '上报时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'merName', label: '渠道商', isShow: true }, { prop: 'networkName', label: '网点', isShow: false }], ids: '', searchData: { upgradeId: '', status: '', currentCenterVersion: '', sn: '', merName: '', networkName: '', startTime: '', endTime: '', targetVersion: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, pages: 1 }, addData: { id: '' }, defaultData: { id: '' }, statusList: [{ name: '待升级', id: '0' }, { name: '升级中', id: '1' }, { name: '升级失败', id: '2' }, { name: '升级成功', id: '3' }], newadd: false, title: '' };}, computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])), filters: { statusFilters: function statusFilters(val) {if (val == '0') {return '待升级';} else if (val == '1') {return '升级中';} else if (val == '2') {return '升级失败';} else if (val == '3') {return '升级成功';
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.searchData.upgradeId = self.$route.params.parentId;
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/firmwareUpgradeDevice/upgradeId/' + self.$route.params.parentId, self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.status = '';
      this.searchData.currentCenterVersion = '';
      this.searchData.sn = '';
      this.searchData.merName = '';
      this.searchData.networkName = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.searchData.targetVersion = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      var ids = [];
      for (var i in val) {
        ids.push(val[i].networkId);
      }
      self.ids = ids.toString();
      console.log("ids:", ids);
      console.log("self.ids:", self.ids);
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    upgradeFun: function upgradeFun() {
      var self = this;
      self.getData('/api/firmwareUpgradeDevice/upgradeId/' + self.searchData.upgradeId + '/fail', {}, function (res) {
        self.depotData = res;
        self.title = '批量再次升级';
        self.newadd = true;
      });
    },
    againUpgradeFun: function againUpgradeFun(item) {
      var self = this;
      var sns = [];
      sns.push(item.sn);
      self.postData('/api/firmwareUpgrade/batch/' + self.searchData.upgradeId, {
        sns: sns },
      function (res) {
        self.$message({
          message: '升级上报成功!',
          type: 'success' });

        self.init();
      });
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      done();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      var sns = [];
      for (var i in self.depotData) {
        console.log(self.depotData[i]);
        sns.push(self.depotData[i].sn);
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/firmwareUpgrade/batch/' + self.searchData.upgradeId, {
            sns: sns },
          function (res) {
            self.$message({
              message: '升级上报成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
    },
    returnFun: function returnFun() {
      // 返回上一级
      // this.$router.go(-1);
      this.$router.push('/firmware/centerPlate');
    } } };exports.default = _default;