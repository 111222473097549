var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择一级渠道商",
                filterable: "",
                clearable: ""
              },
              model: {
                value: _vm.searchData.merId,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "merId", $$v)
                },
                expression: "searchData.merId"
              }
            },
            _vm._l(_vm.oneMerchantList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入渠道商账号",
              clearable: ""
            },
            model: {
              value: _vm.searchData.userName,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "userName", $$v)
              },
              expression: "searchData.userName"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择协议",
                filterable: "",
                clearable: ""
              },
              model: {
                value: _vm.searchData.agreement,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "agreement", $$v)
                },
                expression: "searchData.agreement"
              }
            },
            _vm._l(_vm.agreementList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "success", icon: "el-icon-printer" },
              on: { click: _vm.excelFun }
            },
            [_vm._v("导出Excel")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("新增渠道商")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t\t显示/隐藏列\n\t\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道商账号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "colBlue",
                        attrs: { title: "渠道商账号" },
                        on: {
                          click: function($event) {
                            return _vm.tzPath(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.userName))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "定价权限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "有",
                        "inactive-text": "无"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isPricing,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isPricing", $$v)
                        },
                        expression: "scope.row.isPricing"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "下架/解绑权限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "有",
                        "inactive-text": "无"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun2($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isOffShelfAuthority,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isOffShelfAuthority", $$v)
                        },
                        expression: "scope.row.isOffShelfAuthority"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "开启充值" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "有",
                        "inactive-text": "无"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun3($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isRecharge,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isRecharge", $$v)
                        },
                        expression: "scope.row.isRecharge"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "自动退款设置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "有",
                        "inactive-text": "无"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun4($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isAutomaticRefund,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isAutomaticRefund", $$v)
                        },
                        expression: "scope.row.isAutomaticRefund"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道商价格", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/channel/onePriceDetail/" + scope.row.merId
                        }
                      },
                      [
                        scope.row.tempName != null && scope.row.tempId != null
                          ? _c(
                              "el-button",
                              { attrs: { title: "编辑价格", size: "mini" } },
                              [_vm._v(_vm._s(scope.row.tempName))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.tempName == null && scope.row.tempId != null
                          ? _c(
                              "el-button",
                              { attrs: { title: "编辑价格", size: "mini" } },
                              [_vm._v("无模板名称")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.tempName == null && scope.row.tempId == null
                          ? _c(
                              "el-button",
                              { attrs: { title: "编辑价格", size: "mini" } },
                              [_vm._v("无模板")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运营商加单营销" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "开",
                        "inactive-text": "关"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun5($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isIspPackageMarket,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isIspPackageMarket", $$v)
                        },
                        expression: "scope.row.isIspPackageMarket"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道商加单营销" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("isMerPackageMarketFilter")(
                            scope.row.isMerPackageMarket
                          )
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "余额使用" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("og-switch", {
                      staticClass: "switch",
                      attrs: {
                        asyncChange: "",
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#2d8cf0",
                        "inactive-color": "#cccccc",
                        "active-text": "开",
                        "inactive-text": "关"
                      },
                      on: {
                        change: function($event) {
                          return _vm.switchFun6($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.balancePrivileges,
                        callback: function($$v) {
                          _vm.$set(scope.row, "balancePrivileges", $$v)
                        },
                        expression: "scope.row.balancePrivileges"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "310", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", title: "编辑", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.updateItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "warning",
                          title: "付款信息",
                          size: "mini"
                        },
                        on: {
                          click: function($event) {
                            return _vm.payMenu(scope.row)
                          }
                        }
                      },
                      [_vm._v("付款信息")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          title: "渠道商分账规则",
                          size: "mini"
                        },
                        on: {
                          click: function($event) {
                            return _vm.ledgerMenu(scope.row)
                          }
                        }
                      },
                      [_vm._v("渠道商分账规则")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/channel/twoDistributor/" + scope.row.merCode
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { title: "二级渠道商", size: "mini" } },
                          [_vm._v("二级渠道商")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "500px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false,
            "validate-on-rule-change": false
          },
          on: {
            close: _vm.onDialogClose,
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "180px",
                size: "small",
                "validate-on-rule-change": false
              }
            },
            [
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道商名称", prop: "merName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "32" },
                        model: {
                          value: _vm.addData.merName,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "merName", $$v)
                          },
                          expression: "addData.merName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道商账号", prop: "userName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "15" },
                        model: {
                          value: _vm.addData.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "userName", $$v)
                          },
                          expression: "addData.userName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "输入密码", prop: "password" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "15",
                          placeholder: "请输入6位以上的密码"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            return _vm.InputKg(_vm.addData.password)
                          }
                        },
                        model: {
                          value: _vm.addData.password,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "password", $$v)
                          },
                          expression: "addData.password"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "确认密码", prop: "confirmPwd" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "15" },
                        nativeOn: {
                          keyup: function($event) {
                            return _vm.InputKg2(_vm.addData.confirmPwd)
                          }
                        },
                        model: {
                          value: _vm.addData.confirmPwd,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "confirmPwd", $$v)
                          },
                          expression: "addData.confirmPwd"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "有无定价权限", prop: "isPricing" } },
                    [
                      _c("el-switch", {
                        staticClass: "switch switch2",
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#2d8cf0",
                          "inactive-color": "#cccccc",
                          "active-text": "有",
                          "inactive-text": "无"
                        },
                        on: { change: _vm.switchIsPricingFun },
                        model: {
                          value: _vm.addData.isPricing,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "isPricing", $$v)
                          },
                          expression: "addData.isPricing"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "下架解绑权限",
                        prop: "isOffShelfAuthority"
                      }
                    },
                    [
                      _c("el-switch", {
                        staticClass: "switch switch2",
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#2d8cf0",
                          "inactive-color": "#cccccc",
                          "active-text": "有",
                          "inactive-text": "无"
                        },
                        on: { change: _vm.switchIsOffShelfAuthorityFun },
                        model: {
                          value: _vm.addData.isOffShelfAuthority,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "isOffShelfAuthority", $$v)
                          },
                          expression: "addData.isOffShelfAuthority"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "一级协议", prop: "agreement" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            placeholder: "请选择协议",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.addData.agreement,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "agreement", $$v)
                            },
                            expression: "addData.agreement"
                          }
                        },
                        _vm._l(_vm.agreementList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "省份", prop: "provinceCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择省",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.addData.provinceCode,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "provinceCode", $$v)
                            },
                            expression: "addData.provinceCode"
                          }
                        },
                        _vm._l(_vm.Area, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "contactName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "15" },
                        model: {
                          value: _vm.addData.contactName,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "contactName", $$v)
                          },
                          expression: "addData.contactName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title != "付款信息" && _vm.title != "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "contactNumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "11" },
                        nativeOn: {
                          keyup: function($event) {
                            return _vm.InputKg3(_vm.addData.contactNumber)
                          }
                        },
                        model: {
                          value: _vm.addData.contactNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "contactNumber", $$v)
                          },
                          expression: "addData.contactNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "可选支付方式", prop: "payWay" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "adapay" },
                          model: {
                            value: _vm.addData.payWay,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "payWay", $$v)
                            },
                            expression: "addData.payWay"
                          }
                        },
                        [_vm._v("聚合支付")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户可选支付方式",
                        prop: "userPayChoice"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "Y" },
                          model: {
                            value: _vm.addData.userPayChoice,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "userPayChoice", $$v)
                            },
                            expression: "addData.userPayChoice"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "N" },
                          model: {
                            value: _vm.addData.userPayChoice,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "userPayChoice", $$v)
                            },
                            expression: "addData.userPayChoice"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "订单收款账号", prop: "memberId" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "50" },
                        model: {
                          value: _vm.addData.memberId,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "memberId", $$v)
                          },
                          expression: "addData.memberId"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商户名称", prop: "memberName" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            placeholder: "请选择协议",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.addData.memberName,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "memberName", $$v)
                            },
                            expression: "addData.memberName"
                          }
                        },
                        _vm._l(_vm.memberNameList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否同步二级渠道商",
                        prop: "syncDownLvl"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "Y" },
                          model: {
                            value: _vm.addData.syncDownLvl,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "syncDownLvl", $$v)
                            },
                            expression: "addData.syncDownLvl"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "N" },
                          model: {
                            value: _vm.addData.syncDownLvl,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "syncDownLvl", $$v)
                            },
                            expression: "addData.syncDownLvl"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道商编码", prop: "merchCode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: "15", readonly: "" },
                        model: {
                          value: _vm.addData.merchCode,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "merchCode", $$v)
                          },
                          expression: "addData.merchCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分出服务费(%)", prop: "divRatio" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          onkeyup: "value=value.replace(/[^\\d.]/g,'')"
                        },
                        model: {
                          value: _vm.addData.divRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.addData, "divRatio", $$v)
                          },
                          expression: "addData.divRatio"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "启用状态", prop: "disabled" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.addData.disabled,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "disabled", $$v)
                            },
                            expression: "addData.disabled"
                          }
                        },
                        [_vm._v("启用中")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.addData.disabled,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "disabled", $$v)
                            },
                            expression: "addData.disabled"
                          }
                        },
                        [_vm._v("未启用")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否同步二级渠道商",
                        prop: "syncDownLvl"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "Y" },
                          model: {
                            value: _vm.addData.syncDownLvl,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "syncDownLvl", $$v)
                            },
                            expression: "addData.syncDownLvl"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "N" },
                          model: {
                            value: _vm.addData.syncDownLvl,
                            callback: function($$v) {
                              _vm.$set(_vm.addData, "syncDownLvl", $$v)
                            },
                            expression: "addData.syncDownLvl"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "新增渠道商"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("新增渠道商")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "编辑"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onUpdate }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "付款信息"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onPay }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "渠道商分账规则"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onLedger }
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("关闭")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }