"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;






var _screenfull = _interopRequireDefault(require("screenfull")); //
//
//
//
//
//
var _default = { name: 'Screenfull', data: function data() {return { isFullscreen: false };

  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      // screenfull 5.2版本是screenfull.isEnabled而不是screenfull.enabled
      if (!_screenfull.default.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning' });

        return false;
      }
      _screenfull.default.toggle();
    },
    change: function change() {
      this.isFullscreen = _screenfull.default.isFullscreen;
    },
    init: function init() {
      if (_screenfull.default.enabled) {
        _screenfull.default.on('change', this.change);
      }
    },
    destroy: function destroy() {
      if (_screenfull.default.enabled) {
        _screenfull.default.off('change', this.change);
      }
    } } };exports.default = _default;