"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.to-string");
















































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'hotline', data: function data() {var h = this.$createElement;return { tableData: [], // 列表数据
      searchData: { name: '', contact: '', phone: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', uncooperativeData: [], // 未关联指令列表
      cooperatedData: [], // 已关联指令列表
      reqData: [], //已选择
      renderFunc: function renderFunc(h, option) {return h("span", [option.label]);}, addData: { id: '', name: '', contact: '', phone: '', isOfficial: 1, merCode: [] }, defaultData: { id: '', name: '', contact: '', phone: '', isOfficial: 1, merCode: [] }, rules: { name: [{ required: true, message: '请输入名称', trigger: 'blur' }], contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }], phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'insName', label: '运营组织', isShow: true }, { prop: 'name', label: '名称', isShow: true }, { prop: 'contact', label: '联系人', isShow: true }, { prop: 'phone', label: '手机号', isShow: true }, { prop: 'isOfficial', label: '是否官方设置', formatter: _formatter.isOfficialFormatter, isShow: true } // {
      // 	prop: 'merName',
      // 	label: '合作渠道商',
      // 	isShow: true
      // }
      ] };}, filters: { merNameFilters: function merNameFilters(val) {console.log('val:', val);if (val.length == 0) {return '';} else {var merName = val.toString();return merName;}} }, mounted: function mounted() {var self = this;self.init();self.transferFun();}, methods: { init: function init() {var self = this;self.getData('/api/mar/hotline', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.name = '';
      this.searchData.contact = '';
      this.searchData.phone = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 添加
      this.title = '添加';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      this.cooperatedData = [];
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
      this.cooperatedData = [];
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.phone) {
        if (!/^1[3456789]\d{9}$/.test(self.addData.phone)) {
          self.$message({
            showClose: true,
            message: '手机号码有误，请重填',
            type: 'error' });

          self.addData.phone = '';
          return false;
        }
      }
      if (self.addData.isOfficial == 0) {
        self.addData.merCode = self.cooperatedData;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/mar/hotline', self.addData, function (res) {
            console.log('res:', res);
            self.$message({
              message: res,
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.addData = {
        id: item.id,
        name: item.name,
        phone: item.phone,
        contact: item.contact,
        isOfficial: item.isOfficial };

      for (var i = 0; i < self.uncooperativeData.length; i++) {
        var ins = self.uncooperativeData[i];
        console.log('ins:', ins);
        for (var j = 0; j < item.merCode.length; j++) {
          var insRel = item.merCode[j];
          console.log('insRel:', insRel);
          console.log('ins.key:', ins.key);
          if (ins.key == insRel) {
            self.cooperatedData.push(ins.key);
          }
        }
      }
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.phone) {
        if (!/^1[3456789]\d{9}$/.test(self.addData.phone)) {
          self.$message({
            showClose: true,
            message: '手机号码有误，请重填',
            type: 'error' });

          self.addData.phone = '';
          return false;
        }
      }
      if (self.addData.isOfficial == 0) {
        self.addData.merCode = self.cooperatedData;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/mar/hotline', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/mar/hotline', {
          id: delId },
        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    transferFun: function transferFun() {
      var self = this;
      self.getData('/api/merMerchant/list', {}, function (res) {
        console.log(res);
        self.uncooperativeData = res;
        for (var i = 0; i < self.uncooperativeData.length; i++) {
          self.uncooperativeData[i] = {
            key: self.uncooperativeData[i].code,
            label: self.uncooperativeData[i].name,
            id: self.uncooperativeData[i].id };

        }
      });
    },
    handleChange: function handleChange(value, direction, movedKeys) {
      var self = this;
      self.reqData = [];
      for (var i = 0; i < self.cooperatedData.length; i++) {
        var key = self.cooperatedData[i];
        self.reqData.push(key);
      }
      console.log('self.reqData:', self.reqData);
    } } };exports.default = _default;