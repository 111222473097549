"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");

































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'longRange', data: function data() {return { tableData: [], // 列表数据
      searchData: { merchantCode: '', beginTime: (0, _formatter.datesFormatter)(new Date() - 3600 * 1000 * 24 * 30), endTime: '', controlResult: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, optionsList: [], // 渠道商下拉列表
      props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, controlResultList: [{ name: '启动成功', id: '1' }, { name: '启动失败', id: '2' }, { name: '正在启动', id: '3' }], // 表格列
      tableColumns: [{ prop: 'merchantNameL1', label: '一级商户名称', isShow: true }, { prop: 'merchantNameL2', label: '二级商户名称', isShow: true }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'programType', label: '程序类型', formatter: _formatter.programTypeFormatter, isShow: true }, { prop: 'programTime', label: '启动时长（分钟）', isShow: true }, { prop: 'controlResult', label: '启动情况', formatter: _formatter.controlResultFormatter, isShow: true },
      {
        prop: 'createTime',
        label: '启动时间',
        formatter: _formatter.dateFormatter,
        isShow: true },

      {
        prop: 'creator',
        label: '启动操作人',
        isShow: true }] };



  },
  mounted: function mounted() {
    var self = this;
    self.init();
    self.getMerMerchant();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/device/control/log', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.merchantCode = '';
      this.searchData.beginTime = '';
      this.searchData.endTime = '';
      this.searchData.controlResult = '';
      this.init();
    },
    getMerMerchant: function getMerMerchant() {
      // 渠道商下拉
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      var start = new Date(this.searchData.beginTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.searchData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.searchData.endTime = '';
        return false;
      }
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    } } };exports.default = _default;