"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");





















































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'journal', data: function data() {return { timeRange: [(0, _formatter.datesFormatter)(new Date() - 1000 * 60 * 60 * 24), (0, _formatter.datesFormatter)(new Date())], //时间范围
      tableData: [], // 列表数据
      searchData: { sn: '', beginTime: (0, _formatter.datesFormatter)(new Date() - 1000 * 60 * 60 * 24), endTime: (0, _formatter.datesFormatter)(new Date()), page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, // 表格列
      tableColumns: [{ prop: 'merchantNameL1', label: '一级商户名称', isShow: true }, { prop: 'merchantNameL2', label: '二级商户名称', isShow: true }, { prop: 'typeCode', label: '设备类型', isShow: true }, { prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'deviceIp', label: '设备IP地址', isShow: true }, { prop: 'address', label: '设备位置', isShow: false }, { prop: 'createTime', label: '更新时间', formatter: _formatter.dateFormatter, isShow: true },

      {
        prop: 'faultCode',
        label: '故障代码',
        isShow: true },

      {
        prop: 'deviceStatus',
        label: '状态',
        formatter: _formatter.deviceStatusFormatter,
        isShow: true }] };



  },
  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/device/log', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.sn = '';
      this.searchData.beginTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      if (this.timeRange) {
        this.$set(this.searchData, 'beginTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'beginTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      this.searchData.page = 1;
      var start = new Date(this.searchData.beginTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.searchData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.searchData.endTime = '';
        return false;
      }
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      // 校验小于30天的时间
      var day_30 = 3600 * 30 * 24 * 1000;
      if (deliverTimeEnd - deliverTimeStart > day_30) {
        self.$message({
          message: '只能查询小于等于30天的设备信息',
          type: 'error' });

        return false;
      }
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    } } };exports.default = _default;