"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");











var _pathToRegexp = _interopRequireDefault(require("path-to-regexp")); //
//
//
//
//
//
//
//
//
//
//
var _default = { data: function data() {return { levelList: null };}, watch: { $route: function $route() {this.getBreadcrumb();} },

  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {return item.meta && item.meta.title;});
      var first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: '/dashboard', meta: { title: '摩享靠垫' } }].concat(matched);
      }

      this.levelList = matched.filter(function (item) {return item.meta && item.meta.title && item.meta.breadcrumb !== false;});
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = _pathToRegexp.default.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {var
      redirect = item.redirect,path = item.path;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    } } };exports.default = _default;