"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.getMenus = exports.getChildHiddenRouters = exports.addThirdHiddenRouters = exports.addHiddenRouters = exports.getParentPath = exports.getRouters = exports.mergeMenusToRouters = exports.checkPermission = exports.convertPermissions = void 0;require("core-js/modules/web.dom.iterable");require("core-js/modules/es6.regexp.replace");require("core-js/modules/es6.string.starts-with");require("core-js/modules/es7.array.includes");require("core-js/modules/es6.string.includes");var _store = _interopRequireDefault(require("@/store"));
/**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     *  用户相关工具类
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     */

/**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * 根据权限列表过滤出权限数据
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * @param {Array} permissions 权限列表
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * @returns {Array} 用户所拥有的权限集合
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         */
var convertPermissions = function convertPermissions(permissions, res) {
  for (var i = 0; i < permissions.length; i++) {
    var per = permissions[i];
    if (per.isPer) {
      if (per.permission) {
        res.push(per.permission);
      }
      if (per.childs && per.childs.length > 0) {
        convertPermissions(per.childs, res);
      }
    }
  }
  return res;
};

/**
    *  权限校验
    * @param {Array} value 校验的权限值  注意：必须是一个数组
    * @returns {Boolean}
    */exports.convertPermissions = convertPermissions;
var checkPermission = function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var permissions = _store.default.getters && _store.default.getters.permissions;
    var permission = value;

    var hasPermission = permissions.some(function (per) {
      return permission.includes(per);
    });

    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    console.error("need permissions! Like v-permission=\"['admin','editor']\"");
    return false;
  }
};

/**
    *  合并动态菜单和静态路由配置为真正的路由
    *  一级路由path是否必须有'/'?		经测试发现一级路由必须前缀'/'，二级路由不能前缀'/'
    * @param {Array} menus 菜单列表
    * @param {Array} routers 静态路由列表
    * @returns {Array}
    */exports.checkPermission = checkPermission;
var mergeMenusToRouters = function mergeMenusToRouters(menus, routers) {
  var result = [];
  //   const menus = getMenus(menuList)
  for (var i = 0; i < menus.length; i++) {
    var menu = menus[i];
    if (menu.path) {
      for (var j = 0; j < routers.length; j++) {
        var r = routers[j];
        // 一级菜单
        if (menu.menuType == 1 && menu.parentId == 0) {
          // console.log('menu.menuType:',menu)
          var menuPath = menu.path.startsWith('/') ? menu.path : '/' + menu.path;
          var rPath = r.path.startsWith('/') ? r.path : '/' + r.path;
          if (menuPath === rPath) {
            menu.path = menuPath;
            menu.component = r.component;
            if (!menu.meta.icon && r.meta && r.meta.icon) {
              menu.meta.icon = r.meta.icon;
            }
            // 重定向
            if (r.redirect) {
              menu.redirect = r.redirect;
            }
            // menu.name = menuPath.substr(1)
            if (menu.children && menu.children.length > 0 && r.children) {
              menu.children = mergeMenusToRouters(menu.children, r.children);
            }
            delete menu.menuType;
            result.push(menu);
          }
        }

        /**
           * 改动了后端接口api/menu/left为只返回菜单数据
           */

        // 二级菜单  因为后台设计只到二级菜单，而且连按钮的menuType、level也是2，所以就到二级菜单就不去递归了
        if (menu.menuType == 1 && menu.parentId > 0) {
          // if (menu.parent && r.hidden) {
          //   menu.parent.children.push(r)
          // }
          // 获取父级完整路径
          var parentPath = getParentPath(menu);
          // 适配二级菜单的url包含一级菜单的url和不包含的情况
          // 由于二级菜单的path不能前缀'/'，所以先判断menu.path是否包含父级菜单的url
          var menuParentPath = parentPath.startsWith('/') ? parentPath.substr(1) : parentPath;
          // 去除二级菜单的前缀'/'
          menu.path = menu.path.startsWith('/') ? menu.path.substr(1) : menu.path;
          var b = menu.path.startsWith(menuParentPath + '/');
          if (b) {
            // 第一个'/'的坐标
            var _i = menu.path.indexOf('/');
            menu.path = menu.path.substr(_i + 1);
          }
          var _rPath = r.path.startsWith('/') ? r.path.substr(1) : r.path;
          if (menu.path === _rPath) {
            menu.component = r.component;
            menu.name = menu.path;
            if (!menu.meta.icon && r.meta && r.meta.icon) {
              menu.meta.icon = r.meta.icon;
            }
            if (menu.children && menu.children.length > 0 && r.children) {
              menu.children = mergeMenusToRouters(menu.children, r.children);
            }
            delete menu.parent;
            delete menu.menuType;
            result.push(menu);
          }
        }
      }
    }
  }
  // addHiddenRouters(result, routers)
  return result;
};exports.mergeMenusToRouters = mergeMenusToRouters;

var getRouters = function getRouters(menu, routers) {
  var result = mergeMenusToRouters(menu, routers);
  // addHiddenRouters(result, routers)
  addThirdHiddenRouters(result, routers);
  return result;
};exports.getRouters = getRouters;

var getParentPath = function getParentPath(menu) {
  var parentPath = menu.parent.path;

  if (menu.parent.parent) {
    parentPath += getParentPath(menu.parent);
  }
  return parentPath;
};

/**
    * 添加静态路由中的隐藏路由至路由中
    * @param {Array} result 路由集合
    * @param {Array} routers 静态路由列表
    * @param {String} parentPath 父级路由路径
    */exports.getParentPath = getParentPath;
var addHiddenRouters = function addHiddenRouters(result, routers) {var _loop = function _loop(
  j) {
    var res = result[j];
    for (var _i2 = 0; _i2 < routers.length; _i2++) {
      var _router = routers[_i2];
      if (_router.hidden) {
        break;
      }
      if (res.path.replace('/', '') === _router.path.replace('/', '')) {
        var hiddenRouters = [];
        getChildHiddenRouters(_router, hiddenRouters);
        if (hiddenRouters.length > 0) {
          hiddenRouters.forEach(function (hiddenRouter) {
            res.children.push(hiddenRouter);
          });
        }
      }
    }};for (var j = 0; j < result.length; j++) {_loop(j);
  }
  for (var i = 0; i < routers.length; i++) {
    var router = routers[i];
    if (router.hidden) {
      result.push(router);
    }
  }
};

/**
    * 添加静态路由中的隐藏路由至路由中
    * 支持多级菜单的路由
    * @param {Array} result 路由集合
    * @param {Array} routers 静态路由列表
    * @param {String} parentPath 父级路由路径
    */exports.addHiddenRouters = addHiddenRouters;
var addThirdHiddenRouters = function addThirdHiddenRouters(result, routers) {
  result.forEach(function (res) {
    routers.forEach(function (router) {
      if (router.hidden) {
        result.push(router);
      } else {
        if (res.path.replace('/', '') === router.path.replace('/', '')) {
          getThirdHiddenRouters(res, router);
        }
      }
    });
  });

  /**
      for (let j = 0; j < result.length; j++) {
        const res = result[j]
        for (let i = 0; i < routers.length; i++) {
          const router = routers[i]
          if (router.hidden) {
            break
          }
          if (res.path.replace('/', '') === router.path.replace('/', '')) {
            const hiddenRouters = []
            getChildHiddenRouters(router, hiddenRouters)
            console.log('hiddeRouters', hiddenRouters)
            if (hiddenRouters.length > 0) {
              hiddenRouters.forEach(hiddenRouter => {
                res.children.push(hiddenRouter)
              })
            }
          }
        }
      }
      for (let i = 0; i < routers.length; i++) {
        const router = routers[i]
        if (router.hidden) {
          result.push(router)
        }
      } */
};exports.addThirdHiddenRouters = addThirdHiddenRouters;

var getThirdHiddenRouters = function getThirdHiddenRouters(result, router) {
  if (result.children && router.children) {
    router.children.forEach(function (r) {
      if (r.hidden) {
        result.children.push(r);
      }
      if (r.children) {
        result.children.forEach(function (res) {
          if (res.path.replace('/', '') === r.path.replace('/', '')) {
            getThirdHiddenRouters(res, r);
          }
        });
      }
    });
  }
};

var getChildHiddenRouters = function getChildHiddenRouters(router, hiddenRouters) {
  // const hiddenRouters = []
  router.children.forEach(function (r) {
    if (r.hidden) {
      hiddenRouters.push(r);
    }
  });
  return hiddenRouters;
};

/**
    * 根据menu列表转为框架适配结构
    * @param {Array} menus 菜单列表
    * @returns {Array}
    */exports.getChildHiddenRouters = getChildHiddenRouters;
var getMenus = function getMenus(menus) {
  var result = [];
  if (menus != null) {var _loop2 = function _loop2() {

      var menu = {};
      var m = menus[i];
      menu.path = m.url;
      var meta = {};
      meta.title = m.menuName;
      meta.icon = m.menuIcon;
      menu.meta = meta;
      menu.menuType = m.menuType;
      menu.parentId = m.parentId;
      // menu.level = m.level
      if (m.child) {
        menu.children = getMenus(m.child);
        // 为子菜单加上父级菜单
        menu.children.forEach(function (e) {
          e.parent = menu;
        });
      }
      result.push(menu);};for (var i = 0; i < menus.length; i++) {_loop2();
    }
    return result;
  } else {
    alert('菜单列表为空，请确认账号密码是否正确！');
    return false;
  }
};exports.getMenus = getMenus;