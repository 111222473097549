var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "filter-item mb10",
          attrs: { type: "success", icon: "el-icon-back" },
          on: { click: _vm.returnFun }
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "Box" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("屏幕")]),
          _vm._v(" "),
          _vm._l(_vm.tableData, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "osBox" },
              [
                _c("div", { staticClass: "orderNum" }, [
                  _vm._v(_vm._s(item.num))
                ]),
                _vm._v(" "),
                _vm._l(item.tab, function(item2, index2) {
                  return _c("div", { key: index2, staticClass: "seatBox" }, [
                    item2.status == "-1"
                      ? _c("div", { staticClass: "seat" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "-2"
                      ? _c("div", { staticClass: "seat ccc" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "0"
                      ? _c("div", { staticClass: "seat zx" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "1"
                      ? _c("div", { staticClass: "seat syz" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "2"
                      ? _c("div", { staticClass: "seat lx" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "3"
                      ? _c("div", { staticClass: "seat zt" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "4"
                      ? _c("div", { staticClass: "seat jg" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "5"
                      ? _c("div", { staticClass: "seat gz" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item2.status == "6"
                      ? _c("div", { staticClass: "seat wx" }, [
                          _vm._v(_vm._s(index2 + 1))
                        ])
                      : _vm._e()
                  ])
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm._m(0)
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common" }, [
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("无椅子")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice ccc" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("未知")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice zx" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("空闲")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice syz" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("使用中")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice lx" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("离线")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice zt" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("暂停到复位")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice jg" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("暂停")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice gz" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("故障")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "latticeBox" }, [
        _c("div", { staticClass: "lattice wx" }),
        _vm._v(" "),
        _c("div", { staticClass: "latticeTit" }, [_vm._v("升级中")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }