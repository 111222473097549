"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;























































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { data: function data() {return { oneMerchantList: [], tableData: [], // 列表数据
      optionsList: [], searchData: { merId: '', userName: '', agreement: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, title: '', addData: { id: '', payWay: 'adapay', userPayChoice: '', memberId: '', memberName: '', code: '', merchCode: '', divRatio: '', disabled: '', syncDownLvl: '' }, defaultData: { id: '', payWay: 'adapay', userPayChoice: '', memberId: '', memberName: '', code: '', merchCode: '', divRatio: '', disabled: '', syncDownLvl: '' }, rules: { payWay: [{ required: true, message: '请选择可选支付方式', trigger: 'change' }], userPayChoice: [{ required: true, message: '请选择用户可以选支付方式', trigger: 'change' }], memberId: [{ required: true, message: '请输入订单收款账号', trigger: 'blur' }], memberName: [{ required: true, message: '请选择商户名称', trigger: 'change' }], divRatio: [{ required: true, message: '请输入分出服务费', trigger: 'blur' }], disabled: [{ required: true, message: '请选择启用状态', trigger: 'change' }], syncDownLvl: [{ required: true, message: '请选择是否同步二级渠道商', trigger: 'change' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'merName', label: '商户名称', isShow: true }, // {
      // 	prop: 'userName',
      // 	label: '渠道商账号',
      // 	isShow: true
      // },
      { prop: 'sharePercentage', label: '设置分成比例(%)', isShow: true }, { prop: 'insName', label: '运营组织', isShow: true }, { prop: 'merCode', label: '渠道商编码', isShow: true }, { prop: 'agreement', label: '协议类型', formatter: _formatter.agreementFormatter, isShow: true }, { prop: 'chairTotalNum', label: '设备总数', isShow: true }, { prop: 'singleChairMonthAvgIncome', label: '上月日台均(元)', isShow: true }, { prop: 'singleChairDayAvgIncome', label: '本月日台均(元)', isShow: true }, { prop: 'createTime', label: '录入时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'totalIncome', label: '总收益(元)', isShow: true }, { prop: 'refundRate', label: '退款率(%)', formatter: _formatter.refundRateFormatter, isShow: true }], agreementList: [{ name: '买断', id: '0' }, { name: '联营', id: '1' }, { name: '直营', id: '2' }, { name: '其它 ', id: '3' }], Area: [], //省数据列表
      cityQuery: [], //市数据列表
      areaQuery: [], provinceCode: '', cityId: '', memberNameList: [] // 商户信息列表
    };}, filters: { isMerPackageMarketFilter: function isMerPackageMarketFilter(val) {if (val == '0') {return '关';} else if (val == '1') {return '开';}} }, mounted: function mounted() {var self = this;self.init();self.getDeviceTypeList();self.oneMerchantFun();self.getProvince();}, methods: { init: function init() {var self = this;self.getData('/api/merMerchant/page', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, resetFun: function resetFun() {// 重置
      this.searchData.merId = '';this.searchData.userName = '';this.searchData.agreement = '';this.init();},
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    getMemberNameList: function getMemberNameList(val) {
      var self = this;
      self.getData('/api/payment/member/list', {}, function (res) {
        self.memberNameList = res;
      });
    },
    oneMerchantFun: function oneMerchantFun(item) {
      var self = this;
      var merName = '';
      if (item != undefined) {
        merName = item;
      }
      self.getData('/api/merMerchant/search', {
        merName: merName },
      function (res) {
        self.oneMerchantList = res.list;
      });
    },
    dataFilter: function dataFilter(val) {
      this.oneMerchantFun(val);
    },
    getProvince: function getProvince() {
      var self = this;
      self.getData('/api/dictArea/province', {}, function (res) {
        self.Area = res;
      });
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      this.provinceCode = '';
      this.cityId = '';
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.provinceCode = '';
      this.cityId = '';
      this.$refs['addForm'].resetFields();
    },

    payMenu: function payMenu(item) {
      // console.log(item)
      var self = this;
      self.title = '付款信息';
      self.getData('/api/payment/member/merch/payinfo', {
        code: item.merCode },
      function (res) {
        console.log(res);
        self.addData = {
          code: res.code,
          memberId: res.memberId,
          memberName: res.memberName,
          payWay: 'adapay',
          userPayChoice: res.userPayChoice,
          syncDownLvl: res.syncDownLvl };

      });
      self.getMemberNameList();
      self.newadd = true;
    },
    onPay: function onPay() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/payment/member/merch', self.addData, function (res) {
            self.$message({
              message: '提交成功!',
              type: 'success' });

            self.onCencal();
            // self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    ledgerMenu: function ledgerMenu(item) {
      var self = this;
      console.log(item);
      self.title = '渠道商分账规则';
      self.newadd = true;
      self.getData('/api/payment/merch/divRule', {
        merchCode: item.merCode },
      function (res) {
        if (res != null) {
          self.addData = {
            merchCode: item.merCode,
            divRatio: res.divRatio,
            memberName: res.memberName,
            syncDownLvl: res.syncDownLvl + '',
            disabled: res.disabled + '' };

        } else {
          self.addData.merchCode = item.merCode;
        }
      });
    },
    onLedger: function onLedger() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/payment/merch/divRule', self.addData, function (res) {
            self.$message({
              message: '提交成功!',
              type: 'success' });

            self.onCencal();
            // self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    // changeMechanism(val) {
    // 	this.addData.merchCode = val
    // },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merNetwork/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          // self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    switchFun: function switchFun(item, row) {
      var self = this;
      self.postData('/api/merMerchant/' + row.merId + '/pricing/' + item, {}, function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchFun2: function switchFun2(item, row) {
      var self = this;
      self.postData('/api/merMerchant/' + row.merId + '/offShelfAuthority/' + item, {}, function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchFun3: function switchFun3(item, row) {
      var self = this;
      self.postData('/api/merMerchant/' + row.merId + '/recharge/' + item, {}, function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchFun4: function switchFun4(item, row) {
      var self = this;
      self.postData('/api/merMerchant/' + row.merId + '/refund/' + item, {}, function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchFun5: function switchFun5(item, row) {
      var self = this;
      self.postData('/api/merMerchant/' + row.merId + '/ispPackageMarket/stauts/' + item, {}, function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchFun6: function switchFun6(item, row) {
      var self = this;
      self.putData('/api/merMerchant/balancePrivileges', {
        merCode: row.merCode,
        balancePrivileges: item },
      function (res) {
        self.$message({
          message: '修改成功!',
          type: 'success' });

        self.init();
      });
    },
    switchIsPricingFun: function switchIsPricingFun(item) {
      console.log(this.addData.isPricing);
    },
    switchIsOffShelfAuthorityFun: function switchIsOffShelfAuthorityFun(item) {
      console.log(this.addData.isOffShelfAuthority);
    },
    excelFun: function excelFun() {
      var self = this;
      window.location.href =
      '/api/merMerchant/export?merId=' +
      self.searchData.merId +
      '&userName=' +
      self.searchData.userName +
      '&agreement=' +
      self.searchData.agreement;
    },
    tzPath: function tzPath(item) {
      var self = this;
      self.$router.push({
        path: "/channel/channelAccount",
        query: {
          id: item.merId,
          code: item.merCode } });


    } } };exports.default = _default;