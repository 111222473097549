"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.string.link");require("core-js/modules/es6.function.name");









































































































var _aliOss = _interopRequireDefault(require("@/utils/aliOss"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'banner', data: function data() {return { tableData: [{ type: '0' }, { type: '1' }], // 列表数据
      detailList: [], // 编辑列表
      srcImgList: [], //图片列表
      title: '', type: '', addData: { id: '', name: '', link: '', startTime: '', endTime: '', orderNum: '', picture: '', type: '' }, defaultData: { id: '', name: '', link: '', startTime: '', endTime: '', orderNum: '', picture: '', type: '' }, rules: { name: [{ required: true, message: '请输入轮播图名称', trigger: 'blur' }], orderNum: [{ required: true, message: '请输入排序', trigger: 'blur' }], picture: [{ required: true, message: '请添加图片', trigger: 'change' }] }, newadd: false, newadd2: false, // 表格列
      tableColumns: [{ prop: 'type', label: 'banner位置', formatter: _formatter.bannerTypeFormatter, isShow: true }], pickerOptions: { disabledDate: function disabledDate(time) {return time.getTime() + 2000 <= Date.now();} } };}, mounted: function mounted() {var self = this; // self.init();
    console.log('dateFilter=====>', this);}, methods: { // init: function() {
    // 	const self = this;
    // 	self.getData('/api/mar/banner', {}, function(res) {
    // 		self.tableData = res;
    // 	});
    // },
    checkPermission: _user.checkPermission, closeRefund: function closeRefund(done) {// 重置新增列表
      this.srcImgList = [];done();}, closeRefund2: function closeRefund2(done) {// 重置新增列表
      this.$refs['addForm'].resetFields();done();}, onCencal: function onCencal() {// 详情弹窗关闭
      this.newadd2 = false;this.$refs['addForm'].resetFields();}, addFun: function addFun() {// 添加活动
      this.title = '添加轮播图';this.addData = JSON.parse(JSON.stringify(this.defaultData));this.newadd2 = true;}, onSubmit: function onSubmit() {// 详情弹窗提交
      var self = this;self.addData.type = self.type;if (self.addData.startTime != null) {var start = new Date(self.addData.startTime);var deliverTimeStart = start.getTime();} else {self.addData.startTime = '';}if (self.addData.endTime != null) {var end = new Date(self.addData.endTime);var deliverTimeEnd = end.getTime();} else {self.addData.endTime = '';
      }
      var now = new Date().getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        self.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        self.addData.endTime = '';
        return false;
      } else if (deliverTimeStart < now) {
        self.$message({
          message: '请选择开始时间要大于当前系统时间',
          type: 'error' });

        self.addData.startTime = '';
        return false;
      } else if (deliverTimeEnd < now) {
        self.$message({
          message: '请选择结束时间要大于当前系统时间',
          type: 'error' });

        self.addData.endTime = '';
        return false;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/mar/banner', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.updateItem();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      // 修改弹窗
      var self = this;
      self.title = '添加轮播图';
      self.newadd = true;
      if (item != undefined) {
        self.type = item.type;
      }
      self.getData('/api/mar/banner/type', {
        type: self.type },
      function (res) {
        self.detailList = res;
        self.srcImgList = [];
        for (var img in res) {
          self.srcImgList.push(res[img].picture);
        }
      });
    },
    updateItem2: function updateItem2(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '编辑轮播图';
      self.addData = {
        id: item.id,
        name: item.name,
        link: item.link,
        startTime: item.startTime,
        endTime: item.endTime,
        orderNum: item.orderNum,
        picture: item.picture,
        type: item.type };

      self.newadd2 = true;
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.startTime != null) {
        var start = new Date(self.addData.startTime);
        var deliverTimeStart = start.getTime();
      } else {
        self.addData.startTime = '';
      }
      if (self.addData.endTime != null) {
        var end = new Date(self.addData.endTime);
        var deliverTimeEnd = end.getTime();
      } else {
        self.addData.endTime = '';
      }
      var now = new Date().getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        self.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        self.addData.endTime = '';
        return false;
      } else if (deliverTimeStart < now) {
        self.$message({
          message: '请选择开始时间要大于当前系统时间',
          type: 'error' });

        self.addData.startTime = '';
        return false;
      } else if (deliverTimeEnd < now) {
        self.$message({
          message: '请选择结束时间要大于当前系统时间',
          type: 'error' });

        self.addData.endTime = '';
        return false;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/mar/banner', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.updateItem();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/mar/banner', {
          id: delId },
        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.updateItem();
        });
      }).
      catch(function () {});
    },
    // 图片上传
    handleChangePic: function handleChangePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    fnUploadRequest: function fnUploadRequest(option) {
      var path = 'mxsg/banner';
      _aliOss.default.ossUploadFile(option, path);
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var fileName = file.name;
      var confirm = '确定移除' + fileName + '？';
      return this.$confirm(confirm);
    },
    handleOnRemove: function handleOnRemove(file, fileList) {
      this.addData.picture = '';
      this.$message('移除' + file.name + '成功');
    },
    onsuccess: function onsuccess(res) {
      var fileUrl = 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + res.name;
      this.addData.picture = fileUrl;
    } } };exports.default = _default;