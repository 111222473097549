"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.login = login;exports.getInfo = getInfo;exports.logout = logout;exports.getMenuList = getMenuList;exports.getButtonPermissions = getButtonPermissions;exports.getConfig = getConfig;
var _request = _interopRequireDefault(require("@/utils/request")); /* 用户相关api */

function login(data) {
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data });

}

function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: { token: token } });

}

function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'get' });

}

// 获取菜单列表
function getMenuList() {
  return (0, _request.default)({
    url: '/api/menu/left',
    method: 'get' });

}

// 获取按钮权限列表
function getButtonPermissions() {
  return (0, _request.default)({
    url: '/api/menu/button/pers',
    method: 'get' });

}

/**
   * 获取首页配置
   */
function getConfig() {
  return (0, _request.default)({
    url: '/api/feedback/getConfig',
    method: 'get' });

}