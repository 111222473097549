import { render, staticRenderFns } from "./account.vue?vue&type=template&id=2ba05b41&"
import script from "./account.vue?vue&type=script&lang=js&"
export * from "./account.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ba05b41')) {
      api.createRecord('2ba05b41', component.options)
    } else {
      api.reload('2ba05b41', component.options)
    }
    module.hot.accept("./account.vue?vue&type=template&id=2ba05b41&", function () {
      api.rerender('2ba05b41', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sys/account.vue"
export default component.exports