"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.replace");var _message = _interopRequireDefault(require("element-ui/lib/theme-chalk/message.css"));require("element-ui/lib/theme-chalk/base.css");var _message2 = _interopRequireDefault(require("element-ui/lib/message"));var _axios = _interopRequireDefault(require("axios"));

var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");var _this = void 0;


var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(
function (config) {
  // do something before request is sent
  var token = (0, _auth.getToken)();
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['Page-Title'] = encodeURIComponent(router.history.current.meta.title)
    config.headers['X-Token'] = 'token';
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  }
  return config;
},
function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});


// response interceptor
service.interceptors.response.use(
/**
                                    * If you want to get http information such as headers or status
                                    * Please return  response => response
                                   */

/**
                                       * Determine the request status by custom code
                                       * Here is just an example
                                       * You can also judge the status by HTTP Status Code
                                       */
function (response) {
  var res = response.data;
  // console.log('res=====>',JSON.stringify(res))

  // if the custom code is not 0, it is judged as an error.
  if (res.errCode !== '0') {
    (0, _message2.default)({
      message: res.errMsg || 'Error',
      type: 'error',
      duration: 5 * 1000 });

    if (res.errMsg == '用户未登录！') {
      location.reload();
    }
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.errCode === 'E1001') {
      // to re-login
      // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //   confirmButtonText: 'Re-Login',
      //   cancelButtonText: 'Cancel',
      //   type: 'warning'
      // }).then(() => {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
      // })
    }
    return Promise.reject(new Error(res.errMsg || 'Error'));
  } else {
    return res;
  }
},
function (error) {
  console.log('err' + error); // for debug
  // 如果报错为Network Error就删除token，否则无法跳转到login页面
  if (error.message === 'Network Error') {
    (0, _auth.removeToken)();
    console.log(window.location.href);
    // 待验证
    _this.$router.replace({
      path: '/redirect' + _this.$router.fullPath });

    // window.location.href = window.location.href + 'login'
  }
  // router.resolve({ name: 'login', path: '/login' })
  _Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000 });

  return Promise.reject(error);
});var _default =


service;exports.default = _default;