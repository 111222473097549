"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  data: function data() {
    return {
      tableData: [], // 列表数据
      addData: {
        id: '',
        rowNum: '',
        columnNum: '' },

      tableColumns: [
      {
        prop: 'rowNum',
        label: '第几排',
        isShow: true },

      {
        prop: 'columnNum',
        label: '列数',
        isShow: true }] };



  },

  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/merNetworkRank/list/' + self.$route.params.parentId, {}, function (res) {
        self.tableData = res;
      });
    },
    delMenu: function delMenu(index, rows) {
      console.log('index:', index);
      console.log('rows:', rows);
      rows.splice(index, 1);
    },
    addReadRow: function addReadRow() {
      var list = {
        rowNum: this.rowNum,
        columnNum: this.columnNum };

      this.tableData.unshift(list);
    },
    onSubmit: function onSubmit() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merNetworkRank/batch/' + self.$route.params.parentId, self.tableData, function (res) {
            self.$message({
              message: '保存成功!',
              type: 'success' });

            self.init();
          });
        } else {
          return false;
        }
      });
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.push('/channel/network');
    } } };exports.default = _default;