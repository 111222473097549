"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");
























































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'moreMsg', data: function data() {return { tableData: [], // 列表数据
      typeList: [{ name: '其它', value: 0 }, { name: '新增', value: 1 }, { name: '修改', value: 2 }, { name: '删除', value: 3 }, { name: '授权', value: 4 }, { name: '导出', value: 5 }, { name: '导入', value: 6 }, { name: '强退', value: 7 }, { name: '生成代码', value: 8 }, { name: '清空', value: 9 }, { name: '登录', value: 10 }, { name: '登出', value: 11 }], statusList: [{ name: '异常', value: 1 }, { name: '正常', value: 0 }], searchData: { title: '', businessType: '', status: '', operName: '', startTime: '', endTime: '', // 当前页码
        page: 1, // 页码条数
        rows: 10 }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, // 表格列
      tableColumns: [{ prop: 'title', label: '模块标题', isShow: true }, { prop: 'businessType', label: '操作类型', formatter: _formatter.businessTypeFormatter, isShow: true }, { prop: 'status', label: '状态', formatter: _formatter.czStatusFormatter, isShow: true }, { prop: 'operName', label: '操作人员', isShow: true }, { prop: 'operIp', label: '主机地址', isShow: true }, { prop: 'createTime', label: '操作时间', formatter: _formatter.dateFormatter, isShow: true }] };}, mounted: function mounted() {var self = this;self.init();}, methods: { init: function init() {var self = this;self.getData('/api/sysOperLog', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, // 设置imageList
    resetFun: function resetFun() {// 重置
      this.searchData.title = '';this.searchData.businessType = '';this.searchData.status = '';this.searchData.operName = '';this.searchData.startTime = '';this.searchData.endTime = '';this.init();}, searchFun: function searchFun() {// 搜索事件
      this.searchData.page = 1;var start = new Date(this.searchData.startTime);var deliverTimeStart = start.getTime();var end = new Date(this.searchData.endTime);var deliverTimeEnd = end.getTime();if (deliverTimeStart > deliverTimeEnd) {this.$message({ message: '请选择结束时间要大于开始时间', type: 'error' });this.searchData.endTime = '';return false;}if (this.searchData.endTime) {var endTime = this.searchData.endTime;if (endTime.split(' ').length < 2) {endTime = endTime + ' 23:59:59';this.searchData.endTime = endTime;}}this.init();}, handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;this.searchData.page = 1;this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {// 分页页码
      this.searchData.page = val;
      this.init();
    } } };exports.default = _default;