import { render, staticRenderFns } from "./communicationDetail.vue?vue&type=template&id=7d4bfd0d&"
import script from "./communicationDetail.vue?vue&type=script&lang=js&"
export * from "./communicationDetail.vue?vue&type=script&lang=js&"
import style0 from "./communicationDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d4bfd0d')) {
      api.createRecord('7d4bfd0d', component.options)
    } else {
      api.reload('7d4bfd0d', component.options)
    }
    module.hot.accept("./communicationDetail.vue?vue&type=template&id=7d4bfd0d&", function () {
      api.rerender('7d4bfd0d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/firmware/communicationDetail.vue"
export default component.exports