var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-switch",
      class: { "is-disabled": _vm.switchDisabled, "is-checked": _vm.checked },
      attrs: {
        role: "switch",
        "aria-checked": _vm.checked,
        "aria-disabled": _vm.switchDisabled
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.switchValue($event)
        }
      }
    },
    [
      _c("input", {
        ref: "input",
        staticClass: "el-switch__input",
        attrs: {
          type: "checkbox",
          id: _vm.id,
          name: _vm.name,
          "true-value": _vm.activeValue,
          "false-value": _vm.inactiveValue,
          disabled: _vm.switchDisabled
        },
        on: {
          change: _vm.handleChange,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.switchValue($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.inactiveIconClass || _vm.inactiveText
        ? _c(
            "span",
            {
              class: [
                "el-switch__label",
                "el-switch__label--left",
                !_vm.checked ? "is-active" : ""
              ]
            },
            [
              _vm.inactiveIconClass
                ? _c("i", { class: [_vm.inactiveIconClass] })
                : _vm._e(),
              _vm._v(" "),
              !_vm.inactiveIconClass && _vm.inactiveText
                ? _c("span", { attrs: { "aria-hidden": _vm.checked } }, [
                    _vm._v(_vm._s(_vm.inactiveText))
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        ref: "core",
        staticClass: "el-switch__core",
        style: { width: _vm.coreWidth + "px" }
      }),
      _vm._v(" "),
      _vm.activeIconClass || _vm.activeText
        ? _c(
            "span",
            {
              class: [
                "el-switch__label",
                "el-switch__label--right",
                _vm.checked ? "is-active" : ""
              ]
            },
            [
              _vm.activeIconClass
                ? _c("i", { class: [_vm.activeIconClass] })
                : _vm._e(),
              _vm._v(" "),
              !_vm.activeIconClass && _vm.activeText
                ? _c("span", { attrs: { "aria-hidden": !_vm.checked } }, [
                    _vm._v(_vm._s(_vm.activeText))
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }