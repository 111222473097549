import { render, staticRenderFns } from "./voiceDetail.vue?vue&type=template&id=736f1969&"
import script from "./voiceDetail.vue?vue&type=script&lang=js&"
export * from "./voiceDetail.vue?vue&type=script&lang=js&"
import style0 from "./voiceDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('736f1969')) {
      api.createRecord('736f1969', component.options)
    } else {
      api.reload('736f1969', component.options)
    }
    module.hot.accept("./voiceDetail.vue?vue&type=template&id=736f1969&", function () {
      api.rerender('736f1969', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/firmware/voiceDetail.vue"
export default component.exports