"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var state = {
  mechanismList: [],
  departmentList: [],
  categoryList: [] };


var mutations = {
  setMechanismList: function setMechanismList(state, data) {
    state.mechanismList = data;
  },
  setDepartmentList: function setDepartmentList(state, data) {
    state.departmentList = data;
  },
  setCategoryList: function setCategoryList(state, data) {
    state.categoryList = data;
  } };


var actions = {
  setMechanismList: function setMechanismList(_ref, data) {var commit = _ref.commit;
    commit('setMechanismList', data);
  },
  setDepartmentList: function setDepartmentList(_ref2, data) {var commit = _ref2.commit;
    commit('setDepartmentList', data);
  },
  setCategoryList: function setCategoryList(_ref3, data) {var commit = _ref3.commit;
    commit('setCategoryList', data);
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;