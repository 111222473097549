var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile" && _vm.showSettings === true
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" }
                }),
                _vm._v(" "),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                  attrs: { id: "error-log" }
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" }
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("navbar.size"),
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("lang-select", {
                  staticClass: "right-menu-item hover-effect"
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-tag", { attrs: { type: "success" } }, [
            _vm._v(_vm._s(_vm.env))
          ]),
          _vm._v(" "),
          _c(
            "el-tag",
            { staticStyle: { "margin-top": "-5px" }, attrs: { scoped: "" } },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.handleShowPwd }
                      },
                      [_vm._v("修改密码")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出登录")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            width: "500px",
            visible: _vm.newAdd,
            "before-close": _vm.closeRefund,
            "modal-append-to-body": false,
            center: "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newAdd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "160px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "oldPwd" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "15", placeholder: "请输入内容" },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.InputKg(_vm.addData.oldPwd)
                      }
                    },
                    model: {
                      value: _vm.addData.oldPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "oldPwd", $$v)
                      },
                      expression: "addData.oldPwd"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "newPwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      maxlength: "15",
                      placeholder: "请输入内容"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.InputKg2(_vm.addData.newPwd)
                      }
                    },
                    model: {
                      value: _vm.addData.newPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "newPwd", $$v)
                      },
                      expression: "addData.newPwd"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "checkPwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      maxlength: "15",
                      placeholder: "请输入内容"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.InputKg3(_vm.addData.checkPwd)
                      }
                    },
                    model: {
                      value: _vm.addData.checkPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.addData, "checkPwd", $$v)
                      },
                      expression: "addData.checkPwd"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.closeRefund } }, [
                    _vm._v("取消")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }