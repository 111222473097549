"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;





























































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { data: function data() {return { tableData: [], // 列表数据
      typeCodeList: [], isEnableList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }], tableColumns: [{ prop: 'ctype', label: '升级模块', formatter: _formatter.firmwareUpdateCtypeFormatter, isShow: true }, { prop: 'name', label: '升级计划名称', isShow: true }, { prop: 'code', label: '编码', isShow: true }, { prop: 'version', label: '中心板', isShow: true }, { prop: 'productTypeCode', label: '设备型号', isShow: true // 是否显示
      }, { prop: 'isEnable', label: '是否启用', formatter: _formatter.isEnableFormatter, isShow: true }, { prop: 'downloadType', label: '下载方式', formatter: _formatter.downloadTypeFormatter, isShow: true // 是否显示
      }, { prop: 'upgradeTime', label: '升级时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'merNames', label: '渠道商', isShow: true }, { prop: 'networkNames', label: '网点名称', isShow: true },
      {
        prop: 'remarks',
        label: '备注',
        isShow: true }],


      searchData: {
        ctype: '',
        upgradeVersion: '',
        upgradeName: '',
        upgradeCode: '',
        productTypeCode: '',
        isEnable: '',
        merName: '',
        networkName: '',
        page: 1, // 当前页码
        rows: 10 // 页码条数
      },
      pageData: {
        page: 1,
        rows: 10,
        total: 0,
        pages: 1 } };


  },
  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/firmwareUpgrade/page/all', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.ctype = '';
      this.searchData.upgradeName = '';
      this.searchData.upgradeCode = '';
      this.searchData.upgradeVersion = '';
      this.searchData.productTypeCode = '';
      this.searchData.isEnable = '';
      this.searchData.merName = '';
      this.searchData.networkName = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
    } } };exports.default = _default;