"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.replace");require("core-js/modules/es7.symbol.async-iterator");require("core-js/modules/es6.symbol");var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));require("core-js/modules/es6.function.name");require("core-js/modules/web.dom.iterable");



























var _ScrollPane = _interopRequireDefault(require("./ScrollPane"));
var _i18n = require("@/utils/i18n");
var _path = _interopRequireDefault(require("path")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { components: { ScrollPane: _ScrollPane.default }, data: function data() {return { visible: false, top: 0, left: 0, selectedTag: {}, affixTags: [] };}, computed: { visitedViews: function visitedViews() {return this.$store.state.tagsView.visitedViews;}, routes: function routes() {return this.$store.state.permission.routes;} }, watch: { $route: function $route() {this.addTags();this.moveToCurrentTag();}, visible: function visible(value) {if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    } },

  mounted: function mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    generateTitle: _i18n.generateTitle, // generateTitle by vue-i18n
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags: function filterAffixTags(routes) {var _this = this;var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = _path.default.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: (0, _objectSpread2.default)({}, route.meta) });

        }
        if (route.children) {
          var tempTags = _this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [].concat((0, _toConsumableArray2.default)(tags), (0, _toConsumableArray2.default)(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);var _iteratorNormalCompletion = true;var _didIteratorError = false;var _iteratorError = undefined;try {
        for (var _iterator = affixTags[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {var tag = _step.value;
          // Must have tag name
          if (tag.name) {
            this.$store.dispatch('tagsView/addVisitedView', tag);
          }
        }} catch (err) {_didIteratorError = true;_iteratorError = err;} finally {try {if (!_iteratorNormalCompletion && _iterator.return != null) {_iterator.return();}} finally {if (_didIteratorError) {throw _iteratorError;}}}
    },
    addTags: function addTags() {var
      name = this.$route.name;
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route);
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {var _this2 = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {var _iteratorNormalCompletion2 = true;var _didIteratorError2 = false;var _iteratorError2 = undefined;try {
          for (var _iterator2 = tags[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {var tag = _step2.value;
            if (tag.to.path === _this2.$route.path) {
              _this2.$refs.scrollPane.moveToTarget(tag);
              // when query is different then update
              if (tag.to.fullPath !== _this2.$route.fullPath) {
                _this2.$store.dispatch('tagsView/updateVisitedView', _this2.$route);
              }
              break;
            }
          }} catch (err) {_didIteratorError2 = true;_iteratorError2 = err;} finally {try {if (!_iteratorNormalCompletion2 && _iterator2.return != null) {_iterator2.return();}} finally {if (_didIteratorError2) {throw _iteratorError2;}}}
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {var _this3 = this;
      this.$store.dispatch('tagsView/delCachedView', view).then(function () {var
        fullPath = view.fullPath;
        _this3.$nextTick(function () {
          _this3.$router.replace({
            path: '/redirect' + fullPath });

        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {var _this4 = this;
      this.$store.dispatch('tagsView/delView', view).then(function (_ref) {var visitedViews = _ref.visitedViews;
        if (_this4.isActive(view)) {
          _this4.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags: function closeOthersTags() {var _this5 = this;
      this.$router.push(this.selectedTag);
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(function () {
        _this5.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {var _this6 = this;
      this.$store.dispatch('tagsView/delAllViews').then(function (_ref2) {var visitedViews = _ref2.visitedViews;
        if (_this6.affixTags.some(function (tag) {return tag.path === view.path;})) {
          return;
        }
        _this6.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath });
        } else {
          this.$router.push('/');
        }
      }
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      var offsetWidth = this.$el.offsetWidth; // container width
      var maxLeft = offsetWidth - menuMinWidth; // left boundary
      var left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }

      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    } } };exports.default = _default;