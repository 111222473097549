"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");






var _index = _interopRequireDefault(require("element-ui/lib/theme-chalk/index.css"));var _lib = _interopRequireDefault(require("element-ui/lib"));require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/core-js/modules/es6.array.iterator.js");require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/core-js/modules/es6.promise.js");require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/core-js/modules/es6.object.assign.js");require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/core-js/modules/es7.promise.finally.js");var _vue = _interopRequireDefault(require("vue"));var _jsCookie = _interopRequireDefault(require("js-cookie"));require("normalize.css/normalize.css");

var _lang = _interopRequireDefault(require("./lang"));

require("./styles/element-variables.scss");
require("@/styles/index.scss");

var _lodash = _interopRequireDefault(require("lodash"));


var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./utils/common");

require("@/icons");
require("@/permission");
require("./utils/error-log");


var _OgSwitch = _interopRequireDefault(require("@/components/OgSwitch")); // import locale from 'element-ui/lib/locale/lang/en' // lang i18n
// import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
// global css
_vue.default.prototype.$lodash = _lodash.default;_vue.default.use(_OgSwitch.default); // // 引入jquery
// import $ from 'jquery'
// Vue.prototype.$ = $
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// // if (process.env.NODE_ENV === 'production') {
// if (process.env.NODE_ENV === 'development') {
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_lib.default, {
  size: _jsCookie.default.get('size') || 'medium', // set element-ui default size
  i18n: function i18n(key, value) {return _lang.default.t(key, value);} });


_vue.default.config.productionTip = false;

new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {return h(_App.default);} });