"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");require("core-js/modules/es6.regexp.replace");require("core-js/modules/es6.number.constructor");






























































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'personalLedger', data: function data() {return { tableData: [], // 列表数据
      platCodeList: [], searchData: { nickname: '', telNo: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', startData: { miniAmt: '', memberType: 'individual', memberId: '' }, addData: { id: '', platCode: '', nickname: '', bankName: '', cardName: '', certType: '', certId: '', cardId: '', telNo: '', email: '', location: '', rebind: 'false', memberId: '' }, defaultData: { id: '', platCode: '', nickname: '', bankName: '', cardName: '', certType: '', certId: '', cardId: '', telNo: '', email: '', location: '' }, rules: { platCode: [{ required: true, message: '请选择运营商', trigger: 'change' }], nickname: [{ required: true, message: '请填写昵称', trigger: 'blur' }], cardName: [{ required: true, message: '请输入银行开户名称', trigger: 'blur' }], certType: [{ required: true, message: '请选择开户证件类型', trigger: 'change' }], certId: [{ required: true, message: '请输入身份证号', trigger: 'blur' }], cardId: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }], telNo: [{ required: true, message: '请输入用户手机号', trigger: 'blur' }] }, newadd: false, newadd2: false, // 表格列
      tableColumns: [{ prop: 'nickname', label: '用户名称', isShow: true }, { prop: 'memberId', label: '账户', isShow: true }, { prop: 'telNo', label: '用户手机号', isShow: true }, { prop: 'cardName', label: '银行开户人', isShow: true }, { prop: 'bankName', label: '银行', isShow: true },

      {
        prop: 'createTime',
        label: '创建时间',
        formatter: _formatter.dateFormatter,
        isShow: false },

      {
        prop: 'cardId',
        label: '银行卡号',
        isShow: true }],


      certTypeList: [{ name: '身份证', id: '00' }] };

  },
  mounted: function mounted() {
    var self = this;
    self.init();
    self.getPlatCodeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/payment/member/inList', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    getPlatCodeList: function getPlatCodeList() {
      var self = this;
      self.getData('/api/payment/brand', {}, function (res) {
        self.platCodeList = res;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.nickname = '';
      this.searchData.telNo = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 创建商户进件
      this.title = '创建商户进件';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      this.$refs['addForm2'].resetFields();
      done();
    },
    onCencal2: function onCencal2() {
      // 详情弹窗关闭
      this.newadd2 = false;
      this.$refs['addForm2'].resetFields();
    },
    IdentityCodeValid: function IdentityCodeValid(code) {
      var self = this;
      var city = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江 ',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北 ',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏 ',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '中国台湾',
        81: '中国香港',
        82: '中国澳门',
        91: '国外 ' };


      if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
        self.$message({
          showClose: true,
          message: '身份证号格式错误',
          type: 'error' });

        self.addData.certId = '';
        return;
      }

      if (!city[code.substr(0, 2)]) {
        self.$message({
          showClose: true,
          message: '地址编码错误',
          type: 'error' });

        self.addData.certId = '';
        return;
      }
      if (code.length == 18) {
        var sBirthday = code.substr(6, 4) + '-' + Number(code.substr(10, 2)) + '-' + Number(code.substr(12, 2));
        var d = new Date(sBirthday.replace(/-/g, '/'));
        if (sBirthday != d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()) {
          self.$message({
            showClose: true,
            message: '非法生日',
            type: 'error' });

          self.addData.certId = '';
          return;
        }
      }
      //18位身份证需要验证最后一位校验位
      if (code.length == 18) {
        code = code.split('');
        //∑(ai×Wi)(mod 11)
        //加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        //校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        var sum = 0;
        var ai = 0;
        var wi = 0;
        for (var i = 0; i < 17; i++) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
        }
        var last = parity[sum % 11];
        if (parity[sum % 11] != code[17]) {
          self.$message({
            showClose: true,
            message: '校验位错误',
            type: 'error' });

          self.addData.certId = '';
          return;
        }
      }
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.certId) {
        if (!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(self.addData.certId)) {
          self.$message({
            showClose: true,
            message: '身份证号格式错误',
            type: 'error' });

          self.addData.certId = '';
          return false;
        }
      }
      if (self.addData.email) {
        var emailNum = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!emailNum.test(self.addData.email)) {
          self.$message({
            showClose: true,
            message: '邮箱有误，请重填',
            type: 'error' });

          self.addData.email = '';
          return false;
        }
      }
      if (self.addData.telNo) {
        if (self.addData.telNo.length == '11') {
          if (!/^1[3456789]\d{9}$/.test(self.addData.telNo)) {
            self.$message({
              showClose: true,
              message: '手机号码有误，请重填',
              type: 'error' });

            self.addData.telNo = '';
            return false;
          }
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/payment/member/individual', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    lookItem: function lookItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '查看';
      self.newadd = true;
      self.addData = {
        id: item.id,
        nickname: item.nickname,
        platCode: item.platCode,
        bankName: item.bankName,
        cardName: item.cardName,
        certType: item.certType + '',
        certId: item.certId,
        cardId: item.cardId,
        telNo: item.telNo,
        email: item.email,
        location: item.location,
        rebind: 'false' };

    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.addData = {
        id: item.id,
        nickname: item.nickname,
        platCode: item.platCode,
        bankName: item.bankName,
        cardName: item.cardName,
        certType: item.certType + '',
        certId: item.certId,
        cardId: item.cardId,
        telNo: item.telNo,
        email: item.email,
        location: item.location,
        rebind: 'false',
        memberId: item.memberId };

    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.certId) {
        if (!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(self.addData.certId)) {
          self.$message({
            showClose: true,
            message: '身份证号格式错误',
            type: 'error' });

          self.addData.certId = '';
          return false;
        }
      }
      if (self.addData.email) {
        var emailNum = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!emailNum.test(self.addData.email)) {
          self.$message({
            showClose: true,
            message: '邮箱有误，请重填',
            type: 'error' });

          self.addData.email = '';
          return false;
        }
      }
      if (self.addData.telNo) {
        if (self.addData.telNo.length == '11') {
          if (!/^1[3456789]\d{9}$/.test(self.addData.telNo)) {
            self.$message({
              showClose: true,
              message: '手机号码有误，请重填',
              type: 'error' });

            self.addData.telNo = '';
            return false;
          }
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/payment/member/individual', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    startItem: function startItem(item) {
      console.log(item);
      var self = this;
      self.startData.memberId = item.memberId;
      self.startData.memberType = 'individual';
      self.getData('/api/payment/member/settleInfo', self.startData, function (res) {
        if (res != null) {
          self.startData.miniAmt = res;
        }
        self.title = '起始金设置';
        self.newadd2 = true;
      });
    },
    onSubmitStart: function onSubmitStart() {
      var self = this;
      self.$refs.addForm2.validate(function (valid) {
        if (valid) {
          self.putData('/api/payment/member/settleInfo', self.startData, function (res) {
            self.$message({
              message: '设置成功!',
              type: 'success' });

            self.onCencal2();
            self.init();
          });
        } else {
          return false;
        }
      });
    } } };exports.default = _default;