"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");





















































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'cashback', data: function data() {return { timeRange: '', //时间范围
      tableData: [], // 列表数据
      searchData: { id: '', sn: '', status: '', startTime: '', endTime: '', openid: '', userPhone: '', thirdOrderId: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', rechargeAmt: '', amt: '', orderNum: '', status: '4' }, rules: { amt: [{ required: true, message: '请输入退款金额', trigger: 'blur' }], refundPasswd: [{ required: true, message: '请输入退款密码', trigger: 'blur' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'id', label: '订单编号', isShow: true }, { prop: 'thirdOrderId', label: '第三方商户单号', isShow: true }, { prop: 'platCode', label: '运营商', isShow: true }, { prop: 'merchName', label: '渠道商', isShow: true }, { prop: 'networkName', label: '网点', isShow: true }, { prop: 'receiveAccount', label: '收款账号', isShow: true }, { prop: 'userPhone', label: '用户手机', isShow: true }, { prop: 'rechargeAmt', label: '充值金额', isShow: true },
      {
        prop: 'rewardAmt',
        label: '返现金额',
        isShow: true },

      {
        prop: 'rechargeAmt',
        label: '实际支付金额',
        isShow: true },

      {
        prop: 'refundAmt',
        label: '退款金额',
        isShow: true },

      {
        prop: 'status',
        label: '订单状态',
        formatter: _formatter.orderStatusFormatter,
        isShow: true },

      {
        prop: 'payTime',
        label: '充值时间',
        formatter: _formatter.dateFormatter,
        isShow: false },

      {
        prop: 'refundTime',
        label: '退款时间',
        formatter: _formatter.dateFormatter,
        isShow: false },

      {
        prop: 'payChannel',
        label: '支付方式',
        formatter: _formatter.payChannelFormatter,
        isShow: false }],


      statusList: [
      { name: '未支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '支付取消', id: '2' },
      { name: '人工退款', id: '3' },
      { name: '一键退款', id: '4' },
      { name: '自动退款', id: '5' },
      { name: '所有已退款', id: '6' }],

      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        } } };


  },
  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('/api/recharge/bill/list', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.id = '';
      this.searchData.sn = '';
      this.searchData.openid = '';
      this.searchData.thirdOrderId = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.searchData.status = '';
      this.searchData.userPhone = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      this.searchData.page = 1;
      var start = new Date(this.searchData.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.searchData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.searchData.endTime = '';
        return false;
      }
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 退款弹窗
      var self = this;
      self.title = '退款操作';
      self.newadd = true;
      self.addData = {
        id: item.id,
        rechargeAmt: item.rechargeAmt,
        status: '4' };

    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/recharge/bill/refund', self.addData, function (res) {
            self.$message({
              message: '退款成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    excelFun: function excelFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/recharge/bill/export?id=' +
      self.searchData.id +
      '&sn=' +
      self.searchData.sn +
      '&status=' +
      self.searchData.status +
      '&startTime=' +
      self.searchData.startTime +
      '&endTime=' +
      self.searchData.endTime +
      '&openid=' +
      self.searchData.openid +
      '&userPhone=' +
      self.searchData.userPhone +
      '&thirdOrderId=' +
      self.searchData.thirdOrderId;
    } } };exports.default = _default;