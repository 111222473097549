"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/web.dom.iterable");var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));
















































































































var _vuex = require("vuex");
var _index = require("@/utils/index");
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'feedback', data: function data() {return { searchForm: { id: '', orderId: '', mchCodeL1: '', status: '', startTime: '', endTime: '' }, timeRange: '', //时间范围
      page: 1, //页码
      rows: 10, //每页条数
      pageData: { page: 1, rows: 10, total: 0, pages: 1 }, reply: { txt: '' }, replyRule: { txt: [{ required: true, message: '请输入回复内容', trigger: 'blur' }] }, showReply: false, //是否显示回复弹窗
      showDetail: false, //是否显示详情弹窗
      currentRow: '', //当前问题item
      pickerOptions: { disabledDate: function disabledDate(time) {return time.getTime() > Date.now();} }, oneChannelList: [], //一级渠道商列表
      statusList: [{ id: 0, name: '未回复' }, { id: 1, name: '已回复' }], //状态筛选列表
      tableColumns: [{ props: 'id', label: '问题编号', isShow: true, width: 230 }, { props: 'createTime', label: '反馈时间', isShow: true, width: 180, formatter: _formatter.dateFormatter }, { props: 'orderId', label: '支付订单编号', isShow: true, width: 230 }, { props: 'mchnameL1', label: '一级渠道商', isShow: true, width: 120 }, { props: 'mchnameL2', label: '二级渠道商', isShow: true, width: 120 }, { props: 'deviceLocation', label: '设备位置', isShow: true, formatter: _formatter.feedbackDeviceLocation, width: 100 }, { props: 'orderStatus', label: '订单状态', isShow: true, formatter: _formatter.orderStatusFormatter, width: 200 }, { props: 'merPicRefList', label: '图片', isShow: true }, { props: 'demand', label: '问题描述', isShow: true }, { props: 'status', label: '状态', isShow: true, formatter: _formatter.feedbackStatus }, { props: 'reply', label: '客服回复', isShow: true, width: 200 }], tableData: [], //表格数据
      payStatus: ['未支付', '已支付', '支付取消', '人工退款', '一键退款', '自动退款'] //订单支付状态
    };}, watch: {
    "searchForm.startTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchForm, 'startTime', '');
        }
      },
      deep: true },

    "searchForm.endTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchForm, 'endTime', '');
        }
      },
      deep: true } },



  computed: (0, _objectSpread2.default)({
    /**
                                           * 图片预览
                                           */
    previewImgList: function previewImgList() {
      return function (item) {
        var list = [];
        if (item.merPicRefList) {
          item.merPicRefList.forEach(function (e) {
            list.push(e.target);
          });
        }
        return list;
      };
    },

    /**
        * 详情订单状态
        */
    orderStatus: function orderStatus() {
      return this.payStatus[this.currentRow.orderStatus];
    },

    /**
        * 详情反馈时间
        */
    feedbackTime: function feedbackTime() {
      return (0, _index.parseTime)(this.currentRow.createTime);
    },

    /**
        * 详情设备位置
        */
    deviceLocation: function deviceLocation() {
      var network = this.currentRow['networkName'];
      var res = network;

      if (this.currentRow['deviceLocation']) {
        var val = JSON.parse(this.currentRow['deviceLocation']);
        if (val[0] && val[0].positionRow) {
          res += ' ' + val[0].positionRow + '排';
        }
        if (val[0] && val[0].positionColumn) {
          res += val[0].positionColumn + '列';
        }
        if (val[1] && val[1].positionRow) {
          res += ' ' + val[1].positionRow + '排';
        }
        if (val[1] && val[1].positionColumn) {
          res += val[1].positionColumn + '列';
        }
      }
      console.log('res====>', res);
      return res;
    } },

  (0, _vuex.mapState)('user', ['problems'])),


  mounted: function mounted() {
    this.fetchOneChannelList();
    this.fetchFeedbackList();
  },

  methods: (0, _objectSpread2.default)({},
  (0, _vuex.mapMutations)('user', ['SET_PROBLEMS']), {
    /**
                                                        * 获取一级渠道商列表
                                                        */
    fetchOneChannelList: function fetchOneChannelList() {var _this = this;
      this.getData('/api/merMerchant/primary/list', {}, function (res) {
        _this.oneChannelList = res;
      });
    },

    /**
        * 获取问题反馈列表
        */
    fetchFeedbackList: function fetchFeedbackList() {var _this2 = this;
      var params = Object.assign({}, this.searchForm);
      params.page = this.page;
      params.rows = this.rows;
      if (this.timeRange) {
        params.startTime = this.timeRange[0];
        params.endTime = this.timeRange[1];
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      this.getData('/api/feedback', params, function (res) {
        _this2.tableData = res.list;
        _this2.pageData.page = res.pageNum;
        _this2.pageData.total = res.total;
      });
    },

    /**
        * 分页size-change
        */
    handleSizeChange: function handleSizeChange(val) {
      this.rows = val;
      this.page = 1;
      this.fetchFeedbackList();
    },

    /**
        * 分页页码-change
        */
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.fetchFeedbackList();
    },

    /**
        * 查询
        */
    searchFun: function searchFun() {
      this.page = 1;
      this.fetchFeedbackList();
    },

    /**
        * 重置搜索条件
        */
    resetFun: function resetFun() {
      this.$set(this.searchForm, 'id', '');
      this.$set(this.searchForm, 'orderId', '');
      this.$set(this.searchForm, 'mchCodeL1', '');
      this.$set(this.searchForm, 'status', '');
      this.$set(this, 'timeRange', '');
      this.$set(this.searchForm, 'startTime', '');
      this.$set(this.searchForm, 'endTime', '');
      this.page = 1;
      this.rows = 10;
      this.fetchFeedbackList();
    },

    /**
        * 导出Excel
        */
    excelFun: function excelFun() {
      // console.log('this.queryParam====>',this.queryParams(this.searchForm, true, 'comma'))
      if (this.timeRange) {
        this.searchForm.startTime = this.timeRange[0];
        this.searchForm.endTime = this.timeRange[1];
      } else {
        this.$set(this.searchForm, 'startTime', '');
        this.$set(this.searchForm, 'endTime', '');
      }
      var href = '/api/feedback/export' + '?id=' + this.searchForm.id + '&orderId=' + this.searchForm.orderId + '&mchCodeL1=' + this.searchForm.mchCodeL1 + '&status=' + this.searchForm.status + '&startTime=' + this.searchForm.startTime + '&endTime=' + this.searchForm.endTime;
      window.location.href = href;
    },

    /**
        * 关闭回复弹窗
        */
    closeReply: function closeReply(done) {var _this3 = this;
      this.$nextTick(function () {
        _this3.$set(_this3.reply, 'txt', '');
      });
    },

    /**
        * 提交回复
        */
    onClickSubmitReply: function onClickSubmitReply() {var _this4 = this;
      if (!this.reply.txt) {
        this.$message({
          message: '请填写回复再提交',
          type: 'error' });

        return;
      }
      var params = {
        id: this.currentRow.id,
        reply: this.reply.txt };

      this.putData('/api/feedback', params, function (res) {
        _this4.$message({
          message: '回复成功',
          type: 'success' });

        var num = _this4.problems - 1;
        _this4.SET_PROBLEMS(num);
        _this4.$set(_this4.currentRow, 'reply', _this4.reply.txt);
        _this4.$set(_this4.currentRow, 'status', 1);
        _this4.$set(_this4.reply, 'txt', '');
        _this4.showReply = false;
      });
    },

    /**
        * 查看详情
        */
    checkDetail: function checkDetail(id) {var _this5 = this;
      this.getData('/api/feedback/' + id, {}, function (res) {
        _this5.currentRow = Object.assign({}, res);
        _this5.showDetail = true;
      });
    },

    /**
        * 关闭详情弹窗
        */
    closeDetail: function closeDetail(done) {
      done();
    } }) };exports.default = _default;