var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-cascader", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              options: _vm.optionsList,
              props: _vm.props,
              "collapse-tags": "",
              filterable: "",
              clearable: "",
              placeholder: "请选择渠道商"
            },
            on: { change: _vm.changeMechanism },
            model: {
              value: _vm.searchData.merchantCode,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "merchantCode", $$v)
              },
              expression: "searchData.merchantCode"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择网点"
              },
              model: {
                value: _vm.searchData.networkCode,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "networkCode", $$v)
                },
                expression: "searchData.networkCode"
              }
            },
            _vm._l(_vm.networkCodeList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.code }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                clearable: "",
                placeholder: "请选择场景类型"
              },
              model: {
                value: _vm.searchData.sceneCode,
                callback: function($$v) {
                  _vm.$set(_vm.searchData, "sceneCode", $$v)
                },
                expression: "searchData.sceneCode"
              }
            },
            _vm._l(_vm.sceneCodeList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.code }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("按时间：")]),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              clearable: "",
              "picker-options": _vm.pickerOptions,
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "range-separator": "至",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.timeRange,
              callback: function($$v) {
                _vm.timeRange = $$v
              },
              expression: "timeRange"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "success", icon: "el-icon-s-promotion" },
              on: { click: _vm.excelNetworkFun }
            },
            [_vm._v("导出网点")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item mb10",
              attrs: { type: "primary", icon: "el-icon-s-promotion" },
              on: { click: _vm.excelDeviceFun }
            },
            [_vm._v("导出设备")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.newadd,
            width: "800px",
            "before-close": _vm.closeRefund,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  type: "text",
                  placeholder: "请输入序列号",
                  clearable: ""
                },
                model: {
                  value: _vm.deviceSData.sn,
                  callback: function($$v) {
                    _vm.$set(_vm.deviceSData, "sn", $$v)
                  },
                  expression: "deviceSData.sn"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "filter-item" }, [_vm._v("按时间：")]),
              _vm._v(" "),
              _c("el-date-picker", {
                staticClass: "filter-item",
                staticStyle: { width: "300px" },
                attrs: {
                  clearable: "",
                  "picker-options": _vm.pickerOptions,
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "range-separator": "至",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.timeRange1,
                  callback: function($$v) {
                    _vm.timeRange1 = $$v
                  },
                  expression: "timeRange1"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.searchFun2 }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "warning", icon: "el-icon-refresh" },
                  on: { click: _vm.resetFun2 }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item mb10",
                  attrs: { type: "primary", icon: "el-icon-s-promotion" },
                  on: { click: _vm.excelFun }
                },
                [_vm._v("导出设备")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.deviceData,
                "tooltip-effect": "dark",
                border: "",
                stripe: ""
              },
              on: { "selection-change": _vm.handleSelectionChange2 }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sn", label: "设备序列号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "typeCode", label: "产品型号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "出货日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm._f("dateFilter")(scope.row.createTime)) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "运营日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm._f("dateFilter")(scope.row.operateTime)
                            ) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "incomeTotal", label: "实收总金额(元)" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "packageTotal", label: "套餐总金额(元)" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "incomeAvgDay", label: "日台均收益(元)" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm._f("statusFilter")(scope.row.status)) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("\n\t\t\t\t显示/隐藏列\n\t\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "260", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.lookMenu(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看设备")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }