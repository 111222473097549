"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.replace"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  data: function data() {
    return {
      sizeOptions: [
      { label: 'Default', value: 'default' },
      { label: 'Medium', value: 'medium' },
      { label: 'Small', value: 'small' },
      { label: 'Mini', value: 'mini' }] };


  },
  computed: {
    size: function size() {
      return this.$store.getters.size;
    } },

  methods: {
    handleSetSize: function handleSetSize(size) {
      this.$ELEMENT.size = size;
      this.$store.dispatch('app/setSize', size);
      this.refreshView();
      this.$message({
        message: 'Switch Size Success',
        type: 'success' });

    },
    refreshView: function refreshView() {var _this = this;
      // In order to make the cached page re-rendered
      this.$store.dispatch('tagsView/delAllCachedViews', this.$route);var

      fullPath = this.$route.fullPath;

      this.$nextTick(function () {
        _this.$router.replace({
          path: '/redirect' + fullPath });

      });
    } } };exports.default = _default;