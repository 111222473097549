"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/lang/index");

var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false },

  device: 'desktop',
  language: (0, _index.getLanguage)(),
  size: _jsCookie.default.get('size') || 'medium',
  env: _jsCookie.default.get('env') || '开发环境' };


var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    _jsCookie.default.set('language', language);
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set('size', size);
  },
  SET_ENV: function SET_ENV(state, env) {
    state.env = env;
    _jsCookie.default.set('env', env);
  } };


var actions = {
  toggleSideBar: function toggleSideBar(_ref) {var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {var commit = _ref2.commit;var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setLanguage: function setLanguage(_ref5, language) {var commit = _ref5.commit;
    commit('SET_LANGUAGE', language);
  },
  setSize: function setSize(_ref6, size) {var commit = _ref6.commit;
    commit('SET_SIZE', size);
  },
  setEnv: function setEnv(_ref7, env) {var commit = _ref7.commit;
    commit('SET_ENV', env);
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;