"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;




































































































var _auth = require("@/utils/auth"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'index', data: function data() {return { searchData: { type: 'year' }, total: '', //历史收益
      currentMonth: '', //本月收益
      monthlyAvg: '', //本月台均收益
      totalNum: '', //投放数
      onlineNum: '', //在线数
      offlineNum: '', //离线数
      faultNum: '', //故障数
      refundRate: '', //退款率
      orderNums: [], //已完成订单
      refundNums: [], //退款订单
      orderAmount: [], //订单金额
      tableData: [] //排序前15
    };}, mounted: function mounted() {var self = this;self.init();self.dataTime();}, methods: { init: function init() {var self = this;self.getData('/api/homePageStatistics/income', {}, function (res) {self.total = res.total;self.currentMonth = res.currentMonth;self.monthlyAvg = res.monthlyAvg;});self.getData('/api/homePageStatistics/device', {}, function (res) {self.totalNum = res.total;self.onlineNum = res.online;self.offlineNum = res.offline;self.faultNum = res.fault;});self.getData('/api/homePageStatistics/rankingList', {}, function (res) {self.tableData = res;});self.getData('/api/homePageStatistics/refundRate', {}, function (res) {self.refundRate = res;});}, dataTime: function dataTime() {var self = this;self.getData('/api/homePageStatistics/order', {}, function (res) {self.timeNum = res.date;self.orderNums = res.orderNums;self.refundNums = res.refundNums;self.orderAmount = res.orderAmount;self.drawLine();});}, drawLine: function drawLine() {var self = this; // 基于准备好的dom，初始化echarts实例
      var echarts = require('echarts');var myChart = echarts.init(document.getElementById('myChart1'));var myChart2 = echarts.init(document.getElementById('myChart2')); // 绘制图表
      myChart.setOption({ color: ['#38a28a', '#FF00FF'], title: { text: '订单统计', right: '2%' }, tooltip: { trigger: 'axis' }, legend: { textStyle: { color: '#000', fontSize: 16 } }, grid: { left: '6%', right: '6%', bottom: '3%', containLabel: true }, xAxis: { type: 'category', data: self.timeNum, axisLine: { lineStyle: { color: "#8A8D92" } }, nameLocation: "end", nameTextStyle: { color: "#000", fontSize: 16, padding: [30, 30, 0, 0] } }, yAxis: { name: '(单)', type: 'value', nameTextStyle: { color: "rgba(0, 0, 0, 1)", fontSize: 18, padding: [0, 80, 20, 0] } }, series: [{ name: '已完成订单', type: 'line', data: self.orderNums },

        {
          name: '退款订单',
          type: 'line',
          data: self.refundNums }] });



      myChart2.setOption({
        color: ['#ffca1b'],
        title: {
          text: '订单金额统计',
          left: '2%' },

        tooltip: {
          trigger: 'axis' },

        legend: {
          textStyle: {
            color: '#000',
            fontSize: 16 } },


        grid: {
          left: '6%',
          right: '6%',
          bottom: '3%',
          containLabel: true },

        xAxis: {
          type: 'category',
          data: self.timeNum,
          axisLine: {
            lineStyle: {
              color: "#8A8D92" } },


          nameLocation: "end",
          nameTextStyle: {
            color: "#000",
            fontSize: 16,
            padding: [30, 30, 0, 0] } },


        yAxis: {
          type: 'value' },

        series: [
        {
          name: '订单金额',
          type: 'line',
          data: self.orderAmount }] });



    } } };exports.default = _default;