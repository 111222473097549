import { render, staticRenderFns } from "./channelLedger.vue?vue&type=template&id=15add380&scoped=true&"
import script from "./channelLedger.vue?vue&type=script&lang=js&"
export * from "./channelLedger.vue?vue&type=script&lang=js&"
import style0 from "./channelLedger.vue?vue&type=style&index=0&id=15add380&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15add380",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhixin/Documents/ogawa/svn/摩享靠垫/后台/trunk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15add380')) {
      api.createRecord('15add380', component.options)
    } else {
      api.reload('15add380', component.options)
    }
    module.hot.accept("./channelLedger.vue?vue&type=template&id=15add380&scoped=true&", function () {
      api.rerender('15add380', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ledger/channelLedger.vue"
export default component.exports