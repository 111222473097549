"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.to-string");require("core-js/modules/web.dom.iterable");





































































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'releaseActivity', data: function data() {return { tableData: [], // 列表数据
      optionsList: [], searchData: { name: '', ctype: '', // 活动类型（0第二单优惠、 1套餐活动优惠、 2体验活动优惠）
        isEnable: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', name: '', ctype: '', preferentialWay: '', effectiveWay: '', startTimeStr: '', endTimeStr: '', preferentialCycleDays: null, cmode: '', massageIntervalMinutes: '', preferentialPackage: '', preferentialDiscount: '', preferentialAmount: '', isEnable: '', merIds: [] }, defaultData: { id: '', name: '', ctype: '', preferentialWay: '', effectiveWay: '', startTimeStr: '', endTimeStr: '', preferentialCycleDays: null, cmode: '', massageIntervalMinutes: '', preferentialPackage: '', preferentialDiscount: '', preferentialAmount: '', isEnable: '', merIds: [] }, merIdsData: { id: '', merIds: [] }, rules: { name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }], ctype: [{ required: true, message: '请选择活动类型', trigger: 'change' }], preferentialWay: [{ required: true, message: '请选择优惠方式', trigger: 'change' }], cmode: [{ required: true, message: '请选择活动方式', trigger: 'change' }], preferentialDiscount: [{ required: true, message: '请选择优惠打折', trigger: 'change' }], startTimeStr: [{ required: true, message: '请选择活动时间', trigger: 'change' }], effectiveWay: [{ required: true, message: '请选择有效方式', trigger: 'change' }], merIds: [{ required: true, message: '请选择渠道商', trigger: 'change' }] // preferentialWay: [
        // 	{
        // 		required: true,
        // 		message: '请选择优惠方式',
        // 		trigger: 'change'
        // 	}
        // ],
      }, newadd: false, newadd2: false, // 表格列
      tableColumns: [{ prop: 'name', label: '活动名称', isShow: true }, { prop: 'ctype', label: '活动类型', formatter: _formatter.ctypeFormatter2, isShow: true }, { prop: 'preferentialWay', label: '优惠方式', formatter: _formatter.preferentialWayFormatter, isShow: true }, { prop: 'cmode', label: '活动方式', formatter: _formatter.cmodeFormatter, isShow: true }, { prop: 'massageIntervalMinutes', label: '按摩结束多久时间内', formatter: _formatter.massageIntervalMinutesFormatter, isShow: true }, // {
      // 	prop: 'preferentialDiscount',
      // 	label: '优惠',
      // 	isShow: true
      // },
      { prop: 'preferentialPackage', label: '参与的套餐档', formatter: _formatter.preferentialPackageFormatter, isShow: true }, { prop: 'effectiveWay', label: '有效方式', formatter: _formatter.effectiveWayFormatter, isShow: true }, { prop: 'startTime', label: '开始时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'endTime', label: '结束时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'isEnable', label: '状态', formatter: _formatter.isEnableFormatter, isShow: false }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: false }], programList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }], ctypeList: [{ name: '第二单优惠', id: '0' }, { name: '套餐活动优惠', id: '1' }, { name: '体验活动优惠', id: '2' }], preferentialWayList: [{ name: '限一次', id: '0' }, { name: '每天一次', id: '1' }, { name: '每周一次', id: '2' }, { name: '每月一次', id: '3' }, { name: '自由配置', id: '4' }], cmodeList: [
      { name: '折扣', id: '0' },
      { name: '金额优惠', id: '1' }],

      massageIntervalMinutesList: [
      { name: '不限时间', id: '0' },
      { name: '5分钟', id: '5' },
      { name: '10分钟', id: '10' },
      { name: '20分钟', id: '20' },
      { name: '30分钟', id: '30' },
      { name: '40分钟', id: '40' },
      { name: '60分钟', id: '60' },
      { name: '2小时', id: '120' },
      { name: '3小时', id: '180' },
      { name: '4小时', id: '240' },
      { name: '5小时', id: '300' },
      { name: '6小时', id: '360' },
      { name: '12小时', id: '720' },
      { name: '24小时', id: '1440' },
      { name: '48小时', id: '2880' },
      { name: '72小时', id: '4320' }],

      packageList: [
      { name: '第一档套餐', id: '1' },
      { name: '第二档套餐', id: '2' },
      { name: '第三档套餐', id: '3' },
      { name: '第四档套餐', id: '4' },
      { name: '第五档套餐', id: '5' },
      { name: '第六档套餐', id: '6' },
      { name: '第七档套餐', id: '7' },
      { name: '第八档套餐', id: '8' },
      { name: '第九档套餐', id: '9' },
      { name: '第十档套餐', id: '10' }],

      preferentialDiscountList: [
      { name: '1折', id: '1' },
      { name: '2折', id: '2' },
      { name: '3折', id: '3' },
      { name: '4折', id: '4' },
      { name: '5折', id: '5' },
      { name: '6折', id: '6' },
      { name: '7折', id: '7' },
      { name: '8折', id: '8' },
      { name: '9折', id: '9' }],

      effectiveWayList: [
      { name: '全部', id: '0' },
      { name: '工作日', id: '1' },
      { name: '周末', id: '2' }],

      props: {
        children: 'subMers',
        label: 'name',
        value: 'id',
        checkStrictly: false, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: true },

      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() + 2000 <= Date.now();
        } },

      // start
      checked: false,
      datalist: []
      // end
    };
  },
  filters: {
    preferentialDiscountFilters: function preferentialDiscountFilters(val) {
      if (val.cmode == 1) {
        return '优惠金额' + val.preferentialAmount;
      } else if (val.cmode == 0) {
        return val.preferentialDiscount + '折';
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/actActivity', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.name = '';
      this.searchData.ctype = '';
      this.searchData.isEnable = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '添加活动';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      this.checked = false;
      this.datalist = [];
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      this.$refs['addForm2'].resetFields();
      this.checked = false;
      this.datalist = [];
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.checked = false;
      this.datalist = [];
      this.$refs['addForm'].resetFields();
    },
    onCencal2: function onCencal2() {
      // 详情弹窗关闭
      this.newadd2 = false;
      this.checked = false;
      this.datalist = [];
      this.$refs['addForm2'].resetFields();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        console.log('res:', res);
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(value) {var _this = this;
      // this.addData.merIds = value
      var merIds = [];
      merIds = this.$refs.merIdsRef.getCheckedNodes();
      merIds.forEach(function (item) {
        if (_this.addData.merIds.indexOf(item.value) === -1) {
          _this.addData.merIds.push(item.value);
          _this.merIdsData.merIds = _this.addData.merIds;
        }
      });
      if (merIds.length - value.length != 1) {
        this.addData.merIds = value;
        this.merIdsData.merIds = value;
      }
    },
    onSubmit: function onSubmit() {var _this2 = this;
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        var start = new Date(_this2.addData.startTimeStr);
        var deliverTimeStart = start.getTime();
        var end = new Date(_this2.addData.endTimeStr);
        var deliverTimeEnd = end.getTime();
        var now = new Date().getTime();
        if (deliverTimeStart > deliverTimeEnd) {
          _this2.$message({
            message: '请选择结束时间要大于开始时间',
            type: 'error' });

          _this2.addData.endTimeStr = '';
          return false;
        } else if (deliverTimeStart < now) {
          _this2.$message({
            message: '请选择开始时间要大于当前系统时间',
            type: 'error' });

          _this2.addData.startTimeStr = '';
          return false;
        } else if (deliverTimeEnd < now) {
          _this2.$message({
            message: '请选择结束时间要大于当前系统时间',
            type: 'error' });

          _this2.addData.endTimeStr = '';
          return false;
        }
        if (self.addData.preferentialWay != '4') {
          self.addData.preferentialCycleDays = null;
        }
        if (self.addData.ctype == '2') {
          self.addData.cmode = '';
          self.addData.massageIntervalMinutes = '';
          self.addData.preferentialPackage = '';
          self.addData.preferentialDiscount = '';
          self.addData.preferentialAmount = '';
          self.addData.merIds = null;
        }
        if (self.addData.cmode == '0') {
          self.addData.preferentialAmount = '1';
        } else {
          self.addData.preferentialDiscount = '';
        }
        if (self.addData.preferentialPackage != '') {
          self.addData.preferentialPackage = self.addData.preferentialPackage.toString();
        } else if (self.addData.preferentialPackage.length == 0) {
          self.addData.preferentialPackage = null;
        }
        if (valid) {
          self.postData('/api/actActivity', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.getData('/api/actActivity/' + item.id, {}, function (res) {
        console.log('res:', res);
        self.addData = {
          id: res.id,
          name: res.name,
          ctype: res.ctype + '',
          preferentialWay: res.preferentialWay + '',
          effectiveWay: res.effectiveWay + '',
          preferentialCycleDays: res.preferentialCycleDays,
          cmode: res.cmode + '',
          massageIntervalMinutes: res.massageIntervalMinutes + '',
          preferentialDiscount: res.preferentialDiscount + '',
          preferentialAmount: res.preferentialAmount,
          isEnable: res.isEnable + '',
          merIds: res.merIds,
          startTimeStr: res.startTime,
          endTimeStr: res.endTime,
          preferentialPackage: res.preferentialPackage };

        if (res.startTime != '') {
          self.addData.startTimeStr = (0, _formatter.datesFormatter)(res.startTime);
        }
        if (res.endTime != '') {
          self.addData.endTimeStr = (0, _formatter.datesFormatter)(res.endTime);
        }
        if (res.preferentialPackage != '') {
          self.addData.preferentialPackage = res.preferentialPackage.split(",");
        }
        console.log('self.addData.preferentialPackage:', self.addData.preferentialPackage);
      });
      self.newadd = true;
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      var start = new Date(this.addData.startTimeStr);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.addData.endTimeStr);
      var deliverTimeEnd = end.getTime();
      var now = new Date().getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.addData.endTimeStr = '';
        return false;
      } else if (deliverTimeStart < now) {
        this.$message({
          message: '请选择开始时间要大于当前系统时间',
          type: 'error' });

        this.addData.startTimeStr = '';
        return false;
      } else if (deliverTimeEnd < now) {
        this.$message({
          message: '请选择结束时间要大于当前系统时间',
          type: 'error' });

        this.addData.endTimeStr = '';
        return false;
      }
      if (self.addData.preferentialWay != '4') {
        self.addData.preferentialCycleDays = null;
      }
      if (self.addData.ctype == '2') {
        self.addData.cmode = '';
        self.addData.massageIntervalMinutes = '';
        self.addData.preferentialPackage = '';
        self.addData.preferentialDiscount = '';
        self.addData.preferentialAmount = '';
        self.addData.merIds = null;
      }
      if (self.addData.cmode == '0') {
        self.addData.preferentialAmount = '1';
      } else {
        self.addData.preferentialDiscount = '';
      }
      if (self.addData.preferentialPackage != '') {
        self.addData.preferentialPackage = self.addData.preferentialPackage.toString();
      } else if (self.addData.preferentialPackage.length == 0) {
        self.addData.preferentialPackage = null;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/actActivity/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    enableMenu: function enableMenu(item) {
      var self = this;
      self.postData('/api/actActivity/' + item.id + '/enable/1', {}, function (res) {
        self.$message({
          message: '已启用!',
          type: 'success' });

        self.init();
      });
    },
    disableMenu: function disableMenu(item) {
      var self = this;
      self.postData('/api/actActivity/' + item.id + '/enable/0', {}, function (res) {
        self.$message({
          message: '已禁用!',
          type: 'success' });

        self.init();
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/actActivity/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    partakeMenu: function partakeMenu(item) {
      var self = this;
      self.title = '参与渠道商';
      self.newadd2 = true;
      self.getData('/api/actActivity/' + item, {}, function (res) {
        console.log('res:', res);
        self.merIdsData = {
          id: res.id,
          merIds: res.merIds };

      });
    },
    onUpdateMer: function onUpdateMer() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm2.validate(function (valid) {
        if (valid) {
          self.postData('/api/actActivity/update/mer', self.merIdsData, function (res) {
            self.$message({
              message: '更新成功!',
              type: 'success' });

            self.onCencal2();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    // start
    checkedFun: function checkedFun(item, e) {
      var self = this;
      if (item == true && e == 'addData') {
        self.doObj(self.optionsList);
        self.addData.merIds = self.datalist;
      } else if (item == true && e == 'merIdsData') {
        self.doObj(self.optionsList);
        self.merIdsData.merIds = self.datalist;
      } else if (item == false && e == 'addData') {
        self.addData.merIds = [];
        self.datalist = [];
      } else if (item == false && e == 'merIdsData') {
        self.merIdsData.merIds = [];
        self.datalist = [];
      }
    },
    doObj: function doObj(datas) {
      var self = this;
      for (var i in datas) {
        if (datas[i].subMers && datas[i].subMers.length > 0) {
          self.doObj(datas[i].subMers);
        }
        self.datalist.push(datas[i].id);
      }
    }
    // end
  } };exports.default = _default;