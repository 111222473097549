"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false } },


  methods: {
    toggleClick: function toggleClick() {
      this.$emit('toggleClick');
    } } };exports.default = _default;