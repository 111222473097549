"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;














































































































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'activity', data: function data() {return { tableData: [], // 列表数据
      depotData: [], // 返现比例列表
      tableColumns: [{ prop: 'platCode', label: '运营商', isShow: true }, { prop: 'type', label: '活动方式', formatter: _formatter.typeFormatter, isShow: true }, { prop: 'status', label: '状态', formatter: _formatter.statusFormatter2, isShow: true // 是否显示
      }, { prop: 'startTime', label: '活动开始时间', formatter: _formatter.dateFormatter, isShow: true // 是否显示
      }, { prop: 'endTime', label: '活动结束时间', formatter: _formatter.dateFormatter, isShow: true // 是否显示
      }], searchData: { status: '', // 状态
        page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, platCodeList: [], statusList: [{ name: '上架', id: '0' }, { name: '下架', id: '1' }], typeList: [{ name: '按比例返现', id: '0' }, { name: '按金额返现', id: '1' }, { name: '官方充值模板', id: '2' }], newadd: false, title: '添加活动', title2: '充值设置提示', newadd2: false, titleName: '', addData: { rechargeActivity: { platCode: '', type: '0', startTime: null, endTime: null, status: '0' }, rechargeActivityRules: [{ rechargeAmt: '', ratio: '', offerAmt: '' }] }, defaultData: { rechargeActivity: { platCode: '', type: '0', startTime: null, endTime: null, status: '0' }, rechargeActivityRules: [{ rechargeAmt: '', ratio: '', offerAmt: '' }] }, rules: { title: [{ required: true, message: '请输入标题', trigger: 'blur' }], content: [{ required: true, message: '请输入消息内容', trigger: 'blur' }], receiveLevel: [{ required: true, message: '请选择接收的渠道商', trigger: 'change' }], isPublish: [{ required: true, message: '请选择是否发布', trigger: 'change' }] }, offerAmt: '', ratio: '', pickerOptions: { disabledDate: function disabledDate(time) {return time.getTime() + 2000 <= Date.now();} } };}, mounted: function mounted() {var self = this;self.init();self.getPlatCodeList();}, methods: { init: function init() {var self = this;self.getData('/api/recharge/activity/list', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, getPlatCodeList: function getPlatCodeList() {var self = this;self.getData('/api/payment/brand', {}, function (res) {self.platCodeList = res;});}, resetFun: function resetFun() {// 重置
      this.searchData.status = '';this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '添加活动';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置添加活动列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置添加活动列表
      this.$refs['addForm2'].resetFields();
      done();
    },
    onAddList: function onAddList() {
      var list = {
        offerAmt: this.offerAmt,
        ratio: this.ratio };

      // this.addData.rechargeActivityRules.unshift(list);
      this.addData.rechargeActivityRules.push(list);
    },
    delList: function delList(index, rows) {
      console.log('index:', index);
      console.log('rows:', rows);
      rows.splice(index, 1);
    },
    onCencal: function onCencal() {
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onCencal2: function onCencal2() {
      this.newadd2 = false;
    },
    typeFun: function typeFun(item) {
      var self = this;
      console.log(item);
      if (item == '0') {
        if (self.addData.rechargeActivityRules.length > 0) {
          for (var i in self.addData.rechargeActivityRules) {
            self.addData.rechargeActivityRules[i].offerAmt = '';
          }
        }
      } else if (item == '1') {
        if (self.addData.rechargeActivityRules.length > 0) {
          for (var _i in self.addData.rechargeActivityRules) {
            self.addData.rechargeActivityRules[_i].ratio = '';
          }
        }
      } else {
        if (self.addData.rechargeActivityRules.length > 0) {
          self.addData.rechargeActivity.startTime = '';
          self.addData.rechargeActivity.endTime = '';
          for (var _i2 in self.addData.rechargeActivityRules) {
            self.addData.rechargeActivityRules[_i2].offerAmt = '';
            self.addData.rechargeActivityRules[_i2].ratio = '';
          }
        }
      }
    },
    onSubmit: function onSubmit() {
      var self = this;
      var start = new Date(this.addData.rechargeActivity.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.addData.rechargeActivity.endTime);
      var deliverTimeEnd = end.getTime();
      var now = new Date().getTime();
      if (self.addData.rechargeActivity.type != '2') {
        if (deliverTimeStart > deliverTimeEnd) {
          this.$message({
            message: '请选择结束时间要大于开始时间',
            type: 'error' });

          this.addData.rechargeActivity.endTime = '';
          return false;
        } else if (deliverTimeStart < now) {
          this.$message({
            message: '请选择开始时间要大于当前系统时间',
            type: 'error' });

          this.addData.rechargeActivity.startTime = '';
          return false;
        } else if (deliverTimeEnd < now) {
          this.$message({
            message: '请选择结束时间要大于当前系统时间',
            type: 'error' });

          this.addData.rechargeActivity.endTime = '';
          return false;
        }
        if (self.addData.rechargeActivity.startTime == '') {
          self.$message({
            message: '请选择优惠开始时间',
            type: 'error' });

        }
        if (self.addData.rechargeActivity.endTime == '') {
          self.$message({
            message: '请选择优惠结束时间',
            type: 'error' });

        }
      }
      console.log('self.addData.rechargeActivityRules.length：', self.addData.rechargeActivityRules.length);
      if (self.addData.rechargeActivityRules.length > 0) {
        for (var i in self.addData.rechargeActivityRules) {
          if (self.addData.rechargeActivityRules[i].rechargeAmt <= 0) {
            self.addData.rechargeActivityRules[i].rechargeAmt = '';
            self.$message({
              message: '请重新输入充值金额，充值金额必须大于0！',
              type: 'error' });

          }
          if (self.addData.rechargeActivity.type == '0') {
            if (self.addData.rechargeActivityRules[i].ratio != '') {
              if (self.addData.rechargeActivityRules[i].ratio > 50) {
                self.titleName = '返现率';
                this.newadd2 = true;
                return;
              } else {
                self.yesFun();
                return;
              }
            } else {
              self.$message({
                message: '返现率不能为空，请填写返现率！',
                type: 'error' });

              return false;
            }
          }
          if (self.addData.rechargeActivity.type == '1') {
            if (self.addData.rechargeActivityRules[i].offerAmt != '') {
              if (self.addData.rechargeActivityRules[i].rechargeAmt / 2 < self.addData.rechargeActivityRules[i].offerAmt) {
                self.titleName = '返现金额';
                this.newadd2 = true;
                return;
              } else {
                self.yesFun();
                return;
              }
            } else {
              self.$message({
                message: '返现金额不能为空，请填写返现金额！',
                type: 'error' });

              return false;
            }
          }
          if (self.addData.rechargeActivityRules[i].rechargeAmt == '') {
            self.$message({
              message: '充值金额不能为空，请填写充值金额！',
              type: 'error' });

            return false;
          }
        }
        if (self.addData.rechargeActivity.type == '2') {
          self.yesFun();
        }
      }
    },
    yesFun: function yesFun() {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/recharge/activity', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal2();
            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          self.onCencal2();
          return false;
        }
      });
    },
    lookMenu: function lookMenu(item) {
      var self = this;
      self.title = '查看';
      self.getData('/api/recharge/activity', {
        id: item.id },
      function (res) {
        console.log('res:', res);
        self.addData.rechargeActivity = res.rechargeActivity;
        self.addData.rechargeActivity.platCode = res.rechargeActivity.platCode + '';
        self.addData.rechargeActivity.type = res.rechargeActivity.type + '';
        self.addData.rechargeActivityRules = res.rechargeActivityRules;
        self.newadd = true;
      });
    },
    delMenu: function delMenu(delId) {
      var self = this;
      self.$confirm('您确定要删除这条信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/recharge/activity', {
          id: delId },
        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    },
    revokeMenu: function revokeMenu(item) {
      var self = this;
      self.$confirm('您确定要撤销这条活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.putData('/api/recharge/activity/down', {
          id: item.id,
          status: '1' },
        function (res) {
          self.$message({
            message: '撤销成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).
      catch(function () {});
    } } };exports.default = _default;