"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.split");var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));var _defineProperty2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));




















































































































































































var _vuex = require("vuex");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'order', data: function data() {return { timeRange: '', //时间范围
      tableData: [], // 列表数据
      deviceData: [], // 弹窗列表数据
      optionsList: [], // 渠道商下拉数据
      tableColumns: [{ prop: 'id', label: '订单编号', isShow: true }, { prop: 'thirdOrderId', label: '第三方商户单号', isShow: true }, { prop: 'outTransId', label: '微信/支付宝订单编号', isShow: true }, { prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'snSlave', label: '设备2序列号', isShow: true }, { prop: 'deviceType', label: '产品型号', isShow: false }, { prop: 'merchantNameL1', label: '一级渠道商', isShow: false }, { prop: 'merchantNameL2', label: '二级渠道商', isShow: false }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'receiveAccount', label: '收款账号', isShow: true }, { prop: 'thirdNickname', label: '用户昵称', isShow: false // 是否显示
      }, { prop: 'pkgName', label: '商品', isShow: true }, { prop: 'pkgDuration', label: '时长', isShow: true }, { prop: 'runTime', label: '运行时长', isShow: false }, { prop: 'pkgAmt', label: '套餐金额(元)', isShow: true }, { prop: 'payAmt', label: '总支付金额(元)', isShow: true }, { prop: 'payChannel', label: '支付方式', formatter: _formatter.payChannelFormatter, isShow: true }, { prop: 'status', label: '订单状态', formatter: _formatter.orderStatusFormatter, isShow: true }, { prop: 'payTime', label: '下单时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'marketingName', label: '营销名称', isShow: true }, { prop: 'marketingPrice', label: '加单金额（元）', isShow: true }, { prop: 'marketingTime', label: '加单时长（分钟）', isShow: true }, { prop: 'refundTime', label: '退款时间', formatter: _formatter.dateFormatter, sortable: true, isShow: false // 是否显示
      }, { prop: 'refundDescription', label: '退款原因', isShow: false }, { prop: 'refundAmt', label: '退款金额', isShow: false }, { prop: 'couponType', label: '券包类型', formatter: _formatter.couponTypeFormatter, isShow: false }, { prop: 'couponName', label: '券包名', isShow: false }, { prop: 'couponNo', label: '券号', isShow: false }], ids: '', searchData: { // insId: '32',
        id: '', thirdOrderId: '', sn: '', merchCode: '', status: '', startTime: '', endTime: '', outTransId: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, addData: (0, _defineProperty2.default)({ id: '', platCode: '', mchnameL1: '', amt: '', thirdNickname: '', deviceType: '', sn: '', payTime: '', pkgEndTime: '', pkgErrTime: '', pkgAmt: '', pkgName: '',
        runTime: '',
        pkgDuration: '',
        status: '3',
        refundContact: '',
        refundPhone: '',
        reason: '',
        refundState: '',
        refundPasswd: '',
        optUser: '' }, "amt",
      ''),

      defaultData: (0, _defineProperty2.default)({
        id: '',
        platCode: '',
        mchnameL1: '',
        amt: '',
        thirdNickname: '',
        deviceType: '',
        sn: '',
        payTime: '',
        pkgEndTime: '',
        pkgErrTime: '',
        pkgAmt: '',
        pkgName: '',
        runTime: '',
        pkgDuration: '',
        status: '3',
        refundContact: '',
        refundPhone: '',
        reason: '',
        refundState: '',
        refundPasswd: '',
        optUser: '' }, "amt",
      ''),

      maxAmt: 0, //最大退款金额
      props: {
        children: 'subMers',
        label: 'name',
        value: 'code',
        checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: false },

      statusList: [
      { name: '未支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '支付取消', id: '2' },
      { name: '人工退款', id: '3' },
      { name: '一键退款', id: '4' },
      { name: '自动退款', id: '5' },
      { name: '所有已退款', id: '6' }],

      newadd: false,
      title: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        } } };


  },
  computed: (0, _objectSpread2.default)({},
  (0, _vuex.mapGetters)([
  'name'])),


  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDataList();
  },
  watch: {
    "searchData.startTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'startTime', '');
        }
      },
      deep: true },

    "searchData.endTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'endTime', '');
        }
      },
      deep: true } },


  methods: {
    init: function init() {
      var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('api/package/bill/list', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        // self.pageData.total = res.total;
        // for(var i in self.tableData) {
        // 	console.log(parseFloat(self.tableData[i].id))
        // }
      });
    },
    /**
        * 时间范围-change
        */
    onTimeRangeChange: function onTimeRangeChange(e) {
      this.$set(this.searchData, 'startTime', e[0]);
      this.$set(this.searchData, 'endTime', e[1]);
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.id = '';
      this.searchData.thirdOrderId = '';
      this.searchData.merchCode = '';
      this.searchData.sn = '';
      this.searchData.status = '';
      this.$set(this, 'timeRange', '');
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.searchData.outTransId = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      var start = new Date(this.searchData.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(this.searchData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        this.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'error' });

        this.searchData.endTime = '';
        return false;
      }
      this.init();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      var ids = [];
      for (var i in val) {
        ids.push(val[i].networkId);
      }
      self.ids = ids.toString();
      console.log("ids:", ids);
      console.log("self.ids:", self.ids);
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDataList: function getDataList() {
      var self = this;
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.merchCode = val || '';
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      done();
    },
    lookMenu: function lookMenu(item, title) {
      var self = this;
      console.log(item);
      console.log(title);
      self.getData('/api/package/bill/refund', {
        id: item.id },
      function (res) {
        // console.log('res',res.refundState)
        if (res != null) {
          self.addData = (0, _defineProperty2.default)({
            id: item.id,
            platCode: item.platCode,
            mchnameL1: item.mchnameL1,
            amt: item.payAmt,
            thirdNickname: item.thirdNickname,
            deviceType: item.deviceType,
            sn: item.sn,
            payTime: item.payTime,
            pkgEndTime: item.pkgEndTime,
            pkgErrTime: item.pkgErrTime,
            pkgAmt: item.pkgAmt,
            pkgName: item.pkgName,
            runTime: item.runTime,
            pkgDuration: item.pkgDuration,
            status: res.status,
            refundContact: res.refundContact,
            refundPhone: res.refundPhone,
            reason: res.reason,
            refundState: res.refundState,
            optUser: self.name,
            comboType: item.comboType,
            specialType: item.specialType }, "amt",
          item.pkgAmt);

        } else {
          self.addData.id = item.id,
          self.addData.platCode = item.platCode,
          self.addData.mchnameL1 = item.mchnameL1,
          self.addData.amt = item.payAmt,
          self.addData.thirdNickname = item.thirdNickname,
          self.addData.deviceType = item.deviceType,
          self.addData.sn = item.sn,
          self.addData.payTime = item.payTime,
          self.addData.pkgEndTime = item.pkgEndTime,
          self.addData.pkgErrTime = item.pkgErrTime,
          self.addData.pkgAmt = item.pkgAmt,
          self.addData.pkgName = item.pkgName,
          self.addData.runTime = item.runTime,
          self.addData.pkgDuration = item.pkgDuration,
          self.addData.optUser = self.name;
          self.addData.comboType = item.comboType;
          self.addData.specialType = item.specialType,
          self.addData.amt = item.pkgAmt;
        }
        self.maxAmt = item.pkgAmt;
        self.title = title;
        self.newadd = true;
      });
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      // console.log('addData====>',JSON.stringify(this.addData))
      if (this.addData.status == 4 && this.addData.comboType == 1 && this.addData.specialType == 1) {
        //双人套餐及开启一键退款
        if (!this.addData.amt) {
          this.$message({
            type: "error",
            message: "请输入退款金额" });

          return;
        }
        if (this.addData.amt > this.maxAmt) {
          this.$message({
            type: "error",
            message: "退款金额不可超过套餐金额" });

          return;
        }
      }
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        // if(self.addData.preferentialWay != '4') {
        // 	self.addData.preferentialCycleDays = ''
        // }
        // if(self.addData.ctype == '2') {
        // 	self.addData.cmode = ''
        // 	self.addData.massageIntervalMinutes = ''
        // 	self.addData.preferentialPackage = ''
        // 	self.addData.preferentialDiscount = ''
        // 	self.addData.preferentialAmount = ''
        // 	self.addData.merIds = ''
        // }
        // if(self.addData.cmode == '0') {
        // 	self.addData.preferentialAmount = '1'
        // }else{
        // 	self.addData.preferentialDiscount = ''
        // }
        // if(self.addData.preferentialPackage != '') {
        // 	self.addData.preferentialPackage = self.addData.preferentialPackage.toString()
        // }
        if (valid) {
          self.postData('/api/package/bill/refund', self.addData, function (res) {
            self.$message({
              message: '退款成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
    },
    // 导出查询
    excelFun: function excelFun() {
      var self = this;
      self.getData('/api/package/bill/exportLock', {}, function (res) {
        console.log('res', res);
        if (res == null) {
          self.daochuFun();
        }
      });
    },
    // 导出
    daochuFun: function daochuFun() {
      var self = this;
      window.location.href =
      '/api/package/bill/export?id=' +
      self.searchData.id +
      '&sn=' +
      self.searchData.sn +
      '&thirdOrderId=' +
      self.searchData.thirdOrderId +
      '&merchCode=' +
      self.searchData.merchCode +
      '&status=' +
      self.searchData.status +
      '&startTime=' +
      self.searchData.startTime +
      '&endTime=' +
      self.searchData.endTime +
      '&outTransId=' +
      self.searchData.outTransId;
    },
    switchFun: function switchFun(item) {
      var self = this;
      self.addData.status = item;
    } } };exports.default = _default;