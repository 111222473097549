"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

















var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'Layout', components: { AppMain: _components.AppMain, Navbar: _components.Navbar, RightPanel: _RightPanel.default, Settings: _components.Settings, Sidebar: _components.Sidebar, TagsView: _components.TagsView }, mixins: [_ResizeHandler.default], computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({ sidebar: function sidebar(state) {return state.app.sidebar;}, device: function device(state) {return state.app.device;}, showSettings: function showSettings(state) {return state.settings.showSettings;},
    needTagsView: function needTagsView(state) {return state.settings.tagsView;},
    fixedHeader: function fixedHeader(state) {return state.settings.fixedHeader;} }), {

    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile' };

    } }),

  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    } } };exports.default = _default;