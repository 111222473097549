var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入问题编号", clearable: "" },
            model: {
              value: _vm.searchForm.id,
              callback: function($$v) {
                _vm.$set(_vm.searchForm, "id", $$v)
              },
              expression: "searchForm.id"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { type: "text", placeholder: "请输入订单号", clearable: "" },
            model: {
              value: _vm.searchForm.orderId,
              callback: function($$v) {
                _vm.$set(_vm.searchForm, "orderId", $$v)
              },
              expression: "searchForm.orderId"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "请选择一级渠道商",
                filterable: "",
                clearable: ""
              },
              model: {
                value: _vm.searchForm.mchCodeL1,
                callback: function($$v) {
                  _vm.$set(_vm.searchForm, "mchCodeL1", $$v)
                },
                expression: "searchForm.mchCodeL1"
              }
            },
            _vm._l(_vm.oneChannelList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.code }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "请选择状态", clearable: "" },
              model: {
                value: _vm.searchForm.status,
                callback: function($$v) {
                  _vm.$set(_vm.searchForm, "status", $$v)
                },
                expression: "searchForm.status"
              }
            },
            _vm._l(_vm.statusList, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("反馈时间：")]),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              clearable: "",
              "picker-options": _vm.pickerOptions,
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "range-separator": "至",
              type: "daterange",
              "value-format": "yyyy-MM-dd"
            },
            model: {
              value: _vm.timeRange,
              callback: function($$v) {
                _vm.timeRange = $$v
              },
              expression: "timeRange"
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "success", icon: "el-icon-printer" },
              on: { click: _vm.excelFun }
            },
            [_vm._v("导出Excel")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.props == "merPicRefList"
                ? _c("el-table-column", {
                    attrs: { label: "图片", width: "360", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.merPicRefList
                                ? [
                                    _vm._l(scope.row.merPicRefList, function(
                                      item,
                                      index
                                    ) {
                                      return [
                                        _c("el-image", {
                                          key: index,
                                          staticClass: "reply-img",
                                          attrs: {
                                            src: item.target,
                                            "preview-src-list": _vm.previewImgList(
                                              scope.row
                                            )
                                          }
                                        })
                                      ]
                                    })
                                  ]
                                : _c("span", [_vm._v("无")])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              column.props == "demand"
                ? _c("el-table-column", {
                    attrs: { label: "问题描述", width: "200", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", { staticClass: "line-2" }, [
                                _vm._v(_vm._s(scope.row.demand))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              column.props == "reply"
                ? _c("el-table-column", {
                    attrs: { label: "客服回复", width: "200", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", { staticClass: "line-2" }, [
                                _vm._v(_vm._s(scope.row.reply))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              column.isShow &&
              column.props != "merPicRefList" &&
              column.props != "reply" &&
              column.props != "demand"
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.props,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              title: "回复",
                              size: "mini"
                            },
                            on: {
                              click: function() {
                                ;(_vm.currentRow = scope.row),
                                  (_vm.showReply = true)
                              }
                            }
                          },
                          [_vm._v("回复")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", title: "详情", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.checkDetail(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "600px",
            title: "回复处理",
            center: "",
            visible: _vm.showReply,
            "show-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showReply = $event
            },
            close: _vm.closeReply
          }
        },
        [
          _c(
            "el-form",
            { ref: "reply", attrs: { model: _vm.reply, rules: _vm.replyRule } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客服回复", required: "", prop: "txt" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      maxlength: "256",
                      placeholder: "请填写回复信息"
                    },
                    model: {
                      value: _vm.reply.txt,
                      callback: function($$v) {
                        _vm.$set(_vm.reply, "txt", $$v)
                      },
                      expression: "reply.txt"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.showReply = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onClickSubmitReply }
                    },
                    [_vm._v("回复")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "800px",
            title: "问题详情",
            center: "",
            visible: _vm.showDetail,
            "before-close": _vm.closeDetail,
            "show-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showDetail = $event
            }
          }
        },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                column: 1,
                labelClassName: "desc-label",
                contentClassName: "desc-content"
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "问题编号" } }, [
                _vm._v(_vm._s(_vm.currentRow.id))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "反馈时间" } }, [
                _vm._v(_vm._s(_vm.feedbackTime))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "支付订单编号" } }, [
                _vm._v(_vm._s(_vm.currentRow.orderId))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "一级渠道商" } }, [
                _vm._v(_vm._s(_vm.currentRow.mchnameL1 || "无"))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "二级渠道商" } }, [
                _vm._v(_vm._s(_vm.currentRow.mchnameL2 || "无"))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "设备位置" } }, [
                _vm._v(_vm._s(_vm.deviceLocation))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "订单状态" } }, [
                _vm._v(_vm._s(_vm.orderStatus))
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions-item",
                { attrs: { label: "反馈图片" } },
                [
                  _vm.currentRow.merPicRefList &&
                  _vm.currentRow.merPicRefList.length
                    ? [
                        _vm._l(_vm.currentRow.merPicRefList, function(
                          item,
                          index
                        ) {
                          return [
                            _c("el-image", {
                              key: index,
                              staticClass: "reply-img",
                              attrs: {
                                src: item.target,
                                "preview-src-list": _vm.previewImgList(
                                  _vm.currentRow
                                )
                              }
                            })
                          ]
                        })
                      ]
                    : _c("span", [_vm._v("无")])
                ],
                2
              ),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "问题描述" } }, [
                _vm._v(_vm._s(_vm.currentRow.demand))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "状态" } }, [
                _vm._v(_vm._s(_vm.currentRow.status == 1 ? "已回复" : "未回复"))
              ]),
              _vm._v(" "),
              _c("el-descriptions-item", { attrs: { label: "客服回复" } }, [
                _vm._v(_vm._s(_vm.currentRow.reply || "无"))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showDetail = false
                    }
                  }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }