"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/web.dom.iterable");require("core-js/modules/es6.set");require("core-js/modules/es6.string.iterator");require("core-js/modules/es6.array.from");require("core-js/modules/es6.string.link");


















































































































































var _aliOss = _interopRequireDefault(require("@/utils/aliOss"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'notice', data: function data() {return { tableData: [], // 列表数据
      searchData: { title: '', enable: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', srcImgList: [], //图片列表
      checked: false, datalist: [], optionsList: [], props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: false, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: true }, addData: { id: '', title: '', content: '', enable: '', startTime: '', endTime: '', link: '', imageUrl: '', merCodeList: [] }, defaultData: { id: '', title: '', content: '', enable: '', startTime: '', endTime: '', link: '', imageUrl: '', merCodeList: [] }, rules: { title: [{ required: true, message: '请输入活动公告标题', trigger: 'blur' }], content: [{ required: true, message: '请输入公告内容备注', trigger: 'blur' }], enable: [{ required: true, message: '请选择是否启用', trigger: 'change' }], startTime: [{ required: true, message: '请选择公告时间', trigger: 'change' }], imageUrl: [{ required: true, message: '请上传活动图片', trigger: 'change' }], merCodeList: [{ required: true, message: '请选择渠道商', trigger: 'change' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'title', label: '公告标题', isShow: true }, { prop: 'link', label: '链接', isShow: true }, { prop: 'content', label: '公告内容备注', isShow: true }, { prop: 'createTime', label: '时间', formatter: _formatter.dateFormatter, isShow: true }, { prop: 'enable', label: '是否启用', formatter: _formatter.isEnableFormatter, isShow: true }], enableList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }] };}, mounted: function mounted() {var self = this;self.init();self.getDeviceTypeList();}, methods: { init: function init() {var self = this;self.getData('/api/mar/notice/list', self.searchData, function (res) {self.tableData = res.list;self.srcImgList = [];for (var img in res.list) {self.srcImgList.push(res.list[img].imageUrl);console.log('self.srcImgList:', self.srcImgList);}self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.title = '';
      this.searchData.enable = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增
      this.title = '新增';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.endTime == '') {
        self.$message({
          message: '结束时间未选择，请选择后再提交',
          type: 'warning' });

        return false;
      }
      var start = new Date(self.addData.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(self.addData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        self.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'warning' });

        self.addData.endTime = '';
        return false;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/mar/notice', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.getData('/api/mar/notice', {
        id: item.id },
      function (res) {
        self.addData = {
          id: res.id,
          title: res.title,
          content: res.content,
          enable: res.enable,
          startTime: res.startTime,
          endTime: res.endTime,
          link: res.link,
          imageUrl: res.imageUrl,
          merCodeList: [] };

        for (var merCode in res.merList) {
          self.addData.merCodeList.push(res.merList[merCode].merCode);
          console.log('self.addData.merCodeList:', self.addData.merCodeList);
        }
      });
      self.title = '编辑';
      self.newadd = true;
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.endTime == '') {
        self.$message({
          message: '结束时间未选择，请选择后再提交',
          type: 'warning' });

        return false;
      }
      var start = new Date(self.addData.startTime);
      var deliverTimeStart = start.getTime();
      var end = new Date(self.addData.endTime);
      var deliverTimeEnd = end.getTime();
      if (deliverTimeStart > deliverTimeEnd) {
        self.$message({
          message: '请选择结束时间要大于开始时间',
          type: 'warning' });

        self.addData.endTime = '';
        return false;
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/mar/notice', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/mar/notice', {
          id: delId },
        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    enableMenu: function enableMenu(item) {
      var self = this;
      self.putData('/api/mar/notice/enable', {
        id: item.id,
        enable: '1' },
      function (res) {
        self.$message({
          message: '已启用!',
          type: 'success' });

        self.init();
      });
    },
    disableMenu: function disableMenu(item) {
      var self = this;
      self.putData('/api/mar/notice/enable', {
        id: item.id,
        enable: '0' },
      function (res) {
        self.$message({
          message: '已禁用!',
          type: 'success' });

        self.init();
      });
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      var cnode = this.$refs.downLoadLvlRef.getCheckedNodes();
      var merList = [];
      for (var i in cnode) {
        merList.push(cnode[i].value);
      }
      // 去重
      this.addData.merCodeList = Array.from(new Set(merList));
      // this.addData.merCodeList = val
    },
    checkedFun: function checkedFun(item) {
      var self = this;
      if (item == true) {
        self.doObj(self.optionsList);
        self.addData.merCodeList = self.datalist;
      } else if (item == false) {
        self.addData.merCodeList = [];
        self.datalist = [];
      }
    },
    doObj: function doObj(datas) {
      var self = this;
      for (var i in datas) {
        if (datas[i].subMers && datas[i].subMers.length > 0) {
          self.doObj(datas[i].subMers);
        }
        self.datalist.push(datas[i].code);
      }
    },
    // 图片上传
    handleChangePic: function handleChangePic(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    fnUploadRequest: function fnUploadRequest(option) {
      var path = 'mxsg/notice';
      _aliOss.default.ossUploadFile(option, path);
    },
    beforeRemove: function beforeRemove(file, fileList) {
      var fileName = file.name;
      var confirm = '确定移除' + fileName + '？';
      return this.$confirm(confirm);
    },
    handleOnRemove: function handleOnRemove(file, fileList) {
      this.addData.imageUrl = '';
      this.$message('移除' + file.name + '成功');
    },
    onsuccess: function onsuccess(res) {
      var fileUrl = 'https://share-chair.oss-cn-hangzhou.aliyuncs.com/' + res.name;
      this.addData.imageUrl = fileUrl;
    } } };exports.default = _default;