"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));require("core-js/modules/es6.regexp.to-string");require("core-js/modules/es6.regexp.split");
















































































































































































































var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'shipment', data: function data() {return { tableData: [], // 列表数据
      qrData: [], // 二维码详情列表数据
      depotData: [], outstorageData: [], optionsList: [], nos: [], imgName: '', timeRange: '', //时间范围
      searchData: { sn: '', merchantCode: '', outstorageNo: '', beginTime: '', endTime: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, qrSData: { typeCode: '', sn: '', selects: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, qsPageData: { page: 1, rows: 10, total: 0, pages: 1 }, props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, title: '', title2: '', addData: { id: '', outstorageNo: '', merchantCode: '', remark: '', sns: [], importFile: '' }, defaultData: { id: '', outstorageNo: '', merchantCode: '', remark: '', sns: [], importFile: '' }, rules: { merchantCode: [{ required: true, message: '请选择渠道商', trigger: 'change' }], sns: [{ required: true, message: '请选择序列号', trigger: 'change' }] }, newadd: false, newadd2: false, newadd3: false, // 表格列
      tableColumns: [{ prop: 'creator', label: '制单人', isShow: true }, { prop: 'createTime', label: '出库时间', formatter: _formatter.dateFormatter, isShow: true }], modelVersionList: [{ name: 'WiFi', id: 'A' }, { name: '4G', id: 'B' }, { name: '2G', id: 'C' }], factoryList: [{ name: '同安蒙发利', value: '同安蒙发利' }, { name: '集美蒙发利', value: '集美蒙发利' }, { name: '漳州蒙发利', value: '漳州蒙发利' }, { name: '怡和电子', value: '怡和电子' }, { name: '深圳蒙发利', value: '深圳蒙发利' }] };}, mounted: function mounted() {var self = this;self.init();self.getDeviceTypeList();}, watch: { "searchData.beginTime": { handler: function handler(val) {if (!val) {this.$set(this.searchData, 'beginTime', '');}}, deep: true }, "searchData.endTime": { handler: function handler(val) {if (!val) {this.$set(this.searchData, 'endTime', '');}}, deep: true } }, methods: { init: function init() {var self = this;self.getData('/api/factory/outstorage', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, resetFun: function resetFun() {// 重置
      this.searchData.merchantCode = '';this.searchData.sn = '';this.searchData.outstorageNo = '';this.searchData.beginTime = '';this.searchData.endTime = '';this.timeRange = '';this.init();}, searchFun: function searchFun() {// 搜索事件
      if (this.timeRange) {this.$set(this.searchData, 'beginTime', this.timeRange[0]);this.$set(this.searchData, 'endTime', this.timeRange[1]);} else {this.$set(this.searchData, 'beginTime', '');this.$set(this.searchData, 'endTime', '');}var start = new Date(this.searchData.beginTime);var deliverTimeStart = start.getTime();var end = new Date(this.searchData.endTime);var deliverTimeEnd = end.getTime();if (deliverTimeStart > deliverTimeEnd) {this.$message({ message: '请选择结束时间要大于开始时间', type: 'error' });this.searchData.endTime = '';return false;}if (this.searchData.endTime) {var endTime = this.searchData.endTime;if (endTime.split(' ').length < 2) {endTime = endTime + ' 23:59:59';this.searchData.endTime = endTime;}}this.searchData.page = 1;this.init();}, handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;this.searchData.page = 1;this.init();}, handleCurrentChange: function handleCurrentChange(val) {// 分页页码
      this.searchData.page = val;this.init();}, getDeviceTypeList: function getDeviceTypeList() {var self = this;self.getData('/api/merMerchant/tree', {}, function (res) {console.log('res:', res);self.optionsList = res;});}, changeMechanism: function changeMechanism(val) {if (val) {this.searchData.merchantCode = val.toString();} else {this.searchData.merchantCode = '';}}, changeMechanism2: function changeMechanism2(val) {if (val) {this.addData.merchantCode = val.toString();} else {this.addData.merchantCode = '';}}, onChoice: function onChoice() {var self = this;if (self.depotData.length > 0) {var selects = [];for (var i in self.depotData) {selects.push(self.depotData[i].sn);}
        self.qrSData.selects = selects.toString();
      }
      self.getData('/api/factory/qr/droplist', self.qrSData, function (res) {
        console.log('res:', res);
        self.outstorageData = res.list;
        self.qsPageData.page = res.pageNum;
        self.qsPageData.total = res.total;
        self.title2 = '新增出货';
        self.newadd3 = true;
      });
    },
    resetFun2: function resetFun2() {
      // 重置
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      this.onChoice();
    },
    searchFun2: function searchFun2() {
      // 搜索事件
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.qrSData.rows = val;
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.qrSData.page = val;
      this.onChoice();
    },
    delDepotMenu: function delDepotMenu(index, rows) {
      console.log('index:', index);
      console.log('rows:', rows);
      rows.splice(index, 1);
      console.log(this.depotData);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.choiceStr = val;
    },
    onOutstorage: function onOutstorage() {
      var self = this;
      if (self.depotData.length > 0) {var _self$depotData;
        (_self$depotData = self.depotData).push.apply(_self$depotData, (0, _toConsumableArray2.default)(self.choiceStr));
      } else {
        self.depotData = self.choiceStr;
      }
      self.newadd3 = false;
      console.log(self.depotData);
    },
    onCencal2: function onCencal2() {
      this.choiceStr = [];
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      this.newadd3 = false;
    },
    countFun: function countFun(item) {
      var self = this;
      self.title = '单号详情';
      self.getData('/api/factory/outstorage/detail/' + item.no, {}, function (res) {
        self.qrData = res.detailList;
        self.qsPageData.page = res.pageNum;
        self.qsPageData.total = res.total;
      });
      self.newadd2 = true;
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '新增';
      this.depotData = [];
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      done();
    },
    closeRefund3: function closeRefund3(done) {
      // 重置新增列表
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      console.log('self.depotData:', self.depotData);
      if (self.depotData.length > 0) {
        self.addData.sns = [];
        for (var i in self.depotData) {
          self.addData.sns.push(self.depotData[i].sn);
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/factory/outstorage/add', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      // 编辑弹窗
      var self = this;
      self.title = '编辑';
      self.newadd = true;
      self.getData('/api/factory/outstorage/detail/' + item.no, {}, function (res) {
        console.log('res:', res);
        self.addData = {
          id: res.id,
          outstorageNo: res.no,
          merchantCode: res.merchantCode,
          remark: res.remark };

        self.depotData = res.detailList;
        self.addData.sns = [];
        for (var i in res.detailList) {
          self.addData.sns.push(res.detailList[i].sn);
        }
      });
    },
    onUpdate: function onUpdate() {
      // 编辑弹窗提交
      var self = this;
      if (self.depotData.length > 0) {
        self.addData.sns = [];
        for (var i in self.depotData) {
          self.addData.sns.push(self.depotData[i].sn);
        }
      } else {
        self.addData.sns = [];
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/factory/outstorage', self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delNo) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/factory/outstorage/' + delNo, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    download: function download() {
      window.location.href = 'http://manual.cozzia.com.cn/download/出货导入模板.xlsx';
    },
    importFun: function importFun() {
      var self = this;
      self.title = '导入';
      self.newadd = true;
      document.getElementById("daoru").value = '';
    },
    getFile: function getFile(event) {
      this.addData.importFile = event.target.files[0];
      console.log('importFile', this.addData.importFile);
    },
    onImport: function onImport(event) {
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          var File = new FormData();
          File.append('importFile', self.addData.importFile);
          self.postData('/api/factory/outstorage/import', File, function (res) {
            console.log('res:', res);
            // self.closeRefund();
            self.searchData.page = 1;
            self.init();
            self.onCencal();
          });
        } else {
          return false;
        }
      });
    },
    excelFun: function excelFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'beginTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'beginTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      var url = '/api/factory/outstorage/export?sn=' + self.searchData.sn + '&merchantCode=' + self.searchData.merchantCode + '&outstorageNo=' + self.searchData.outstorageNo + '&beginTime=' + self.searchData.beginTime + '&endTime=' + self.searchData.endTime + '&nos=' + self.nos;
      console.log('url:', url);
      window.location.href = url;
    },
    lookView: function lookView(item) {// 查看弹窗
      this.title = '查看';
      $('#dialog2').show();
      this.addData.code = item.code;
      this.qrcode(item);
    },
    onCencal3: function onCencal3() {
      $('#dialog2').hide();
      this.imgName = '';
      // window.location.reload()
    },
    qrcode: function qrcode(item) {
      console.log('item:', item.sn);
      this.imgName = item.sn;
      document.getElementById('qrcode').innerHTML = '';
      new _qrcodejs.default('qrcode', {
        width: 200,
        height: 200, // 高度
        text: item.qrCodeUrl, // 二维码内容
        render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        //			        background: 'red',
        //			        foreground: 'green',
      });
      //		      	downloadButton: true
    },
    handleSelectionChange2: function handleSelectionChange2(val) {
      console.log(val);
      var self = this;
      self.nos = [];
      val.map(function (item) {
        self.nos.push(item.no);
      });
    },
    downloadQrcode: function downloadQrcode() {
      var qrcode = document.getElementById('qrcode');
      var img = qrcode.getElementsByTagName('img')[0];
      var link = document.createElement('a');
      var url = img.getAttribute('src');
      link.setAttribute('href', url);
      link.setAttribute('download', this.imgName + '.png');
      link.click();
      this.onCencal2();
    } } };exports.default = _default;