"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _objectSpread2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));




















var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { components: { SidebarItem: _SidebarItem.default, Logo: _Logo.default }, computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['routers', 'sidebar']), { // 这里不注释的话就要设置this.$router.options.routes这个值，所以在src/store/modules/user.js 中设置了这个值才能看到菜单
    // routers() {
    //   return this.$router.options.routes
    // },
    activeMenu: function activeMenu() {var route = this.$route;var meta = route.meta,path = route.path; // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {return meta.activeMenu;}return path;}, showLogo: function showLogo() {return this.$store.state.settings.sidebarLogo;}, variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    } }) };exports.default = _default;