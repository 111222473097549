var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "el-button",
          {
            staticClass: "filter-item",
            attrs: { type: "success", icon: "el-icon-back" },
            on: { click: _vm.returnFun }
          },
          [_vm._v("返回")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "filter-item",
            attrs: { type: "primary", icon: "el-icon-plus" },
            on: { click: _vm.addReadRow }
          },
          [_vm._v("添加位置")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "filter-item",
            attrs: { type: "primary", icon: "el-icon-check" },
            on: { click: _vm.onSubmit }
          },
          [_vm._v("保存")]
        ),
        _vm._v(" "),
        _c(
          "el-main",
          [
            _c(
              "el-form",
              { ref: "addForm", attrs: { model: _vm.addData } },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "95%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      stripe: ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        type: "index",
                        width: "60",
                        align: "center"
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "第几排", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  onkeyup: "value=value.replace(/[^\\d]/g,'')",
                                  maxlength: "3"
                                },
                                model: {
                                  value: scope.row.rowNum,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "rowNum", $$v)
                                  },
                                  expression: "scope.row.rowNum"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "列数" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  onkeyup: "value=value.replace(/[^\\d]/g,'')",
                                  maxlength: "3"
                                },
                                model: {
                                  value: scope.row.columnNum,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "columnNum", $$v)
                                  },
                                  expression: "scope.row.columnNum"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delMenu(
                                        scope.$index,
                                        _vm.tableData
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }