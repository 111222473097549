"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var state = {
  channelList: [] };


var mutations = {
  setChannelList: function setChannelList(state, data) {
    state.channelList = data;
  } };


var actions = {
  setChannelList: function setChannelList(_ref, data) {var commit = _ref.commit;
    commit('setChannelList', data);
  } };var _default =


{
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions };exports.default = _default;