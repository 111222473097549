"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true } },


  data: function data() {
    return {
      title: '摩享靠垫',
      logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png' };

  } };exports.default = _default;