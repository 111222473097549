"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.regexp.split");
















































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'ledgerDetails', data: function data() {return { timeRange: '', //时间范围
      tableData: [], // 列表数据
      optionsList: [], // 渠道商下拉数据
      tableColumns: [{ prop: 'mchNameL1', label: '一级渠道商', isShow: true }, { prop: 'mchNameL2', label: '二级渠道商', isShow: true }, { prop: 'networkName', label: '网点名称', isShow: true }, { prop: 'outTransId', label: '微信/支付宝订单编号', isShow: true }, { prop: 'cooperation', label: '合作方式', formatter: _formatter.cooperationFormatter, isShow: true }, { prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'orderId', label: '订单号', isShow: true }, { prop: 'orderStatus', label: '订单状态', formatter: _formatter.orderStatusFormatter2, isShow: true }, { prop: 'createTime', label: '订单时间', formatter: _formatter.dateFormatter, sortable: true, isShow: true // 是否显示
      }, { prop: 'payTime', label: '支付时间', formatter: _formatter.dateFormatter, sortable: true, isShow: false // 是否显示
      }, { prop: 'payAmt', label: '订单金额', isShow: true }, { prop: 'divRatio', label: '服务费抽成', isShow: true // 是否显示
      }, // {
      // 	prop: 'networkAddress',
      // 	label: '租金抽成',
      // 	isShow: true
      // },
      // {
      // 	prop: 'incomeTotal',
      // 	label: '月累计实付租金',
      // 	isShow: true
      // },
      // {
      // 	prop: 'incomeTotal',
      // 	label: '月应付租金',
      // 	isShow: true
      // },
      { prop: 'divAmt', label: '实际入账金额', isShow: true }, { prop: 'recieveAccount', label: '收入账号', isShow: true }, { prop: 'accType',
        label: '账号类型',
        formatter: _formatter.accTypeFormatter,
        isShow: true },

      {
        prop: 'divStatus',
        label: '状态',
        formatter: _formatter.divStatusFormatter,
        isShow: true },

      {
        prop: 'description',
        label: '描述',
        isShow: true }],


      ids: '',
      searchData: {
        merchName: '',
        networkName: '',
        cooperation: '',
        orderStatus: '',
        orderId: '',
        divStatus: '',
        recieveAccount: '',
        startTime: '',
        endTime: '',
        page: 1, // 当前页码
        rows: 10 // 页码条数
      },
      pageData: {
        page: 1,
        rows: 10,
        total: 0,
        pages: 1 },

      props: {
        children: 'subMers',
        label: 'name',
        value: 'name',
        checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: false },

      title: '',
      networkCode: '',
      sns: '',
      cooperationList: [
      { name: '买断', id: '0' },
      { name: '联营', id: '1' },
      { name: '直营', id: '2' },
      { name: '其它', id: '3' },
      { name: '代理', id: 'D' },
      { name: '自营', id: 'Z' }],

      orderStatusList: [
      { name: '未支付', id: '0' },
      { name: '已支付', id: '1' },
      { name: '支付取消', id: '2' },
      { name: '人工退款', id: '3' },
      { name: '一键退款', id: '4' },
      { name: '自动退款', id: '5' },
      { name: '所有已退款', id: '6' }],

      divStatusList: [{ name: '成功', id: 'Y' }, { name: '失败', id: 'N' }, { name: '分账中', id: 'P' }] };

  },
  filters: {
    statusFilter: function statusFilter(val) {
      if (val == '0') {
        return '空闲';
      } else if (val == '1') {
        return '使用中';
      } else if (val == '2') {
        return '离线';
      } else if (val == '3') {
        return '暂停到复位';
      } else if (val == '4') {
        return '暂停';
      } else if (val == '5') {
        return '故障';
      } else if (val == '6') {
        return '升级中';
      } else if (val == '7') {
        return '时间到复位';
      } else {
        return val;
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDataList();
  },
  watch: {
    "searchData.startTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'startTime', '');
        }
      },
      deep: true },

    "searchData.endTime": {
      handler: function handler(val) {
        if (!val) {
          this.$set(this.searchData, 'endTime', '');
        }
      },
      deep: true } },


  methods: {
    init: function init() {
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      if (this.searchData.endTime) {
        var endTime = this.searchData.endTime;
        if (endTime.split(' ').length < 2) {
          endTime = endTime + ' 23:59:59';
          this.searchData.endTime = endTime;
        }
      }
      if (this.searchData.startTime) {
        var startTime = this.searchData.startTime;
        if (startTime.split(' ').length < 2) {
          startTime = startTime + ' 00:00:00';
          this.searchData.startTime = startTime;
        }
      }
      self.getData('/api/payment/divRecord/list', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.merchName = '';
      this.searchData.networkName = '';
      this.searchData.cooperation = '';
      this.searchData.orderStatus = '';
      this.searchData.orderId = '';
      this.searchData.divStatus = '';
      this.searchData.recieveAccount = '';
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.timeRange = '';
      this.searchData.outTransId = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    // handleSelectionChange(val) {
    // 	console.log(val);
    // 	let self = this;
    // 	var ids = []
    // 	for(var i in val) {
    // 		ids.push(val[i].networkId)
    // 	}
    // 	self.ids = ids.toString()
    // 	console.log("ids:",ids)
    // 	console.log("self.ids:",self.ids)
    // },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDataList: function getDataList() {
      var self = this;
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.merchName = val || '';
    },
    excelNetworkFun: function excelNetworkFun() {
      if (this.timeRange) {
        this.$set(this.searchData, 'startTime', this.timeRange[0]);
        this.$set(this.searchData, 'endTime', this.timeRange[1]);
      } else {
        this.$set(this.searchData, 'startTime', '');
        this.$set(this.searchData, 'endTime', '');
      }
      var self = this;
      window.location.href =
      '/api/payment/divRecord/export?merchName=' +
      self.searchData.merchName +
      '&networkName=' +
      self.searchData.networkName +
      '&cooperation=' +
      self.searchData.cooperation +
      '&orderStatus=' +
      self.searchData.orderStatus +
      '&orderId=' +
      self.searchData.orderId +
      '&divStatus=' +
      self.searchData.divStatus +
      '&recieveAccount=' +
      self.searchData.recieveAccount +
      '&startTime=' +
      self.searchData.startTime +
      '&endTime=' +
      self.searchData.endTime;
      //  + '&ids=' +
      // self.ids;
    } } };exports.default = _default;