"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/web.dom.iterable"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/** import { validUsername } from '@/utils/validate'*/var _default =

{
  name: 'Login',
  data: function data() {
    /** const validateUsername = (rule, value, callback) => {
                           if (!validUsername(value)) {
                             callback(new Error('请输入名称'))
                           } else {
                             callback()
                           }
                         }*/
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else {
        callback();
      }
    };
    return {
      title: '摩享靠垫',
      switchEnvText: {
        test: '测试环境',
        dev: '开发环境',
        internal: '正式环境',
        overseas: '海外正式版' },

      switchEnv: {
        name1: '测试环境',
        name2: '正式环境' },

      switchEnvUrl: {
        test: 'http://cushionadmintest.aojiahuashare.com', // 测试环境
        internal: 'https://cushionadmin.aojiahuashare.com' // 正式环境
      },
      loginForm: {
        username: '',
        password: '' },

      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }] },

      loading: false,
      passwordType: 'password',
      redirect: undefined };

  },
  mounted: function mounted() {var _this = this;
    var url = window.location.href;
    document.onkeydown = function (event) {
      var e = event || window.event; // || arguments.callee.caller.arguments[0]
      if (e && e.keyCode === 13 && _this.$route.path === '/login') {// enter 键
        _this.handleLogin();
      }
    };
    if (url.indexOf(this.switchEnvUrl.test) !== -1) {
      this.switchEnv.name1 = this.switchEnvText.test;
      this.switchEnv.name2 = this.switchEnvText.dev;
    }
    if (url.indexOf(this.switchEnvUrl.internal) !== -1) {
      this.switchEnv.name1 = this.switchEnvText.internal;
      this.switchEnv.name2 = this.switchEnvText.overseas;
    }
    console.log('switchEnv=====>', JSON.stringify(this.switchEnv));
    // if (url.indexOf('localhost') || url.indexOf('127.0.0.1')) {
    //   this.switchEnv.name1 = '本地环境'
    //   this.switchEnv.name2 = null
    // }
    this.$store.dispatch('app/setEnv', this.switchEnv.name1);
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true } },


  methods: {
    jump: function jump(e) {
      var text = e.target.innerHTML;
      if (text === this.switchEnvText.test) {
        window.location.href = 'http://' + this.switchEnvUrl.test;
      }
      if (text === this.switchEnvText.dev) {
        window.location.href = 'http://' + this.switchEnvUrl.dev;
      }
      if (text === this.switchEnvText.internal) {
        window.location.href = 'http://' + this.switchEnvUrl.internal;
      }
      if (text === this.switchEnvText.overseas) {
        window.location.href = 'http://' + this.switchEnvUrl.overseas;
      }
    },
    showPwd: function showPwd() {var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          // this.loginForm.username = encodeURIComponent(this.loginForm.username)
          _this3.$store.dispatch('user/login', _this3.loginForm).then(function (res) {
            _this3.$store.dispatch('user/getRouters').then(function (res) {
              // console.log('router', this.$store.getters.$router)
              // that.$router.addRoutes(that.$store.getters.routers)
              try {
                _this3.handleHasPermission(res, _this3.redirect);
                _this3.redirect = '/';
              } catch (err) {
                console.log('permission', err);
              }

              _this3.$router.push({ path: _this3.redirect || '/views/dashboard/index' });
              _this3.loading = false;
            }).catch(function (e) {
              console.log('login=>', e);
              _this3.loading = false;
            });
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleHasPermission: function handleHasPermission(menuList, path) {var _this4 = this;
      if (menuList && menuList.length > 0) {
        menuList.forEach(function (element) {
          if (element.child) {
            _this4.handleHasPermission(element.child, path);
          } else {
            if (element.url === path) {
              new Error('用户拥有URL权限');
            }
          }
        });
      }
    } } };exports.default = _default;