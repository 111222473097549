"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;




























































































var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'firmwareUpdate', data: function data() {return { tableData: [], // 列表数据
      typeCodeList: [], commModuleVersionList: [], commModuleFactoryList: [], tableColumns: [{ prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'productTypeCode', label: '设备型号', isShow: true }, { prop: 'commModuleFactoryName', label: '（通信）模块厂商', isShow: true }, { prop: 'commModuleVersionName', label: '模块型号', isShow: true }, { prop: 'commVersion', label: '通信模块版本', isShow: true }, { prop: 'centerVersion', label: '中心板版本', isShow: true // 是否显示
      }, // {
      // 	prop: 'musicVersion',
      // 	label: '语音板版本',
      // 	isShow: true // 是否显示
      // },
      { prop: 'status', label: '状态', formatter: _formatter.firmwareUpdateStatusFormatter, isShow: true }, { prop: 'updateTime', label: '上报时间', formatter: _formatter.dateFormatter, isShow: true }], searchData: { sn: '', deviceTypeCode: '', commModuleFactoryId: '', page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', newadd: false, ids: [], qrData: [] };}, filters: { statusFilter: function statusFilter(val) {if (val == '1') {return '升级中';} else if (val == '2') {return '升级失败';} else if (val == '3') {return '升级成功';}}, failReasonFilter: function failReasonFilter(val) {if (val == '-1') {return '远程调用升级接口失败';} else if (val == '2') {return '超时失败';} else if (val == '9') {return '设备离线';} else if (val == '10') {return '回应超时';} else if (val == '11') {return '设备可用';} else if (val == '12') {return '设备使用中';} else if (val == '13') {return '设备故障';
      } else if (val == '14') {
        return '设备维修';
      } else if (val == '15') {
        return '设备不可用';
      } else if (val == '16') {
        return '设备正在升级';
      } else if (val == '17') {
        return '设备暂停中';
      } else if (val == '31') {
        return '未找到该设备的信息';
      } else if (val == '33') {
        return '设备无版本号';
      } else if (val == '37') {
        return '指定的版本号与当前版本号一致，无需升级';
      } else if (val == '38') {
        return '指定的版本号与当前设备不匹配';
      } else if (val == '42') {
        return '目前设备不处于可进行升级文件传输的状态';
      } else if (val == '44') {
        return '文件下载地址有误，无法下载';
      } else if (val == '45') {
        return '版本名错误';
      } else if (val == '46') {
        return '下载地址格式错误';
      } else if (val == '47') {
        return '音乐文件版本号一致，无需传输';
      } else {
        return val;
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
    self.getcommModuleVersion();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/factoryFirmwareUpgrade', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    resetFun: function resetFun() {
      // 重置
      this.searchData.sn = '';
      this.searchData.deviceTypeCode = '';
      this.searchData.commModuleFactoryId = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        console.log('res11111:', res);
        self.typeCodeList = res;
      });
      // 通讯模块厂商
      self.getData('/api/commModuleFactory/list', {}, function (res) {
        self.commModuleFactoryList = res;
      });
    },
    getcommModuleVersion: function getcommModuleVersion(item) {
      var self = this;
      var productTypeCode = '';
      if (item != undefined) {
        productTypeCode = item;
      }
      self.getData('/api/factoryFirmware/list', {
        ctype: '1',
        productTypeCode: productTypeCode },
      function (res) {
        if (res != []) {
          self.commModuleVersionList = res;
        }
      });
    },
    updateItem: function updateItem(item) {
      var self = this;
      self.title = '重新升级';
      var ids = [];
      ids.push(item.id);
      self.postData('/api/factoryFirmwareUpgrade/retry', ids, function (res) {
        self.$message({
          message: '重新升级提交成功!',
          type: 'success' });

        self.init();
      });
    },
    addFun: function addFun() {
      // 添加活动
      var self = this;
      self.title = '批量升级';
      self.getData('/api/factoryFirmwareUpgrade/upgradeFailList', {}, function (res) {
        self.qrData = res;
      });
      self.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.ids = [];
      val.map(function (item) {
        self.ids.push(item.id);
      });
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      // debugger
      if (self.ids.length == 0) {
        self.$message({
          message: '请选择需批量升级的设备!',
          type: 'warning' });

        return false;
      }
      self.postData('/api/factoryFirmwareUpgrade/retry', self.ids, function (res) {
        self.$message({
          message: '批量升级提交成功!',
          type: 'success' });

        self.onCencal();
        self.searchData.page = 1;
        self.init();
      });
    } } };exports.default = _default;