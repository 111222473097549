"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.to-string");
































































































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter");
var _channelCheckbox = _interopRequireDefault(require("../../components/channel/channelCheckbox.vue")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
// import ChannelCheckbox from '@/src/components/channel/channelCheckbox.vue'
var _default = { name: 'network', components: { ChannelCheckbox: _channelCheckbox.default }, data: function data() {return { optionsList: [], oneMerchantList: [], twoMerchantList: [], outstorageData: [], dropHistory2: {}, dropHistory3: {}, props: { children: 'subMers', label: 'name', value: 'id', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, props2: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false }, tableData: [], // 列表数据
      deviceTypeList: [], searchData: { name: '', code: '', merIds: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', code: '', merCode: '', sceneCodeL1: '', sceneCodeL2: '', name: '', regionCode: '', provinceCode: '', cityCode: '', address: '' }, defaultData: { id: '', code: '', merCode: '', sceneCodeL1: '', sceneCodeL2: '', name: '', regionCode: '', provinceCode: '', cityCode: '', address: '' }, rules: {}, newadd: false, newadd2: false, newadd3: false, // 表格列
      tableColumns: [{ prop: 'name', label: '网点名称', isShow: true }, { prop: 'code', label: '网点编号', isShow: true }, { prop: 'insName', label: '运营组织', isShow: true }, { prop: 'merNameL1', label: '一级渠道商', isShow: true }, { prop: 'merNameL2', label: '二级渠道商', isShow: true }, { prop: 'sceneName', label: '场景', isShow: true }, { prop: 'address', label: '网点地址', isShow: true }, { prop: 'totalIncome', label: '总收益(元)', isShow: true }, { prop: 'currentMonthAvgDayIncome', label: '本月日台均(元)', isShow: true }, { prop: 'historyAvgDayIncome', label: '上月日台均(元)', isShow: true }, { prop: 'createTime', label: '添加时间', formatter: _formatter.dateFormatter, isShow: true }], regionData: [], Area: [], //省数据列表
      cityQuery: [], //市数据列表
      areaQuery: [], provinceCode: '', cityCode: '', bootTypeData: [{ name: '部分', id: '0' }, { name: '全部', id: '1' }], depotData: [], qrSData: { networkCode: '', sn: '', typeCode: '', checkedIds: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, qsPageData: { page: 1, rows: 10, total: 0, pages: 1 }, title2: '', choiceStr: '', sData: { time: '1', bootType: '', funType: '', sns: [], networkCode: '' } };}, filters: { statusFilter: function statusFilter(val) {if (val == '0') {return '空闲';} else if (val == '1') {return '使用中';} else if (val == '2') {return '离线';} else if (val == '3') {return '暂停到复位';} else if (val == '4') {return '暂停';} else if (val == '5') {return '故障';} else if (val == '6') {return '升级中';} else if (val == '7') {return '时间到复位';} else if (val == '-2') {return '未知';} else {return val;}} }, // created() {
  // 	this.Area = require('../../utils/area.json');
  // 	console.log('this.Area', this.Area);
  // },
  // watch: {
  // 	// 监听省级
  // 	provinceCode: function(newValue) {
  // 		(this.cityQuery = []), (this.areaQuery = []), (this.shi = ''), (this.qu = '');
  // 		this.Area.forEach(item => {
  // 			if (newValue.label === item.label) {
  // 				this.cityQuery = item.children;
  // 				console.log('city', this.cityQuery);
  // 			}
  // 		});
  // 	}
  // },
  mounted: function mounted() {var self = this;self.init();self.getDeviceTypeList();self.oneMerchantFun();self.getRegionData();}, methods: { init: function init() {var self = this;self.getData('/api/merNetwork', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});}, checkPermission: _user.checkPermission, resetFun: function resetFun() {// 重置
      this.searchData.name = '';this.searchData.code = '';this.searchData.merIds = '';this.init();}, searchFun: function searchFun() {// 搜索事件
      this.searchData.page = 1;this.init();}, handleSizeChange: function handleSizeChange(val) {// 分页条数
      this.searchData.rows = val;this.searchData.page = 1;this.init();}, handleCurrentChange: function handleCurrentChange(val) {// 分页页码
      this.searchData.page = val;this.init();}, getDeviceTypeList: function getDeviceTypeList() {var self = this;self.getData('/api/merMerchant/tree', {}, function (res) {console.log('res:', res);
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      if (val) {
        this.searchData.merIds = val.toString();
      } else {
        this.searchData.merIds = '';
      }
    },
    changeMechanism2: function changeMechanism2(val) {
      this.addData.merCode = val || '';
    },
    addFun: function addFun() {
      // 添加网点
      this.title = '添加网点';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    oneMerchantFun: function oneMerchantFun() {
      var self = this;
      self.getData('/api/merScene/primary', {}, function (res) {
        console.log('res:', res);
        self.oneMerchantList = res;
      });
    },
    twoMerchantFun: function twoMerchantFun(data) {
      var self = this;
      self.addData.sceneCodeL2 = '';
      if (data != '') {
        self.getData('/api/merScene/pcode/' + data, {}, function (res) {
          self.twoMerchantList = res;
        });
      }
    },
    getRegionData: function getRegionData() {
      var self = this;
      self.getData('/api/dictArea/pcode/0', {}, function (res) {
        console.log('res:', res);
        self.regionData = res;
      });
    },
    getProvince: function getProvince(data) {
      var self = this;
      self.addData.provinceCode = '';
      self.addData.cityCode = '';
      if (data != '') {
        self.getData('/api/dictArea/pcode/' + data, {}, function (res) {
          self.Area = res;
        });
      }
    },
    getCity: function getCity(data) {
      var self = this;
      self.addData.cityCode = '';
      if (data != '') {
        self.getData('/api/dictArea/pcode/' + data, {}, function (res) {
          self.cityQuery = res;
        });
      }
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      this.provinceCode = '';
      this.cityCode = '';
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.provinceCode = '';
      this.cityCode = '';
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merNetwork', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item) {
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.getData('/api/merNetwork/' + item.id, {}, function (res) {
        self.addData = {
          id: res.id,
          code: res.code,
          merCode: res.merCode + '',
          sceneCodeL1: res.sceneCodeL1,
          sceneCodeL2: res.sceneCodeL2,
          name: res.name,
          regionCode: res.regionCode + '',
          provinceCode: res.provinceCode,
          cityCode: res.cityCode,
          address: res.address };

        if (self.addData.sceneCodeL1 != '') {
          self.twoMerchantFun(self.addData.sceneCodeL1);
          self.addData.sceneCodeL2 = res.sceneCodeL2 + '';
        }
        if (self.addData.regionCode != '') {
          self.getProvince(self.addData.regionCode);
          self.addData.provinceCode = res.provinceCode + '';
        }
        if (self.addData.provinceCode != '') {
          self.getCity(self.addData.provinceCode);
          self.addData.cityCode = res.cityCode + '';
        }
      });
      self.newadd = true;
      // setTimeout(function() {
      // 	self.newadd = true;
      // },500)

    },
    onUpdate: function onUpdate() {// 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merNetwork/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {// 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).
      then(function () {
        self.delData('/api/merNetwork/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.searchData.page = 1;
          self.init();
        });
      }).catch(function () {});
    },
    excelFun: function excelFun() {
      var self = this;
      window.location.href =
      '/api/merNetwork/export?name=' +
      self.searchData.name +
      '&code=' +
      self.searchData.code +
      '&merIds=' +
      self.searchData.merIds;
    },
    remoteMenu: function remoteMenu(item) {
      this.qrSData.networkCode = item.code;
      this.sData.networkCode = item.code;
      this.title = '远程启动';
      this.newadd2 = true;
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      this.$refs['addForm2'].resetFields();
      done();
    },
    onCencal2: function onCencal2() {
      // 详情弹窗关闭
      this.newadd2 = false;
      this.$refs['addForm2'].resetFields();
      this.depotData = [];
    },
    onChoice: function onChoice() {
      var self = this;
      self.qrSData.checkedIds = [];
      if (self.depotData.length > 0) {
        for (var i in self.depotData) {
          self.qrSData.checkedIds.push(self.depotData[i].id);
        }
        console.log('self.qrSData.checkedIds:', self.qrSData.checkedIds);
        self.qrSData.checkedIds = self.qrSData.checkedIds.toString();
      }
      self.getData('/api/merNetwork/device/page', self.qrSData, function (res) {
        console.log('res:', res);
        self.outstorageData = res.list;
        self.qsPageData.page = res.pageNum;
        self.qsPageData.total = res.total;
        self.title2 = '选择设备';
        self.newadd3 = true;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.choiceStr = val;
    },
    onOutstorage: function onOutstorage() {
      var self = this;
      if (self.depotData.length > 0) {var _self$depotData;
        (_self$depotData = self.depotData).push.apply(_self$depotData, (0, _toConsumableArray2.default)(self.choiceStr));
      } else {
        self.depotData = self.choiceStr;
      }
      self.newadd3 = false;
      console.log(self.depotData);
    },
    closeRefund3: function closeRefund3(done) {
      // 重置新增列表
      this.choiceStr = [];
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      done();
    },
    onCencal3: function onCencal3() {
      this.choiceStr = [];
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      this.newadd3 = false;
    },
    resetFun2: function resetFun2() {
      // 重置
      this.qrSData.typeCode = '';
      this.qrSData.sn = '';
      this.onChoice();
    },
    searchFun2: function searchFun2() {
      // 搜索事件
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.qrSData.rows = val;
      this.qrSData.page = 1;
      this.onChoice();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.qrSData.page = val;
      this.onChoice();
    },
    delDepotMenu: function delDepotMenu(index, rows) {
      console.log('index:', index);
      console.log('rows:', rows);
      rows.splice(index, 1);
      console.log(this.depotData);
    },
    onStartUp: function onStartUp() {
      var self = this;
      if (self.depotData.length > 0) {
        self.sData.sns = [];
        for (var i in self.depotData) {
          self.sData.sns.push(self.depotData[i].sn);
        }
      }
      self.$refs.addForm2.validate(function (valid) {
        if (valid) {
          self.postData('/api/merNetwork/remoteBoot', self.sData, function (res) {
            self.$message({
              message: '操作成功!',
              type: 'success' });

            self.onCencal2();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    } } };exports.default = _default;