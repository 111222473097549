"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;














































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'firmwareNews', data: function data() {return { tableData: [], // 列表数据
      optionsList: [], // 渠道商下拉数据
      networkCodeList: [], // 网点下拉数据
      qrData: [], sns: [], typeCodeList: [], commModuleVersionList: [], searchData: { sn: '', deviceTypeCode: '', commModuleFactoryId: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, batchData: { deviceTypeCode: '', communicate: '', centralPlate: '', voice: '', networkCode: '', merCode: '', status: 0, page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, batchpageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { sn: '', ctype: '', version: '' }, defaultData: { sn: '', ctype: '', version: '' }, statusList: [{ name: '空闲', id: 0 }, { name: '使用中', id: 1 }, { name: '离线', id: 2 }, { name: '暂停到复位', id: 3 }, { name: '暂停', id: 4 }, { name: '故障', id: 5 }, { name: '升级中', id: 6 }, { name: '时间到复位', id: 7 }], rules: { version: [{ required: true, message: '请选择版本号', trigger: 'change' }] }, newadd: false, batch: false, // 表格列
      tableColumns: [{ prop: 'sn', label: '设备序列号', isShow: true }, { prop: 'deviceTypeCode', label: '设备型号', isShow: true }, { prop: 'commModuleFactoryName', label: '（通信）模块厂商', isShow: true }, { prop: 'commModuleVersionName', label: '模块型号', isShow: true }, { prop: 'status', label: '设备状态', formatter: _formatter.firmwareNewsStatusFormatter, isShow: true }, { prop: 'updateTime', label: '上报时间', formatter: _formatter.dateFormatter, isShow: true }], firmwareCommVersionList: [], firmwareCenterVersionList: [], firmwareMusicVersionList: [], communicateList: [], //通讯列表
      centralPlateList: [], //中心列表
      voiceList: [], //语音列表
      props: { children: 'subMers', label: 'name', value: 'code', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover', multiple: false } };}, filters: { statusFilter: function statusFilter(val) {if (val == '0') {return '空闲';} else if (val == '1') {return '使用中';} else if (val == '2') {return '离线';} else if (val == '3') {return '暂停到复位';} else if (val == '4') {return '暂停';} else if (val == '5') {return '故障';} else if (val == '6') {return '升级中';} else if (val == '7') {return '时间到复位';}} }, watch: { "batchData.deviceTypeCode": { handler: function handler(val) {this.getfirmwareInfo();}, deep: true }, "batchData.networkCode": { handler: function handler(val) {this.getfirmwareInfo();}, deep: true }, "batchData.merCode": { handler: function handler(val) {this.getfirmwareInfo();this.getNetworkList();}, deep: true }, "batchData.status": { handler: function handler(val) {this.getfirmwareInfo();}, deep: true } }, mounted: function mounted() {var self = this;self.init();self.getcommModuleVersion();self.getDeviceTypeList();}, methods: { init: function init() {var self = this;self.getData('/api/factoryFirmware/firmwareInfo', self.searchData, function (res) {self.tableData = res.list;self.pageData.page = res.pageNum;self.pageData.total = res.total;});},
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.sn = '';
      this.searchData.deviceTypeCode = '';
      this.searchData.commModuleFactoryId = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    resetBatchFun: function resetBatchFun() {
      // 重置
      this.batchData.deviceTypeCode = '';
      this.batchData.voice = '';
      this.batchData.communicate = '';
      this.batchData.centralPlate = '';
      this.batchData.status = '';
      this.batchData.merCode = '';
      this.batchData.networkCode = '';
      this.getfirmwareInfo();
    },
    searchBatchFun: function searchBatchFun() {
      // 搜索事件
      this.batchData.page = 1;
      this.getfirmwareInfo();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.batchData.rows = val;
      this.batchData.page = 1;
      this.getfirmwareInfo();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.batchData.page = val;
      this.getfirmwareInfo();
    },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/device/typeCode/droplist', {}, function (res) {
        self.typeCodeList = res;
      });
    },
    getcommModuleVersion: function getcommModuleVersion(item) {
      var self = this;
      self.getData('/api/commModuleFactory/list', {
        name: '' },
      function (res) {
        self.commModuleVersionList = res;
      });
    },
    deviceTypeCodeChange: function deviceTypeCodeChange(item) {
      var self = this;
      // 通信模块版本列表
      self.getData('/api/factoryFirmware/list', {
        ctype: '1',
        productTypeCode: item },
      function (res) {
        self.communicateList = res;
      });
      // 中心板版本列表
      self.getData('/api/factoryFirmware/list', {
        ctype: '2',
        productTypeCode: item },
      function (res) {
        self.centralPlateList = res;
      });
      // 语音板版本列表
      self.getData('/api/factoryFirmware/list', {
        ctype: '0',
        productTypeCode: item },
      function (res) {
        self.voiceList = res;
      });
    },
    commVersionFun: function commVersionFun(item) {
      var self = this;
      self.title = '通信模块版本';
      self.addData = JSON.parse(JSON.stringify(this.defaultData));
      self.addData.sn = item.sn;
      self.addData.ctype = '1';
      self.firmwareCommVersionList = [];
      self.getData('/api/factoryFirmware/list', {
        ctype: '1',
        productTypeCode: item.deviceTypeCode },
      function (res) {
        for (var i = 0; i <= res.length; i++) {
          if (item.deviceTypeCode == res[i].productTypeCode) {
            res[i] = {
              version: res[i].version,
              disabled: false };

            if (res[i].version == item.firmwareCommVersion) {
              res[i].disabled = true;
            } else {
              res[i].disabled = false;
            }
            self.firmwareCommVersionList.push(res[i]);
          }
        }
      });
      self.newadd = true;
    },
    firmwareCenterVersionFun: function firmwareCenterVersionFun(item) {
      var self = this;
      self.title = '中心板版本';
      self.addData = JSON.parse(JSON.stringify(this.defaultData));
      self.addData.sn = item.sn;
      self.addData.ctype = '2';
      self.firmwareCenterVersionList = [];
      self.getData('/api/factoryFirmware/list', {
        ctype: '2',
        productTypeCode: item.deviceTypeCode },
      function (res) {
        for (var i = 0; i <= res.length; i++) {
          res[i] = {
            version: res[i].version,
            disabled: false };

          if (res[i].version == item.firmwareCenterVersion) {
            res[i].disabled = true;
          } else {
            res[i].disabled = false;
          }
          self.firmwareCenterVersionList.push(res[i]);
        }
      });
      self.newadd = true;
    },
    firmwareMusicVersionFun: function firmwareMusicVersionFun(item) {
      var self = this;
      self.title = '语音板版本';
      self.addData = JSON.parse(JSON.stringify(this.defaultData));
      self.addData.sn = item.sn;
      self.addData.ctype = '0';
      self.firmwareMusicVersionList = [];
      self.getData('/api/factoryFirmware/list', {
        ctype: '0',
        productTypeCode: item.deviceTypeCode },
      function (res) {
        for (var i = 0; i < res.length; i++) {
          res[i] = {
            version: res[i].version,
            disabled: false };

          if (res[i].version == item.firmwareMusicVersion) {
            res[i].disabled = true;
          } else {
            res[i].disabled = false;
          }
          self.firmwareMusicVersionList.push(res[i]);
        }
      });
      self.newadd = true;
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onCencal2: function onCencal2() {
      // 详情弹窗关闭
      this.batch = false;
      this.resetBatchFun();
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      this.$refs['batchForm'].resetFields();
      done();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      var obj = [];
      obj.push(self.addData);
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/factoryFirmwareUpgrade', obj, function (res) {
            self.$message({
              message: '提交成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    batchFun: function batchFun() {
      // 添加活动
      var self = this;
      self.title = '批量升级';
      this.getfirmwareInfo();
      this.getNetworkList();
      // self.getData('/api/factoryFirmware/firmwareInfo', self.batchData, function(res) {
      // 	self.qrData = res.list
      // });
      self.batch = true;
    },
    getfirmwareInfo: function getfirmwareInfo() {
      var self = this;
      self.getData('/api/factoryFirmware/firmwareInfo', self.batchData, function (res) {
        self.qrData = res.list;
        self.batchpageData.page = res.pageNum;
        self.batchpageData.total = res.total;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.sns = [];
      val.map(function (item) {
        self.sns.push(item.sn);
      });
    },
    // //复选框禁用
    selectable: function selectable(row) {
      console.log('row111:', row);
      //row.status状态为   0离线  2使用中 5故障  6维修  7升级中 时不可勾选
      if (row.status == 1) {
        return false; //禁用
      } else if (row.status == 2) {
        return false; //禁用
      } else if (row.status == 3) {
        return false; //禁用
      } else if (row.status == 4) {
        return false; //禁用
      } else if (row.status == 5) {
        return false; //禁用
      } else if (row.status == 6) {
        return false; //禁用
      } else if (row.status == 7) {
        return false; //禁用
      } else {
        return true; //不禁用
      }
    },
    communicateBatch: function communicateBatch(val) {
      var self = this;
      if (val != '' && val != null) {
        self.batchData.centralPlate = '';
        self.batchData.voice = '';
      }
    },
    centralPlateBatch: function centralPlateBatch(val) {
      var self = this;
      if (val != '' && val != null) {
        self.batchData.communicate = '';
        self.batchData.voice = '';
      }
    },
    voiceBatch: function voiceBatch(val) {
      var self = this;
      if (val != '' && val != null) {
        self.batchData.communicate = '';
        self.batchData.centralPlate = '';
      }
    },
    getNetworkList: function getNetworkList() {
      var self = this;
      // 渠道商下拉
      self.getData('/api/merMerchant/tree', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      var self = this;
      self.batchData.merCode = val;
      if (self.batchData.merCode != '') {
        // 网点下拉
        self.getData('/api/merNetwork/droplist?merCode=' + self.batchData.merCode, {}, function (res) {
          self.networkCodeList = res;
        });
      } else {
        self.batchData.networkCode = '';
        self.networkCodeList = [];
      }
    },
    onSubmitBatch: function onSubmitBatch() {
      // 批量升级   0、语音   1、通信   2、中心版
      var self = this;
      var obj = [];
      var ctype = 0;
      var version = '';
      if (self.batchData.deviceTypeCode == '') {
        self.$message({
          message: '请先选择设备型号!',
          type: 'error' });

        return;
      }
      if (self.sns.length == 0) {
        self.$message({
          message: '请先勾选需升级的设备!',
          type: 'error' });

        return;
      }
      if (self.batchData.communicate != '' && self.batchData.communicate != null) {
        version = self.batchData.communicate;
        ctype = 1;
      } else if (self.batchData.centralPlate != '' && self.batchData.centralPlate != null) {
        version = self.batchData.centralPlate;
        ctype = 2;
      } else if (self.batchData.voice != '' && self.batchData.voice != null) {
        version = self.batchData.voice;
        ctype = 0;
      } else if (self.batchData.communicate != '' && self.batchData.communicate != null && self.batchData.centralPlate != '' && self.batchData.centralPlate != null && self.batchData.voice != '' && self.batchData.voice != null) {
        self.$message({
          message: '您当前无选择需要升级的模块，请先选择要升级的模块版本号!',
          type: 'error' });

        return false;
      }
      for (var i in self.sns) {
        var arr = {
          sn: self.sns[i],
          ctype: ctype,
          version: version };

        obj.push(arr);
      }
      console.log('批量升级:', obj);
      self.postData('/api/factoryFirmwareUpgrade', obj, function (res) {
        self.$message({
          message: '批量升级提交成功!',
          type: 'success' });

        self.onCencal2();
        self.searchData.page = 1;
        self.init();
      });
    } } };exports.default = _default;