"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.resetRouter = resetRouter;exports.default = exports.constantRoutes = void 0;var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));




var _layout = _interopRequireDefault(require("@/layout"));_vue.default.use(_vueRouter.default); /* Layout */

/**
                                                                                                              * Note: sub-menu only appear when route children.length >= 1
                                                                                                              * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
                                                                                                              *
                                                                                                              * hidden: true                   if set true, item will not show in the sidebar(default is false)
                                                                                                              * alwaysShow: true               if set true, will always show the root menu
                                                                                                              *                                if not set alwaysShow, when item has more than one children route,
                                                                                                              *                                it will becomes nested mode, otherwise not show the root menu
                                                                                                              * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
                                                                                                              * name:'router-name'             the name is used by <keep-alive> (must set!!!)
                                                                                                              * meta : {
                                                                                                                 roles: ['admin','editor']    control the page roles (you can set multiple roles)
                                                                                                                 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
                                                                                                                 icon: 'svg-name'             the icon show in the sidebar
                                                                                                                 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
                                                                                                                 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
                                                                                                               }
                                                                                                              */

/**
                                                                                                                  * constantRoutes
                                                                                                                  * a base page that does not have permission requirements
                                                                                                                  * all roles can be accessed
                                                                                                                  */
var constantRoutes = [
// 作用：重定向到指定页面  例子：调整字体大小 @/components/SizeSelect
{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [
  {
    path: '/redirect/:path*',
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));});} }] },



{
  path: '/login',
  component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));});},
  hidden: true },


{
  path: '/404',
  component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/404'));});},
  hidden: true },

{
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: '首页',
    component: function component() {return Promise.resolve().then(function () {return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));});},
    meta: { title: '首页', icon: 'dashboard' } }] }


// 404 page must be placed at the end !!!
// ,
// { path: '*', redirect: '/404', hidden: true }
];exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {return { y: 0 };},
    routes: constantRoutes });};


var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}var _default =

router;exports.default = _default;