"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/es6.regexp.replace");





































































































































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'coupon', data: function data() {return { tableData: [], // 列表数据
      mTableData: [], //优惠券码列表数据
      optionsList: [], platCodeList: [], couponNo: [], tempId: '', searchData: { name: '', type: '', status: '', couponNo: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, updateData: { tempId: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData2: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', checked: false, datalist: [], addData: { id: '', name: '', type: '', preferentialType: '', offerAmt: '', ratio: '', goalAmt: '', pkgNum: '', freeTime: '', amount: '', merCodes: [], startTime: '', endTime: '', validType: '', description: '', platCode: '', isAll: '0', validTime: '' }, defaultData: { id: '', name: '', type: '', preferentialType: '', offerAmt: '', ratio: '', goalAmt: '', pkgNum: '', freeTime: '', amount: '', merCodes: [], startTime: '', endTime: '', validType: '', description: '', platCode: '', isAll: '0', validTime: '' }, merIdsData: { // id: '',
        merIds: [] }, rules: { name: [{ required: true, message: '请输入券包名称', trigger: 'blur' }], platCode: [{ required: true, message: '请选择运营商', trigger: 'change' }], type: [{ required: true, message: '请选择券包类型', trigger: 'change' }], preferentialType: [{ required: true, message: '请选择优惠方式', trigger: 'change' }], goalAmt: [{ required: true, message: '请输入满减金额', trigger: 'blur' }], ratio: [{ required: true, message: '请输入折数', trigger: 'blur' }], pkgNum: [{ required: true, message: '请输入兑换第几档', trigger: 'blur' }], freeTime: [{ required: true, message: '请输入优惠分钟数', trigger: 'blur' }], amount: [{ required: true, message: '请输入生成数量', trigger: 'blur' }], merCodes: [{ required: true, message: '请选择渠道商', trigger: 'change' }], startTime: [{ required: true, message: '请选择有效时间', trigger: 'change' }], validTime: [{ required: true, message: '请输入有效天数', trigger: 'blur' }], validType: [{ required: true, message: '请选择有效方式', trigger: 'change' }] }, newadd: false, newadd2: false, newadd3: false, // 表格列
      tableColumns: [{ prop: 'brandName', label: '运营商', isShow: true }, // {
      // 	prop: 'merName',
      // 	label: '渠道商',
      // 	isShow: true
      // },
      { prop: 'type', label: '优惠类型', formatter: _formatter.activityTypeFormatter, isShow: true }, { prop: 'name', label: '券包名称', isShow: true }, { prop: 'amount', label: '数量', isShow: true }, // {
      // 	prop: 'ratio',
      // 	label: '优惠金额/折扣/分钟',
      // 	isShow: true
      // },
      { prop: 'validTime', label: '有效时间', isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: false }, { prop: 'usedAmount', label: '已使用数量', isShow: true }, { prop: 'unusedAmount', label: '未使用数量', isShow: true }, { prop: 'status', label: '状态', formatter: _formatter.activityStatusFormatter, isShow: true }], statusList: [{ name: '禁用', id: '0' }, { name: '启用', id: '1' }], typeList: [{ name: '优惠券', id: '1' }, { name: '兑换券', id: '2' }, { name: '分钟券', id: '3' }],
      preferentialTypeList: [
      { name: '优惠金额', id: '1' },
      { name: '折扣', id: '2' }],

      validTypeList: [
      { name: '全部', id: '1' },
      { name: '工作日', id: '2' },
      { name: '周末', id: '3' }],

      props: {
        children: 'subMers',
        label: 'name',
        value: 'code',
        checkStrictly: false, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover',
        multiple: true },

      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // return time.getTime() < Date.now() //禁止选择当前时间以前的时间
          return time.getTime() < new Date().getTime() - 86400000; //禁止选择今天以前的时间
        } } };


  },
  filters: {
    typeFilters: function typeFilters(val) {
      if (val == '1') {
        return '优惠券';
      } else if (val == '2') {
        return '兑换券';
      } else if (val == '3') {
        return '分钟券';
      } else {
        return val;
      }
    },
    usageStatusFilters: function usageStatusFilters(val) {
      if (val == '0') {
        return '未兑换';
      } else if (val == '1') {
        return '已使用';
      } else {
        return val;
      }
    },
    preferentialDiscountFilters: function preferentialDiscountFilters(val) {
      if (val.offerAmt != null) {
        return val.offerAmt;
      } else if (val.ratio != null) {
        return val.ratio;
      } else if (val.freeTime != null) {
        return val.freeTime;
      }
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
    self.getDeviceTypeList();
    self.getPlatCodeList();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/pkg/coupon/template', self.searchData, function (res) {
        self.tableData = res.list;
        self.pageData.page = res.pageNum;
        self.pageData.total = res.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.name = '';
      this.searchData.type = '';
      this.searchData.status = '';
      this.searchData.couponNo = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      // 分页条数
      this.updateData.rows = val;
      this.updateData.page = 1;
      this.init2();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      // 分页页码
      this.updateData.page = val;
      this.init2();
    },
    addFun: function addFun() {
      // 添加活动
      this.title = '添加优惠券';
      this.addData = JSON.parse(JSON.stringify(this.defaultData));
      this.newadd = true;
    },
    getPlatCodeList: function getPlatCodeList() {
      var self = this;
      self.getData('/api/payment/brand', {}, function (res) {
        self.platCodeList = res;
      });
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
      this.newadd = false;
      this.checked = false;
      this.datalist = [];
    },
    closeRefund2: function closeRefund2(done) {
      // 重置新增列表
      // this.$refs['table'].resetFields();
      done();
      this.tempId = '';
      this.newadd2 = false;
    },
    closeRefund3: function closeRefund3(done) {
      // 重置新增列表
      this.$refs['addForm2'].resetFields();
      this.checked = false;
      this.datalist = [];
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.checked = false;
      this.datalist = [];
      this.$refs['addForm'].resetFields();
    },
    onCencal2: function onCencal2() {
      // 详情弹窗关闭
      this.newadd2 = false;
      // this.$refs['table'].resetFields();
    },
    onCencal3: function onCencal3() {
      // 详情弹窗关闭
      this.newadd3 = false;
      this.checked = false;
      this.datalist = [];
      this.$refs['addForm2'].resetFields();
    },
    // getDeviceTypeList: function() {
    // 	const self = this;
    // 	self.getData('/api/merMerchant/tree', {}, function(res) {
    // 		console.log('res:', res);
    // 		self.optionsList = res;
    // 	});
    // },
    getDeviceTypeList: function getDeviceTypeList() {
      var self = this;
      self.getData('/api/merMerchant/primary/list', {}, function (res) {
        console.log('res:', res);
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.addData.merCodes = val;
    },
    validTimeChange: function validTimeChange() {
      var self = this;
      console.log('self.addData.validTime:', self.addData.validTime);
      console.log('self.addData.startTime:', self.addData.startTime);
      console.log('self.addData.endTime:', self.addData.endTime);
      if (self.addData.startTime != '') {
        console.log('self.addData.validTime:', self.addData.validTime);
        var time = self.addData.validTime * 86400000;
        self.addData.endTime = self.addData.startTime + time;
      }
    },
    validTimeChangeStart: function validTimeChangeStart() {
      var self = this;
      if (self.addData.validTime != '' && self.addData.endTime != null) {
        var time = self.addData.validTime * 86400000;
        self.addData.endTime = self.addData.startTime + time;
      }
    },
    validTimeChangeEnd: function validTimeChangeEnd() {
      var self = this;
      if (self.addData.endTime != '' && self.addData.endTime != null) {
        self.addData.validTime = (self.addData.endTime - self.addData.startTime) / 86400000;
      }
      console.log('endTime:', self.addData.endTime);
    },
    onSubmit: function onSubmit() {var _this = this;
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        console.log('this.addData.startTime:', _this.addData.startTime);
        console.log('this.addData.endTime:', _this.addData.endTime);
        console.log('this.addData.validTime:', _this.addData.validTime);
        var start = new Date(_this.addData.startTime);
        var deliverTimeStart = start.getTime();
        var end = new Date(_this.addData.endTime);
        var deliverTimeEnd = end.getTime();
        var now = new Date().getTime();
        if (deliverTimeStart >= deliverTimeEnd) {
          _this.$message({
            message: '请选择结束时间要大于开始时间',
            type: 'error' });

          _this.addData.endTime = '';
          return false;
        }
        // else if(deliverTimeStart < now) {
        // 	this.$message({
        // 		message: '请选择开始时间要大于当前系统时间',
        // 		type: 'error'
        // 	});
        // 	this.addData.startTime = '';
        // 	return false;
        // }else if(deliverTimeEnd < now) {
        // 	this.$message({
        // 		message: '请选择结束时间要大于当前系统时间',
        // 		type: 'error'
        // 	});
        // 	this.addData.endTime = '';
        // 	return false;
        // }
        if (self.addData.endTime == '') {
          _this.$message({
            message: '结束时间不能为空，请选择结束时间！',
            type: 'error' });

          _this.addData.endTime = '';
          return false;
        }
        if (self.addData.type == '1') {
          self.addData.pkgNum = '';
          self.addData.freeTime = '';
          if (self.addData.preferentialType == '1') {
            self.addData.ratio = '';
          } else {
            self.addData.offerAmt = '';
          }
        } else if (self.addData.type == '2') {
          self.addData.preferentialType = '';
          self.addData.goalAmt = '';
          self.addData.offerAmt = '';
          self.addData.goalAmt = '';
          self.addData.freeTime = '';
        } else if (self.addData.type == '3') {
          self.addData.preferentialType = '';
          self.addData.goalAmt = '';
          self.addData.offerAmt = '';
          self.addData.goalAmt = '';
          self.addData.pkgNum = '';
        }
        if (self.addData.preferentialType == '1') {
          self.addData.goalAmt = parseFloat(self.addData.goalAmt);
          self.addData.offerAmt = parseFloat(self.addData.offerAmt);
          if (self.addData.goalAmt <= self.addData.offerAmt) {
            _this.$message({
              message: '满减金额要大于被减金额！',
              type: 'error' });

            _this.addData.offerAmt = '';
            return false;
          }
        }
        if (valid) {
          self.postData('/api/pkg/coupon/template', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateSubmit: function updateSubmit() {var _this2 = this;
      // 详情弹窗提交
      var self = this;
      self.$refs.addForm.validate(function (valid) {
        console.log('this.addData.startTime:', _this2.addData.startTime);
        console.log('this.addData.endTime:', _this2.addData.endTime);
        console.log('this.addData.validTime:', _this2.addData.validTime);
        var start = new Date(_this2.addData.startTime);
        var deliverTimeStart = start.getTime();
        var end = new Date(_this2.addData.endTime);
        var deliverTimeEnd = end.getTime();
        var now = new Date().getTime();
        if (deliverTimeStart >= deliverTimeEnd) {
          _this2.$message({
            message: '请选择结束时间要大于开始时间',
            type: 'error' });

          _this2.addData.endTime = '';
          return false;
        }
        if (self.addData.endTime == '') {
          _this2.$message({
            message: '结束时间不能为空，请选择结束时间！',
            type: 'error' });

          _this2.addData.endTime = '';
          return false;
        }
        if (self.addData.type == '1') {
          self.addData.pkgNum = '';
          self.addData.freeTime = '';
          if (self.addData.preferentialType == '1') {
            self.addData.ratio = '';
          } else {
            self.addData.offerAmt = '';
          }
        } else if (self.addData.type == '2') {
          self.addData.preferentialType = '';
          self.addData.goalAmt = '';
          self.addData.offerAmt = '';
          self.addData.goalAmt = '';
          self.addData.freeTime = '';
        } else if (self.addData.type == '3') {
          self.addData.preferentialType = '';
          self.addData.goalAmt = '';
          self.addData.offerAmt = '';
          self.addData.goalAmt = '';
          self.addData.pkgNum = '';
        }
        if (self.addData.preferentialType == '1') {
          self.addData.goalAmt = parseFloat(self.addData.goalAmt);
          self.addData.offerAmt = parseFloat(self.addData.offerAmt);
          if (self.addData.goalAmt <= self.addData.offerAmt) {
            _this2.$message({
              message: '满减金额要大于被减金额！',
              type: 'error' });

            _this2.addData.offerAmt = '';
            return false;
          }
        }
        var params = Object.assign({}, self.addData);
        params.validTime = params.validTime.replace('天', '');
        if (valid) {
          self.putData('/api/pkg/coupon/template', params, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    init2: function init2() {
      var self = this;
      self.getData('/api/pkg/coupon', self.updateData, function (res) {
        self.mTableData = res.list;
        self.pageData2.page = res.pageNum;
        self.pageData2.total = res.total;
      });
    },
    updateItem: function updateItem(item) {
      // 修改弹窗
      var self = this;
      self.title = '查看';
      self.updateData.tempId = item.id;
      self.init2();
      self.newadd2 = true;
    },
    updateMenu: function updateMenu(item) {
      console.log(item);
      // 修改弹窗
      var self = this;
      self.title = '编辑';
      self.getData('/api/pkg/coupon/template/one', {
        id: item.id },
      function (res) {
        console.log('res:', res);
        self.addData = {
          id: res.id,
          name: res.name,
          platCode: res.platCode,
          type: res.type + '',
          preferentialType: res.preferentialType + '',
          ratio: res.ratio,
          goalAmt: res.goalAmt,
          offerAmt: res.offerAmt,
          pkgNum: res.pkgNum,
          freeTime: res.freeTime,
          amount: res.amount,
          merCodes: [],
          startTime: res.startTime,
          endTime: res.endTime,
          validType: res.validType + '',
          isAll: res.isAll,
          description: res.description,
          validTime: res.validTime };

        self.partakeMenu(item.id, '2');
      });
      self.newadd = true;
    },
    enableMenu: function enableMenu(item) {
      var self = this;
      self.getData('/api/pkg/coupon/template/status', {
        status: '1',
        tempId: item.id },
      function (res) {
        self.$message({
          message: '已启用!',
          type: 'success' });

        self.init();
      });
    },
    disableMenu: function disableMenu(item) {
      var self = this;
      self.getData('/api/pkg/coupon/template/status', {
        status: '0',
        tempId: item.id },
      function (res) {
        self.$message({
          message: '已禁用!',
          type: 'success' });

        self.init();
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该优惠券包吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/pkg/coupon/template', {
          id: delId },
        function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      var self = this;
      self.couponNo = [];
      val.map(function (item) {
        self.couponNo.push(item.couponNo);
      });
    },
    excelFun: function excelFun() {
      var self = this;
      self.tempId = self.updateData.tempId;
      window.location.href = '/api/pkg/coupon/export?tempId=' + self.tempId + '&couponNo=' + self.couponNo;
    },
    checkedFun: function checkedFun(item) {
      var self = this;
      if (item == true) {
        self.doObj(self.optionsList);
        self.addData.merCodes = self.datalist;
        self.addData.isAll = '1';
      } else if (item == false) {
        self.addData.merCodes = [];
        self.datalist = [];
        self.addData.isAll = '0';
      }
    },
    doObj: function doObj(datas) {
      var self = this;
      for (var i in datas) {
        if (datas[i].subMers && datas[i].subMers.length > 0) {
          self.doObj(datas[i].subMers);
        }
        self.datalist.push(datas[i].code);
      }
    },
    partakeMenu: function partakeMenu(item, index) {
      var self = this;
      self.getData('/api/pkg/coupon/template/merCodeList', {
        tempId: item },
      function (res) {
        console.log('res:', res);
        if (index == '1') {
          self.merIdsData = {
            merIds: res };

          self.title = '参与渠道商';
          self.newadd3 = true;
        } else if (index == '2') {
          self.addData.merCodes = res;
        }
      });
    } } };exports.default = _default;