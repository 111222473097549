"use strict";module.exports = {
  title: '摩享靠垫',

  /**
                  * @type {boolean} true | false
                  * @description Whether show the settings right-panel
                  */
  showSettings: false,

  /**
                        * @type {boolean} true | false
                        * @description Whether need tagsView
                        */
  tagsView: true,

  /**
                   * @type {boolean} true | false
                   * @description Whether fix the header
                   */
  fixedHeader: true,

  /**
                      * @type {boolean} true | false
                      * @description Whether show the logo in sidebar
                      */
  sidebarLogo: true,

  /**
                      * @type {string | array} 'production' | ['production', 'development']
                      * @description Need show err logs component.
                      * The default is only used in the production env
                      * If you want to also use it in dev, you can pass ['production', 'development']
                      */
  errorLog: 'development'
  // errorLog: ['production', 'development']
};