"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));require("core-js/modules/web.dom.iterable");
































































var _mechanism = _interopRequireDefault(require("@/components/Cascader/mechanism.vue")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { name: 'rolefplist', components: { Mechanism: _mechanism.default }, data: function data() {return { tableData: [], // 菜单列表数据
      tableData2: [], // 组织列表数据
      resData: [], roleList: [], twolevel: [], roleId: '', activeName: 0, listData: [], onelist: [], onelist2: [], twolist: [], threelist: [], optionsList: [], props: { children: 'child', label: 'insName', value: 'id', checkStrictly: true, // 是否严格的遵守父子节点不互相关联
        emitPath: false, // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        expandTrigger: 'hover' }, searchData: { insId: '', roleName: '', status: 1, page: 1, // 当前页码
        rows: 10 // 页码条数
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, defaultProps: { children: 'child', label: 'menuName' }, defaultProps2: { children: 'child', label: 'insName' }, i: 0, newArr: [] };}, mounted: function mounted() {var self = this;self.init();self.getMenu();self.getMenu2();self.getAddress();}, methods: { init: function init() {// 角色列表
      var self = this;self.getData('/api/role/page', self.searchData, function (res) {self.roleList = res.list;self.pageData.page = res.pageNum;self.pageData.pages = res.pages;self.pageData.total = res.total;self.treeList(self.roleList[0].id);});},
    resetFun: function resetFun() {
      // 重置
      this.searchData.insId = '';
      this.searchData.roleName = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },
    getMenu: function getMenu() {// 菜单列表
      var self = this;
      self.getData('/api/menu/list', {}, function (res) {
        self.tableData = res;
        self.getParentIds(self.tableData, self);
      });
    },
    getMenu2: function getMenu2() {// 组织列表
      var self = this;
      self.getData('/api/institution/list', {}, function (res) {
        self.tableData2 = res;
        self.getParentIds2(self.tableData2, self);
      });
    },
    getParentIds: function getParentIds(dataList, self) {
      for (var index in dataList) {
        if (dataList[index].child && dataList[index].child.length > 0) {
          self.onelist.push(dataList[index].id);
          self.getParentIds(dataList[index].child, self);
        }
      }
    },
    getParentIds2: function getParentIds2(dataList, self) {
      for (var index in dataList) {
        if (dataList[index].child && dataList[index].child.length > 0) {
          self.onelist2.push(dataList[index].id);
          self.getParentIds2(dataList[index].child, self);
        }
      }
    },

    treeList: function treeList(roleId) {// 获取角色菜单id
      var self = this;
      self.roleId = roleId;
      console.log('self.tableData:', self.tableData);
      self.getData('/api/rolePri/' + roleId, {}, function (res) {
        var n = []; // 一个新的临时数组
        var m = [];
        var menuId = [];
        menuId = res.menuIds;
        var insIds = res.insIds;
        // 遍历当前数组
        for (var i = 0; i < menuId.length; i++) {
          n.push(menuId[i]);
          // if (self.onelist.indexOf(menuId[i]) === -1) n.push(menuId[i])
        }
        console.log('n:', n);
        console.log('menuId:', menuId);
        for (var j = 0; j < insIds.length; j++) {
          m.push(insIds[j]);
          // if (self.onelist2.indexOf(insIds[j]) === -1) m.push(insIds[j])
        }
        // 重新选中
        console.info('重新选中');
        if (menuId && menuId.length > 0) {
          menuId.forEach(function (item) {
            self.$refs.tree.setChecked(item, true, false);
          });
        }
        console.info('获取重新选中的半选');
        var t = [];
        t = self.$refs.tree.getCheckedKeys(true);
        console.info('半选值为:' + t);

        console.info('清空重新选中');
        self.$refs.tree.setCheckedNodes([]);

        console.info('设置最终选中');
        self.$refs.tree.setCheckedKeys(t);
        var aa = [].concat((0, _toConsumableArray2.default)(self.$refs.tree.getCheckedKeys(false)), (0, _toConsumableArray2.default)(self.$refs.tree.getHalfCheckedKeys()));
        console.info('最终选中：', aa);
        self.$refs.tree2.setCheckedKeys(m);
      });
    },

    onSubmit: function onSubmit(menu) {// 保存
      var menuIds = this.$refs.tree.getCheckedKeys();
      var HalfChecked = this.$refs.tree.getHalfCheckedKeys();
      menuIds.push.apply(menuIds, HalfChecked);
      var insIds = this.$refs.tree2.getCheckedKeys();
      var self = this;
      self.$confirm('确定保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).
      then(function () {
        //					self.postData('/api/roleMenu', {
        self.postData('/api/rolePri', {
          roleId: self.roleId,
          menuIds: menuIds,
          insIds: insIds },
        function (res) {
          self.$message({
            message: '修改成功!',
            type: 'success' });

        });
      }).catch(function () {});
    },

    changeScen: function changeScen(index, item) {// 切换角色
      this.activeName = index;
      this.treeList(item.id);
      this.$refs.tree.setCheckedNodes([]);
    },
    prevScen: function prevScen() {// 场景上一页
      this.searchData.page -= 1;
      this.init();
    },
    nextScen: function nextScen() {// 场景下一页
      this.searchData.page += 1;
      this.init();
    },
    getAddress: function getAddress() {
      var self = this;
      self.getData('/api/institution/list', {}, function (res) {
        self.optionsList = res;
      });
    },
    changeMechanism: function changeMechanism(val) {
      this.searchData.insId = val;
    } } };exports.default = _default;