"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;








var _validate = require("@/utils/validate"); //
//
//
//
//
//
//
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
var _default = { name: 'SvgIcon', props: { iconClass: { type: String, required: true },
    className: {
      type: String,
      default: '' } },


  computed: {
    isExternal: function isExternal() {
      return (0, _validate.isExternal)(this.iconClass);
    },
    iconName: function iconName() {
      return "#icon-".concat(this.iconClass);
    },
    svgClass: function svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
    styleExternalIcon: function styleExternalIcon() {
      return {
        mask: "url(".concat(this.iconClass, ") no-repeat 50% 50%"),
        '-webkit-mask': "url(".concat(this.iconClass, ") no-repeat 50% 50%") };

    } } };exports.default = _default;