"use strict";var _interopRequireDefault = require("/Users/zhixin/Documents/ogawa/svn/\u6469\u4EAB\u9760\u57AB/\u540E\u53F0/trunk/node_modules/@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;




























var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker")); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = { components: { ThemePicker: _ThemePicker.default }, data: function data() {return {};}, computed: { isShowJob: function isShowJob() {return this.$store.getters.language === 'zh';}, fixedHeader: { get: function get() {return this.$store.state.settings.fixedHeader;}, set: function set(val) {this.$store.dispatch('settings/changeSetting', { key: 'fixedHeader', value: val });} }, tagsView: { get: function get() {return this.$store.state.settings.tagsView;}, set: function set(val) {this.$store.dispatch('settings/changeSetting', { key: 'tagsView',
          value: val });

      } },

    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val });

      } } },


  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val });

    } } };exports.default = _default;