"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0;require("core-js/modules/es6.function.name");require("core-js/modules/web.dom.iterable");

























































































































































var _user = require("@/utils/user");
var _formatter = require("@/utils/formatter"); //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数 注意vue页面中import的函数需要在methods注册才可生效
var _default = { name: 'factory', data: function data() {return { tableData: [], // 列表数据
      searchData: { name: '', program: '', page: 1, // 当前页码
        rows: 10 // 页码条
      }, pageData: { page: 1, rows: 10, total: 0, pages: 1 }, title: '', addData: { id: '', name: '', ctype: '', program: '', time: '1', price: '', orderNum: '', angleMark: '', remarks: '', tempType: '0', recommend: '0' }, defaultData: { id: '', name: '', ctype: '', program: '', time: '1', price: '', orderNum: '', angleMark: '', remarks: '', tempType: '0', recommend: '0' }, rules: { name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }], price: [{ required: true, message: '请输入价格', trigger: 'blur' }], orderNum: [{ required: true, message: '请输入档次', trigger: 'blur' }] }, newadd: false, // 表格列
      tableColumns: [{ prop: 'packageType', label: '套餐类型', isShow: true }, { prop: 'name', label: '套餐名称', isShow: true }, { prop: 'price', label: '金额', isShow: true }, { prop: 'time', label: '时长', isShow: true }, { prop: 'orderNum', label: '档次', isShow: true }, { prop: 'orderNum', label: '套餐顺序', isShow: true }, { prop: 'createTime', label: '创建时间', formatter: _formatter.dateFormatter, isShow: false }], programList: [{ name: '肩部按摩', id: '0' }, { name: '腰部按摩', id: '1' }, { name: '全身按摩', id: '2' }, { name: '定时按摩', id: '3' }, { name: '充电按摩', id: '4' }], ctypeList: [{ name: '单个', id: '1' }, { name: '多个', id: '2' }], tuijianZt: 0, // 推荐状态
      packageTypeList: [{ label: '普通套餐', id: 1, enable: true }, { label: '特殊套餐', id: 2, enable: true }], newAdd4: false, newAdd5: false, newadd6: false, packageType: 0, //当前选择的套餐类型
      specialForm: { ctype: 1, program: 1, name: '', time: '', angleMark: '', recommend: '0', price: '', orderNum: '', comboType: 1, specialType: 0, tempType: '0' }, defSpecialForm: { ctype: 1, program: 1, name: '', time: '', angleMark: '', recommend: '0', price: '', orderNum: '', comboType: 1, specialType: 0, tempType: '0' },

      specialRules: {
        name: [
        {
          required: true,
          message: '请输入套餐名称',
          trigger: 'blur' }],


        price: [
        {
          required: true,
          message: '请输入价格',
          trigger: 'blur' }],


        time: [
        {
          required: true,
          message: '请选择套餐时间',
          trigger: 'blur' }],


        specialType: [
        {
          required: true,
          message: '请选择套餐模式',
          trigger: 'blur' }] } };




  },
  computed: {
    // 是否推荐
    tuijian: function tuijian() {
      var tj = false;
      var arr = [];
      for (var i in this.tableData) {
        arr.push(parseInt(this.tableData[i].recommend));
      }
      console.log('arr:', arr);
      if (arr.indexOf(1) == -1) {
        this.tuijianZt = 0;
        return tj = false;
      } else {
        this.tuijianZt = this.tableData[arr.indexOf(1)].id;
        return tj = true;
      }
    },
    onlyCommon: function onlyCommon() {
      //是否已有双人及充电套餐
      return this.hasDouble && this.hasCharge;
    },
    hasDouble: function hasDouble() {
      //是否已有双人套餐
      var has = false;
      this.tableData.forEach(function (e) {
        if (e.comboType == 1 && e.specialType == 1) {
          has = true;
        }
      });
      return has;
    },
    hasCharge: function hasCharge() {
      //是否已有充电套餐
      var has = false;
      this.tableData.forEach(function (e) {
        if (e.comboType == 1 && e.specialType == 2) {
          has = true;
        }
      });
      return has;
    } },

  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/merTemplateDetail/0/page', self.searchData, function (res) {
        self.tableData = res.pageResult.list;
        self.pageData.page = res.pageResult.pageNum;
        self.pageData.total = res.pageResult.total;
      });
    },
    checkPermission: _user.checkPermission,
    resetFun: function resetFun() {
      // 重置
      this.searchData.name = '';
      this.searchData.program = '';
      this.init();
    },
    searchFun: function searchFun() {
      // 搜索事件
      this.searchData.page = 1;
      this.init();
    },

    handleSizeChange: function handleSizeChange(val) {
      // 分页条数
      this.searchData.rows = val;
      this.searchData.page = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // 分页页码
      this.searchData.page = val;
      this.init();
    },
    addFun: function addFun() {
      // 新增套餐
      if (this.onlyCommon) {
        this.title = '新增套餐';
        this.addData = JSON.parse(JSON.stringify(this.defaultData));
        this.newadd = true;
      } else {
        this.newAdd4 = true;
      }
    },
    closeRefund: function closeRefund(done) {
      // 重置新增列表
      this.$refs['addForm'].resetFields();
      done();
    },
    onCencal: function onCencal() {
      // 详情弹窗关闭
      this.newadd = false;
      this.$refs['addForm'].resetFields();
    },
    onSubmit: function onSubmit() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.orderNum) {
        if (!/(^[0-9]\d*$)/.test(self.addData.orderNum)) {
          self.$message({
            showClose: true,
            message: '档次有误，请重填',
            type: 'error' });

          self.addData.orderNum = '';
          return false;
        }
      }
      if (self.addData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，请重填',
            type: 'error' });

          self.addData.price = '';
          return false;
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.postData('/api/merTemplateDetail', self.addData, function (res) {
            self.$message({
              message: '添加成功!',
              type: 'success' });

            self.onCencal();
            self.searchData.page = 1;
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    updateItem: function updateItem(item, e) {var _this = this;
      console.log(item);
      console.log('e:', e);
      if (parseInt(item.comboType) == 1) {
        //特殊套餐
        this.specialForm = {
          id: item.id,
          ctype: 1,
          program: 1,
          name: item.name,
          time: item.time,
          recommend: item.recommend + '',
          price: item.price,
          orderNum: item.orderNum,
          comboType: item.comboType,
          specialType: item.specialType + '',
          tempType: item.tempType };

        this.$set(this.specialForm, 'angleMark', item.angleMark);
        this.$forceUpdate();
        console.log('specialForm===>', JSON.stringify(this.specialForm));
        this.$nextTick(function () {
          _this.newAdd5 = true;
        });
      } else {
        //普通套餐
        // 修改弹窗
        var self = this;
        self.title = '编辑';
        self.newadd = true;
        self.addData = {
          id: item.id,
          name: item.name,
          ctype: item.ctype + '',
          program: item.program + '',
          time: item.time,
          price: item.price,
          orderNum: item.orderNum,
          angleMark: item.angleMark,
          remarks: item.remarks,
          tempType: '0',
          recommend: item.recommend + '',
          comboType: item.comboType,
          specialType: item.specialType };

        if (self.addData.recommend == '1') {
          self.addData.angleMark = '';
        }
      }
    },
    onUpdate: function onUpdate() {
      // 详情弹窗提交
      var self = this;
      if (self.addData.orderNum) {
        if (!/(^[0-9]\d*$)/.test(self.addData.orderNum)) {
          self.$message({
            showClose: true,
            message: '档次有误，请重填',
            type: 'error' });

          self.addData.orderNum = '';
          return false;
        }
      }
      if (self.addData.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(self.addData.price)) {
          self.$message({
            showClose: true,
            message: '价格有误，请重填',
            type: 'error' });

          self.addData.price = '';
          return false;
        }
      }
      self.$refs.addForm.validate(function (valid) {
        if (valid) {
          self.putData('/api/merTemplateDetail/' + self.addData.id, self.addData, function (res) {
            self.$message({
              message: '修改成功!',
              type: 'success' });

            self.onCencal();
            self.init();
          });
        } else {
          return false;
        }
      });
    },
    delMenu: function delMenu(delId) {
      // 删除
      var self = this;
      self.$confirm('确定删除该记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true }).

      then(function () {
        self.delData('/api/merTemplateDetail/' + delId, {}, function (res) {
          self.$message({
            message: '删除成功!',
            type: 'success' });

          self.init();
        });
      }).
      catch(function () {});
    },
    // 推荐操作
    tuijianFun: function tuijianFun(i, e) {
      console.log(i);
      console.log(e);
      if (e == false) {
        if (i == '1') {
          this.addData.angleMark = '';
        }
      } else {
        if (i == '1') {
          this.addData.angleMark = '';
        }
      }
    }, closeRefund4: function closeRefund4(done) {
      this.newAdd4 = false;
      // done()
    },
    closeRefund5: function closeRefund5(done) {
      this.newAdd5 = false;
    },
    closeRefund6: function closeRefund6(done) {
      this.newadd6 = false;
    },
    onSpecialSubmit: function onSpecialSubmit() {
      if (this.specialForm.price) {
        if (!/^([1-9][\d]{0,6}|0)(\.[\d]{1,2})?$/.test(this.specialForm.price)) {
          this.$message({
            showClose: true,
            message: '价格有误，最多保留两位小数，请重填',
            type: 'error' });

          this.specialForm.price = '';
          return false;
        }
      }
      this.specialForm.tempId = this.searchData.tempId;
      this.specialForm.tempType = 0;
      var that = this;
      that.$refs.special.validate(function (valid) {
        if (valid) {
          console.log('form====>', JSON.stringify(that.specialForm));
          if (that.specialForm.id > 0) {
            //编辑
            that.putData('/api/merTemplateDetail/' + that.specialForm.id, Object.assign({}, that.specialForm), function (res) {
              that.$message({
                message: '保存成功!',
                type: 'success' });

              that.newAdd5 = false;
              that.searchData.page = 1;
              that.init();
            });
          } else {
            //新增
            that.postData('/api/merTemplateDetail', Object.assign({}, that.specialForm), function (res) {
              that.$message({
                message: '添加成功!',
                type: 'success' });

              that.newAdd5 = false;
              that.searchData.page = 1;
              that.init();
            });
          }

        }
      });
    },
    onSpecialCancel: function onSpecialCancel() {
      this.newAdd5 = false;
    },
    onSpecialDialogClose: function onSpecialDialogClose() {var _this2 = this;
      this.$nextTick(function () {
        _this2.specialForm = _this2.defSpecialForm;
      });
    },
    onConfirmType: function onConfirmType() {
      if (!this.packageType) {
        this.$message({
          showClose: true,
          message: '请先选择套餐类型',
          type: 'error' });

        return;
      }
      this.newAdd4 = false;
      if (this.packageType == '普通按摩套餐') {
        this.title = '新增套餐';
        this.addData = JSON.parse(JSON.stringify(this.defaultData));
        this.newadd = true;
      } else {
        if (this.hasCharge && !this.hasDouble) {
          //含有充电套餐，无双人套餐，套餐模式默认选中双人套餐
          this.$set(this.specialForm, 'specialType', '1');
        }
        if (this.hasDouble && !this.hasCharge) {
          //含有双人套餐，套餐模式默认选中充电套餐
          this.$set(this.specialForm, 'specialType', '2');
        }
        this.newAdd5 = true;
      }
    } } };exports.default = _default;