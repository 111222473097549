var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入产品型号",
              clearable: ""
            },
            model: {
              value: _vm.searchData.typeCode,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "typeCode", $$v)
              },
              expression: "searchData.typeCode"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              type: "text",
              placeholder: "请输入设备序列号编码",
              clearable: ""
            },
            model: {
              value: _vm.searchData.sn,
              callback: function($$v) {
                _vm.$set(_vm.searchData, "sn", $$v)
              },
              expression: "searchData.sn"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchFun }
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "warning", icon: "el-icon-refresh" },
              on: { click: _vm.resetFun }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addFun }
            },
            [_vm._v("生成二维码")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { staticClass: "mb10" },
        [
          _c("el-button", { attrs: { type: "primary", scoped: "" } }, [
            _vm._v("\n\t\t\t显示/隐藏列\n\t\t\t"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.tableColumns, function(column, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  nativeOn: {
                    click: function($event) {
                      return _vm.showHide(column.isShow)
                    }
                  }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: column.isShow,
                        callback: function($$v) {
                          _vm.$set(column, "isShow", $$v)
                        },
                        expression: "column.isShow"
                      }
                    },
                    [_vm._v(_vm._s(column.label))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "cell-style": _vm.isCenter,
            "header-cell-style": _vm.isCenter,
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _vm._v(" "),
          _vm._l(_vm.tableColumns, function(column, index) {
            return [
              column.isShow
                ? _c("el-table-column", {
                    key: index,
                    attrs: {
                      prop: column.prop,
                      label: column.label,
                      formatter: column.formatter,
                      width: column.width,
                      sortable: column.sortable
                    }
                  })
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "生成数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "colBlue",
                        attrs: { title: "生成数量" },
                        on: {
                          click: function($event) {
                            return _vm.countFun(scope.row.id)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.count))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "310", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.downLoadItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("下载")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.delMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0"
              }
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
              "page-size": _vm.pageData.rows,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "690px",
            visible: _vm.newadd,
            "before-close": _vm.closeRefund,
            center: "",
            "close-on-click-modal": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              staticClass: "mark",
              attrs: {
                model: _vm.addData,
                rules: _vm.rules,
                "label-width": "110px",
                size: "small"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品型号", prop: "typeCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.addData.typeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.addData, "typeCode", $$v)
                                },
                                expression: "addData.typeCode"
                              }
                            },
                            _vm._l(_vm.typeCodeList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设备组织编码",
                            prop: "insDeviceCode"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              maxlength: "15",
                              placeholder: "请输入",
                              onkeyup: "value=value.replace(/[^\\w]/g,'')"
                            },
                            model: {
                              value: _vm.addData.insDeviceCode,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "insDeviceCode", $$v)
                              },
                              expression: "addData.insDeviceCode"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "模块版本", prop: "modelVersion" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.addData.modelVersion,
                                callback: function($$v) {
                                  _vm.$set(_vm.addData, "modelVersion", $$v)
                                },
                                expression: "addData.modelVersion"
                              }
                            },
                            _vm._l(_vm.modelVersionList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产厂", prop: "factory" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.addData.factory,
                                callback: function($$v) {
                                  _vm.$set(_vm.addData, "factory", $$v)
                                },
                                expression: "addData.factory"
                              }
                            },
                            _vm._l(_vm.factoryList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生成数量", prop: "count" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              maxlength: "4",
                              placeholder: "请输入",
                              onkeyup: "value=value.replace(/[^\\d]/g,'')"
                            },
                            model: {
                              value: _vm.addData.count,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "count", $$v)
                              },
                              expression: "addData.count"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "年月日", prop: "createDate" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "200px" },
                            attrs: {
                              type: "date",
                              format: "yyMMdd",
                              "value-format": "yyMMdd",
                              placeholder: "选择日期时间"
                            },
                            model: {
                              value: _vm.addData.createDate,
                              callback: function($$v) {
                                _vm.$set(_vm.addData, "createDate", $$v)
                              },
                              expression: "addData.createDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "生成二维码"
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("生成二维码")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onCencal } }, [
                        _vm._v("取消")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            width: "800px",
            visible: _vm.newadd2,
            "before-close": _vm.closeRefund2,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.newadd2 = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.qrData,
                "tooltip-effect": "dark",
                border: "",
                stripe: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sn", label: "序列号编码" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "生成时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm._f("dateFilter")(scope.row.createTime)) +
                            "\n\t\t\t\t"
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "二维码图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              title: "查看",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.lookView(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageData.total > 0,
                    expression: "pageData.total > 0"
                  }
                ],
                attrs: {
                  "current-page": _vm.pageData.page,
                  "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
                  "page-size": _vm.pageData.rows,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageData.total
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "dialog2" } }, [
        _c("div", { attrs: { id: "dia_box" } }, [
          _c("div", { attrs: { id: "qrcode" } }),
          _vm._v(" "),
          _c("div", { staticClass: "imgName" }, [_vm._v(_vm._s(_vm.imgName))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnBox" },
            [
              _c("el-button", { on: { click: _vm.onCencal2 } }, [
                _vm._v("关闭")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadQrcode }
                },
                [_vm._v("下载")]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }