"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports.default = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default =
{
  data: function data() {
    return {
      tableData: [] // 列数据
    };
  },

  mounted: function mounted() {
    var self = this;
    self.init();
  },
  methods: {
    init: function init() {
      var self = this;
      self.getData('/api/merNetworkRank/status/' + self.$route.params.parentId, {}, function (res) {
        for (var key in res) {
          var json = {};
          json['num'] = key;
          json['tab'] = res[key];
          self.tableData.push(json);
        }
      });
    },
    returnFun: function returnFun() {
      // 返回上一级
      this.$router.push('/channel/network');
    } } };exports.default = _default;